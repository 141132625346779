import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    HolidayDto, HolidayPaginator,
    CreateHolidayDto,
    UpdateHolidayDto,
    QueryHolidayDto,
    SearchHolidayDto
} from "../models/holiday.dto";
import ENDPOINTS from "./utils/endpoints";
import {QueryOptionsDto} from "../models/generic.dto";

export function useGetHoliday(options?: QueryHolidayDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.HOLIDAY.GET;
    const request = (options: QueryHolidayDto) => API.ProcessingCompanyAPI.getHoliday(options);

    const { data, isLoading, error, refetch } = useQuery<HolidayPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch }
}

export function useGetHolidaySearch() {

    return useMutation(
        (options?: SearchHolidayDto) => API.ProcessingCompanyAPI.getHolidaySearch(options),
        {
            onSuccess: (response,) => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useGetHolidayById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.HOLIDAY.DETAIL;
    const request = API.ProcessingCompanyAPI.getHolidayById(id);

    const {data, isLoading, error} = useQuery<{ data:HolidayDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}

export function useGetHolidayByCode(code: string) {
    const url = ENDPOINTS.PROCESSING_COMPANY.HOLIDAY.GETBYCODE;
    const request = API.ProcessingCompanyAPI.getHolidayByCode(code);

    const {data, isLoading, error} = useQuery<{ data:HolidayDto }, Error>(
        [url, code],
        (() => request),
        {
            enabled: !!(code),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}

export function useCreateHoliday() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateHolidayDto}) => API.ProcessingCompanyAPI.createHoliday(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.HOLIDAY.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateHoliday() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateHolidayDto, id: number}) => API.ProcessingCompanyAPI.updateHoliday(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.HOLIDAY.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useDeleteHoliday() {

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteHoliday(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}