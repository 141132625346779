import React from "react";
import { Popover } from "@mui/material";
import { BsPencil, BsTrash } from "react-icons/bs";

type IActionPopover = {
	id: string | undefined;
	open: boolean;
	anchorEl: HTMLButtonElement | null;
	handleClose: () => void;
	setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
	setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
	setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
	additionalOptions?: React.ReactNode;
	doNotShowDeleteButton?: boolean;
}

const ActionPopover = ({
	id,
	open,
	anchorEl,
	handleClose,
	setOpenDeleteModal,
	setOpenEdit,
	setOpenModal,
	additionalOptions,
	doNotShowDeleteButton,
}: IActionPopover) => {
	return (
		<Popover
			id={id}
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
			PaperProps={{
				style: {
					borderRadius: 8,
					display: "flex",
					justifyContent: "center",
				},
			}}
		>
			<div className="w-full flex flex-col justify-center p-3">
				<div
					onClick={() => {
						handleClose();
						setOpenEdit(true);
						setOpenModal && setOpenModal(true);
					}}
					className="table-action-popover"
				>
					<BsPencil />
					<p className="text-2sm">Edit</p>
				</div>

				{additionalOptions}
				
				{!doNotShowDeleteButton && (
					<div
						onClick={() => {
							handleClose();
							setOpenDeleteModal(true);
						}}
						className="table-action-popover"
					>
						<BsTrash />
						<p className="text-2sm">Delete</p>
					</div>
				)}
			</div>
		</Popover>
	);
};

export default ActionPopover;
