import { useState } from "react";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import Label from "../../../../components/form_fields/Label";
import { SubmitHandler, useForm } from "react-hook-form";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { IAddEditTableForm, manageResourceIds } from "../../../../helpers/appHelpers";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import { useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import { Box, LinearProgress, Avatar } from "@mui/material";
import { GradeLevelDto } from "../../../../models/grade-level.dto";
import { AnnouncementResourceObject, ResourceType, AnnouncementResource } from "../../../../models/announcement.dto";
import { useCreateAnnouncement, useUpdateAnnouncement } from "../../../../services/announcement.service";
import { useDropzone } from "react-dropzone";
import { BsUpload, BsFillFileEarmarkTextFill } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { uploadOneDocument } from "../../../../services/document.service";
import { Link } from "react-router-dom";
import { PATHS } from "../../../../routes/routes.paths";
import MultiSelectField from "../../../../components/form_fields/MultiSelectField";

interface IFormInputs {
	title: string;
	body: string;
	publishDate: string;
	active?: boolean;
	public?: boolean;
	resources?: AnnouncementResourceObject[];
	targetGradeLevelIds?: number[];
	addResources?: AnnouncementResourceObject[];
	removeResourcesIds?: number[];
	unassignedTargetGradeLevelIds?: number[];
	assignedTargetGradeLevelIds?: number[];
}

type FileStatus = "success" | "error" | "uploading" | "";

type AnnouncementsModalFormProps = IAddEditTableForm & {
	gradeLevels: GradeLevelDto[];
}

const AnnouncementsModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	gradeLevels,
}: AnnouncementsModalFormProps) => {
	const { currentCompany } = useCompanyStore();
	const {formDataValues} = useEditFormStore();
	const [uploading, setUploading] = useState(false);
	const [files, setFiles] = useState<File[]>([]);
	const [filesStatus, setFilesStatus] = useState<Record<string, FileStatus>>({});
	const [uploadedResources, setUploadedResources] = useState<string[]>([]);
	const [removeResourcesIds, setRemoveResourcesIds] = useState<number[]>([]);
	const [resources, setResources] = useState<AnnouncementResource[]>(
		formDataValues?.resources || []
	);
	
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			title: isEditForm ? formDataValues?.title : undefined,
			body: isEditForm ? formDataValues?.body : undefined,
			publishDate: isEditForm ? formDataValues?.publishDate?.slice(0, 10) : undefined,
			public: isEditForm ? formDataValues?.public : undefined,
			active: isEditForm ? formDataValues?.active : undefined,
		}
	});

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"image/*": [".png", ".jpeg", ".jpg", ".heic", ".svg"],
			"text/pdf": [".pdf"],
			"text/xlsx": [".xlsx"],
		},
		maxFiles: 5,
		onDrop: (acceptedFiles: File[]) => {
			const validFiles: File[] = [];
			const maxFileSize = 2 * 1024 * 1024; // (2 megabytes)
			const largeFiles: string[] = [];
	
			// If the selected file size is larger than the maximum file size
			acceptedFiles.forEach((file) => {
				if (file.size > maxFileSize) {
					largeFiles.push(file.name);
				} else {
					validFiles.push(file);
				}
			})

			setFiles(prev => ([...prev, ...validFiles]));

			if (largeFiles.length) {
				alert(
					"This file(s) are too large: " + 
					largeFiles.reduce((acc, fileName) => acc += `${acc ? ", " : ""}${fileName}`, "")
				);
			}
		},
		onDropRejected: () => {
			alert("Invalid file format. Please upload a valid file (pdf, xlsx, png, jpeg, jpg, heic or svg)");
		},
	});

	const [targetGradeLevelIds, setTargetGradeLevelIds] = useState<number[]>([]);
	const { mutate: createAnnouncement } = useCreateAnnouncement();
	const { mutate: updateAnnouncement } = useUpdateAnnouncement();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
		setUploading(true);

		let resources = uploadedResources;
		data.public = !Boolean(targetGradeLevelIds.length);

		for (let i = 0; i < files.length; i++) {

			if (filesStatus[files[i].name] !== "success") {
				setFilesStatus(prev => ({ ...prev, [`${files[i].name}`]: "uploading" }));

				const formData = new FormData();
				formData.append("file", files[i]);

				const uploadedFile = await uploadOneDocument(formData);
				const fileUrl = uploadedFile?.data?.url

				if (fileUrl) {
					resources.push(fileUrl);
					setUploadedResources(prev => ([ ...prev, fileUrl ]));
					setFilesStatus(prev => ({ ...prev, [`${files[i].name}`]: "success" }));
				} else {
					setFilesStatus(prev => ({ ...prev, [`${files[i].name}`]: "error" }));
				}
			}
		}
		
		if (isEditForm) {
			const { addIds, removeIds } = manageResourceIds({
				newData: targetGradeLevelIds,
				oldData: formDataValues?.targetGradeLevels?.map(gradeLevel => gradeLevel.id) || [],
			});
			
			data.assignedTargetGradeLevelIds = addIds;
			data.unassignedTargetGradeLevelIds = removeIds;

			if (resources.length) {
				data.addResources = resources.map(
					(resource) => ({ resourceType: "OTHER" as ResourceType, url: resource })
				);
			}
			if (removeResourcesIds.length) {
				data.removeResourcesIds = removeResourcesIds;
			}

			updateAnnouncement(
				{ payload: data, id: formDataValues?.id }, 
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			data.targetGradeLevelIds = targetGradeLevelIds.length ? targetGradeLevelIds as any : undefined;

			if (resources.length) {
				data.resources = resources.map(
					(resource) => ({ resourceType: "OTHER" as ResourceType, url: resource })
				);
			}

			createAnnouncement(
				{
					payload: {
						...data,
						companyId: currentCompany?.id as number,
					}
				}, 
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		setOpen(false);
		reset();
	};

	return (
		<ModalComponent open={open} title={title} handleClose={handleClose}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Title" for="title" />
						<input
							placeholder={"Enter title"}
							type="text"
							{...register("title", {
								required: "Enter title here",
							})}
							id="title"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.title
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.title && (
							<p className="text-red-500 text-sm mt-1">{errors.title.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Publish Date" for="publishDate" />
						<input
							type="date"
							{...register("publishDate", {
								required: "Enter publish date here",
							})}
							id="publishDate"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.publishDate
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.publishDate && (
							<p className="text-red-500 text-sm mt-1">
								{errors.publishDate.message}
							</p>
						)}
					</div>
                    <div className="w-full flex flex-col gap-2">
                        <Label title="Assign to Grade Levels" for="targetGradeLevelIds" optional />
						<MultiSelectField
							key={"targetGradeLevelIds"}
							options={gradeLevels}
							setResourceIds={setTargetGradeLevelIds}
							placeholder={"Select Grade Levels"}
							pathname={PATHS.COMPANY_SETTINGS + "?view=gradeLevels"}
							required={false}
							defaultValues={!isEditForm ? [] : (formDataValues?.targetGradeLevels?.map(gradeLevel => gradeLevel.id) || [])}
						/>
                    </div>
					<div className="col-span-2 w-full">
						<Label title="Body" for="body" />
						<textarea
							placeholder={"Enter body"}
							{...register("body", {
								required: "Enter body here",
							})}
							id="body"
							className={`w-full bg-secondary-gray rounded-lg h-[168px] px-5 py-4 mt-2 text-sm resize-none ${
								errors.body
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.body && (
							<p className="text-red-500 text-sm mt-1">
								{errors.body.message}
							</p>
						)}
					</div>
				</div>
				<div className="w-full mt-6">
					<Label title="Resources" for="resources" optional />
					<div
						{...getRootProps()}
						className="mt-2 border-2 border-dashed border-spacing-3 border-primary-blue rounded-xl h-[100px] w-full flex 
						flex-col justify-center m-auto items-center text-primary-blue bg-[#26A0F91A] text-xs cursor-pointer"
					>
						<input {...getInputProps()} />
						<BsUpload className="text-xl mb-2" />
						<p>
							Upload images and files 
							<span className="font-semibold"> (2mb max)</span>
						</p>
					</div>
				</div>
				<div className={`flex flex-col gap-1 ${files.length ? "mt-2" : ""}`}>
					{files.map((file, index) => (
						<div key={index} className="flex items-center space-x-4 w-full">
							<BsFillFileEarmarkTextFill className="text-6xl text-gray-500" />
							<div className="flex flex-col items-start w-full">
								<div className="flex justify-between items-center w-full mb-1">
									<p className="text-xs w-[250px] text-primary-blue text-ellipsis truncate">
										{file.name}
									</p>
									<button 
										type="button"
										onClick={() => {
											setFiles(prev => prev.filter(addedFile => addedFile.name !== file.name));
											setFilesStatus(prev => ({ ...prev, [`${file.name}`]: "" }));
										}}
									>
										<IoMdClose className="text-xl hover:scale-[1.1]" />
									</button>
								</div>
								<Box sx={{ width: "100%", mb: 1, mt: 0 }}>
									<LinearProgress
										color={filesStatus[file.name] === "error" ? "error" : "primary"}
										variant={"determinate"}
										value={filesStatus[file.name] ? 100 : 0}
									/>
								</Box>
								<div className="flex justify-between text-xs w-full">
									<p>
										File size: {(file.size / 1000000).toFixed(2)}mb
									</p>
									{filesStatus[file.name] === "success" ? (
										<span className="text-green-500 text-xs">
											File uploaded successfully
										</span>
									) : filesStatus[file.name] === "error" ? (
										<span className="text-red-500 text-xs">
											File upload error! Try again
										</span>
									) : null}
								</div>
							</div>
						</div>
					))}
				</div>
				{isEditForm && (
					<div className={`flex gap-4 ${resources.length ? "mt-6" : ""}`}>
						{resources.map((resource, index) => (
							<div key={index} className="relative">
								<div className="text-white">
									<div 
										onClick={() => {
											setResources(prev => prev.filter(res => res.id !== resource.id));
											setRemoveResourcesIds(prev => ([...prev, resource.id]));
										}}
										className="peer absolute -top-1.5 -right-1.5 z-[999] p-1 w-fit rounded-full bg-charcoal"
									>
										<IoMdClose className="text-sm" />
									</div>
									<div className="hidden peer-hover:block absolute top-5 -right-6 z-[999] py-1.5 px-2.5 rounded text-xs bg-[#5b5757]">
										Remove
									</div>
								</div>
								<Link 
									to={resource.url} 
									target="_blank"
									className="w-fit"
								>
									<Avatar
										alt={"file " + (index + 1)}
										src={resource.url}
										variant="rounded"
										className="border border-primary-blue hover:border-2 cursor-pointer"
										sx={{ width: 80, height: 80 }}
									/>
								</Link>
							</div>
						))}
					</div>
				)}
				<div className="flex space-x-3 items-center mt-6">
					<input 
						type="checkbox"
						{...register("active", {
							required: false,
						})}
						id="active"
					/>
					<label htmlFor="active">Active</label>
				</div>
				
				<div className="w-full flex justify-end mt-8">
					<OrangeButton
						type="submit"
						title={
							uploading
							? "Uploading..."
							: isEditForm
								? "Update Announcement"
								: "Create Announcement"
						}
						className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default AnnouncementsModalForm;
