import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import API from './utils/api';
import {
    PaymentInstitutionDto, PaymentInstitutionPaginator,
    CreatePaymentInstitutionDto, QueryPaymentInstitutionDto,
    UpdatePaymentInstitutionDto,
    SearchPaymentInstitutionDto
} from "../models/payment-institution.dto";
import ENDPOINTS from "./utils/endpoints";
import { toast } from "react-toastify";

export function useGetPaymentInstitution(options?: QueryPaymentInstitutionDto) {
    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.PAYMENT_INSTITUTION.GET;
    const request = (options: QueryPaymentInstitutionDto) => API.BillingAndSubscriptionAPI.getPaymentInstitution(options);

    const { data, isLoading, error, refetch } = useQuery<PaymentInstitutionPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch };
}

export function useGetPaymentInstitutionSearch() {
    
    return useMutation(
        (options?: SearchPaymentInstitutionDto) => API.BillingAndSubscriptionAPI.getPaymentInstitutionSearch(options),
        {
            onSuccess: () => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetPaymentInstitutionById(id: number) {
    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.PAYMENT_INSTITUTION.DETAIL
    const request = API.BillingAndSubscriptionAPI.getPaymentInstitutionById(id);

    const {data, isLoading, error} = useQuery<{ data:PaymentInstitutionDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreatePaymentInstitution() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreatePaymentInstitutionDto}) => API.BillingAndSubscriptionAPI.createPaymentInstitution(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PAYMENT_INSTITUTION.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdatePaymentInstitution() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdatePaymentInstitutionDto, id: number}) => API.BillingAndSubscriptionAPI.updatePaymentInstitution(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PAYMENT_INSTITUTION.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useDeletePaymentInstitution() {

    return useMutation(
        ({ id }: { id: number }) => API.BillingAndSubscriptionAPI.deletePaymentInstitution(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}
