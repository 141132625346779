import { Modal, Box } from "@mui/material";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { useState } from "react";
import { ModalStyle } from "../../../../../../helpers/appHelpers";
import { useUpdateUser, useReactivateUser, useRemoveUser } from "../../../../../../services/user.service";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import { toast } from "react-toastify";

interface TeamMemberActionModalProps {
	mode: "REACTIVATE" | "DISABLE" | "DELETE";
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	tableAPIRef: React.MutableRefObject<GridApiCommunity>;
    reloadData: () => void;
}
const TeamMemberActionModal = ({ 
    mode, 
    open, 
    setOpen, 
    tableAPIRef, 
    reloadData,
}: TeamMemberActionModalProps) => {
	const { formDataValues } = useEditFormStore();
    const [uploading, setUploading] = useState(false);
    
	const { mutate: updateUser } = useUpdateUser();
	const { mutate: reactivateUser } = useReactivateUser();
	const { mutate: removeUser } = useRemoveUser();

    const handleModeAction = () => {
        setUploading(true);

        switch (mode) {
            case "REACTIVATE":
                reactivateUser(
                    { id: formDataValues?.id },
                    {
                        onSuccess: (updatedData) => {
                            tableAPIRef?.current.updateRows([updatedData.data]);
                            setOpen(false);
                        },
                        onError: () => setUploading(false),
                    }
                )
                break;

            case "DISABLE":
                updateUser(
                    { payload: { status: "DISABLED" }, id: formDataValues?.id },
                    {
                        onSuccess: (updatedData) => {
                            tableAPIRef?.current.updateRows([updatedData.data]);
                            toast.success('User disabled successfully');
                            setOpen(false);
                        },
                        onError: () => setUploading(false),
                    }
                )
                break;

            case "DELETE":
                removeUser(
                    { id: formDataValues?.id },
                    {
                        onSuccess: () => {
                            reloadData();
                            setOpen(false);
                        },
                        onError: () => setUploading(false),
                    }
                )
        }
    };

	return (
		<Modal
			open={open}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={ModalStyle} width={400}>
				<p className="text-center">
                    Are you sure you want to {mode.toLowerCase()}{" "}
                    <span className="font-semibold">{formDataValues?.firstName} {formDataValues?.lastName}'s</span>
                    {" "}account?
				</p>

				<div className="flex w-full justify-center space-x-4 items-center mt-10 whitespace-nowrap">
					<button
						onClick={() => setOpen(false)}
						className={`px-12 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs ${uploading && "opacity-50"}`}
						disabled={uploading}
					>
						Cancel
					</button>
					<button
						onClick={handleModeAction}
						className={`px-12 py-4 border text-white rounded-lg text-2xs hover:opacity-90 
                            ${mode === "REACTIVATE" ? "bg-primary-mango" : "bg-red-500"}
                        `}
						disabled={uploading}
					>
                        {mode === "REACTIVATE" ? (
                            <>{uploading ? "Actiavating..." : "Yes, Activate"}</>
                        ): mode === "DISABLE" ? (
                            <>{uploading ? "Disabling..." : "Yes, Disable"}</>
                        ): (
                            <>{uploading ? "Deleting..." : "Yes, Delete"}</>
                        )}
					</button>
				</div>
			</Box>
		</Modal>
	);
};

export default TeamMemberActionModal;