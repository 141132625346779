import { useEffect, useState } from "react";
import CompanySetupLayout from "../../../components/layouts/CompanySetupLayout";
import { Helmet } from "react-helmet-async";
import OrangeButton from "../../../components/buttons/OrangeButton";
import { Box, Modal, Skeleton } from "@mui/material";
import { ModalStyle, moneyFormat, subscriptionCardColors } from "../../../helpers/appHelpers";
import { IoMdClose } from "react-icons/io";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PATHS } from "../../../routes/routes.paths";
import { IoCheckmarkCircle } from "react-icons/io5";
import { SubscriptionTierOrderBy, DetailedSubscriptionTierDto } from "../../../models/subscription-tier.dto";
import { useGetSubscriptionTierPackages } from "../../../services/subscription-tier.service";
import useUserStore from "../../../state-management/useUserStore";
import useCompanyStore from "../../../state-management/useCompanyStore";

const CreatePayrollCompany = () => {
	const navigate = useNavigate();
	const { savedCompanies } = useCompanyStore();
	const { currentUser } = useUserStore();
	const userOrganization = currentUser.user.organization;
	const [searchParams] = useSearchParams();
	const upgradeRequired = searchParams.get("upgradeRequired");
	const displayPackages = searchParams.get("displayPackages");
	const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
	const [openChoosePackageModal, setOpenChoosePackageModal] = useState(false);

	useEffect(() => {
		if (displayPackages) {
			setOpenChoosePackageModal(true);
			return
		}

		const maxCompanies = userOrganization?.config.maxCompanies as number;
		
		if (maxCompanies === savedCompanies.length && upgradeRequired) {
			setOpenUpgradeModal(true);
		}
	}, [searchParams])
	
	const { 
		data: subscriptionTierPackages, 
		isLoading: isLoadingSubscriptions
	} = useGetSubscriptionTierPackages({
		detailed: true,
		page: 1,
		limit: 20,
		orderBy: SubscriptionTierOrderBy.PRICE_AMOUNT_ASC,
	});

	const handleCreatePayrollCompany = () => {
		const maxCompanies = userOrganization?.config.maxCompanies as number;
		
		if (maxCompanies === savedCompanies.length) {
			setOpenUpgradeModal(true);
			return
		}
		navigate(PATHS.COMPANY_ACCOUNT_SETUP);
	};

	return (
		<CompanySetupLayout pathname={window.location.pathname}>
			<Helmet>
				<title>Create Payroll Company | Akatua</title>
			</Helmet>
			<div className="w-full h-full grid place-content-center">
				<div className="flex flex-col items-center justify-center">
					<h3 className="font-bold mb-2">No Payroll Company Yet</h3>
					<p className="text-2xs mb-6">
						Create your company and start managing your payroll
					</p>
					<OrangeButton
						title={"Create Payroll Company"}
						onClick={handleCreatePayrollCompany}
						className={"px-8"}
					/>
				</div>
			</div>
			<Modal
				open={openUpgradeModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={ModalStyle} width={572}>
					<div className="flex justify-between items-center mb-4">
						<h1 className="font-bold text-lg">Upgrade your Account</h1>
						<button onClick={() => setOpenUpgradeModal(false)}>
							<IoMdClose className="text-xl hover:scale-[1.1]" />
						</button>
					</div>
					<p className="text-[15px] mb-8">
						Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
						sint. Velit officia consequat duis enim velit mollit. Exercitation
						veniam consequat sunt nostrud amet.
					</p>
					<div className="flex justify-end">
						<OrangeButton
							title={"Upgrade Account"}
							onClick={() => {
								setOpenUpgradeModal(false);
								setOpenChoosePackageModal(true);
							}}
							className={"px-12"}
						/>
					</div>
				</Box>
			</Modal>
			<Modal
				open={openChoosePackageModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={ModalStyle} width={1150}>
					<div className="flex justify-end items-center mb-10">
						<button onClick={() => setOpenChoosePackageModal(false)}>
							<IoMdClose className="text-xl hover:scale-[1.1]" />
						</button>
					</div>
					<div className="w-full flex flex-col justify-center items-center mb-[80px]">
						<h1 className="font-bold text-2xl text-secondary-darkgray mb-2">
							Choose your Package
						</h1>
						<p className="text-2xs mb-8">
							We’ve got you covered. Select a package that best serves your
							organisation
						</p>
						{isLoadingSubscriptions ? (
							<div className="w-[940px] grid grid-cols-3 gap-6 mx-auto">
								{Array.from({ length:  3 }).map(() => (
									<SkeletonSubscriptionCard key={Math.random()} />
								))}
							</div>
						):(
							<div className="w-[940px] grid grid-cols-3 gap-6 mx-auto">
								{subscriptionTierPackages?.data?.map((subscriptionTierPackage: DetailedSubscriptionTierDto, index) => (
									<>
										{(subscriptionTierPackage.id !== userOrganization?.subsciptionTier?.id) ? (
											<div
												key={subscriptionTierPackage.id}
												style={{ 
													backgroundImage: `linear-gradient(to bottom right, 
														${subscriptionCardColors[index.toString()][0]} , 
														${subscriptionCardColors[index.toString()][0]} ,
														${subscriptionCardColors[index.toString()][1]})` 
												}}
												className={`w-full h-[340px] py-8 px-7 text-white rounded-[10px]`}
											>
												<h3 className="text-2sm font-semibold mb-1">
													{subscriptionTierPackage.name}
												</h3>
												<h1 className="text-[28px] leading-tight font-bold whitespace-nowrap">
													{Number(subscriptionTierPackage.priceAmount) === 0
														? "FREE"
														: getPackageBillingStats(subscriptionTierPackage)
													}
												</h1>
												<p className="text-[11px]">Per month billed annually</p>
												<button
													onClick={() =>
														navigate(
															PATHS.SUBSCRIPTION_PAYMENT + 
															`?subscriptionTierId=${subscriptionTierPackage.id}`
														)
													}
													className="w-full h-8 text-[11px] bg-white rounded-lg font-bold mt-4"
													style={{ color: subscriptionCardColors[index.toString()][0] }}
												>
													Choose Plan
												</button>
												<div className="mt-4 space-y-2">
													<div className="flex items-center space-x-1.5">
														<AiOutlineCheckCircle className="text-2xs" />
														<p className="text-[11px]">
															{subscriptionTierPackage.maxCompanies > 0 ?
																`Up to ${subscriptionTierPackage.maxCompanies} Payroll Companies`
															:
																"Unlimited Payroll Companies"
															}
														</p>
													</div>
													<div className="flex items-center space-x-1.5">
														<AiOutlineCheckCircle className="text-2xs" />
														<p className="text-[11px]">
															{subscriptionTierPackage.maxCompanies > 0 ?
																`Limit of ${subscriptionTierPackage.maxCompanies} Employees`
															:
																"Unlimited Employees"
															}
														</p>
													</div>
													<div className="flex items-center space-x-1.5">
														<AiOutlineCheckCircle className="text-2xs" />
														<p className="text-[11px]">
															{subscriptionTierPackage.maxCompanies > 0 ?
																`Limit of ${subscriptionTierPackage.maxCompanies} Team Members`
															:
																"Unlimited Team Members"
															}
														</p>
													</div>
												</div>
											</div>
										):(
											<div
												key={subscriptionTierPackage.id}
												className="w-full h-[340px] py-8 px-7 text-[#4F4F4F] bg-white border-2
												 border-[#26A0F9] rounded-[10px] relative"
											>
												<IoCheckmarkCircle 
													className="text-primary-blue text-lg absolute top-5 right-5" 
												/>
												<h3 className="text-2sm font-semibold mb-1">
													{subscriptionTierPackage.name}
												</h3>
												<h1 className="text-[28px] leading-tight font-bold whitespace-nowrap">
													{Number(subscriptionTierPackage.priceAmount) === 0
														? "FREE"
														: getPackageBillingStats(subscriptionTierPackage)
													}
												</h1>
												<p className="text-[11px]">Per month billed annually</p>
												<button
													className="w-full h-8 bg-white border-2 border-[#26A0F9] text-[11px] rounded-lg font-bold mt-4 cursor-default"
												>
													Current Plan
												</button>
												<div className="mt-4 space-y-2">
													<div className="flex items-center space-x-1.5">
														<AiOutlineCheckCircle className="text-2xs" />
														<p className="text-[11px]">
															{subscriptionTierPackage.maxCompanies > 0 ?
																`Up to ${subscriptionTierPackage.maxCompanies} Payroll Companies`
															:
																"Unlimited Payroll Companies"
															}
														</p>
													</div>
													<div className="flex items-center space-x-1.5">
														<AiOutlineCheckCircle className="text-2xs" />
														<p className="text-[11px]">
															{subscriptionTierPackage.maxCompanies > 0 ?
																`Limit of ${subscriptionTierPackage.maxCompanies} Employees`
															:
																"Unlimited Employees"
															}
														</p>
													</div>
													<div className="flex items-center space-x-1.5">
														<AiOutlineCheckCircle className="text-2xs" />
														<p className="text-[11px]">
															{subscriptionTierPackage.maxCompanies > 0 ?
																`Limit of ${subscriptionTierPackage.maxCompanies} Team Members`
															:
																"Unlimited Team Members"
															}
														</p>
													</div>
												</div>
											</div>
										)}
									</>
								))}
							</div>
						)}
					</div>
				</Box>
			</Modal>
		</CompanySetupLayout>
	);
};

export default CreatePayrollCompany;

const SkeletonSubscriptionCard = () => (
	<div
		key={Math.random()} // Use a random key for each skeleton to ensure they are treated as unique elements
		style={{
		backgroundImage: `linear-gradient(to bottom right, #f3f4f6, #e5e7eb, #f3f4f6)`
		}}
		className={`w-full h-[340px] py-8 px-7 text-white rounded-[10px]`}
	>
		<Skeleton variant="text" width={200} height={20} className="mb-2" />
		<Skeleton variant="text" width={100} height={30} className="mb-2" />
		<Skeleton variant="text" width={150} height={15} className="mb-2" />
		<Skeleton variant="rectangular" width="100%" height={30} className="my-4" />
		<div className="mt-4 space-y-2">
		<div className="flex items-center space-x-1.5">
			<Skeleton variant="circular" width={15} height={15} />
			<Skeleton variant="text" width={150} height={15} />
		</div>
		<div className="flex items-center space-x-1.5">
			<Skeleton variant="circular" width={15} height={15} />
			<Skeleton variant="text" width={150} height={15} />
		</div>
		<div className="flex items-center space-x-1.5">
			<Skeleton variant="circular" width={15} height={15} />
			<Skeleton variant="text" width={150} height={15} />
		</div>
		</div>
	</div>
);

const getPackageBillingStats = (subscriptionTierPackage: DetailedSubscriptionTierDto) => {
	const currencyCode = subscriptionTierPackage.currency?.code || "$";
	const priceAmount = moneyFormat(subscriptionTierPackage.priceAmount || "");

	switch(subscriptionTierPackage.billingPeriod) {
		case "MONTHLY":
			return currencyCode + " " + priceAmount + "/mo"
		case "ANNUALLY":
			return currencyCode + " " + priceAmount + "/yr"
		default:
			return currencyCode + " " + priceAmount
	}
};