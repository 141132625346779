import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import {toast} from 'react-toastify';
import API from './utils/api';
import { 
    PromotionDto, PromotionPaginator,
    CreatePromotionDto, QueryPromotionDto,
    UpdatePromotionDto,
    SearchPromotionDto
} from '../models/promotion.dto';
import ENDPOINTS from './utils/endpoints';

export function useGetPromotion(options?: QueryPromotionDto) {
    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.PROMOTION.GET;
    const request = (options: QueryPromotionDto) => API.BillingAndSubscriptionAPI.getPromotion(options);

    const { data, isLoading, error, refetch } = useQuery<PromotionPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption }
    );

    return { data, isLoading, error, refetch };
}

export function useGetPromotionById(id: number) {
    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.PROMOTION.DETAIL;
    const request = API.BillingAndSubscriptionAPI.getPromotionById(id);

    const { data, isLoading, error } = useQuery<PromotionDto, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return { data, isLoading, error };
}

export function useGetPromotionSearch() {
    const queryClient = useQueryClient();

    return useMutation(
        (options?: SearchPromotionDto) => API.BillingAndSubscriptionAPI.getPromotionSearch(options),
        {
            onSuccess: () => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PROMOTION.GET);
            },
        },
    );
}

export function useCreatePromotion() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload }: { payload: CreatePromotionDto }) => API.BillingAndSubscriptionAPI.createPromotion(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PROMOTION.GET);
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useUpdatePromotion() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload, id }: { payload: UpdatePromotionDto, id: number }) => API.BillingAndSubscriptionAPI.updatePromotion(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PROMOTION.GET);
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useDeletePromotion() {

    return useMutation(
        ({ id }: { id: number }) => API.BillingAndSubscriptionAPI.deletePromotion(id),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}