import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import { 
    CreateLoanBenefitDto,
    LoanBenefitDto,
    LoanBenefitPaginator,
    QueryLoanBenefitDto,
    SearchLoanBenefitDto,
    UpdateLoanBenefitDto
} from "../models/loan-benefit.dto";
import ENDPOINTS from "./utils/endpoints";
import { EmployeeSavingsSchemeDto } from "../models/savings-scheme.dto";

export function useGetLoanBenefit(options?: QueryLoanBenefitDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.LOAN_BENEFIT.GET;
    const request = (options: QueryLoanBenefitDto) => API.ProcessingCompanyAPI.getLoanBenefit(options);

	const { data, isLoading, error, refetch } = useQuery<LoanBenefitPaginator, Error>(
		[url, options],
		({ queryKey, pageParam }) =>
			request(Object.assign({}, queryKey[1], pageParam)),
		{...API.Handlers.pageParamOption}
	);

	return { data, isLoading, error, refetch };
}

export function useGetLoanBenefitSearch() {

    return useMutation(
        (options?: SearchLoanBenefitDto) => API.ProcessingCompanyAPI.getLoanBenefitSearch(options),
        {
            onSuccess: () => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetLoanBenefitById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.LOAN_BENEFIT.DETAIL;
    const request = API.ProcessingCompanyAPI.getLoanBenefitById(id);

    const {data, isLoading, error} = useQuery<{ data: LoanBenefitDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateLoanBenefit() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateLoanBenefitDto}) => API.ProcessingCompanyAPI.createLoanBenefit(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.LOAN_BENEFIT.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateLoanBenefit() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateLoanBenefitDto, id: number}) => API.ProcessingCompanyAPI.updateLoanBenefit(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.LOAN_BENEFIT.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useDeleteLoanBenefit() {

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteLoanBenefit(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}