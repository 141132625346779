import {PaginatorInfo} from "./generic.dto";
import {EmployeeBandDto} from "./employee-band.dto";
import { BenefitTypeInstance } from "./benefit-type.dto";
import { EmployeeDto } from "./employee.dto";
import { EmployeeBandBenefitDto } from "./employee-band-benefit.dto";


export const FREQUENCY = {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    FORTNIGHTLY: 'FORTNIGHTLY',
    MONTHLY: 'MONTHLY',
    QUARTERLY: 'QUARTERLY',
    BIANNUALLY: 'BIANNUALLY',
    ANNUALLY: 'ANNUALLY'
};

export type FREQUENCY = (typeof FREQUENCY)[keyof typeof FREQUENCY]

export type Benefit = {
    id: number
    companyId: number
    code: string
    name: string
    description: string
    benefitTypeInstanceId: number
    value: number | null
    taxCode: string | null
    frequency: FREQUENCY | null
    createdAt: string
    modifiedAt: string | null
}

export class CreateBenefitDto {
    companyId!: number
    code!: string
    name!: string
    description!: string
    benefitTypeInstanceId!: number;
    value?: number
    taxCode?: string
    frequency?: FREQUENCY
    employeeIds?: number[]
    employeeBandIds?: number[]
    deductionIds?: number[]
}

export class UpdateBenefitDto {
    code?: string
    name?: string
    description?: string
    benefitTypeInstanceId?: number
    value?: number
    taxCode?: string
    frequency?: FREQUENCY
    addDeductionIds?: number[]
    removeDeductionIds?: number[]
}

export interface BenefitDto extends Benefit {
    benefitTypeInstance?: BenefitTypeInstance
  employeeBenefits?: EmployeeBenefitDto[];
  employeeBandBenefits?: EmployeeBandBenefitDto[];
}

export interface EmployeeBenefitDto extends EmployeeBenefit {
    employee?: EmployeeDto;
}

type EmployeeBenefit = {
    id: number;
    employeeId: number;
    benefitId: number;
    active: boolean;
    createdAt: string;
    modifiedAt: string | null;
}

export interface BenefitPaginator extends PaginatorInfo<BenefitDto> {}

export enum BenefitOrderBy {
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
    MODIFIED_AT_ASC = 'modifiedAt:asc',
    MODIFIED_AT_DESC = 'modifiedAt:desc',
    CODE_ASC = 'code:asc',
    CODE_DESC = 'code:desc',
    NAME_ASC = 'name:asc',
    NAME_DESC = 'name:desc'
}

export class QueryBenefitDto {
    companyId?: number;
    code?: string
    taxCode?: string
    frequency?: FREQUENCY
    benefitTypeInstanceId?: number
    page?: number = 1;
    limit?: number;
    orderBy?: BenefitOrderBy = BenefitOrderBy.CREATED_AT_ASC;
}


export class SearchBenefitDto {
    q?: string;
    page?: number = 1;
    limit?: number;
    orderBy?: BenefitOrderBy = BenefitOrderBy.CREATED_AT_ASC;
}

