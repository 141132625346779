import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { TbTriangleFilled } from "react-icons/tb";
import { useNavigate, useSearchParams } from "react-router-dom";
import AdminLayout from "../../../components/layouts/AdminLayout";
import { PATHS } from "../../../routes/routes.paths";
import Promotions from "./views/promotions";
import Countries from "./views/countries";
import TaxCodes from "./views/tax_codes";
import TaxRules from "./views/tax_rules";
import TaxReliefTypes from "./views/tax_relief_types";
import Currencies from "./views/currencies";
import LeaveTypes from "./views/leave_types";
import Industries from "./views/industries";
import BenefitTypes from "./views/benefit_types";
import Nationalities from "./views/nationalities";
import SubscriptionPackages from "./views/subscription_packages";
import PaymentInstitutions from "./views/payment_Institutions";
import PaymentInstitutionBranches from "./views/payment_institutions_branch";
import ExchangeRates from "./views/exchange_rate";
import Pensions from "./views/pensions";
import PasswordPolicy from "./views/password_policy";

const secondSideBars = [
	{
		key: "subscriptionPackages",
		name: "Subscription Packages",
		component: SubscriptionPackages,
	},
	{
		key: "promotions",
		name: "Promotions",
		component: Promotions,
	},
	{
		key: "countries",
		name: "Countries",
		component: Countries,
	},
	{
		key: "currencies",
		name: "Currencies",
		component: Currencies,
	},
	{
		key: "nationalities",
		name: "Nationalities",
		component: Nationalities,
	},
	{
		key: "taxCodes",
		name: "Tax Codes",
		component: TaxCodes,
	},
	{
		key: "taxRules",
		name: "Tax Rules",
		component: TaxRules,
	},
	{
		key: "taxReliefTypes",
		name: "Tax Relief Types",
		component: TaxReliefTypes,
	},
	{
		key: "leaveTypes",
		name: "Leave Types",
		component: LeaveTypes,
	},
	{
		key: "industries",
		name: "Industries",
		component: Industries,
	},
	{
		key: "pensions",
		name: "Pensions",
		component: Pensions,
	},
	{
		key: "benefitTypes",
		name: "Benefit Types",
		component: BenefitTypes,
	},
	{
		key: "paymentInstitutions",
		name: "Payment Institutions",
		component: PaymentInstitutions,
	},
	{
		key: "paymentInstitutionBranches",
		name: "Payment Institution Branches",
		component: PaymentInstitutionBranches,
	},
	{
		key: "exchangeRates",
		name: "Exchange Rates",
		component: ExchangeRates,
	},
	{
		key: "passwordPolicy",
		name: "Password Policy",
		component: PasswordPolicy,
	},
];

const SystemConfigurations = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const view = searchParams.get("view") || "subscriptionPackages";
	const [info, setInfo] = useState<any>();

	useEffect(() => {
		if (!view) {
			setSearchParams({ view: "subscriptionPackages" });

			const selectedInfo = secondSideBars.find(
				(x) => x.key === "subscriptionPackages"
			);
			setInfo(selectedInfo);
			return
		}

		const selectedInfo = secondSideBars.find((x) => x.key === view);
		setInfo(selectedInfo);
	}, [view])

	const handleFormClick = (key: string) => {
		setSearchParams({ view: key });
		setInfo(secondSideBars.find((x) => x.key === key));
		navigate(PATHS.ADMIN_SYSTEM_CONFIGURATIONS + `?view=${key}`);
	};

	function switchView(view: string, props?: { [key: string]: any }) {
		const pickedView = secondSideBars.find((x) => x.key === view);
		const defaultView = <></>;
		if (pickedView?.key) {
			const { component } = pickedView;
			switch (view) {
				case pickedView.key:
					if (component.name) return component;
					return defaultView;
				default:
					return defaultView;
			}
		}

		return defaultView;
	}

	return (
		<AdminLayout>
			<Helmet>{info?.name} | System Configurations</Helmet>

			<h1 className="w-full font-bold text-xl bg-white mb-8">
				System Configurations{" "}
				<span className="text-[15px] font-medium text-[#007aff]">
					({info?.name})
				</span>
			</h1>
			{view && (
				<div 
					className="grow w-full flex gap-4"
					style={{ height: "calc(100% - 60px)" }}
				>
					<section className="max-h-full h-fit w-[20%] text-2sm rounded-lg flex flex-col gap-y-6 bg-white border py-7 px-4 overflow-y-auto children-scroll-bar">
						{secondSideBars.map((bar) => {
							return (
								<div
									key={bar.key}
									className={`flex items-center space-x-3 cursor-pointer ${
										view === bar.key ? "text-primary-blue" : "text-black"
									}`}
									onClick={() => handleFormClick(bar.key)}
								>
									<TbTriangleFilled 
										className={`rotate-90 ${view !== bar.key && "text-white"}`} 
									/>
									<span>{bar.name}</span>
								</div>
							);
						})}
					</section>
					<section className="h-full w-[80%] rounded-lg bg-white border py-8 px-7 overflow-y-auto children-scroll-bar">
						{secondSideBars.map((bar) => {
							const Component = switchView(view);
							return (
								view === bar.key && (
									<div className="w-full">
										{/*//@ts-ignore*/}
										<Component title={bar.name} />
									</div>
								)
							);
						})}
					</section>
				</div>
			)}
		</AdminLayout>
	);
};

export default SystemConfigurations;
