import { useState } from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { APP_CONFIG } from "../../../../helpers/appHelpers";
import { BenefitsColumns, BenefitsColumnVisibilityModel } from "../data";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import { useDeleteBenefit, useGetBenefit, useGetBenefitSearch } from "../../../../services/benefit.service";
import DeleteModal from "../../../../components/modals/DeleteModal";
import ActionPopover from "../../../../components/popovers/actionPopover";
import { BenefitDto, FREQUENCY } from "../../../../models/benefit.dto";
import { AiOutlinePlus } from "react-icons/ai";
import { useManageTableWithSearchParams, useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import { BenefitTypeOrderBy } from "../../../../models/benefit-type.dto";
import { useGetBenefitType } from "../../../../services/benefit-type.service";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import BenefitModalForm from "../modal/BenefitModalForm";
import { useGetTaxCode } from "../../../../services/tax-code.service";
import { TaxCodeOrderBy } from "../../../../models/tax-code.dto";
import TableFilter from "../../../../components/table/filter/TableFilter";
import { useGetEmployeeBand } from "../../../../services/employee-band.service";
import { useGetEmployee } from "../../../../services/employee.service";
import APISearchInput from "../../../../components/search/APISearchInput";
import { EmployeeOrderBy } from "../../../../models/employee.dto";
import { useGetExplicitDeduction } from "../../../../services/explicit-deduction.service";
import { EmployeeBandOrderBy } from "../../../../models/employee-band.dto";
import { ExplicitDeductionOrderBy } from "../../../../models/explicit-deduction.dto";
import ManageTableColumns from "../../../../components/table/ManageTableColumns";
import { useColumnVisibilityModel } from "../../../../helpers/useColumnVisibilityModel";

const BenefitsView = () => {
	const { currentCompany } = useCompanyStore();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);
	const {
        tableKey,
        columnVisibilityModel,
        setColumnVisibilityModel,
    } = useColumnVisibilityModel(BenefitsColumnVisibilityModel);
	
	const { mutate: searchBenefits } = useGetBenefitSearch();
	const {
		tableAPIRef,
		fetchedTableData,
		tableData,
		isLoading,
		pageCount,
		setTableData,
    } = useManageTableWithSearchParams<BenefitDto>({
		fetchHook: useGetBenefit,
		options: { companyId: currentCompany?.id }
	});
	
	// Fetched data for select fields requiring data from database
	const { data: benefitTypes } = useGetBenefitType({
		countryId: currentCompany?.countryId as number,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: BenefitTypeOrderBy.NAME_ASC
	});
	const { data: taxCodes } = useGetTaxCode({
		countryId: currentCompany?.countryId as number,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: TaxCodeOrderBy.CODE_ASC
	});
	const { data: employees } = useGetEmployee({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		status: "ACTIVE",
		orderBy: EmployeeOrderBy.FIRST_NAME_ASC,
	});
	const { data: employeeBands } = useGetEmployeeBand({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: EmployeeBandOrderBy.NAME_ASC,
	});
	const { data: explicitDeductions } = useGetExplicitDeduction({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: ExplicitDeductionOrderBy.NAME_ASC,
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setIsEditForm(false);
		setOpenModal(true);
	};
	
	const { formDataValues, setFormData } = useEditFormStore();
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteBenefit } = useDeleteBenefit();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteBenefit(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadTableData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>,
				];
			},
		},
	];

	const columnsWithAdditions = BenefitsColumns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<>
			<div className="flex justify-between items-center mt-4 mb-8">
				<div className="flex items-center gap-3">
					<APISearchInput 
						fallbackData={fetchedTableData}
						setTableData={setTableData}
						mutate={searchBenefits}
					/>
					<TableFilter
						fields={[
							{
								title: "",
								fieldName: "",
								fieldType: "SORT",
								options: [
									{
										title: "Name",
										fieldName: "name",
									},
									{
										title: "Code",
										fieldName: "code",
									},
									{
										title: "Date Created",
										fieldName: "createdAt",
									},
									{
										title: "Date Modified",
										fieldName: "modifiedAt",
									},
								]
							},
							{
								title: "Tax Code",
								fieldName: "taxCode",
								fieldType: "INPUT_FIELD",
							},
							{
								title: "Frequency",
								fieldName: "frequency",
								fieldType: "CHECKBOX_LIST",
								options: FREQUENCY,
							},
							{
								title: "Benefit Type Instance",
								fieldName: "benefitTypeInstanceId",
								fieldType: "SELECT_FIELD",
								options: benefitTypes?.data || [],
							},
						]}
					/>
					<ManageTableColumns 
						columns={BenefitsColumns}
						defaultVisibilityModel={BenefitsColumnVisibilityModel}
						visibilityModel={columnVisibilityModel}
						setVisibilityModel={setColumnVisibilityModel}
					/>
				</div>
				<div
					className="flex items-center text-primary-blue cursor-pointer"
					onClick={handleOpenModal}
				>
					<AiOutlinePlus className="mr-1 text-base" />
					<span className="text-sm">Add New Benefit</span>
				</div>
			</div>
			<CustomStyledTable
				key={tableKey}
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				columnVisibilityModel={columnVisibilityModel}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
			<ActionPopover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				handleClose={handleClose}
				setOpenDeleteModal={setOpenDeleteModal}
				setOpenEdit={setIsEditForm}
				setOpenModal={setOpenModal}
			/>
			<DeleteModal
				openDeleteModal={openDeleteModal}
				spanContent={formDataValues?.name || ""}
				setOpenDeleteModal={setOpenDeleteModal}
				handleDeleteMethod={handleDeleteMethod}
				deleting={deleting}
			/>
			{openModal && (
				<BenefitModalForm
					open={openModal}
					setOpen={setOpenModal}
					title={isEditForm ? "Update Benefit" : "Add New Benefit"}
					isEditForm={isEditForm}
					tableAPIRef={tableAPIRef}
					benefitTypes={benefitTypes?.data || []}
					taxCodes={taxCodes?.data || []}
					employees={employees?.data || []}
					employeeBands={employeeBands?.data || []}
					explicitDeductions={explicitDeductions?.data || []}
				/>
			)}
		</>
	);
};

export default BenefitsView;
