import { Box, Modal } from "@mui/material";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { useApprovePayroll, useRunPayrollParams } from "../../../../services/payrol.service";
import { useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";

type PayrollActionModalProps = {
	openModal: boolean;
	setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
	actionMode: "RUN_PAYROLL" | "APPROVE_PAYROLL";
}

const PayrollActionModal = ({
	openModal,
	setOpenModal,
	actionMode,
}: PayrollActionModalProps) => {
	const { formDataValues } = useEditFormStore();
	const { reloadTableData } = useManageTableWithSearchParamsData();
	const { 
		mutate: runPayroll, 
		isLoading: runPayrollLoading,
	} = useRunPayrollParams();
	const { 
		mutate: approvePayroll, 
		isLoading: approvePayrollLoading,
	} = useApprovePayroll();
	const isLoading = runPayrollLoading || approvePayrollLoading;

	const handleRunPayroll = () => {
		if (actionMode === "RUN_PAYROLL") {
			runPayroll(
				{ id: formDataValues?.id, options: {} },
				{ 
					onSuccess: () => {
						reloadTableData();
						setOpenModal(false);
					}
				}
			);
		} else {
			approvePayroll(
				{ id: formDataValues?.id, options: {} },
				{ onSuccess: () => setOpenModal(false) }
			);
		}
	};

	return (
		<Modal open={openModal}>
			<Box sx={{
				position: "absolute" as "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				width: 450,
				py: 4,
				bgcolor: "background.paper",
				boxShadow: 24,
				border: "none",
				borderRadius: "8px",
				outline: "none",
				overflow: "auto",
				maxHeight: "95vh",
				display: "flex",
				flexDirection: "column",
			}}>
				<div className="w-full px-6 lg:px-8">
					<div className="flex justify-center">
						<h3 className="text-xl font-bold">
							{actionMode === "RUN_PAYROLL" ? "Run Payroll" : "Publish Payslips"}
						</h3>
					</div>
                    <p className="text-2sm text-center mt-6">
                        Are you sure you want to
						{actionMode === "RUN_PAYROLL" ? " run this payroll?" : " publish the payslips of this payroll run to employees?"}
                    </p>
					<div className="max-lg:w-full mt-8 flex justify-center space-x-4 whitespace-nowrap text-center">
						<button
							title={"Cancel"}
							onClick={() => setOpenModal(false)}
							className={`lg:px-14 max-lg:w-full text-gray-400 bg-white border rounded-lg
								border-secondary-lightgray text-2xs ${isLoading && "opacity-50"}
							`}
							disabled={isLoading}
						>
							Cancel
						</button>
						<OrangeButton
							onClick={handleRunPayroll}
							title={
								isLoading 
									? actionMode === "RUN_PAYROLL" ? "Running..." : "Publishing..." 
									: actionMode === "RUN_PAYROLL" ? "Yes, Run" : "Yes, Publish"
							}
							className={"lg:px-14 max-lg:w-full"}
							disabled={isLoading}
						/>
					</div>
				</div>
			</Box>
		</Modal>
	);
};

export default PayrollActionModal;
