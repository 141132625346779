import { useState } from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { APP_CONFIG } from "../../../../helpers/appHelpers";
import { OvertimeColumns, OvertimeColumnVisibilityModel } from "../data";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import DeleteModal from "../../../../components/modals/DeleteModal";
import OvertimeModalForm from "../modals/OvertimeModalForm";
import { AiOutlinePlus } from "react-icons/ai";
import { useManageTableWithSearchParams, useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import { useDeleteOvertime, useGetOvertime, useGetOvertimeSearch } from "../../../../services/overtime.service";
import { OvertimeDto } from "../../../../models/overtime.dto";
import { EmployeeBandOrderBy } from "../../../../models/employee-band.dto";
import { useGetEmployeeBand } from "../../../../services/employee-band.service";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import TableFilter from "../../../../components/table/filter/TableFilter";
import APISearchInput from "../../../../components/search/APISearchInput";
import { useGetCompanyCurrencyConfig } from "../../../../services/company-currency.service";
import { Popover } from "@mui/material";
import { BsPencil, BsTrash } from "react-icons/bs";
import { LuEye } from "react-icons/lu";
import OvertimePaymentTiersModal from "../modals/OvertimePaymentTiersModal.tsx";
import ManageTableColumns from "../../../../components/table/ManageTableColumns";
import { useColumnVisibilityModel } from "../../../../helpers/useColumnVisibilityModel";

const OvertimeView = () => {
	const { currentCompany } = useCompanyStore();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [openPaymentTiersModal, setOpenPaymentTiersModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);
	const {
        tableKey,
        columnVisibilityModel,
        setColumnVisibilityModel,
    } = useColumnVisibilityModel(OvertimeColumnVisibilityModel);

	const { mutate: searchOvertime } = useGetOvertimeSearch();
	const {
        tableAPIRef,
        fetchedTableData,
        tableData,
        isLoading,
        pageCount,
        setTableData,
    } = useManageTableWithSearchParams<OvertimeDto>({
		fetchHook: useGetOvertime,
		options: { companyId: currentCompany?.id }
	});

	// Fetched data for select fields requiring data from database
	const { data: employeeBands } = useGetEmployeeBand({
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: EmployeeBandOrderBy.CREATED_AT_DESC
	});
	const { data: companyCurrencies } = useGetCompanyCurrencyConfig({
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setIsEditForm(false);
		setOpenModal(true);
	};

	const { formDataValues, setFormData } = useEditFormStore();
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteOvertime } = useDeleteOvertime();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteOvertime(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadTableData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>,
				];
			},
		},
	];

	const columnsWithAdditions = OvertimeColumns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<div className="font-inter">
			<div className="flex justify-between items-center mt-4 mb-8">
				<div className="flex items-center gap-3">
					<APISearchInput 
						fallbackData={fetchedTableData}
						setTableData={setTableData}
						mutate={searchOvertime}
					/>
					<TableFilter
						fields={[
							{
								title: "",
								fieldName: "",
								fieldType: "SORT",
								options: [
									{
										title: "Name",
										fieldName: "name",
									},
									{
										title: "Code",
										fieldName: "code",
									},
									{
										title: "Min Hours Required",
										fieldName: "minHoursRequired",
									},
									{
										title: "Max Hours Permitted",
										fieldName: "maxHoursPermitted",
									},
									{
										title: "Date Created",
										fieldName: "createdAt",
									},
									{
										title: "Date Modified",
										fieldName: "modifiedAt",
									},
								]
							},
							{
								title: "Employee Band",
								fieldName: "employeeBandId",
								fieldType: "SELECT_FIELD",
								options: employeeBands?.data || [],
							},
							{
								title: "Min Hours Required",
								fieldName: "minHoursRequired",
								fieldType: "INPUT_FIELD",
								inputType: "number",
							},
							{
								title: "Max Hours Permitted",
								fieldName: "maxHoursPermitted",
								fieldType: "INPUT_FIELD",
								inputType: "number",
							},
							{
								title: "Taxable",
								fieldName: "taxable",
								fieldType: "YES_NO",
							},
							{
								title: "Active",
								fieldName: "active",
								fieldType: "YES_NO",
							},
						]}
					/>
					<ManageTableColumns 
						columns={OvertimeColumns}
						defaultVisibilityModel={OvertimeColumnVisibilityModel}
						visibilityModel={columnVisibilityModel}
						setVisibilityModel={setColumnVisibilityModel}
					/>
				</div>
				<div
					className="flex items-center text-primary-blue cursor-pointer"
					onClick={handleOpenModal}
				>
					<AiOutlinePlus className="mr-1 text-base" />
					<span className="text-sm">Add New Overtime Type</span>
				</div>
			</div>
			<CustomStyledTable
				key={tableKey}
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				columnVisibilityModel={columnVisibilityModel}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
            <Popover
                id={anchorEl ? "simple-popover" : undefined}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                PaperProps={{
                    style: {
                        width: "auto",
                        borderRadius: 8,
                        display: "flex",
                        justifyContent: "center",
                    },
                }}
            >
                <div className="flex flex-col justify-center p-3 text-secondary-darkgray">
					<div
						onClick={() => {
							setIsEditForm(true);
							setOpenModal(true);
							handleClose();
						}}
						className="table-action-popover"
					>
						<BsPencil />
						<p className="text-2sm">Edit</p>
					</div>
                    <div
                        onClick={() => {
                            setOpenPaymentTiersModal(true);
                            handleClose();
                        }}
                        className="table-action-popover"
                    >
                        <LuEye />
                        <p className="text-2sm">View Payment Tiers</p>
                    </div>
					<div
						onClick={() => {
							handleClose();
							setOpenDeleteModal(true);
						}}
						className="table-action-popover"
					>
						<BsTrash />
						<p className="text-2sm">Delete</p>
					</div>
                </div>
            </Popover>
			<DeleteModal
				openDeleteModal={openDeleteModal}
				spanContent={formDataValues?.name || ""}
				setOpenDeleteModal={setOpenDeleteModal}
				handleDeleteMethod={handleDeleteMethod}
				deleting={deleting}
			/>

			{openModal && (
				<OvertimeModalForm
					open={openModal}
					setOpen={setOpenModal}
					title={
						isEditForm ? "Update Overtime Type" : "Add New Overtime Type"
					}
					isEditForm={isEditForm}
					tableAPIRef={tableAPIRef}
					employeeBands={employeeBands?.data || []}
					companyCurrencies={companyCurrencies?.data || []}
				/>
			)}
			{openPaymentTiersModal && (
				<OvertimePaymentTiersModal
					open={openPaymentTiersModal}
					setOpen={setOpenPaymentTiersModal}
					companyCurrencies={companyCurrencies?.data || []}
				/>
			)}
		</div>
	);
};

export default OvertimeView;
