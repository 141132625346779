import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import API from './utils/api';
import {
    LeavePlanDto, LeavePlanPaginator,
    CreateLeavePlanDto, QueryLeavePlanDto,
    UpdateLeavePlanDto, 
} from '../models/leave-plan.dto';
import ENDPOINTS from './utils/endpoints';

export function useGetLeavePlan(options?: QueryLeavePlanDto) {
    const url = ENDPOINTS.HR.LEAVE_PLAN.GET;
    const request = (options: QueryLeavePlanDto) => API.HrAPI.getLeavePlans(options);

    const { data, isLoading, error, refetch } = useQuery<LeavePlanPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption},
    );

    return { data, isLoading, error, refetch };
}

export function useGetLeavePlanById(id: number){
    const url = ENDPOINTS.HR.LEAVE_PLAN.DETAIL;
    const request = API.HrAPI.getLeavePlanById(id);

    const { data, isLoading, error } = useQuery<{ data: LeavePlanDto }, Error>(
        [url, id],
        (() => request),
        { enabled: !!(id) },
    );
    
    return { data, isLoading, error };
}


export function useCreateLeavePlan() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload }: { payload: CreateLeavePlanDto }) => API.HrAPI.createLeavePlan(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.HR.LEAVE_PLAN.GET);
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useUpdateLeavePlan() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id, payload }: { id: number, payload: UpdateLeavePlanDto }) => API.HrAPI.updateLeavePlan(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.HR.LEAVE_PLAN.GET);
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useDeleteLeavePlan() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id }: { id: number }) => API.HrAPI.deleteLeavePlan(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
                queryClient.invalidateQueries(ENDPOINTS.HR.LEAVE_PLAN.GET);
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}