import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import FormDescriptionInput from "../../../../components/form_fields/FormDescriptionInput";
import MoneyInput from "../../../../components/form_fields/MoneyInput";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import { formatRate, IAddEditTableForm } from "../../../../helpers/appHelpers";
import { useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import { EmployeeBandDto } from "../../../../models/employee-band.dto";
import { EmployeeDto } from "../../../../models/employee.dto";
import { useCreateLoanBenefit, useUpdateLoanBenefit } from "../../../../services/loan-benefit.service";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import Label from "../../../../components/form_fields/Label";
import FormCodeInput from "../../../../components/form_fields/FormCodeInput";
import MultiSelectField from "../../../../components/form_fields/MultiSelectField";
import { PATHS } from "../../../../routes/routes.paths";
import RateComponent from "../../../../components/form_fields/RateComponent";

interface IFormInputs {
	name: string;
	code: string;
	description: string;
    centralBankInterestRate: number;
    employerInterestRate: number;
    loanTenor: number;
    floor?: number;
    ceiling?: number;
	employeeIds?: number[];
	employeeBandIds?: number[];
}

type ILoanBenefitModalForm = IAddEditTableForm & {
	employees: EmployeeDto[];
	employeeBands: EmployeeBandDto[];
}

const LoanBenefitModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	employees,
	employeeBands,
}: ILoanBenefitModalForm) => {
	const { currentCompany } = useCompanyStore();
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);
	
	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			name: isEditForm ? formDataValues?.name : "",
			code: isEditForm ? formDataValues?.code : "",
			description: isEditForm ? formDataValues?.description : "",
			centralBankInterestRate: isEditForm ? (Number(formDataValues?.centralBankInterestRate) * 100) as any : "",
			employerInterestRate: isEditForm ? (Number(formDataValues?.employerInterestRate) * 100) as any : "",
			loanTenor: isEditForm ? formDataValues?.loanTenor : "",
			floor: isEditForm ? formDataValues?.floor : undefined,
			ceiling: isEditForm ? formDataValues?.ceiling : undefined,
		}
	});

	const [employeeIds, setEmployeeIds] = useState<number[]>([]);
	const [employeeBandIds, setEmployeeBandIds] = useState<number[]>([]);
	const { mutate: createLoanBenefit } = useCreateLoanBenefit();
	const { mutate: updateLoanBenefit } = useUpdateLoanBenefit();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
		setUploading(true);
		formatRate(data, "centralBankInterestRate");
		formatRate(data, "employerInterestRate");

		if (isEditForm) {
			updateLoanBenefit(
				{ payload: data, id: formDataValues?.id }, 
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			data.employeeIds = employeeIds.length ? employeeIds as any : undefined;
			data.employeeBandIds = employeeBandIds.length ? employeeBandIds as any : undefined;

			createLoanBenefit(
				{ payload: { companyId: currentCompany?.id, ...data } },
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		reset();
		setOpen(false);
	};

	return (
		<ModalComponent
			width={775}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="mt-5 grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Name" for="name" />
						<input
							autoFocus
							placeholder={"Enter name"}
							type="text"
							{...register("name", {
								required: "Enter name here",
							})}
							id="name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.name
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.name && (
							<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Unique Code" for="code" />
						<FormCodeInput
							register={register}
							attributes={{
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.code
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.code && (
							<p className="text-red-500 text-sm mt-1">{errors.code.message}</p>
						)}
					</div>
					<div className="col-span-2 w-full">
                        <Label title="Description" for="description" optional />
						<FormDescriptionInput 
							register={register}
							attributes={{
								defaultValue: isEditForm ? formDataValues?.description : "",
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.description
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`
							}}
							required={false}
						/>
						{errors.description && (
							<p className="text-red-500 text-sm mt-1">
								{errors.description.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Central Bank Interest Rate" for="centralBankInterestRate" />
						<RateComponent 
							attributes={{ 
								id: "centralBankInterestRate",
							}}
							register={register}
							name="centralBankInterestRate"
							required="Enter central bank interest rate here"
							error={errors.centralBankInterestRate}
						/>
						{errors.centralBankInterestRate && (
							<p className="text-red-500 text-sm mt-1">
								{errors.centralBankInterestRate.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Employer Interest Rate" for="employerInterestRate" />
						<RateComponent 
							attributes={{ 
								id: "employerInterestRate",
							}}
							register={register}
							name="employerInterestRate"
							required="Enter employer interest rate here"
							error={errors.employerInterestRate}
						/>
						{errors.employerInterestRate && (
							<p className="text-red-500 text-sm mt-1">
								{errors.employerInterestRate.message}
							</p>
						)}
					</div>
                    <div className="w-full">
                        <Label title="Floor" for="floor" optional />
                        <MoneyInput 
                            attributes={{
                                id: "floor",
                                placeholder: "0"
                            }}
                            register={register}
                            name="floor"
                            setValue={setValue}
                            defaultValue={isEditForm ? formDataValues?.floor : undefined}
                            required={false}
                            error={errors.floor}
                        />
                        {errors.floor && (
                            <p className="text-red-500 text-sm mt-1">
                                {errors.floor.message}
                            </p>
                        )}
                    </div>
                    <div className="w-full">
                        <Label title="Ceiling" for="ceiling" optional />
                        <MoneyInput 
                            attributes={{
                                id: "ceiling",
                                placeholder: "0"
                            }}
                            register={register}
                            name="ceiling"
                            setValue={setValue}
                            defaultValue={isEditForm ? formDataValues?.ceiling : undefined}
                            required={false}
                            error={errors.ceiling}
                        />
                        {errors.ceiling && (
                            <p className="text-red-500 text-sm mt-1">
                                {errors.ceiling.message}
                            </p>
                        )}
                    </div>
					<div className="flex flex-col w-full">
						<Label title="Loan Tenor" for="loanTenor" />
						<div className="flex items-center mt-2 h-14">
							<input
								placeholder="Enter value"
								type="number"
								{...register("loanTenor", {
									required: "Enter loan tenor here",
									valueAsNumber: true,
								})}
								id="loanTenor"
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 text-sm  ${
									errors.loanTenor
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							/>
							<div className="h-full w-36 text-sm bg-black rounded-r-lg text-white flex items-center justify-center">
								Months
							</div>
						</div>
						{errors.loanTenor && (
							<p className="text-red-500 text-sm mt-1">{errors.loanTenor.message}</p>
						)}
					</div>
					{!isEditForm && (<>
						<div className="w-full">
							<Label title="Assign Employees" for="employeeIds" optional />
							<MultiSelectField
								key={"employeeIds"}
								options={employees}
								setResourceIds={setEmployeeIds}
								placeholder={"Select Employees"}
								pathname={PATHS.EMPLOYEES}
								required={false}
								mapTexts={["firstName", "lastName"]}
							/>
						</div>
						<div className="w-full">
							<Label title="Assign Employee Bands" for="employeeBandIds" optional />
							<MultiSelectField
								key={"employeeBandIds"}
								options={employeeBands}
								setResourceIds={setEmployeeBandIds}
								placeholder={"Select Bands"}
								pathname={PATHS.COMPANY_SETTINGS + "?view=bands"}
								required={false}
							/>
						</div>
					</>)}
				</div>
				<div className="w-full flex justify-end mt-12">
					<OrangeButton
						type="submit"
						title={`${
							uploading 
							? "Uploading..." 
							: isEditForm 
								? "Update Loan Benefit" 
								: "Create Loan Benefit"}`
						}
						className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default LoanBenefitModalForm;