import { Avatar } from "@mui/material";
import { createdAtmodifiedAtActions, formatDate } from "../../../helpers/appHelpers";

export const DisciplinaryActionsColumns = [
	{
		field: "actionNumber",
		headerName: "Action Number",
		width: 180,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "employee",
		headerName: "Employee",
		headerClassName: "super-app-theme--header",
		width: 250,
		renderCell: (params: any) => {
			const employee = params.row.employee || undefined;
			return (
				<div className="flex items-center space-x-4">
					{employee && <>
						<Avatar
							src={employee.photoUrl || ""}
							alt="employee"
							className="w-10 h-10 rounded-full"
						/>
						<p className="text-sm truncate">
							{employee.firstName + " " + employee.lastName}
						</p>
					</>}
				</div>
			);
		},
	},
	{
		field: "actionType",
		headerName: "Action Type",
		headerClassName: "super-app-theme--header",
		width: 200,
		valueGetter: (params) => params.value.name,
	},
	{
		field: "notes",
		headerName: "Note",
		width: 350,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "actionDate",
		headerName: "Action Date",
		headerClassName: "super-app-theme--header",
		width: 220,
		valueGetter: (params) => formatDate(params),
	},
	{
		field: "grievanceReport",
		headerName: "Grievance Report Number",
		headerClassName: "super-app-theme--header",
		width: 280,
		valueGetter: (params) => params.value?.reportNumber || "--",
	},
	
	...createdAtmodifiedAtActions
];

export const DisciplinaryActionsColumnVisibilityModel: Record<string, boolean> = {
	actionNumber: true,
	employee: true,
	actionType: true,
	notes: true,
	actionDate: true,
	grievanceReport: false,
	createdAt: true,
	modifiedAt: true,
};

export const GrievanceReportsColumns = [
	{
		field: "reportNumber",
		headerName: "Report Number",
		headerClassName: "super-app-theme--header",
		width: 180,
	},
	{
		field: "reportingEmployee",
		headerName: "Reporting Employee",
		headerClassName: "super-app-theme--header",
		width: 250,
		renderCell: (params: any) => {
			const reportingEmployee = params.row.reportingEmployee || undefined;
			return (
				<div className="flex items-center space-x-4">
					{reportingEmployee && <>
						<Avatar
							src={reportingEmployee.photoUrl || ""}
							alt="reportingEmployee"
							className="w-10 h-10 rounded-full"
						/>
						<p className="text-sm truncate">
							{reportingEmployee.firstName + " " + reportingEmployee.lastName}
						</p>
					</>}
				</div>
			);
		},
	},
	{
		field: "grievanceType",
		headerName: "Grievance Type",
		headerClassName: "super-app-theme--header",
		width: 200,
		valueGetter: (params) => params.value.name,
	},
	{
		field: "note",
		headerName: "Note",
		width: 350,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "reportDate",
		headerName: "Report Date",
		headerClassName: "super-app-theme--header",
		width: 220,
		valueGetter: (params) => formatDate(params),
	},
	
	...createdAtmodifiedAtActions
];
