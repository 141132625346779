import { useState } from "react";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import { SubmitHandler, useForm } from "react-hook-form";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import Label from "../../../../components/form_fields/Label";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import APISelectField from "../../../../components/form_fields/APISelectField";
import { EmployeeDto } from "../../../../models/employee.dto";
import { DisciplinaryActionType } from "../../../../models/disciplinary-action-type.dto";
import { GrievanceReportDto } from "../../../../models/grievance-report.dto";
import { IAddEditTableForm } from "../../../../helpers/appHelpers";
import { 
	useCreateDisciplinaryAction, 
	useUpdateDisciplinaryAction 
} from "../../../../services/disciplinary-action.service";
import { useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import useUserStore from "../../../../state-management/useUserStore";

interface IFormInputs {
	employeeId: number;
	actionTypeId: number;
	notes: string;
	actionDate: string;
	grievanceReportId?: number;
}

interface IDisciplinaryModalForm extends IAddEditTableForm {
	employees: EmployeeDto[];
	actionTypes: DisciplinaryActionType[];
	grievanceReports: GrievanceReportDto[];
}

const DisciplinaryModalForm = ({
	open,
	title,
	setOpen,
	isEditForm,
	tableAPIRef,
	employees,
	actionTypes,
	grievanceReports,
}: IDisciplinaryModalForm) => {
	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);
	
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			employeeId: isEditForm ? formDataValues?.employeeId : undefined,
			actionTypeId: isEditForm ? formDataValues?.actionTypeId : undefined,
			grievanceReportId: isEditForm ? formDataValues?.grievanceReportId : undefined,
			notes: isEditForm ? formDataValues?.notes : "",
			actionDate: isEditForm ? formDataValues?.actionDate?.slice(0, 10) : "",
		}
	});

	const { mutate: createGrievanceType } = useCreateDisciplinaryAction();
	const { mutate: updateGrievanceType } = useUpdateDisciplinaryAction();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		!data.grievanceReportId && delete data.grievanceReportId;

		if (isEditForm) {
			delete (data as any).employeeId;
			
			updateGrievanceType(
				{ payload: data, id: formDataValues?.id }, 
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			createGrievanceType(
				{ payload: { ...data, companyId: companyProfile.companyId } }, 
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		reset();
		setOpen(false);
	};

	return (
		<ModalComponent
			width={775}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="mt-5 grid grid-cols-2 gap-x-4 gap-y-6">
					{!isEditForm && (
						<div className="w-full">
							<Label title="Employee" for="employeeId" />
							<select
								defaultValue={isEditForm ? formDataValues?.employeeId : ""}
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
									errors.employeeId
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("employeeId", {
									required: "Select employee here",
									valueAsNumber: true,
								})}
							>
								<option value="">
									Select Employee
								</option>
								{employees.map((employee) => (
									<option 
										key={employee.id} 
										value={employee.id} 
									>
										{employee.firstName + " " + employee.lastName}
									</option>
								))}
							</select>
							{errors.employeeId && (
								<p className="text-red-500 text-sm mt-1">
									{errors.employeeId.message}
								</p>
							)}
						</div>
					)}
					<div className="w-full">
						<Label title="Action Type" for="actionTypeId" />
						<select
							defaultValue={isEditForm ? formDataValues?.actionTypeId : ""}
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.actionTypeId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("actionTypeId", {
								required: "Select action type here",
							})}
						>
							<option value="">
								Select Action Type
							</option>
							{actionTypes.map((actionType) => (
								<option 
									key={actionType.id} 
									value={actionType.id} 
								>
									{actionType.name}
								</option>
							))}
						</select>
						{errors.actionTypeId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.actionTypeId.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Action Date" for="actionDate" />
						<input
							placeholder={"mm/dd/yyyy"}
							defaultValue={
								isEditForm ? formDataValues?.actionDate?.slice(0, 10) : ""
							}
							type="date"
							{...register("actionDate", {
								required: "Enter action date here",
							})}
							id="actionDate"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.actionDate
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.actionDate && (
							<p className="text-red-500 text-sm mt-1">
								{errors.actionDate.message}
							</p>
						)}
					</div>
					{isEditForm && (
						<div className="w-full col-span-2">
							<Label title="Note" for="notes" />
							<div className="flex items-center">
								<input
									placeholder={"Write a brief note"}
									defaultValue={formDataValues?.notes}
									type="string"
									{...register("notes", {
										required: "Enter note here",
									})}
									id="notes"
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
										errors.notes
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
								/>
							</div>
							{errors.notes && (
								<p className="text-red-500 text-sm mt-1">
									{errors.notes.message}
								</p>
							)}
						</div>
					)}
					<div className="w-full">
						<Label title="Grievance Report" for="grievanceReportId" optional />
						<select
							defaultValue={isEditForm ? formDataValues?.grievanceReportId : ""}
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.grievanceReportId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("grievanceReportId", {
								required: false,
								valueAsNumber: true,
							})}
						>
							<option value="">
								Select Grievance Report
							</option>
							{grievanceReports.map((report) => (
								<option 
									key={report.id} 
									value={report.id} 
								>
									{report.reportNumber} ({report.grievanceType?.name || report.note})
								</option>
							))}
						</select>
						{errors.grievanceReportId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.grievanceReportId.message}
							</p>
						)}
					</div>
					{!isEditForm && (
						<div className="w-full col-span-2">
							<Label title="Note" for="notes" />
							<div className="flex items-center">
								<input
									placeholder={"Write a brief note"}
									type="string"
									{...register("notes", {
										required: "Enter note here",
									})}
									id="notes"
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
										errors.notes
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
								/>
							</div>
							{errors.notes && (
								<p className="text-red-500 text-sm mt-1">
									{errors.notes.message}
								</p>
							)}
						</div>
					)}
				</div>

				<div className="w-full flex justify-end mt-12">
					<OrangeButton
						title={`${
							uploading 
								? "Uploading..." 
								: isEditForm 
								? "Update Disciplinary Action" 
								: "Add Disciplinary Action"
						}`}
						className={"h-14 px-12"}
						type="submit"
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default DisciplinaryModalForm;
