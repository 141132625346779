import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    OvertimeDto, OvertimePaginator,
    CreateOvertimeDto, QueryOvertimeDto,
    UpdateOvertimeDto,
    SearchOvertimeDto
} from "../models/overtime.dto";
import ENDPOINTS from "./utils/endpoints";
import {QueryOptionsDto} from "../models/generic.dto";

export function useGetOvertime(options?: QueryOvertimeDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.OVERTIME.GET;
    const request = (options: QueryOvertimeDto) => API.ProcessingCompanyAPI.getOvertime(options);

    const { data, isLoading, error, refetch } = useQuery<OvertimePaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch }
}

export function useGetOvertimeSearch() {

    return useMutation(
        (options?: SearchOvertimeDto) => API.ProcessingCompanyAPI.getOvertimeSearch(options),
        {
            onSuccess: () => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetOvertimeById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.OVERTIME.DETAIL;
    const request = API.ProcessingCompanyAPI.getOvertimeById(id);

    const {data, isLoading, error} = useQuery<{ data:OvertimeDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateOvertime() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateOvertimeDto}) => API.ProcessingCompanyAPI.createOvertime(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.OVERTIME.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateOvertime() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateOvertimeDto, id: number}) => API.ProcessingCompanyAPI.updateOvertime(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.OVERTIME.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useDeleteOvertime() {

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteOvertime(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}