import { GridColDef } from "@mui/x-data-grid";
import { moneyFormat } from "../../../helpers/appHelpers";

export const Columns: GridColDef[] = [
	{
		field: "payPeriod",
		headerName: "Pay Period",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.code,
	},
	{
		field: "basicPayAmount",
		headerName: "Basic Pay",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "grossAmount",
		headerName: "Gross Pay",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "netAmount",
		headerName: "Net Pay",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "employeePensionAmount",
		headerName: "Employee Pension Contribution",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "employerPensionAmount",
		headerName: "Employer Pension Contribution",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "taxAmount",
		headerName: "Income Tax",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "employeeSavingSchemeAmount",
		headerName: "Employee Saving Scheme Amount",
		width: 280,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "employerSavingSchemeAmount",
		headerName: "Employer Saving Scheme Amount",
		width: 280,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "allowanceAmount",
		headerName: "Allowance Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "overtimeAmount",
		headerName: "Overtime Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "bonusAmount",
		headerName: "Bonus Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "backpayAmount",
		headerName: "Backpay Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "loanRepaymentAmount",
		headerName: "Loan Repayment Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "partialSalaryAmount",
		headerName: "Partial Salary Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "salaryAdvanceAmount",
		headerName: "Salary Advance Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "unionDeductionsAmount",
		headerName: "Union Deductions Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "actions",
		headerName: "Actions",
		width: 150,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
];

export const PayslipsColumnVisibilityModel: Record<string, boolean> = {
	payPeriod: true,
	grossAmount: true,
	basicPayAmount: true,
	netAmount: true,
	employeePensionAmount: true,
	employerPensionAmount: true,
	taxAmount: true,
	employeeSavingSchemeAmount: false,
	employerSavingSchemeAmount: false,
	allowanceAmount: false,
	overtimeAmount: false,
	bonusAmount: false,
	backpayAmount: false,
	loanRepaymentAmount: false,
	partialSalaryAmount: false,
	salaryAdvanceAmount: false,
	unionDeductionsAmount: false,
};