import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
	formatRate,
	IAddEditTableForm,
} from "../../../../../../helpers/appHelpers";
import ModalComponent from "../../../../../../components/modals/ModalFormComponent";
import Label from "../../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import { CurrencyDto } from "../../../../../../models/currency.dto";
import { useCreateCountry, useUpdateCountry } from "../../../../../../services/country.service";
import { useManageTableWithSearchParamsData } from "../../../../../../helpers/useManageTable";
import RateComponent from "../../../../../../components/form_fields/RateComponent";

interface IFormInputs {
	code: string;
	name: string;
	flagUrl: string;
	currencyId: number;
	maxLoanDeductionPercentage?: number;
	applyLoanDeductionOnBasic?: string;
	active: string;
}

interface CountriesModalFormProps extends IAddEditTableForm {
	currencies: CurrencyDto[];
}

const CountriesModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	currencies,
}: CountriesModalFormProps) => {
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = React.useState(false);
	const [maxLoanDeductionPercentage, setMaxLoanDeductionPercentage] = React.useState(false);

	const handleMaxLoanDeductionPercentage = (value: string | number) => {
		if (value.toString().length) {
			setMaxLoanDeductionPercentage(true);
		} else {
			setMaxLoanDeductionPercentage(false);
		}
	};

	React.useEffect(() => {
		if (isEditForm) {
			formDataValues?.maxLoanDeductionPercentage !== null ? 
			handleMaxLoanDeductionPercentage(formDataValues?.maxLoanDeductionPercentage)
			: null 
		}
	}, [formDataValues, isEditForm])

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			name: isEditForm ? formDataValues?.name : "",
			code: isEditForm ? formDataValues?.code : "",
			flagUrl: isEditForm ? formDataValues?.flagUrl : "",
			maxLoanDeductionPercentage: isEditForm 
				? formDataValues?.maxLoanDeductionPercentage !== null 
					? Number(formDataValues?.maxLoanDeductionPercentage) * 100 
					: undefined 
				: undefined,
			applyLoanDeductionOnBasic: isEditForm ? formDataValues?.applyLoanDeductionOnBasic : "",
			currencyId: isEditForm ? formDataValues?.currencyId : "",
			active: isEditForm ? (formDataValues?.active ? "yes" : "no") : "",
		}
	});

	const { mutate: createCountry } = useCreateCountry();
	const { mutate: updateCountry } = useUpdateCountry();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);
		formatRate(data, "maxLoanDeductionPercentage");

        const active = data.active === "yes" ? true : false;
        const applyLoanDeductionOnBasic = data.applyLoanDeductionOnBasic === "yes" ? true : 
			data.applyLoanDeductionOnBasic === "no" ? false : undefined;

		if (isEditForm) {
			delete (data as any).code;
			updateCountry(
				{
                    payload: {
                        ...data,
                        active,
                        applyLoanDeductionOnBasic,
                    }, 
                    id: formDataValues?.id
                }, 
                {
                    onSuccess: (updatedData: any) => {
                        tableAPIRef?.current.updateRows([updatedData.data]);
                        setOpen(false);
                        reset();
                    },
                    onSettled: () => setUploading(false),
                }
            );
		} else {
			createCountry(
				{
                    payload: {
                        ...data,
                        active,
                        applyLoanDeductionOnBasic,
                    }
                },
				{
                    onSuccess: () => {
                        addNewTableData()
                        setOpen(false);
                        reset();
                    },
                    onSettled: () => setUploading(false),
                }
            );
		}
	};

	const handleClose = () => {
		reset();
		setOpen(false);
	};

	return (
		<ModalComponent
			width={700}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Name" for="name" />
						<input
							defaultValue={isEditForm ? formDataValues?.name : ""}
							placeholder={"Enter name"}
							type="text"
							{...register("name", {
								required: "Enter name here",
							})}
							id="name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.name
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.name && (
							<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Country Code" for="code" />
						<input
							defaultValue={isEditForm ? formDataValues?.code : ""}
							placeholder={"Enter code"}
							type="text"
							{...register("code", {
								required: "Enter country code here",
							})}
							id="code"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.code
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.code && (
							<p className="text-red-500 text-sm mt-1">{errors.code.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Currency" for="currencyId" />
						<select
							defaultValue={isEditForm ? formDataValues?.currencyId : ""}
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.currencyId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("currencyId", {
								required: "Select currency here",
								valueAsNumber: true,
							})}
						>
							<option value="">Select Currency</option>
							{currencies.map((currency) => (
								<option 
									key={currency.id} 
									value={currency.id}
								>
									{currency.name}
								</option>
							))}
						</select>
						{errors.currencyId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.currencyId.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Country Flag URL" for="flagUrl" />
						<input
							defaultValue={isEditForm ? formDataValues?.flagUrl : ""}
							placeholder={"Enter Flag Url"}
							type="text"
							{...register("flagUrl", {
								required: "Enter Flag Url here",
							})}
							id="name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.flagUrl
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.flagUrl && (
							<p className="text-red-500 text-sm mt-1">
								{errors.flagUrl.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Max Loan Deduction Percentage" for="maxLoanDeductionPercentage" optional />
						<RateComponent 
							attributes={{ 
								id: "maxLoanDeductionPercentage",
								onChange: (e) => handleMaxLoanDeductionPercentage(e.target.value),
							}}
							register={register}
							name="maxLoanDeductionPercentage"
							required={false}
							error={errors.maxLoanDeductionPercentage}
						/>
					</div>
					<div className="col-span-2">
						<div className="flex space-x-9 font-light">
							<h3>Active</h3>
							<div className="flex space-x-3 items-center">
								<input
									{...register("active")}
									defaultChecked={isEditForm && formDataValues?.active === true}
									type="radio"
									value={"yes"}
									id="active"
								/>
								<label htmlFor="active">Yes</label>
							</div>
							<div className="flex space-x-3 items-center">
								<input
									{...register("active")}
									defaultChecked={isEditForm && formDataValues?.active === false}
									type="radio"
									value={"no"}
									id="inactive"
								/>
								<label htmlFor="inactive">No</label>
							</div>
						</div>
					</div>
					{maxLoanDeductionPercentage &&
						<div className="col-span-2">
							<div className="flex space-x-9 font-light">
								<h3>Apply loan deduction on basic</h3>
								<div className="flex space-x-3 items-center">
									<input
										{...register("applyLoanDeductionOnBasic", {
											required: "Pick an answer to continue"
										})}
										defaultChecked={isEditForm && formDataValues?.applyLoanDeductionOnBasic === true}
										type="radio"
										value={"yes"}
										id="applyLoanDeductionOnBasic"
									/>
									<label htmlFor="applyLoanDeductionOnBasic">Yes</label>
								</div>
								<div className="flex space-x-3 items-center">
									<input
										{...register("applyLoanDeductionOnBasic")}
										defaultChecked={isEditForm && formDataValues?.applyLoanDeductionOnBasic === false}
										type="radio"
										value={"no"}
										id="inapplyLoanDeductionOnBasic"
									/>
									<label htmlFor="inapplyLoanDeductionOnBasic">No</label>
								</div>
							</div>
							{errors.applyLoanDeductionOnBasic && (
								<p className="text-red-500 text-sm mt-1">
									{errors.applyLoanDeductionOnBasic.message}
								</p>
							)}
						</div>
					}
				</div>
				<div className="w-full flex justify-end mt-10">
					<OrangeButton
						title={`${
							uploading
								? "Uploading..."
								: isEditForm
									? "Update Country"
									: "Add Country"
						}`}
						className={`px-8`}
						type={"submit"}
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default CountriesModalForm;
