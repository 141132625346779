import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import useCompanyStore from "../../../../../../state-management/useCompanyStore";
import { EmployeeDto } from "../../../../../../models/employee.dto";
import { useCreateDepartmentLeadership } from "../../../../../../services/department-leadership.service";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import APISelectField from "../../../../../../components/form_fields/APISelectField";

interface IFormInputs {
	departmentId: number;
	rank: number;
	permanent?: string;
	employeeId?: number;
}

interface INewLeaderForm {
	employees: EmployeeDto[];
	setShowNewLeaderForm: React.Dispatch<React.SetStateAction<boolean>>;
	definedRanks?: number[];
}

const NewLeaderForm = ({ employees, setShowNewLeaderForm, definedRanks }: INewLeaderForm) => {
	const { formDataValues } = useEditFormStore();
	const { currentCompany } = useCompanyStore();
	const { mutate: createLeader } = useCreateDepartmentLeadership();
	const [uploading, setUploading] = useState(false);
	
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>();
	
	const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
		setUploading(true);

		data.permanent = data.permanent === "yes" ? true : false;
		data.departmentId = formDataValues?.id;

		createLeader(
			{ payload: data, companyId: currentCompany?.id as number },
			{
				onSuccess: () => setShowNewLeaderForm(false),
				onSettled: () => setUploading(false),
			}
		)
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-6">
				<div className="w-full">
					<Label title="Rank" for="rank" />
					<select
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
							errors.rank
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
						{...register("rank", {
							required: "Select rank here",
							valueAsNumber: true,
						})}
					>
						<option value="">Select Rank</option>
						{ranks.map((rank) => {
							const defined = definedRanks?.includes(rank.id);
							return !defined ? (
								<option key={rank.id} value={rank.id}>
									{rank.name}
								</option>
							) : null;
						})}
					</select>
					{errors.rank && (
						<p className="text-red-500 text-sm mt-1">
							{errors.rank.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Role Status" for="permanent" />
					<select
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
							errors.permanent
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
						{...register("permanent", {
							required: "Select role status here",
						})}
					>
						<option value="">Select Role Status</option>
						<option value="yes">Permanent</option>
						<option value="no">Acting</option>
					</select>
					{errors.permanent && (
						<p className="text-red-500 text-sm mt-1">
							{errors.permanent.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Assign Employee" for="employeeId" optional />
					<APISelectField 
						attributes={{
							id: "employeeId",
						}}
						placeholder="Select Employee"
						register={register}
						name="employeeId"
						options={employees}
						pathname=""
						mapTexts={["firstName", "lastName"]}
						required={false}
						error={errors.employeeId}
						doNotLinkToResource
						valueAsNumber
					/>
					{errors.employeeId && (
						<p className="text-red-500 text-sm mt-1">
							{errors.employeeId.message}
						</p>
					)}
				</div>
			</div>

			<div className="w-full flex items-center justify-between my-8">
				<button
					onClick={() => setShowNewLeaderForm(false)}
					className="px-10 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
				>
					Close Form
				</button>
				<OrangeButton
					title={`${uploading ? "Saving..." : "Add Leader"}`}
					type="submit"
					className={"h-14 px-8"}
					disabled={uploading}
				/>
			</div>
		</form>
	);
};

export default NewLeaderForm;

const ranks = [
	{ id: 0, name: "Head" },
	{ id: 1, name: "First Deputy" },
	{ id: 2, name: "Second Deputy" },
	{ id: 3, name: "Third Deputy" },
	{ id: 4, name: "Fourth Deputy" },
	{ id: 5, name: "Fifth Deputy" },
];
