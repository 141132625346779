import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    TaxCodeDto, TaxCodePaginator,
    CreateTaxCodeDto, QueryTaxCodeDto,
    UpdateTaxCodeDto, SetDefaultTaxCodeDto, SearchTaxCodeDto
} from "../models/tax-code.dto";
import ENDPOINTS from "./utils/endpoints";
import {QueryOptionsDto} from "../models/generic.dto";

export function useGetTaxCode(options?: QueryTaxCodeDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.TAX_CODE.GET;
    const request = (options: QueryTaxCodeDto) => API.ProcessingCompanyAPI.getTaxCode(options);

    const { data, isLoading, error, refetch } = useQuery<TaxCodePaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch };
}

export function useGetTaxCodeSearch() {

    return useMutation(
        (options?: SearchTaxCodeDto) => API.ProcessingCompanyAPI.getTaxCodeSearch(options),
        {
            onSuccess: () => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetTaxCodeById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.TAX_CODE.DETAIL;
    const request = API.ProcessingCompanyAPI.getTaxCodeById(id);

    const {data, isLoading, error} = useQuery<{ data: TaxCodeDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateTaxCode() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateTaxCodeDto}) => API.ProcessingCompanyAPI.createTaxCode(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.TAX_CODE.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateTaxCode() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateTaxCodeDto, id: number}) => API.ProcessingCompanyAPI.updateTaxCode(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.TAX_CODE.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useDeleteTaxCode() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id }: { id: number }) => API.ProcessingCompanyAPI.deleteTaxCode(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useSetDefaultTaxCode() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: SetDefaultTaxCodeDto, id: number}) => API.ProcessingCompanyAPI.setDefaultTaxCode(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.TAX_CODE.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}
