import { useEffect, useState } from "react";
import ModalComponent from "../../../../../../components/modals/ModalFormComponent";
import Label from "../../../../../../components/form_fields/Label";
import { SubmitHandler, useForm } from "react-hook-form";
import { useCreatePension, useUpdatePension } from "../../../../../../services/pension.service";
import { PENSION_CONTRIBUTION_FORMULA_TYPE } from "../../../../../../models/pension.dto";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import { formatRate, IAddEditTableForm } from "../../../../../../helpers/appHelpers";
import FormDescriptionInput from "../../../../../../components/form_fields/FormDescriptionInput";
import MoneyInput from "../../../../../../components/form_fields/MoneyInput";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import { useManageTableWithSearchParamsData } from "../../../../../../helpers/useManageTable";
import useCompanyStore from "../../../../../../state-management/useCompanyStore";
import { CountryDto } from "../../../../../../models/country.dto";
import FormCodeInput from "../../../../../../components/form_fields/FormCodeInput";
import RateComponent from "../../../../../../components/form_fields/RateComponent";

interface IFormInputs {
    countryId: number;
	name: string;
	tier: number;
	code: string;
	description: string;
    employeeContributionFormulaType: PENSION_CONTRIBUTION_FORMULA_TYPE;
    employeeFixedComponent: number;
    employeeRateComponent: number;
    employerContributionFormulaType: PENSION_CONTRIBUTION_FORMULA_TYPE;
    employerFixedComponent: number;
    employerRateComponent: number;
    active?: string;
}

type PensionModalFormProps = IAddEditTableForm & {
	countries: CountryDto[];
}

const PensionModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	countries,
}: PensionModalFormProps) => {
	const { currentCompany } = useCompanyStore();
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);

	const {
		register,
		watch,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			name: isEditForm ? formDataValues?.name : undefined,
			code: isEditForm ? formDataValues?.code : undefined,
			tier: isEditForm ? formDataValues?.tier : undefined,
			description: isEditForm ? formDataValues?.description : undefined,
			employeeContributionFormulaType: isEditForm ? formDataValues?.employeeContributionFormulaType : undefined,
			employeeFixedComponent: isEditForm ? formDataValues?.employeeFixedComponent : undefined,
			employeeRateComponent: isEditForm 
				? (Number(formDataValues?.employeeRateComponent) * 100) : undefined,
			employerContributionFormulaType: isEditForm ? formDataValues?.employerContributionFormulaType : undefined,
			employerFixedComponent: isEditForm ? formDataValues?.employerFixedComponent : undefined,
			employerRateComponent: isEditForm 
				? (Number(formDataValues?.employerRateComponent) * 100) : undefined,
			active: isEditForm ? (formDataValues?.active ? "yes" : "no") : undefined,
		}
	});

	const employeeContributionFormulaType = watch("employeeContributionFormulaType");
	const employerContributionFormulaType = watch("employerContributionFormulaType");

	useEffect(() => {
		if (employeeContributionFormulaType === "FLAT") {
			setValue("employeeRateComponent", 0)
		} else if (employeeContributionFormulaType === "PERCENT") {
			setValue("employeeFixedComponent", 0)
		}
	}, [employeeContributionFormulaType])

	useEffect(() => {
		if (employerContributionFormulaType === "FLAT") {
			setValue("employerRateComponent", 0)
		} else if (employerContributionFormulaType === "PERCENT") {
			setValue("employeeFixedComponent", 0)
		}
	}, [employerContributionFormulaType])

	const { mutate: createPension } = useCreatePension();
	const { mutate: updatePension } = useUpdatePension();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
		setUploading(true);
		
		formatRate(data, "employeeRateComponent");
		formatRate(data, "employerRateComponent");
		data.active = data.active === "yes" ? true : false;

		if (isEditForm) {
			updatePension(
				{ payload: data, id: formDataValues?.id },
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			createPension(
				{
					payload: {
						...data,
						companyId: currentCompany?.id,
					},
				},
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		setOpen(false);
		reset();
	};

	return (
		<ModalComponent open={open} title={title} handleClose={handleClose}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Name" for="name" />
						<input
							placeholder={"Enter name"}
							type="text"
							{...register("name", {
								required: "Enter name here",
							})}
							id="name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.name
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.name && (
							<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Unique Code" for="code" />
						<FormCodeInput
							register={register}
							attributes={{
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.code
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.code && (
							<p className="text-red-500 text-sm mt-1">{errors.code.message}</p>
						)}
					</div>
					<div className="w-full col-span-2">
						<Label title="Description" for="description" />
						<FormDescriptionInput
							register={register}
							attributes={{
								defaultValue: isEditForm ? formDataValues?.description : "",
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.description
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.description && (
							<p className="text-red-500 text-sm mt-1">
								{errors.description.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Tier" for="tier" />
						<select
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.tier
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("tier", {
								required: "Select tier here",
								valueAsNumber: true,
							})}
						>
							<option value="">Select Tier</option>
							<option value={1}>1</option>
							<option value={2}>2</option>
							<option value={3}>3</option>
						</select>
						{errors.tier && (
							<p className="text-red-500 text-sm mt-1">{errors.tier.message}</p>
						)}
					</div>
					{!isEditForm &&
						<div className="w-full">
							<Label title="Country" for="countryId" />
							<select
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
									errors.countryId
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("countryId", {
									required: "Select country here",
									valueAsNumber: true,
								})}
							>
								<option value="">
									Select Country
								</option>
								{countries.map((country) => (
									<option key={country.id} value={country.id} >
										{country.name}
									</option>
								))}
							</select>
							{errors.countryId && (
								<p className="text-red-500 text-sm mt-1">
									{errors.countryId.message}
								</p>
							)}
						</div>
					}
				</div>
				<div className="mt-6">
					<h3 className="font-semibold text-base mb-2">
						Employee Contribution
					</h3>
					<div className="grid grid-cols-2 gap-x-4 gap-y-6">
						<div className="w-full">
							<Label
								title="Formula Type"
								for="employeeContributionFormulaType"
							/>
							<select
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.employeeContributionFormulaType
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("employeeContributionFormulaType", {
									required: "Select employee contribution formula type here",
								})}
							>
								<option value="">Select Formula Type</option>
								<option value="FLAT">Flat</option>
								<option value="PERCENT">Percent</option>
								<option value="HYBRID">Hybrid</option>
							</select>
							{errors.employeeContributionFormulaType && (
								<p className="text-red-500 text-sm mt-1">
									{errors.employeeContributionFormulaType.message}
								</p>
							)}
						</div>
						<div></div>
						<div className="w-full">
							<Label title="Fixed Component" for="employeeFixedComponent" />
							<MoneyInput 
								attributes={{
									id: "employeeFixedComponent",
									placeholder: "0",
									disabled: employeeContributionFormulaType === "PERCENT",
								}}
								register={register}
								name="employeeFixedComponent"
								setValue={setValue}
								defaultValue={isEditForm ? formDataValues?.employeeFixedComponent : undefined}
								clearValue={employeeContributionFormulaType === "PERCENT"}
								required={
									employeeContributionFormulaType === "PERCENT" 
										? false 
										: "Enter employee fixed component"
								}
								error={errors.employeeFixedComponent}
							/>
							{errors.employeeFixedComponent && (
								<p className="text-red-500 text-sm mt-1">
									{errors.employeeFixedComponent.message}
								</p>
							)}
						</div>
						<div className="w-full">
							<Label title="Rate Component" for="employeeRateComponent" />
							<RateComponent 
								attributes={{ 
									id: "employeeRateComponent",
									disabled: Boolean(employeeContributionFormulaType === "FLAT"),
								}}
								register={register}
								name="employeeRateComponent"
								required={employeeContributionFormulaType === "FLAT" ? false : "Enter employee rate component"}
								error={errors.employeeRateComponent}
							/>
							{errors.employeeRateComponent && (
								<p className="text-red-500 text-sm mt-1">
									{errors.employeeRateComponent.message}
								</p>
							)}
						</div>
					</div>
				</div>
				<div className="mt-6">
					<h3 className="font-semibold text-base mb-2">
						Employer Contribution
					</h3>
					<div className="grid grid-cols-2 gap-x-4 gap-y-6">
						<div className="w-full">
							<Label
								title="Formula Type"
								for="employerContributionFormulaType"
							/>
							<select
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.employerContributionFormulaType
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("employerContributionFormulaType", {
									required: "Select employer contribution formula type here",
								})}
							>
								<option value="">Select Formula Type</option>
								<option value="FLAT">Flat</option>
								<option value="PERCENT">Percent</option>
								<option value="HYBRID">Hybrid</option>
							</select>
							{errors.employerContributionFormulaType && (
								<p className="text-red-500 text-sm mt-1">
									{errors.employerContributionFormulaType.message}
								</p>
							)}
						</div>
						<div></div>
						<div className="w-full">
							<Label title="Fixed Component" for="employerFixedComponent" />
							<MoneyInput 
								attributes={{
									id: "employerFixedComponent",
									placeholder: "0",
									disabled: employerContributionFormulaType === "PERCENT",
								}}
								register={register}
								name="employerFixedComponent"
								setValue={setValue}
								defaultValue={isEditForm ? formDataValues?.employerFixedComponent : undefined}
								clearValue={employerContributionFormulaType === "PERCENT"}
								required={
									employerContributionFormulaType === "PERCENT" 
										? false 
										: "Enter employer fixed component"
								}
								error={errors.employerFixedComponent}
							/>
							{errors.employerFixedComponent && (
								<p className="text-red-500 text-sm mt-1">
									{errors.employerFixedComponent.message}
								</p>
							)}
						</div>
						<div className="w-full">
							<Label title="Rate Component" for="employerRateComponent" />
							<RateComponent 
								attributes={{ 
									id: "employerRateComponent",
									disabled: Boolean(employerContributionFormulaType === "FLAT"),
								}}
								register={register}
								name="employerRateComponent"
								required={employerContributionFormulaType === "FLAT" ? false : "Enter employer rate component"}
								error={errors.employerRateComponent}
							/>
							{errors.employerRateComponent && (
								<p className="text-red-500 text-sm mt-1">
									{errors.employerRateComponent.message}
								</p>
							)}
						</div>
						<div className="col-span-2">
							<div className="flex space-x-12 text-base">
								<h3>Active</h3>
								<div className="flex space-x-3 items-center">
									<input
										{...register("active")}
										type="radio"
										value={"yes"}
										id="active"
									/>
									<label htmlFor="active">Yes</label>
								</div>
								<div className="flex space-x-3 items-center">
									<input
										{...register("active")}
										type="radio"
										value={"no"}
										id="inactive"
									/>
									<label htmlFor="inactive">No</label>
								</div>
							</div>
							{errors.active && (
								<p className="text-red-500 text-sm mt-1">
									{errors.active.message}
								</p>
							)}
						</div>
					</div>
				</div>

				<div className="w-full flex justify-end mt-8">
					<OrangeButton 
						title={
							uploading 
							? "Uploading..." 
							: isEditForm 
								? "Update Pension Tier"
								: "Add Pension Tier"
						}
						type="submit"
						disabled={uploading} 
						className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default PensionModalForm;
