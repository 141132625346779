import {useQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    AssignedAssetDto, AssignedAssetPaginator,
    CreateAssignedAssetDto, QueryAssignedAssetDto,
    UpdateAssignedAssetDto
} from "../models/asset-assign.dto";
import ENDPOINTS from "./utils/endpoints";
import {QueryOptionsDto} from "../models/generic.dto";

export function useGetAssignedAsset(options?: QueryAssignedAssetDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.ASSIGN_ASSET.GET;
    const request = (options: QueryAssignedAssetDto) => API.ProcessingCompanyAPI.getAssignedAsset(options);

	const { data, isLoading, error, refetch } = useQuery<AssignedAssetPaginator, Error>(
		[url, options],
		({ queryKey, pageParam }) =>
			request(Object.assign({}, queryKey[1], pageParam)),
		{...API.Handlers.pageParamOption}
	);

	return { data, isLoading, error, refetch };
}

export function useGetAssignedAssetById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.ASSIGN_ASSET.DETAIL;
    const request = API.ProcessingCompanyAPI.getAssignedAssetById(id);

    const {data, isLoading, error} = useQuery<{ data:AssignedAssetDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateAssignedAsset() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateAssignedAssetDto}) => API.ProcessingCompanyAPI.createAssignedAsset(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.ASSIGN_ASSET.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateAssignedAsset() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateAssignedAssetDto, id: number}) => API.ProcessingCompanyAPI.updateAssignedAsset(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.ASSIGN_ASSET.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useDeleteAssignedAsset() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteAssignedAssetById(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.ASSIGN_ASSET.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}