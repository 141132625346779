import { useState } from "react";
import OnboardingLayout from "../../components/layouts/OnboardingLayout";
import { Link, useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../components/form_fields/Label";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Helmet } from "react-helmet-async";
import { useSignIn } from "../../services/auth.service";
import useUserStore from "../../state-management/useUserStore";
import OrangeButton from "../../components/buttons/OrangeButton";
import { useViewPort } from "../../helpers/useViewPort";
import { PATHS } from "../../routes/routes.paths";
import RotatePasswordModal from "./modals/RotatePasswordModal";

interface IFormInputs {
	username: string;
	password: string;
}

const Signin = () => {
	const navigate = useNavigate();
	const viewPortWidth = useViewPort();
	const { setCurrentUser, setUserType } = useUserStore();
	const [isShown, setIsShown] = useState(false);
	const [rotatePassword, setRotatePassword] = useState(false);
	const { mutate, isLoading } = useSignIn();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>();

	const signIn: SubmitHandler<IFormInputs> = (formData: IFormInputs) => {
		mutate(
			{ payload: formData },
			{
				onSuccess: (data) => {
					setCurrentUser({ ...data, loginTime: new Date() });

					const userCategory = data.user.category;
					const adminUser = data.user.organization?.billingType.admin;

					switch(userCategory.toLowerCase()) {
						case "operations":
							if (adminUser) {
								setUserType("ADMIN");
							} else {
								setUserType("ORGANIZATION");
							}
							break;
						case "hr":
							if (viewPortWidth < 1024) {
								setUserType("EMPLOYEE");
							} else {
								setUserType("HR");
							}
							break;
						case "employee":
							setUserType("EMPLOYEE");
							break;
					}
				},
				onError: (error: any) => {
					if (error.response?.data?.error?.includes("PASSWORD_CHANGE_REQUIRED")) {
						setRotatePassword(true);
					}
				}
			}
		);
	};

	return (
		<OnboardingLayout>
			<Helmet>
				<title>Sign In | Akatua</title>
			</Helmet>
			<div className="flex flex-col lg:px-24 px-4 justify-center w-full my-16 lg:my-0">
				<img src="/assets/logos/akatua-logo.svg" alt="akatua" width={130} />
				<h3 className="mt-6 text-lg font-bold">Hey 👋, welcome to Akatua</h3>
				<form onSubmit={handleSubmit(signIn)} className="mt-4 text-charcoal">
					<div className="mt-6 flex flex-col lg:flex-row lg:items-center lg:space-x-4 w-full">
						<div className="w-full">
							<Label title="Username" for="username" />
							<input
								type="text"
								{...register("username", {
									required: "Enter your username",
								})}
								id="username"
								placeholder="Enter username"
								className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm ${
									errors.username
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								disabled={isLoading}
							/>
							{errors.username && (
								<p className="text-red-500 text-sm mt-1">
									{errors.username.message}
								</p>
							)}
						</div>
						<div className="w-full mt-4 lg:mt-0">
							<Label title="Password" for="password" />
							<div className="relative">
								<input
									type={isShown === false ? "password" : "text"}
									id={"password"}
									placeholder={"Enter password"}
									className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm  ${
										errors.password
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
									{...register("password", {
										required: "Enter your password",
										minLength: {
											value: 8,
											message: "Password should be at least 8 characters",
										},
									})}
									disabled={isLoading}
								/>

								<div
									className={`absolute top-[26px] right-6 block `}
									onClick={() => {
										setIsShown(!isShown);
									}}
								>
									{isShown === false ? (
										<BsEyeSlash className="text-gray-400 text-lg" />
									) : (
										<BsEye className="text-gray-400 text-lg" />
									)}
								</div>
							</div>
							{errors.password && (
								<p className="text-red-500 text-sm mt-1">
									{errors.password.message}
								</p>
							)}
						</div>
					</div>
					<div className="flex justify-between mt-4 text-sm">
						<div className="flex items-center space-x-2 ">
							<input type="checkbox" name="remember" id="remember" />
							<label className="text-xs" htmlFor={"remember"}>
								Keep me signed in
							</label>
						</div>
						<p
							className="text-xs text-primary-blue hover:cursor-pointer"
							onClick={() => navigate(PATHS.FORGOT_PASSWORD)}
						>
							Forgot my password
						</p>
					</div>
					<div className="w-[50%]">
						<OrangeButton
							type="submit"
							title={isLoading ? "Loading..." : "Sign in"}
							className="w-full mt-10"
							disabled={isLoading}
						/>
					</div>
				</form>
				<p className="text-xs mt-6">
					Don't have an account?{" "}
					<span className="text-primary-blue">
						<Link to={PATHS.SIGN_UP}>Sign up</Link>
					</span>
				</p>
			</div>
			{rotatePassword && (
				<RotatePasswordModal
					open={rotatePassword}
					setOpen={setRotatePassword}
				/>
			)}
		</OnboardingLayout>
	);
};

export default Signin;