import { UseFormRegister, FieldError } from "react-hook-form";
import useUserStore from "../../state-management/useUserStore";
import useCompanyStore from "../../state-management/useCompanyStore";

type RateComponentProps = {
    attributes: React.InputHTMLAttributes<HTMLInputElement>; 
    register: UseFormRegister<any>;
    name: string;
    required: boolean | string;
    validate?: (value: any) => boolean | string;
    error?: FieldError;
}

const RateComponent = ({
    attributes,
    register,
    name,
    required,
    validate,
    error,
}: RateComponentProps) => {
    const { userType } = useUserStore();
    const { currentCompany } = useCompanyStore();

    return (
        <div className="flex items-center h-14 mt-2">
            <input
                placeholder={"0"}
                type="number"
                step="any"
                {...register(name, {
                    required: required,
                    valueAsNumber: true,
                    validate: validate,
                })}
                min={(userType === "ORGANIZATION" && currentCompany?.allowNegativeRates) ? undefined : 0}
                className={`w-full bg-secondary-gray rounded-l-lg h-full px-5 text-sm ${
                    error
                        ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                        : "border-none"
                }`}
                { ...attributes }
            />
            <div className="h-full w-28 flex items-center justify-center text-sm rounded-r-lg text-white bg-black px-2">
                %
            </div>
        </div>
    );
}
 
export default RateComponent;