import { GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import ActionPopover from "../../../../../components/popovers/actionPopover";
import useEditFormStore from "../../../../../state-management/useEditFormStore";
import { Columns } from "./data";
import TeamMemberModalForm from "./modal/TeamMemberModalForm";
import useUserStore from "../../../../../state-management/useUserStore";
import { APP_CONFIG } from "../../../../../helpers/appHelpers";
import CustomPagination from "../../../../../components/table/CustomPagination";
import APISearchInput from "../../../../../components/search/APISearchInput";
import { USER_CATEGORY, UserDto } from "../../../../../models/user.dto";
import { useGetUserSearch, useGetUser, useGetUserCount } from "../../../../../services/user.service";
import { useGetOrganizationRole } from "../../../../../services/organization-role.service";
import { OrganizationRoleOrderBy } from "../../../../../models/organization-role.dto";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import UpdateAccountConfigModal from "../../../../../components/modals/UpdateAccountConfigModal";
import { PATHS } from "../../../../../routes/routes.paths";
import CustomStyledTable from "../../../../../components/table/CustomStyledTable";
import { useManageTableWithStateVariables } from "../../../../../helpers/useManageTable";
import { Popover } from "@mui/material";
import { BsTrash } from "react-icons/bs";
import { LuEye, LuActivitySquare } from "react-icons/lu";
import { MdBlock } from "react-icons/md";
import TeamMemberActionModal from "./modal/TeamMemberActionModal";

type ActionMode = "REACTIVATE" | "DISABLE" | "DELETE";

const TeamMembers = () => {
	const navigate = useNavigate();
	const { currentUser } = useUserStore();
	const { savedCompanies } = useCompanyStore();
	const userOrganization = currentUser.user.organization;
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);
	const { formDataValues, setFormData } = useEditFormStore();
	const [openActionsModal, setOpenActionsModal] = useState(false);
	const [actionMode, setActionMode] = useState<ActionMode>("DISABLE");
	const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
	const [upgradeModalContent, setUpgradeModalContent] = useState("");
	const [onClickActionButton, setOnClickActionButton] = useState<(() => void) | null>(null);
	const highestSubscriptionTierId = sessionStorage.getItem("highestSubscriptionTierId");

	const { mutate: searchTeamMember } = useGetUserSearch();
	const {
        tableAPIRef,
        tableData,
        isLoading,
        page,
        pageCount,
		setTableData,
        addNewData,
		reloadData,
        handlePageChange,
	} = useManageTableWithStateVariables<UserDto>({ 
		fetchHook: useGetUser,
		options: {
			organizationId: currentUser.user.organizationId,
			category: USER_CATEGORY.OPERATIONS,
		}
	});

	const { data: userCount } = useGetUserCount({
		organizationId: currentUser.user.organizationId,
	})
	const { data: organizationRoles } = useGetOrganizationRole({
		organizationId: currentUser.user.organizationId,
		active: true,
		page: 1,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: OrganizationRoleOrderBy.NAME_ASC,
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		if (!userOrganization?.config) {
			toast.info("Organization configurations not found! Kindly sign out and sign back in to fix this.");
			return
		}
		
		const maxUsers = userOrganization?.config.maxUsers as number;
		
		if ((maxUsers < 0) || maxUsers > (userCount?.data.count as number)) {
			setAnchorEl(null);
			setIsEditForm(false);
			setOpenModal(true);
			return
		};

		if (userOrganization?.billingType.name.toLowerCase() === "contract") {
			setOpenUpgradeModal(true);
			// setOnClickActionButton(() => navigate(-1));
			setUpgradeModalContent("Maximum team member limit reached! Kindly contact the Akatua team to increase limit.");
			return
		}

		if (highestSubscriptionTierId === userOrganization?.subsciptionTier.id) {
			setOpenUpgradeModal(true);
			// setOnClickActionButton(() => navigate(-1));
			setUpgradeModalContent(`Maximum team member limit reached! Kindly contact the Akatua team to increase 
				limit as you are currently on the highest subscription package.`
			);
			return
		}

		setOpenUpgradeModal(true);
		setOnClickActionButton(() => navigate(PATHS.CREATE_PAYROLL_COMPANY + "?upgradeRequired=true"));
		setUpgradeModalContent("Maximum team member limit reached! Upgrade to higher subscription package to continue.");
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>
				];
			},
		},
	];
	
	const columnsWithAdditions = Columns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<div>
			<h2 className="text-2sm font-semibold mb-3">Team Members</h2>
			<hr />
			<div className="flex items-center justify-between mt-7 mb-8">
				<APISearchInput
					fallbackData={tableData}
					setTableData={setTableData}
					mutate={searchTeamMember}
					popupTable
				/>
				<div
					className="flex items-center text-primary-blue text-sm cursor-pointer w-fit"
					onClick={handleOpenModal}
				>
					<AiOutlinePlus className="mr-1 text-sm" />
					Add New Team Member
				</div>
			</div>
			<CustomStyledTable
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				loading={isLoading}
				pageCount={pageCount}
				pagination={
					<CustomPagination 
						pageCount={pageCount} 
						pageState={page}
						handleChange={handlePageChange}
					/>
				}
				setAnchorEl={setAnchorEl}
			/>
            <Popover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                PaperProps={{
                    style: {
                        width: "auto",
                        borderRadius: 8,
                        display: "flex",
                        justifyContent: "center",
                    },
                }}
            >
                <div className="flex flex-col justify-center p-3 text-secondary-darkgray">
                    <div
                        onClick={() => {
                            setIsEditForm(true);
                            setOpenModal(true);
                            handleClose();
                        }}
                        className="table-action-popover"
                    >
                        <LuEye />
                        <p className="text-2sm">View Details</p>
                    </div>
					{formDataValues?.status === "ACTIVE" && (
						<div
							onClick={() => {
								setActionMode("DISABLE");
								setOpenActionsModal(true);
								handleClose();
							}}
							className="table-action-popover"
						>
							<MdBlock />
							<p className="text-2sm">Disable Account</p>
						</div>
					)}
					{formDataValues?.status === "DISABLED" && (
						<div
							onClick={() => {
								setActionMode("REACTIVATE");
								setOpenActionsModal(true);
								handleClose();
							}}
							className="table-action-popover"
						>
							<LuActivitySquare />
							<p className="text-2sm">Reactivate Account</p>
						</div>
					)}
					{formDataValues?.status === "INACTIVE" && (
						<div
							onClick={() => {
								setActionMode("DELETE");
								setOpenActionsModal(true);
								handleClose();
							}}
							className="table-action-popover"
						>
							<BsTrash />
							<p className="text-2sm">Delete Account</p>
						</div>
					)}
                </div>
            </Popover>
			{openModal && (
				<TeamMemberModalForm
					open={openModal}
					setOpen={setOpenModal}
					title={
						isEditForm
						? `Update ${formDataValues?.firstName} ${formDataValues?.lastName}`
						: "Add New Team Member"
					}
					isEditForm={isEditForm}
					tableAPIRef={tableAPIRef}
					organizationRoles={organizationRoles?.data || []}
					companies={savedCompanies}
					addNewData={addNewData}
				/>
			)}
			
			{openActionsModal && (
				<TeamMemberActionModal
					mode={actionMode}
					open={openActionsModal}
					setOpen={setOpenActionsModal}
					tableAPIRef={tableAPIRef}
					reloadData={reloadData}
				/>
			)}
			
			<UpdateAccountConfigModal 
				open={openUpgradeModal}
				content={upgradeModalContent}
				handleClose={() => setOpenUpgradeModal(false)}
				onClickActionButton={onClickActionButton}
			/>
		</div>
	);
};

export default TeamMembers;
