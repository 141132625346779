import { GridColDef } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, enumToStringConverter, moneyFormat } from "../../../../../helpers/appHelpers";

export const RateTaxRuleColumns: GridColDef[] = [
	{
		field: "country",
		headerName: "Country",
		width: 200,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? params.value.name : "--",
	},
	{
		field: "minTaxableAmount",
		headerName: "Min Taxable Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter(params) {
			const amount = params.row.minTaxableAmount;
			const currency = params.row.country?.currency;
			return currency?.code + " " + moneyFormat(amount);
		},
	},
	{
		field: "maxTaxableAmount",
		headerName: "Max Taxable Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter(params) {
			const amount = params.row.maxTaxableAmount;
			const currency = params.row.country?.currency;
			return currency?.code + " " + moneyFormat(amount);
		},
	},
	{
		field: "rateComponent",
		headerName: "Rate Component",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ?  (params.value * 100 + "%") : "--",
	},
	{
		field: "fixedComponent",
		headerName: "Fixed Component",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter(params) {
			const currency = params.row.country?.currency;
			return currency?.code + " " + moneyFormat(params.value);
		},
	},
	{
		field: "incomePeriod",
		headerName: "Income Period",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "onDirectIncome",
		headerName: "On Direct Income",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},

	...createdAtmodifiedAtActions,
];

export const BenefitsTaxRuleColumns: GridColDef[] = [
	{
		field: "country",
		headerName: "Country",
		width: 200,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? params.value.name : "--",
	},
	{
		field: "benefitTypeInstance",
		headerName: "Benefit Type",
		width: 200,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? params.value.name : "--",
	},
	{
		field: "minTaxableAmount",
		headerName: "Min Taxable Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter(params) {
			const amount = params.row.minTaxableAmount;
			const currency = params.row.country?.currency;
			return currency?.code + " " + moneyFormat(amount);
		},
	},
	{
		field: "maxTaxableAmount",
		headerName: "Max Taxable Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter(params) {
			const amount = params.row.maxTaxableAmount;
			const currency = params.row.country?.currency;
			return currency?.code + " " + moneyFormat(amount);
		},
	},
	{
		field: "rateComponent",
		headerName: "Rate Component",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ?  (params.value * 100 + "%") : "--",
	},
	{
		field: "fixedComponent",
		headerName: "Fixed Component",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter(params) {
			const currency = params.row.country?.currency;
			return currency?.code + " " + moneyFormat(params.value);
		},
	},
	{
		field: "isFinalTax",
		headerName: "Final Tax",
		width: 150,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},

	...createdAtmodifiedAtActions,
];

export const ReliefTaxRuleColumns: GridColDef[] = [
	{
		field: "country",
		headerName: "Country",
		width: 200,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? params.value.name : "--",
	},
	{
		field: "taxReliefType",
		headerName: "Tax Relief Type",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? params.value.name : "--",
	},
	{
		field: "rateComponent",
		headerName: "Rate Component",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ?  (params.value * 100 + "%") : "--",
	},
	{
		field: "fixedComponent",
		headerName: "Fixed Component",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter(params) {
			const currency = params.row.country?.currency;
			return currency?.code + " " + moneyFormat(params.value);
		},
	},
	{
		field: "incomePeriod",
		headerName: "Income Period",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},

	...createdAtmodifiedAtActions,
];
