import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import API from './utils/api';
import {
    LeaveRequestDto, LeaveRequestPaginator,
    CreateLeaveRequestDto, QueryLeaveRequestDto, 
    UpdateLeaveRequestDto, AdjustDaysDto, LeaveResponseInputDto, 
} from '../models/leave-request.dto';
import ENDPOINTS from './utils/endpoints';
import { EmployeLeaveTypeSummary } from '../models/leave-type.dto';

export function useGetLeaveRequest(options?: QueryLeaveRequestDto) {
    const url = ENDPOINTS.HR.LEAVE_REQUEST.GET;
    const request = (options: QueryLeaveRequestDto) => API.HrAPI.getLeaveRequests(options);

    const { data, isLoading, error, refetch } = useQuery<LeaveRequestPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption},
    );

    return { data, isLoading, error, refetch };
}

export function useGetLeaveRequestById(id: number){
    const url = ENDPOINTS.HR.LEAVE_REQUEST.DETAIL;
    const request = API.HrAPI.getLeaveRequestById(id);

    const { data, isLoading, error } = useQuery<{ data: LeaveRequestDto }, Error>(
        [url, id],
        (() => request),
        { enabled: !!(id) },
    );
    
    return { data, isLoading, error };
}


export function useCreateLeaveRequest() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload }: { payload: CreateLeaveRequestDto }) => API.HrAPI.createLeaveRequest(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.HR.LEAVE_REQUEST.GET);
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useUpdateLeaveRequest() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id, payload }: { id: number, payload: UpdateLeaveRequestDto }) => API.HrAPI.updateLeaveRequest(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.HR.LEAVE_REQUEST.GET);
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useDeleteLeaveRequest() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id }: { id: number }) => API.HrAPI.deleteLeaveRequest(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
                queryClient.invalidateQueries(ENDPOINTS.HR.LEAVE_REQUEST.GET);
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useAddLeaveResponse() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id, payload }: { id: number, payload: LeaveResponseInputDto }) => API.HrAPI.addLeaveResponse(id, payload),
        {
            onSuccess: () => {
                toast.success('Record added successfully');
                queryClient.invalidateQueries(ENDPOINTS.HR.LEAVE_REQUEST.GET);
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useCancelLeaveRequest() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id }: { id: number }) => API.HrAPI.cancelLeaveRequest(id),
        {
            onSuccess: () => {
                toast.success('Record canceled successfully');
                queryClient.invalidateQueries(ENDPOINTS.HR.LEAVE_REQUEST.GET);
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useAdjustLeavePackage() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id, payload }: { id: number, payload: AdjustDaysDto }) => API.HrAPI.adjustLeaveRequest(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.HR.LEAVE_REQUEST.GET);
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetEmployeeLeaveTypeSummary() {        

    return useMutation(
        ({ employeeId, leaveTypeId }: { employeeId: number, leaveTypeId: number}) => API.HrAPI.getEmployeeLeaveTypeSummary(employeeId, leaveTypeId),
        {
            onSuccess: () => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}