import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    TaxRateDto, TaxRatePaginator,
    CreateTaxRateDto, QueryTaxRateDto,
    UpdateTaxRateDto
} from "../models/tax-rate.dto";
import ENDPOINTS from "./utils/endpoints";
import {QueryOptionsDto} from "../models/generic.dto";

export function useGetTaxRate(options?: QueryTaxRateDto, queryOptions?: any) {
    const url = ENDPOINTS.PROCESSING_COMPANY.TAX_RATE.GET;
    const request = (options: QueryTaxRateDto) => API.ProcessingCompanyAPI.getTaxRate(options);

    const { data, isLoading, error, refetch } = useQuery<TaxRatePaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption, ...queryOptions}
    );

    return { data, isLoading, error, refetch };
}

export function useGetTaxRateById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.TAX_RATE.DETAIL;
    const request = API.ProcessingCompanyAPI.getTaxRateById(id);

    const {data, isLoading, error} = useQuery<{ data:TaxRateDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateTaxRate() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateTaxRateDto}) => API.ProcessingCompanyAPI.createTaxRate(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.TAX_RATE.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateTaxRate() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateTaxRateDto, id: number}) => API.ProcessingCompanyAPI.updateTaxRate(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.TAX_RATE.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useDeleteTaxRate() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteTaxRate(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.TAX_RATE.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}
