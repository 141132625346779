import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { enumToStringConverter, formatDateAndTime, moneyFormat } from "../../../helpers/appHelpers";
import { Avatar } from "@mui/material";
import { INVOICE_STATUS, TRANSACTION_TYPE } from "../../../models/invoice.dto";

export const InvoicesColumns: GridColDef[] = [
	{
		field: "referenceCode",
		headerName: "Reference Code",
		headerClassName: "super-app-theme--header",
		width: 250,
	},
	{
		field: "organization",
		headerName: "Account",
		headerClassName: "super-app-theme--header",
		width: 300,
		renderCell: (params: any) => {
			const organization = params.row.organization || undefined;
			return (
				<div className="flex items-center space-x-4">
					{organization && <>
						<Avatar
							src={organization?.logoUrl || ""}
							alt="organization"
							className="w-10 h-10 rounded-full"
						/>
						<p className="w-full text-sm truncate">
							{organization?.name || ""}
						</p>
					</>}
				</div>
			);
		},
	},
	{
		field: "amount",
		headerName: "Amount",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value),
	},
	{
		field: "status",
		headerName: "Status",
		headerClassName: "super-app-theme--header",
		width: 200,
		renderCell(params) {
			return (
				<div 
					className="rounded-lg px-4 py-2 min-w-[80px] text-center relative font-semibold overflow-hidden"
					style={{ color: getInvoiceStatusColor(params.value) }}
				>
					<div 
						className="absolute inset-0" 
						style={{ 
							background: getInvoiceStatusColor(params.value), 
							opacity: "15%"
						}}
					/>
					<p className="text-sm">{enumToStringConverter(params.value)}</p>
				</div>
			);
		},
	},
	{
		field: "serviceDate",
		headerName: "Service Date",
		headerClassName: "super-app-theme--header",
		width: 250,
		valueGetter: (params) => formatDateAndTime(params),
	},
	{
		field: "productType",
		headerName: "Product Type",
		headerClassName: "super-app-theme--header",
		width: 180,
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "actions",
		headerName: "Actions",
		cellClassName: "actions",
		headerClassName: "super-app-theme--header",
	}
];

export const TransactionsColumns: GridColDef[] = [
	{
		field: "organization",
		headerName: "Account",
		headerClassName: "super-app-theme--header",
		width: 300,
		renderCell: (params: any) => {
			const organization = params.row.organization || undefined;
			return (
				<div className="flex items-center space-x-4">
					{organization && <>
						<Avatar
							src={organization?.logoUrl || ""}
							alt="organization"
							className="w-10 h-10 rounded-full"
						/>
						<p className="text-sm truncate">
							{organization?.name || ""}
						</p>
					</>}
				</div>
			);
		},
	},
	{
		field: "amount",
		headerName: "Amount",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value),
	},
	{
		field: "type",
		headerName: "Type",
		headerClassName: "super-app-theme--header",
		width: 250,
		renderCell(params) {
			return (
				<div 
					className="rounded-lg px-4 py-2 min-w-[80px] text-center relative font-semibold overflow-hidden"
					style={{ color: getTransactionTypeColor(params.value) }}
				>
					<div 
						className="absolute inset-0" 
						style={{ 
							background: getTransactionTypeColor(params.value), 
							opacity: "15%"
						}}
					/>
					<p className="text-sm">{enumToStringConverter(params.value)}</p>
				</div>
			);
		},
	},
	{
		field: "narration",
		headerName: "Narration",
		headerClassName: "super-app-theme--header",
		width: 380,
	},
	{
		field: "balanceBefore",
		headerName: "Balance Before",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value),
	},
	{
		field: "balanceAfter",
		headerName: "Balance After",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value),
	},
	{
		field: "createdAt",
		headerName: "Date Created",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params: GridValueGetterParams) => {
			const createdOn = formatDateAndTime(params);
			return createdOn;
		},
	},
	{
		field: "actions",
		headerName: "Actions",
		cellClassName: "actions",
		headerClassName: "super-app-theme--header",
	}
];

export const getInvoiceStatusColor = (status: INVOICE_STATUS) => {
	switch(status) {
		case "PAID":
			return "#4CAF50"
		case "CANCELLED":
			return "#F44336"
		case "PENDING":
			return "#FFC107"
		default: 
			return "#2196F3"
	}
};

export const getTransactionTypeColor = (type: TRANSACTION_TYPE) => {
	switch(type) {
		case "CREDIT":
			return "#4CAF50"
		case "DEBIT":
			return "#F44336"
		case "REVERSAL":
			return "#6e44ff"
		default: 
			return "#2196F3"
	}
};