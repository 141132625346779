import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    WorkDaysInAPeriodDto, WorkDaysInAPeriodPaginator,
    CreateWorkDaysInAPeriodDto,
    UpdateWorkDaysInAPeriodDto, QueryWorkDaysInAPeriodDto
} from "../models/work-days-in-a-period.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetWorkDaysInAPeriod(options?: QueryWorkDaysInAPeriodDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.WORK_DAYS_IN_A_PERIOD.GET;
    const request = (options: QueryWorkDaysInAPeriodDto) => API.ProcessingCompanyAPI.getWorkDaysInAPeriod(options);

	const { data, isLoading, error, refetch } = useQuery<WorkDaysInAPeriodPaginator, Error>(
		[url, options],
		({ queryKey, pageParam }) =>
			request(Object.assign({}, queryKey[1], pageParam)),
		{...API.Handlers.pageParamOption}
	);

	return { data, isLoading, error, refetch };
}

export function useGetWorkDaysInAPeriodById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.WORK_DAYS_IN_A_PERIOD.DETAIL;
    const request = API.ProcessingCompanyAPI.getWorkDaysInAPeriodById(id);

    const {data, isLoading, error} = useQuery<{ data:WorkDaysInAPeriodDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateWorkDaysInAPeriod() {
    const queryClient = useQueryClient();
    
    return useMutation(
        ({payload}: {payload: CreateWorkDaysInAPeriodDto}) => API.ProcessingCompanyAPI.createWorkDaysInAPeriod(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.WORK_DAYS_IN_A_PERIOD.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateWorkDaysInAPeriod() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateWorkDaysInAPeriodDto, id: number}) => API.ProcessingCompanyAPI.updateWorkDaysInAPeriod(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.WORK_DAYS_IN_A_PERIOD.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useDeleteWorkDaysInAPeriod() {

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteWorkDaysInAPeriod(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}