import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    CustomTaxReliefDto, CustomTaxReliefPaginator,
    CreateCustomTaxReliefDto, QueryCustomTaxReliefDto,
    UpdateCustomTaxReliefDto
} from "../models/custom-tax-relief.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetCustomTaxRelief(options?: QueryCustomTaxReliefDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.CUSTOM_TAX_RELIEF.GET;
    const request = (options: QueryCustomTaxReliefDto) => API.ProcessingCompanyAPI.getCustomTaxRelief(options);

	const { data, isLoading, error, refetch } = useQuery<CustomTaxReliefPaginator, Error>(
		[url, options],
		({ queryKey, pageParam }) =>
			request(Object.assign({}, queryKey[1], pageParam)),
		{...API.Handlers.pageParamOption}
	);

	return { data, isLoading, error, refetch };
}

export function useGetCustomTaxReliefById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.CUSTOM_TAX_RELIEF.DETAIL
    const request = API.ProcessingCompanyAPI.getCustomTaxReliefById(id);

    const {data, isLoading, error} = useQuery<{ data:CustomTaxReliefDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateCustomTaxRelief() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateCustomTaxReliefDto}) => API.ProcessingCompanyAPI.createCustomTaxRelief(payload),
        {
            onSuccess: (response) => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.CUSTOM_TAX_RELIEF.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateCustomTaxRelief() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateCustomTaxReliefDto, id: number}) => API.ProcessingCompanyAPI.updateCustomTaxRelief(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.CUSTOM_TAX_RELIEF.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useDeleteCustomTaxRelief() {

    return useMutation(
        ({ id }: { id: number }) => API.ProcessingCompanyAPI.deleteCustomTaxRelief(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

