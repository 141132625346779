import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    IndustryDto, IndustryPaginator,
    CreateIndustryDto,
    UpdateIndustryDto,
    QueryIndustryDto,
    SearchIndustryDto,
} from "../models/industry.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetIndustry(options?: QueryIndustryDto, queryOptions?: any) {
    const url = ENDPOINTS.PROCESSING_COMPANY.INDUSTRIES.GET;
    const request = (options: QueryIndustryDto) => API.ProcessingCompanyAPI.getIndustry(options);

    const { data, isLoading, error, refetch } = useQuery<IndustryPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch }
}

export function useGetIndustrySearch() {

    return useMutation(
        (options?: SearchIndustryDto) => API.ProcessingCompanyAPI.getIndustrySearch(options),
        {
            onSuccess: () => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetIndustryById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.INDUSTRIES.DETAIL;
    const request = API.ProcessingCompanyAPI.getIndustryById(id);

    const {data, isLoading, error} = useQuery<{ data: IndustryDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateIndustry() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateIndustryDto}) => API.ProcessingCompanyAPI.createIndustry(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.INDUSTRIES.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateIndustry() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateIndustryDto, id: number}) => API.ProcessingCompanyAPI.updateIndustry(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.INDUSTRIES.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useDeleteIndustry() {

    return useMutation(
        ({ id }: { id: number }) => API.ProcessingCompanyAPI.deleteIndustry(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}