import { useState, useEffect, useRef } from "react";
import { 
    FieldError, 
    UseFormRegister, 
    UseFormSetValue,
} from "react-hook-form";

interface IMoneyInput {
    attributes: React.InputHTMLAttributes<HTMLInputElement>; 
    register: UseFormRegister<any>;
    name: string;
    required: boolean | string;
    setValue: UseFormSetValue<any>;
    defaultValue?: number | string;
    validate?: (value: any) => boolean | string;
    clearValue?: boolean;
    error?: FieldError;
}

const MoneyInput = ({
    attributes,
    register,
    name,
    required,
    setValue,
    defaultValue,
    validate,
    clearValue,
    error,
}: IMoneyInput) => {
    const moneyInputRef = useRef<HTMLInputElement>(null);
    const [moneyInputValue, setMoneyInputValue] = useState("");

    useEffect(() => {
        if (!defaultValue || !moneyInputRef.current) return;
        setValue(name, Number(defaultValue));

        const inputValue = new Intl.NumberFormat().format(Number(defaultValue));

        setMoneyInputValue(inputValue);

        if (moneyInputRef.current.hasAttribute("disabled")) {
            moneyInputRef.current.removeAttribute("disabled");
            moneyInputRef.current.value = inputValue;
            setMoneyInputValue(inputValue);
            moneyInputRef.current.setAttribute("disabled", "");
        }
    }, [defaultValue]);

    useEffect(() => {
        if (clearValue) {
            setMoneyInputValue("");
        }
    }, [clearValue])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const newValue = value.replace(/,/g, '');
        
        if (/^\d*\.?\d*$/.test(newValue) && (Number(value) !== 0)) {
            if (value.includes(".")) {
                const splittedValues = newValue.split(".");

                if (splittedValues[1]) {
                    const decimalValue = (Number(splittedValues[1]) * Math.pow(10, -(splittedValues[1].length)));
                    const wholeValue = Number(splittedValues[0]) + decimalValue;
                    const formattedValue = new Intl.NumberFormat().format(wholeValue);
                    if (decimalValue === 0) {
                        setMoneyInputValue(formattedValue + ".0");
                    } else {
                        setMoneyInputValue(formattedValue);
                    }
                    setValue(name, Number(wholeValue));
                    return
                }

                const formattedValue = new Intl.NumberFormat().format(Number(splittedValues[0]));
                setMoneyInputValue(formattedValue + ".");
                setValue(name, Number(splittedValues[0]));
                return
            }

            const formattedValue = new Intl.NumberFormat().format(Number(newValue));
            setMoneyInputValue(formattedValue);
            setValue(name, Number(newValue));
            return
        }

        if (Number(value) == 0 || isDecimal(value)) {
            setMoneyInputValue(value);
            setValue(name, Number(value));
        }
    };

    return (  
        <div key={name} className="relative w-full mt-2">
            <input 
                type="number"
                step="any"
                {...register(name, {
                    required: required,
                    valueAsNumber: true,
                    validate: validate,
                })}
                className="absolute inset-0 bg-secondary-gray rounded-lg border-none focus:outline-none opacity-0"
            />
            <input
                type="text"
                ref={moneyInputRef}
                value={moneyInputValue}
                onChange={handleChange}
                className={`w-full bg-secondary-gray rounded-lg h-14 px-5 text-sm relative z-[999] ${
                    error
                        ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                        : "border-none"
                }`}
                { ...attributes }
            />
        </div>
    );
}
 
export default MoneyInput;

function isDecimal(str: string): boolean {
    const regex = /^-?\d+(\.\d+)?$/;
    return !!str.match(regex) && !isNaN(parseFloat(str));
}