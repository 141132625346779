import { PATHS } from "../../../../routes/routes.paths";

export interface GlobalSearchDto {
	id: number;
	name?: string;
	link?: string;
	description?: string;
	parentPageName?: string;
	subPages?: {
		id: number,
		name: string;
		link: string;
		description: string;
	}[];
};

let rootId = 0;
const getId = () => rootId++;

export const PayrollCompanySearchItems: GlobalSearchDto[] = [
	{
		id: getId(),
		name: "Overview",
		link: PATHS.OVERVIEW,
		description: "Summary of company data and statistics"
	},
	{
		id: getId(),
		parentPageName: "Company Settings",
		subPages: [
			{
				id: getId(),
				name: "Company Details",
				link: PATHS.SUB_COMPANY_SETTINGS.COMPANY_DETAILS,
				description: "Edit and view company configurations"
			},
			{
				id: getId(),
				name: "Company Levels",
				link: PATHS.SUB_COMPANY_SETTINGS.COMPANY_LEVELS,
				description: "Define and manage company hierarchy"
			},
			{
				id: getId(),
				name: "Company Currency",
				link: PATHS.SUB_COMPANY_SETTINGS.COMPANY_CURRENCY,
				description: "Setup currencies your company operates in"
			},
			{
				id: getId(),
				name: "Cost Centres",
				link: PATHS.SUB_COMPANY_SETTINGS.COST_CENTERS,
				description: "Assign and monitor cost centres for budgeting purposes"
			},
			{
				id: getId(),
				name: "Regions",
				link: PATHS.SUB_COMPANY_SETTINGS.REGIONS,
				description: "Group and manage employees by geographical regions"
			},
			{
				id: getId(),
				name: "Divisions",
				link: PATHS.SUB_COMPANY_SETTINGS.DIVISIONS,
				description: "Group and manage employees by business divisions"
			},
			{
				id: getId(),
				name: "Stations",
				link: PATHS.SUB_COMPANY_SETTINGS.STATIONS,
				description: "Group and manage employees by work stations"
			},
			{
				id: getId(),
				name: "Tribes",
				link: PATHS.SUB_COMPANY_SETTINGS.TRIBES,
				description: "Group and manage employees by their tribes"
			},
			{
				id: getId(),
				name: "Departments",
				link: PATHS.SUB_COMPANY_SETTINGS.DEPARTMENTS,
				description: "Group and manage employees by functional departments"
			},
			{
				id: getId(),
				name: "Grade Levels",
				link: PATHS.SUB_COMPANY_SETTINGS.GRADE_LEVELS,
				description: "Create and assign grade levels for employees"
			},
			{
				id: getId(),
				name: "Employee Bands & Notches",
				link: PATHS.SUB_COMPANY_SETTINGS.BANDS,
				description: "Create and manage employee bands and notches"
			},
			{
				id: getId(),
				name: "Work Days In A Period",
				link: PATHS.SUB_COMPANY_SETTINGS.WORK_DAYS_IN_A_PERIOD,
				description: "Setup workdays for different time periods"
			},
			{
				id: getId(),
				name: "Custom Tax Relief",
				link: PATHS.SUB_COMPANY_SETTINGS.CUSTOM_TAX_RELIEF,
				description: "Define and manage custom tax relief for employees"
			},
			{
				id: getId(),
				name: "Pay Periods",
				link: PATHS.SUB_COMPANY_SETTINGS.PAY_PERIODS,
				description: "Set up and view different pay periods for employees payroll"
			},
			{
				id: getId(),
				name: "Job Titles",
				link: PATHS.SUB_COMPANY_SETTINGS.JOB_TITLES,
				description: "Create and assign job titles for employees"
			},
			{
				id: getId(),
				name: "ERP Integration Mappings",
				link: PATHS.SUB_COMPANY_SETTINGS.ERP_INTEGRATION_MAPPINGS,
				description: "Define and manage ERP integration mappings"
			},
			{
				id: getId(),
				name: "Disciplinary Types",
				link: PATHS.SUB_COMPANY_SETTINGS.DISCIPLINARY_TYPES,
				description: "Define and manage the types of disciplinary actions taken against employees"
			},
			{
				id: getId(),
				name: "Grievance Types",
				link: PATHS.SUB_COMPANY_SETTINGS.GRIEVANCE_TYPES,
				description: "Define and manage the types of grievance reports by employees"
			},
			{
				id: getId(),
				name: "Asset Types",
				link: PATHS.SUB_COMPANY_SETTINGS.ASSET_TYPES,
				description: "Define and manage the types of assets assigned to employees"
			},
			{
				id: getId(),
				name: "Unions",
				link: PATHS.SUB_COMPANY_SETTINGS.UNIONS,
				description: "Define and manage unions for employees"
			},
		]
	},
	{
		id: getId(),
		name: "Employees",
		link: PATHS.EMPLOYEES,
		description: "Add and manage employee details"
	},
	{
		id: getId(),
		parentPageName: "Payroll",
		subPages: [
			{
				id: getId(),
				name: "Payroll Runs",
				link: PATHS.PAYROLL + "?view=companyPayrollRuns",
				description: "Submit and run payroll runs"
			},
			{
				id: getId(),
				name: "Payslips",
				link: PATHS.PAYROLL + "?view=companyPayslips",
				description: "View and download payslips for various pay periods"
			},
		]
	},
	{
		id: getId(),
		parentPageName: "Pay Advances",
		subPages: [
			{
				id: getId(),
				name: "Salary Advance",
				link: PATHS.PAY_ADVANCES + "?view=salaryAdvance",
				description: "View and manage employees salary advances"
			},
			{
				id: getId(),
				name: "Partial Salary",
				link: PATHS.PAY_ADVANCES + "?view=partialSalary",
				description: "View and manage employees partial salary payments"
			},
		]
	},
	{
		id: getId(),
		name: "Benefits and Allowances",
		link: PATHS.BENEFIT_AND_ALLOWANCE,
		description: "Set up and view employee benefits and allowances"
	},
	{
		id: getId(),
		name: "Overtime and Bonuses",
		link: PATHS.OVERTIME_AND_BONUSES,
		description: "Define overtime types and performance bonuses for employees"
	},
	{
		id: getId(),
		name: "Savings and Backpay",
		link: PATHS.SAVINGS_AND_BACKPAY,
		description: "Set up and manage employee savings and backpay"
	},
	{
		id: getId(),
		name: "Deductions and Loans",
		link: PATHS.DEDUCTIONS_AND_LOANS,
		description: "Set up and manage employee deductions and loans"
	},
	{
		id: getId(),
		parentPageName: "Work Time",
		subPages: [
			{
				id: getId(),
				name: "Work Time",
				link: PATHS.WORK_TIME + "?view=workTime",
				description: "Register and manage employee work hours"
			},
			{
				id: getId(),
				name: "Overtime Management",
				link: PATHS.WORK_TIME + "?view=overtimeManagement",
				description: "Register and manage employee overtime hours"
			},
		]
	},
	{
		id: getId(),
		name: "Reports",
		link: PATHS.REPORTS,
		description: "Generate and download company reports"
	},
	{
		id: getId(),
		name: "Support",
		link: PATHS.SUPPORT,
		description: "Contact customer service and access help resources"
	},
	{
		id: getId(),
		name: "Create Payroll Company",
		link: PATHS.CREATE_PAYROLL_COMPANY,
		description: "Create a new company in your organization"
	},
];

export const AdminPanelSearchItems: GlobalSearchDto[] = [
	{
		id: getId(),
		name: "Accounts Management",
		link: PATHS.ADMIN_ACCOUNTS_MANAGEMENT,
		description: "Summary of organizations and their companies"
	},
	{
		id: getId(),
		parentPageName: "System Configurations",
		subPages: [
			{
				id: getId(),
				name: "Subscription Packages",
				link: PATHS.ADMIN_SUB_SYSTEM_CONFIGURATIONS.SUBSCRIPTION_PACKAGES,
				description: "View and manage subscription packages"
			},
			{
				id: getId(),
				name: "Promotions",
				link: PATHS.ADMIN_SUB_SYSTEM_CONFIGURATIONS.PROMOTIONS,
				description: "Track and manage promotions"
			},
			{
				id: getId(),
				name: "Countries",
				link: PATHS.ADMIN_SUB_SYSTEM_CONFIGURATIONS.COUNTRIES,
				description: "Add and view countries added"
			},
			{
				id: getId(),
				name: "Currencies",
				link: PATHS.ADMIN_SUB_SYSTEM_CONFIGURATIONS.CURRENCIES,
				description: "Add and view currencies registered"
			},
			{
				id: getId(),
				name: "Nationalities",
				link: PATHS.ADMIN_SUB_SYSTEM_CONFIGURATIONS.NATIONALITIES,
				description: "Add and view nationalities added"
			},
			{
				id: getId(),
				name: "Tax Codes",
				link: PATHS.ADMIN_SUB_SYSTEM_CONFIGURATIONS.TAX_CODES,
				description: "Setup and manage tax codes for countries added"
			},
			{
				id: getId(),
				name: "Tax Rules",
				link: PATHS.ADMIN_SUB_SYSTEM_CONFIGURATIONS.TAX_RULES,
				description: "Add and view tax rules for countries added"
			},
			{
				id: getId(),
				name: "Tax Relief Types",
				link: PATHS.ADMIN_SUB_SYSTEM_CONFIGURATIONS.TAX_RELIEF_TYPES,
				description: "Add and manage tax relief types for countries added"
			},
			{
				id: getId(),
				name: "Leave Types",
				link: PATHS.ADMIN_SUB_SYSTEM_CONFIGURATIONS.LEAVE_TYPES,
				description: "Add and manage leave types"
			},
			{
				id: getId(),
				name: "Industries",
				link: PATHS.ADMIN_SUB_SYSTEM_CONFIGURATIONS.INDUSTRIES,
				description: "Add and manage industries for countries added"
			},
			{
				id: getId(),
				name: "Pensions",
				link: PATHS.ADMIN_SUB_SYSTEM_CONFIGURATIONS.PENSIONS,
				description: "Add and manage pension plans for countries added"
			},
			{
				id: getId(),
				name: "Benefit Types",
				link: PATHS.ADMIN_SUB_SYSTEM_CONFIGURATIONS.BENEFIT_TYPES,
				description: "Add and manage benefit types for countries added"
			},
			{
				id: getId(),
				name: "Payment Institutions",
				link: PATHS.ADMIN_SUB_SYSTEM_CONFIGURATIONS.PAYMENT_INSTITUTIONS,
				description: "Add and manage payment institutions"
			},
			{
				id: getId(),
				name: "Payment Institution Branches",
				link: PATHS.ADMIN_SUB_SYSTEM_CONFIGURATIONS.PAYMENT_INSTITUTION_BRANCHES,
				description: "Add and manage payment institution branches"
			},
			{
				id: getId(),
				name: "Exchange Rates",
				link: PATHS.ADMIN_SUB_SYSTEM_CONFIGURATIONS.EXCHANGE_RATES,
				description: "Setup exchange rates for currencies added"
			},
		]
	},
	{
		id: getId(),
		name: "Billings",
		link: PATHS.ADMIN_BILLING,
		description: "Financial history of all organizations"
	},
	{
		id: getId(),
		name: "Support",
		link: PATHS.ADMIN_SUPPORT,
		description: "Contact customer service and access help resources"
	},
]

export const SelfServiceSearchItems: GlobalSearchDto[] = [
	{
		id: getId(),
		name: "Dashboard",
		link: PATHS.SELF_SERVICE_DASHBOARD,
		description: "Summary of your leave requests, payslips, and announcements"
	},
	{
		id: getId(),
		parentPageName: "Profile Details",
		subPages: [
			{
				id: getId(),
				name: "Personal Details",
				link: PATHS.SELF_SERVICE_SUB_PROFILE_DETAILS.PERSONAL_DETAILS,
				description: "Edit your personal details"
			},
			{
				id: getId(),
				name: "Employee Relatives",
				link: PATHS.SELF_SERVICE_SUB_PROFILE_DETAILS.EMPLOYEE_RELATIVES,
				description: "Add and manage employee relatives"
			},
			{
				id: getId(),
				name: "Payment Info",
				link: PATHS.SELF_SERVICE_SUB_PROFILE_DETAILS.PAYMENT_INFO,
				description: "Edit your payment information"
			},
			{
				id: getId(),
				name: "Union Membership",
				link: PATHS.SELF_SERVICE_SUB_PROFILE_DETAILS.UNION_MEMBERSHIP,
				description: "Manage union membership"
			},
			{
				id: getId(),
				name: "Employment Documents",
				link: PATHS.SELF_SERVICE_SUB_PROFILE_DETAILS.EMPLOYEE_DOCUMENTS,
				description: "Upload and manage your employment documents"
			},
		]
	},
	{
		id: getId(),
		name: "Payslips",
		link: PATHS.SELF_SERVICE_PAYSLIPS,
		description: "View and download your payslips"
	},
	{
		id: getId(),
		parentPageName: "Work Time",
		subPages: [
			{
				id: getId(),
				name: "Work Time",
				link: PATHS.SELF_SERVICE_WORK_TIME + "?view=workTime",
				description: "View your work time records"
			},
			{
				id: getId(),
				name: "Overtime",
				link: PATHS.SELF_SERVICE_WORK_TIME + "?view=overtime",
				description: "View your overtime hours records"
			},
		]
	},
	{
		id: getId(),
		name: "Leave Management",
		subPages: [
			{
				id: getId(),
				name: "Leave Requests",
				link: PATHS.SELF_SERVICE_LEAVE_MANAGEMENT + "?view=leaveRequestsView",
				description: "Setup and manage your leave requests"
			},
			{
				id: getId(),
				name: "Leave Plans",
				link: PATHS.SELF_SERVICE_LEAVE_MANAGEMENT + "?view=leavePlansView",
				description: "Create and convert leave plans to leave requests"
			},
		]
	},
	{
		id: getId(),
		name: "Reimbursements",
		link: PATHS.SELF_SERVICE_REIMBURSEMENT,
		description: "View and manage your reimbursement claims"
	},
	{
		id: getId(),
		name: "Support",
		link: PATHS.SELF_SERVICE_SUPPORT,
		description: "Contact customer service and access help resources"
	},
]

export const HRSearchItems: GlobalSearchDto[] = [
	{
		id: getId(),
		name: "Employee Management",
		link: PATHS.HR_EMPLOYEE_MANAGEMENT,
		description: "Manage employees records and details"
	},
	{
		id: getId(),
		name: "Payroll Management",
		subPages: [
			{
				id: getId(),
				name: "Payroll Runs",
				link: PATHS.HR_PAYROLL_MANAGEMENT + "?view=companyPayrollRuns",
				description: "Submit and run payroll runs"
			},
			{
				id: getId(),
				name: "Payslips",
				link: PATHS.HR_PAYROLL_MANAGEMENT + "?view=companyPayslips",
				description: "View and download payslips for various pay periods"
			},
		]
	},
	{
		id: getId(),
		parentPageName: "Pay Advances",
		subPages: [
			{
				id: getId(),
				name: "Salary Advance",
				link: PATHS.HR_PAY_ADVANCES + "?view=salaryAdvance",
				description: "View and manage employees salary advances"
			},
			{
				id: getId(),
				name: "Partial Salary",
				link: PATHS.HR_PAY_ADVANCES + "?view=partialSalary",
				description: "View and manage employees partial salary payments"
			},
		]
	},
	{
		id: getId(),
		name: "Organizational Tree",
		link: PATHS.HR_ORGANISATIONAL_TREE,
		description: "View and manage organizational structure"
	},
	{
		id: getId(),
		parentPageName: "Work Time",
		subPages: [
			{
				id: getId(),
				name: "Work Time",
				link: PATHS.HR_WORK_TIME + "?view=workTime",
				description: "Register and manage employee work hours"
			},
			{
				id: getId(),
				name: "Overtime Management",
				link: PATHS.HR_WORK_TIME + "?view=overtimeManagement",
				description: "Register and manage employee overtime hours"
			},
		]
	},
	{
		id: getId(),
		name: "Disciplinary and Grievance",
		link: PATHS.HR_DISCIPLINARY_GRIEVANCE,
		description: "Handle disciplinary and grievance issues"
	},
	{
		id: getId(),
		name: "Leave Management",
		subPages: [
			{
				id: getId(),
				name: "Leave Requests",
				link: PATHS.HR_LEAVE_MANAGEMENT + "?view=leaveRequestsView",
				description: "Create and manage leave requests for employees"
			},
			{
				id: getId(),
				name: "Leave Packages",
				link: PATHS.HR_LEAVE_MANAGEMENT + "?view=leavePackagesView",
				description: "Setup and manage employees leave packages"
			},
		]
	},
	{
		id: getId(),
		name: "Reimbursements (HR)",
		link: PATHS.HR_REIMBURSEMENTS,
		description: "Manage employees reimbursement requests"
	},
	{
		id: getId(),
		name: "Bonus Management",
		link: PATHS.HR_BONUS_MANAGEMENT,
		description: "Manage employees bonus payments"
	},
	{
		id: getId(),
		name: "Announcements",
		link: PATHS.HR_ANNOUNCEMENTS,
		description: "View and manage company announcements"
	},
	{
		id: getId(),
		name: "Document Types",
		link: PATHS.HR_DOCUMENT_TYPES,
		description: "Add and manage employee document types"
	},
	{
		id: getId(),
		name: "Reports",
		link: PATHS.HR_REPORTS,
		description: "Generate and view HR related reports"
	},
	{
		id: getId(),
		name: "Support Service (HR)",
		link: PATHS.HR_SUPPORT,
		description: "Access HR support services and resources"
	},
];
