import { GridColDef } from "@mui/x-data-grid";
import { moneyFormat, createdAtmodifiedAtActions, enumToStringConverter } from "../../../helpers/appHelpers";

export const AllowancesColumns: GridColDef[] = [
	{
		field: "name",
		headerName: "Name",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "code",
		headerName: "Code",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "description",
		headerName: "Description",
		width: 400,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "active",
		headerName: "Active",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	{
		field: "formulaType",
		headerName: "Formula Type",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "fixedComponent",
		headerName: "Fixed Component",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter(params) {
			const amount = params.value;
			const currency = params.row.companyCurrency?.currency;
			return amount ? `${currency?.code || ""} ${moneyFormat(amount)}` : "--";
		},
	},
	{
		field: "rateComponent",
		headerName: "Rate Component",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ?  (params.value * 100 + "%") : "--",
	},
	{
		field: "minEmploymentPeriod",
		headerName: "Min Employment Period",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value + " " + enumToStringConverter(params.row.employmentPeriodUnit),
	},
	{
		field: "minValue",
		headerName: "Min Value",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter(params) {
			const amount = params.value;
			const currency = params.row.companyCurrency?.currency;
			return amount ? `${currency?.code || ""} ${moneyFormat(amount)}` : "--";
		},
	},
	{
		field: "maxValue",
		headerName: "Max Value",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter(params) {
			const amount = params.value;
			const currency = params.row.companyCurrency?.currency;
			return amount ? `${currency?.code || ""} ${moneyFormat(amount)}` : "--";
		},
	},
	{
		field: "prorate",
		headerName: "Prorate",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	
	...createdAtmodifiedAtActions
];

export const AllowancesColumnVisibilityModel: Record<string, boolean> = {
	name: true,
	code: true,
	description: true,
	active: true,
	formulaType: false,
	fixedComponent: false,
	rateComponent: false,
	minEmploymentPeriod: true,
	minValue: true,
	maxValue: true,
	prorate: true,
	createdAt: true,
	modifiedAt: true,
};

export const BenefitsColumns: GridColDef[] = [
	{
		field: "name",
		headerName: "Name",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "code",
		headerName: "Code",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "description",
		headerName: "Description",
		width: 400,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "benefitTypeInstance",
		headerName: "Benefit Type",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value.name,
	},
	{
		field: "value",
		headerName: "Value",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "frequency",
		headerName: "Frequency",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value) || "--",
	},
	{
		field: "taxCode",
		headerName: "Tax Code",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value || "--",
	},
	
	...createdAtmodifiedAtActions
];

export const BenefitsColumnVisibilityModel: Record<string, boolean> = {
	name: true,
	code: true,
	description: true,
	benefitTypeInstance: true,
	value: false,
	frequency: false,
	taxCode: false,
	createdAt: true,
	modifiedAt: true,
};

export const LoanBenefitsColumns: GridColDef[] = [
	{
		field: "name",
		headerName: "Name",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "code",
		headerName: "Code",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "description",
		headerName: "Description",
		width: 400,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "centralBankInterestRate",
		headerName: "Central Bank Interest Rate",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value + "%",
	},
	{
		field: "employerInterestRate",
		headerName: "Employer Interest Rate",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value + "%",
	},
	{
		field: "loanTenor",
		headerName: "Loan Tenor",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value + " Months",
	},
	{
		field: "floor",
		headerName: "Floor",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "ceiling",
		headerName: "Ceiling",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	
	...createdAtmodifiedAtActions
];

export const LoanBenefitsColumnVisibilityModel: Record<string, boolean> = {
	name: true,
	code: true,
	description: true,
	centralBankInterestRate: true,
	employerInterestRate: true,
	loanTenor: true,
	floor: false,
	ceiling: false,
	createdAt: true,
	modifiedAt: true,
};