import { GridColDef } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, enumToStringConverter } from "../../../../../helpers/appHelpers";

export const Columns: GridColDef[] = [
	{
		field: "name",
		headerName: "Name",
		width: 250,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "country",
		headerName: "Country",
		width: 200,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? params.value.name : "--",
	},
	{
		field: "description",
		headerName: "Description",
		width: 350,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "code",
		headerName: "Code",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "benefitType",
		headerName: "Benefit Type",
		width: 200,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "terminal",
		headerName: "Terminal Benefit",
		width: 250,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	
	...createdAtmodifiedAtActions
];