import { Typography } from "@mui/material";
import { IconType } from "react-icons";
import { useNavigate } from "react-router-dom";
import { CustomizedTooltip } from "../../../helpers/appHelpers";

type SidebarItemProps = {
    path: string;
    Icon: IconType;
    title: string;
    showSidebar: boolean;
    handleNavigation?: (path: any) => void;
}
const SidebarItem = ({ 
    path, 
    Icon, 
    title, 
    showSidebar,
    handleNavigation,
}: SidebarItemProps) => {
	const pathname = window.location.pathname;
	const navigate = useNavigate();

    return (
        <div
            className={`w-full flex items-center space-x-3 cursor-pointer rounded-lg py-2 ${showSidebar ? "px-3" : "justify-center"} ${
                pathname === path
                    ? "bg-primary-mango text-white hover:opacity-90"
                    : "bg-none text-gray-500 hover:bg-slate-200"
            }`}
            onClick={() => {
                handleNavigation ? handleNavigation(path) : navigate(path);
            }}
        >
            {showSidebar ? (<>
                <Icon className="text-lg" />
                <h3 className="text-sm w-full truncate">{title}</h3>
            </>):(
                <CustomizedTooltip title={title} placement="right" arrow>
                    <Typography> 
                        <Icon 
                            className="text-xl" 
                            style={{ color: pathname === path ? "white" : "rgb(107 114 128)" }}
                        />
                    </Typography>
                </CustomizedTooltip>
            )}
        </div>
    );
}
 
export default SidebarItem;