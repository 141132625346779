import { useState } from "react";
import EditQualification from "./EditQualification";
import NewQualification from "./NewQualification";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import { AiOutlinePlus } from "react-icons/ai";
import { EmployeeQualificationOrderBy } from "../../../../../../models/employee-qualification.dto";
import { useGetEmployeeQualification } from "../../../../../../services/employee-qualification.service";
import { SkewLoader } from "react-spinners";

const EmployeeQualifications = () => {
	const [showNewQualificationForm, setShowNewQualificationForm] = useState(false);
	const { formDataValues } = useEditFormStore();

	const { data: employeeQualifications, isLoading } = useGetEmployeeQualification({
        employeeId: formDataValues?.id,
        page: 1,
        orderBy: EmployeeQualificationOrderBy.CREATED_AT_DESC,
		limit: 100,
    });
	
	return (
		<div className="mb-4">
			<div className="border-b-[1.5px] pb-3 mb-4 flex w-full justify-between text-sm">
				<h2 className="font-semibold">Available Qualifications</h2>
				{!showNewQualificationForm && (
					<button
						onClick={() => setShowNewQualificationForm(true)}
						className="border-none flex items-center text-primary-blue"
					>
						<AiOutlinePlus className="mr-2 text-lg" />
						Add New Qualification
					</button>
				)}
			</div>
			
			{showNewQualificationForm && (
				<NewQualification 
					employeeId={formDataValues?.id}
					setShowNewQualificationForm={setShowNewQualificationForm} 
				/>
			)}
			
			{isLoading && (
				<div className="text-center mt-10 py-10">
					<SkewLoader color="#F58B00" />
				</div>
			)}
	
			{employeeQualifications?.data.map((qualification) => (
				<EditQualification 
					key={qualification.id}
					qualification={qualification}
				/>
			))}

			{(!isLoading && employeeQualifications?.data.length === 0) && (
				<div>
					<h3 className="text-center text-sm font-semibold mt-10">
						Sorry no qualifications added yet. Click 'Add New Qualification' to begin.
					</h3>
				</div>
			)}
		</div>
	);
};

export default EmployeeQualifications;