import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    SavingsSchemeDto, SavingsSchemePaginator,
    CreateSavingsSchemeDto,
    UpdateSavingsSchemeDto,
    QuerySavingsSchemeDto,
    SearchSavingsSchemeDto
} from "../models/savings-scheme.dto";
import ENDPOINTS from "./utils/endpoints";
import {QueryOptionsDto} from "../models/generic.dto";

export function useGetSavingsScheme(options?: QuerySavingsSchemeDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.SAVINGS_SCHEME.GET;
    const request = (options: QuerySavingsSchemeDto) => API.ProcessingCompanyAPI.getSavingsScheme(options);

    const { data, isLoading, error, refetch } = useQuery<SavingsSchemePaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch }
}

export function useGetSavingsSchemeSearch() {

    return useMutation(
        (options: SearchSavingsSchemeDto) => API.ProcessingCompanyAPI.getSavingsSchemeSearch(options),
        {
            onSuccess: (response,) => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useGetSavingsSchemeById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.SAVINGS_SCHEME.DETAIL;
    const request = API.ProcessingCompanyAPI.getSavingsSchemeById(id);

    const {data, isLoading, error} = useQuery<{ data:SavingsSchemeDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateSavingsScheme() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateSavingsSchemeDto}) => API.ProcessingCompanyAPI.createSavingsScheme(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.SAVINGS_SCHEME.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateSavingsScheme() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateSavingsSchemeDto, id: number}) => API.ProcessingCompanyAPI.updateSavingsScheme(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.SAVINGS_SCHEME.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useDeleteSavingsScheme() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id }: { id: number }) => API.ProcessingCompanyAPI.deleteSavingsScheme(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.SAVINGS_SCHEME.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}
