import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    JobTitleDto, JobTitlePaginator,
    CreateJobTitleDto, QueryJobTitleDto,
    UpdateJobTitleDto
} from "../models/job-titles.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetJobTitle(options?: QueryJobTitleDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.JOB_TITLE.GET
    const request = (options: QueryJobTitleDto) => API.ProcessingCompanyAPI.getJobTitle(options);

	const { data, isLoading, error, refetch } = useQuery<JobTitlePaginator, Error>(
		[url, options],
		({ queryKey, pageParam }) =>
			request(Object.assign({}, queryKey[1], pageParam)),
		{...API.Handlers.pageParamOption}
	);

	return { data, isLoading, error, refetch };
}

export function useGetJobTitleById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.JOB_TITLE.DETAIL
    const request = API.ProcessingCompanyAPI.getJobTitleById(id);

    const {data, isLoading, error} = useQuery<{ data:JobTitleDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateJobTitle() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateJobTitleDto}) => API.ProcessingCompanyAPI.createJobTitle(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.JOB_TITLE.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateJobTitle() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateJobTitleDto, id: number}) => API.ProcessingCompanyAPI.updateJobTitle(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.JOB_TITLE.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useDeleteJobTitle() {

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteJobTitle(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}