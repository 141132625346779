import { useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { LeaveRequestsColumns } from "../data";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import DeleteModal from "../../../../components/modals/DeleteModal";
import { useGetLeaveRequest, useDeleteLeaveRequest } from "../../../../services/leave-request.service";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { LEAVE_REQUEST_OPTIONS, LeaveRequestDto } from "../../../../models/leave-request.dto";
import LeaveRequestModalForm from "../modals/LeaveRequestModalForm";
import { useManageTableWithSearchParams, useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import { Popover } from "@mui/material";
import { BsPencil, BsTrash } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { LuEye } from "react-icons/lu";
import CancelLeaveRequestModal from "../modals/CancelLeaveRequestModal";
import LeaveRequestDetailsModal from "../modals/LeaveRequestDetailsModal";
import TableFilter from "../../../../components/table/filter/TableFilter";

const LeaveRequestsView = () => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openLeaveRequestModalForm, setOpenLeaveRequestModalForm] = useState(false);
	const [isEditLeaveRequestModalForm, setIsEditLeaveRequestModalForm] = useState(false);
	const [openLeaveRequestDetailsModal, setOpenLeaveRequestDetailsModal] = useState(false);
	const [openCancelLeaveRequestModal, setOpenCancelLeaveRequestModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	const {
		tableAPIRef,
		tableData,
		isLoading,
		pageCount,
		setTableData,
	} = useManageTableWithSearchParams<LeaveRequestDto>({
		fetchHook: useGetLeaveRequest,
		options: { queryMode: "SELF" }
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setIsEditLeaveRequestModalForm(false);
		setOpenLeaveRequestModalForm(true);
	};

	const { formDataValues, setFormData } = useEditFormStore();
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteLeaveRequest } = useDeleteLeaveRequest();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteLeaveRequest(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadTableData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>,
				];
			},
		},
	];

	const columnsWithAdditions = LeaveRequestsColumns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<>
			<div className="flex items-center justify-between w-full mb-6">
				<TableFilter
					fields={[
						{
							title: "",
							fieldName: "",
							fieldType: "SORT",
							options: [
								{
									title: "Start Date",
									fieldName: "startDate",
								},
								{
									title: "Return Date",
									fieldName: "returnDate",
								},
								{
									title: "Date Created",
									fieldName: "createdAt",
								},
								{
									title: "Date Modified",
									fieldName: "modifiedAt",
								},
							]
						},
						{
							title: "Status",
							fieldName: "status",
							fieldType: "CHECKBOX_LIST",
							options: LEAVE_REQUEST_OPTIONS,
						},
						{
							title: "Start Date",
							fieldName: "startDate",
							fieldType: "DATE_RANGE",
						},
						{
							title: "Return Date",
							fieldName: "returnDate",
							fieldType: "DATE_RANGE",
						},
					]}
				/>
				<OrangeButton
					title={"Take Time Off"}
					className={"px-8 h-12"}
					onClick={handleOpenModal}
				/>
			</div>
			<CustomStyledTable
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
            <Popover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                PaperProps={{
                    style: {
                        width: "auto",
                        borderRadius: 8,
                        display: "flex",
                        justifyContent: "center",
                    },
                }}
            >
                <div className="flex flex-col justify-center p-3 text-secondary-darkgray">
                    <div
                        onClick={() => {
                            setOpenLeaveRequestDetailsModal(true);
                            handleClose();
                        }}
                        className="table-action-popover"
                    >
                        <LuEye />
                        <p className="text-2sm">View Details</p>
                    </div>
					{(formDataValues?.status === "PENDING") && (
						<div
							onClick={() => {
								setIsEditLeaveRequestModalForm(true);
								setOpenLeaveRequestModalForm(true);
								handleClose();
							}}
							className="table-action-popover"
						>
							<BsPencil />
							<p className="text-2sm">Edit</p>
						</div>
					)}
					{(formDataValues?.status === "APPROVED" && 
						(new Date(formDataValues?.startDate) > new Date())
					) ? (
						<div
							onClick={() => {
								setOpenCancelLeaveRequestModal(true);
								handleClose();
							}}
							className="table-action-popover"
						>
							<IoClose className="scale-[1.2]" />
							<p className="text-2sm">Cancel</p>
						</div>
					): null}
					{(formDataValues?.status === "PENDING") && (
						<div
							onClick={() => {
								handleClose();
								setOpenDeleteModal(true);
							}}
							className="table-action-popover"
						>
							<BsTrash />
							<p className="text-2sm">Delete</p>
						</div>
					)}
                </div>
            </Popover>
			<DeleteModal
				openDeleteModal={openDeleteModal}
				setOpenDeleteModal={setOpenDeleteModal}
				handleDeleteMethod={handleDeleteMethod}
				deleting={deleting}
			/>

			{openLeaveRequestModalForm && (
				<LeaveRequestModalForm
					open={openLeaveRequestModalForm}
					setOpen={setOpenLeaveRequestModalForm}
					title={
						isEditLeaveRequestModalForm ? "Edit Leave Request" : "Request Leave"
					}
					isEditForm={isEditLeaveRequestModalForm}
					tableAPIRef={tableAPIRef}
				/>
			)}
			{openLeaveRequestDetailsModal && (
				<LeaveRequestDetailsModal
					open={openLeaveRequestDetailsModal}
					title={"Request Details"}
					setOpen={setOpenLeaveRequestDetailsModal}
					supervisor={false}
					tableAPIRef={tableAPIRef}
				/>
			)}
			{openCancelLeaveRequestModal && (
				<CancelLeaveRequestModal
					open={openCancelLeaveRequestModal}
					setOpen={setOpenCancelLeaveRequestModal}
					tableAPIRef={tableAPIRef}
				/>
			)}
		</>
	);
};

export default LeaveRequestsView;
