import { useState, useEffect } from "react";

export type VisibilityModel = Record<string, { headerName: string, visibility: boolean }>;

export function useColumnVisibilityModel(initialModel: Record<string, boolean>) {
	const [columnVisibilityModel, setColumnVisibilityModel] = useState(initialModel);
	const [tableKey, setTableKey] = useState("table");

	useEffect(() => {
		setTableKey(Math.random().toString());
	}, [columnVisibilityModel])

    return {
        tableKey,
        columnVisibilityModel,
        setColumnVisibilityModel,
    }
}

export function extractColumnVisibilityModel(model: VisibilityModel) {
    return Object.entries(model)
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value.visibility }), {});
}