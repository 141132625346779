import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import API from './utils/api'
import {CreatePayrollCompanyDto} from "../models/company-account.dto";
import {
    CreateUserDto,
    UpdateUserDto,
    UserPaginator,
    UserDto,
    QueryUserDto, SetPasswordDto, InitiatePasswordResetDto, UpdateSelfDto, CountUserDto, SearchUserDto, ChangePasswordDto,
    AccountCheckDto,
    AccountCheckResponse
} from "../models/user.dto";
import ENDPOINTS from "./utils/endpoints";
import {toast} from "react-toastify";

export function useGetUser(options?: QueryUserDto) {
    const url = ENDPOINTS.USER.GET;
    const request = (options: QueryUserDto) => API.UserAPI.getUser(options);

    const { data, isLoading, error, refetch } = useQuery<UserPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch };
}

export function useGetUserSearch() {

    return useMutation(
        (options: SearchUserDto) => API.UserAPI.getUserBySearch(options),
        {
            onSuccess: (response,) => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useGetUserCount(options?: CountUserDto) {
    const url = ENDPOINTS.USER.GET_COUNT;
    const request = API.UserAPI.getUserCount(options);

    const {data, isLoading, error} = useQuery<{ data: { count: number} }, Error>(
        [url, options],
        (() => request),
    );

    return {
        data,
        isLoading,
        error,
    };
}

export function useGetUserById(id: string) {
    const url = ENDPOINTS.USER.DETAIL;
    const request = API.UserAPI.getUserById(id);

    const {data, isLoading, error} = useQuery<{ data:UserDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}

export function useCreateUser() {

    return useMutation(
        ({payload}: {payload: CreateUserDto}) => API.UserAPI.createUser(payload),
        {
            onSuccess: () => {
                // toast.success('Client details updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateUser() {

    return useMutation(
        ({payload, id}: {payload: UpdateUserDto, id: string}) => API.UserAPI.updateUser(id, payload),
        {
            onSuccess: () => {
                // toast.success('Client details updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateSelf() {

    return useMutation(
        ({payload}: {payload: UpdateSelfDto}) => API.UserAPI.updateSelf(payload),
        {
            onSuccess: () => {
                // toast.success('Client details updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useAccountCheck() {

    return useMutation(
        ({payload}: {payload: AccountCheckDto}) => API.UserAPI.accountCheck(payload)
    );
}

export function useReactivateUser() {

    return useMutation(
        ({ id }: { id: string }) => API.UserAPI.reactivateUser(id),
        {
            onSuccess: () => {
                toast.success('User reactivated successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useRemoveUser() {

    return useMutation(
        ({ id }: { id: string }) => API.UserAPI.removeUser(id),
        {
            onSuccess: () => {
                toast.success('User deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}