import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import {
	createdAtmodifiedAtActions,
	enumToStringConverter,
	formatDate,
	moneyFormat,
} from "../../../../helpers/appHelpers";
import { Avatar } from "@mui/material";
import { STATUS } from "../../../../models/employee.dto";

export const columns: GridColDef[] = [
	{
		field: "employeeName",
		headerName: "Employee Name",
		width: 280,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell: (params: any) => {
			return (
				<div className="flex items-center space-x-4">
					<Avatar
						src={params.row.photoUrl}
						alt="Dp"
						className="w-10 h-10 rounded-full"
					/>
					<p className="text-sm truncate">
						{params.row.firstName + " " + params.row.lastName}
					</p>
				</div>
			);
		},
	},
	{
		field: "gender",
		headerName: "Gender",
		width: 150,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "title",
		headerName: "Title",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "dateOfBirth",
		headerName: "Date Of Birth",
		width: 170,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => formatDate(params),
	},
	{
		field: "employeeNumber",
		headerName: "Employee Number",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "nationality",
		headerName: "Nationality",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "email",
		headerName: "Corporate Email Address",
		width: 265,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "privateEmail",
		headerName: "Private Email Address",
		width: 265,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "status",
		headerName: "Status",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div 
					className="rounded-lg px-4 py-2 min-w-[80px] text-center relative font-semibold overflow-hidden"
					style={{ color: getOrganizationStatusColor(params.value) }}
				>
					<div 
						className="absolute inset-0" 
						style={{ 
							background: getOrganizationStatusColor(params.value), 
							opacity: "15%"
						}}
					/>
					<p>{enumToStringConverter(params.value)}</p>
				</div>
			);
		},
	},
	{
		field: "netSalary",
		headerName: "Net Salary",
		type: "number",
		width: 200,
		align: "left",
		headerAlign: "left",
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter(params) {
			const amount = params.row?.employeePayInfo?.basicPay;
			const currency = params.row?.employeePayInfo?.companyCurrency?.currency?.code;
			return (currency || "") + " " + (moneyFormat(amount) || "");
		},
	},
	{
		field: "msisdn",
		headerName: "Phone Number",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "alternateMsisdn",
		headerName: "Alternate Phone Number",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "ssn",
		headerName: "SSN",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "address",
		headerName: "Address",
		width: 300,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "digitalAddress",
		headerName: "Digital Address",
		width: 300,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "employmentDate",
		headerName: "Employment Date",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => formatDate(params),
	},
	{
		field: "terminationDate",
		headerName: "Termination Date",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? formatDate(params) : "--",
	},
	{
		field: "unionMember",
		headerName: "Union Member",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	{
		field: "reemployed",
		headerName: "Reemployed",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	{
		field: "resident",
		headerName: "Resident",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	{
		field: "hr",
		headerName: "HR Personnel",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	
	...createdAtmodifiedAtActions
];

export const getOrganizationStatusColor = (status: STATUS) => {
	switch(status) {
		case "ACTIVE":
			return "#4CAF50"
		case "TERMINATED":
			return "#808080"
		case "DISABLED":
			return "#F44336"
		case "ELAPSED_CONTRACT":
			return "#FFC107"
		case "ARCHIVED": 
			return "#2196F3"
		default: 
			return "#BF21F3"
	}
};

export const EmployeesColumnVisibilityModel: Record<string, boolean> = {
	employeeName: true,
	gender: true,
	title: false,
	dateOfBirth: false,
	employeeNumber: true,
	nationality: true,
	email: true,
	privateEmail: false,
	status: true,
	netSalary: true,
	msisdn: true,
	alternateMsisdn: false,
	ssn: false,
	address: false,
	digitalAddress: false,
	employmentDate: false,
	terminationDate: false,
	unionMember: false,
	reemployed: false,
	resident: false,
	hr: false,
	createdAt: true,
	modifiedAt: true,
};