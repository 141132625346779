import { useEffect, useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { LeaveRequestsColumnsHR } from "../data";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { useGetLeaveRequest } from "../../../../services/leave-request.service";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { LEAVE_REQUEST_OPTIONS, LeaveRequestDto } from "../../../../models/leave-request.dto";
import { useManageTableWithSearchParams } from "../../../../helpers/useManageTable";
import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import { Popover } from "@mui/material";
import { LuEye } from "react-icons/lu";
import { FiSliders } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";
import LeaveRequestDetailsModal from "../../../self_service/leave_management/modals/LeaveRequestDetailsModal";
import LeaveRequestActionModal from "../../../self_service/leave_management/modals/LeaveRequestActionModal";
import useUserStore from "../../../../state-management/useUserStore";
import TableFilter from "../../../../components/table/filter/TableFilter";
import { APP_CONFIG } from "../../../../helpers/appHelpers";
import { useGetEmployee } from "../../../../services/employee.service";
import { EmployeeOrderBy } from "../../../../models/employee.dto";
import HrLeaveRequestModalForm from "../modals/HrLeaveRequestModalForm";
import { AiOutlinePlus } from "react-icons/ai";

const LeaveRequestsView = () => {
	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];
	const [searchParams] = useSearchParams();
    const requestId = searchParams.get("requestId");
	const { formDataValues, setFormData } = useEditFormStore();

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openLeaveRequestModalForm, setOpenLeaveRequestModalForm] = useState(false);
	const [openLeaveRequestDetailsModal, setOpenLeaveRequestDetailsModal] = useState(false);
	const [openRequestActionModal, setOpenRequestActionModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);

	useEffect(() => {
		if (requestId) {
			setOpenLeaveRequestDetailsModal(true);
		}
	}, [requestId])

	const {
		tableAPIRef,
		tableData,
		isLoading,
		pageCount,
		setTableData,
	} = useManageTableWithSearchParams<LeaveRequestDto>({
		fetchHook: useGetLeaveRequest,
		options: { queryMode: "ALL" }
	});
	
	// Fetched data for select fields requiring data from database
	const { data: employees } = useGetEmployee({
		companyId: companyProfile.companyId,
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		status: "ACTIVE",
		orderBy: EmployeeOrderBy.FIRST_NAME_ASC,
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setIsEditForm(false);
		setOpenLeaveRequestModalForm(true);
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>,
				];
			},
		},
	];

	const columnsWithAdditions = LeaveRequestsColumnsHR.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<>
            <div className="flex items-center justify-between mb-8">
				<TableFilter
					fields={[
						{
							title: "",
							fieldName: "",
							fieldType: "SORT",
							options: [
								{
									title: "Start Date",
									fieldName: "startDate",
								},
								{
									title: "Return Date",
									fieldName: "returnDate",
								},
								{
									title: "Date Created",
									fieldName: "createdAt",
								},
								{
									title: "Date Modified",
									fieldName: "modifiedAt",
								},
							]
						},
						{
							title: "Employee",
							fieldName: "employeeId",
							fieldType: "SELECT_FIELD",
							options: employees?.data || [],
							mapTexts: ["firstName", "lastName"],
						},
						{
							title: "Status",
							fieldName: "status",
							fieldType: "CHECKBOX_LIST",
							options: LEAVE_REQUEST_OPTIONS,
						},
						{
							title: "Start Date",
							fieldName: "startDate",
							fieldType: "DATE_RANGE",
						},
						{
							title: "Return Date",
							fieldName: "returnDate",
							fieldType: "DATE_RANGE",
						},
					]}
				/>
				<div
					className="flex items-center text-primary-blue text-sm cursor-pointer w-fit"
					onClick={handleOpenModal}
				>
					<AiOutlinePlus className="mr-1 text-sm" />
					Add New Leave Request
				</div>
				{/* <OrangeButton
					title={"Add New Leave Request"}
					className={"px-4 h-12"}
					onClick={handleOpenModal}
				/> */}
            </div>
			<CustomStyledTable
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
            <Popover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                PaperProps={{
                    style: {
                        width: "auto",
                        borderRadius: 8,
                        display: "flex",
                        justifyContent: "center",
                    },
                }}
            >
                <div className="flex flex-col justify-center p-3 text-secondary-darkgray">
                    <div
                        onClick={() => {
                            setOpenLeaveRequestDetailsModal(true);
                            handleClose();
                        }}
                        className="table-action-popover"
                    >
                        <LuEye />
                        <p className="text-2sm">View Details</p>
                    </div>
					{/* {(formDataValues?.status === "PENDING") && (
						<div
							onClick={() => {
								setIsEditForm(true);
								setOpenLeaveRequestModalForm(true);
								handleClose();
							}}
							className="table-action-popover"
						>
							<BsPencil />
							<p className="text-2sm">Edit</p>
						</div>
					)} */}
                    {(
						formDataValues?.status === "APPROVED" &&
						formDataValues?.employee?.id !== companyProfile?.employeeId
					) && (
                        <div
                            onClick={() => {
                                setOpenRequestActionModal(true);
                                handleClose();
                            }}
                            className="table-action-popover"
                        >
                            <FiSliders className="text-sm" />
                            <p className="text-2sm">Adjust Request</p>
                        </div>
                    )}
                </div>
            </Popover>
            
			{openLeaveRequestModalForm && (
				<HrLeaveRequestModalForm
					open={openLeaveRequestModalForm}
					setOpen={setOpenLeaveRequestModalForm}
					title={
						isEditForm ? "Update Leave Request" : "Create Leave Request"
					}
					isEditForm={isEditForm}
					tableAPIRef={tableAPIRef}
					employees={employees?.data || []}
				/>
			)}
			{openLeaveRequestDetailsModal && (
				<LeaveRequestDetailsModal
					open={openLeaveRequestDetailsModal}
					title={"Request Details"}
					setOpen={setOpenLeaveRequestDetailsModal}
					tableAPIRef={tableAPIRef}
					supervisor
				/>
			)}
            {openRequestActionModal && (
                <LeaveRequestActionModal 
                    open={openRequestActionModal}
                    setOpen={setOpenRequestActionModal}
                    response={false}
                    tableAPIRef={tableAPIRef}
                />
            )}
		</>
	);
};

export default LeaveRequestsView;
