import React from "react";
import Box from "@mui/material/Box";
import { Avatar } from "@mui/material";
import useUserStore from "../../state-management/useUserStore";
import { PATHS } from "../../routes/routes.paths";
import { useNavigate } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { MdOutlineAccountBox } from "react-icons/md";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { BsCalendar4Week } from "react-icons/bs";
import { TbExchange } from "react-icons/tb";
import { HiOutlineSupport } from "react-icons/hi";
import { LuBookMarked } from "react-icons/lu";

interface MobileSidebarProps {
	onClose: () => void;
	route: string;
}

const MobileSidebar: React.FC<MobileSidebarProps> = ({ onClose, route }) => {
	const { currentUser } = useUserStore();
	const navigate = useNavigate();

	return (
		<Box
			sx={{ width: 250 }}
			role="presentation"
			onClick={onClose}
			onKeyDown={onClose}
		>
			<div className="py-4 h-screen overflow-y-auto sidebar-two-scroll flex justify-center w-full">
				<div className="flex flex-col h-screen justify-between ml-2">
					<div>
						<div className="mb-3">
							<div className={`flex items-center mb-8`}>
								<div className={`flex items-center`}>
									<img
										src={"/assets/logos/akatua-logo.svg"}
										alt="Akatua Logo"
										width={120}
									/>
								</div>
							</div>
							<div className="text-white">
								<div
									className={`rounded-lg flex py-[6px] items-center  ${"w-[230px] bg-primary-blue justify-start pl-2 space-x-3"}`}
								>
									<Avatar
										alt={currentUser?.user?.firstName}
										src={currentUser?.user?.imageUrl}
										sx={{
											width: 40,
											height: 40,
										}}
									/>

									<div className="min-w-0">
										<h3 className="text-[11px] lg:text-xs font-bold">
											{currentUser?.user?.firstName +
												" " +
												currentUser?.user?.lastName}
										</h3>
										<p className="text-[8px] lg:text-[10px] mt-[2px] overflow-hidden truncate font-normal">
											{currentUser?.user?.emailAddress}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="flex flex-col space-y-4 border-b pb-5">
							<div
								className={`flex items-center space-x-3 cursor-pointer rounded-lg px-3 py-2 ${
									route === PATHS.SELF_SERVICE_DASHBOARD
										? "bg-primary-mango text-white hover:opacity-90"
										: "bg-none text-gray-500 hover:bg-slate-200"
								}`}
								onClick={() => navigate(PATHS.SELF_SERVICE_DASHBOARD)}
							>
								<RxDashboard className={`${"text-lg"}`} />
								{<h3 className="text-sm">Dashboard</h3>}
							</div>

							<div
								className={`flex items-center space-x-3 cursor-pointer rounded-lg px-3 py-2 ${
									route === PATHS.SELF_SERVICE_PROFILE_DETAILS
										? "bg-primary-mango text-white hover:opacity-90"
										: "bg-none text-gray-500 hover:bg-slate-200"
								}`}
								onClick={() => navigate(PATHS.SELF_SERVICE_PROFILE_DETAILS)}
							>
								<MdOutlineAccountBox className={`${"text-xl"}`} />
								<h3 className="text-sm">Profile Details</h3>
							</div>
							<div
								className={`flex items-center space-x-3 cursor-pointer rounded-lg px-3 py-2 ${
									route === PATHS.SELF_SERVICE_PAYSLIPS
										? "bg-primary-mango text-white hover:opacity-90"
										: "bg-none text-gray-500 hover:bg-slate-200"
								}`}
								onClick={() => navigate(PATHS.SELF_SERVICE_PAYSLIPS)}
							>
								<LiaFileInvoiceDollarSolid className={`${"text-xl"}`} />
								<h3 className="text-sm">Payslips</h3>
							</div>
							<div
								className={`flex items-center space-x-3 cursor-pointer rounded-lg px-3 py-2 ${
									route === PATHS.SELF_SERVICE_WORK_TIME
										? "bg-primary-mango text-white hover:opacity-90"
										: "bg-none text-gray-500 hover:bg-slate-200"
								}`}
								onClick={() => navigate(PATHS.SELF_SERVICE_WORK_TIME)}
							>
								<LuBookMarked className={`${"text-xl"}`} />
								<h3 className="text-sm">Work Time</h3>
							</div>
							<div
								className={`flex items-center space-x-3 cursor-pointer rounded-lg px-3 py-2 ${
									route === PATHS.SELF_SERVICE_LEAVE_MANAGEMENT
										? "bg-primary-mango text-white hover:opacity-90"
										: "bg-none text-gray-500 hover:bg-slate-200"
								}`}
								onClick={() => navigate(PATHS.SELF_SERVICE_LEAVE_MANAGEMENT)}
							>
								<BsCalendar4Week className={`${"text-lg"}`} />
								<h3 className="text-sm">Leave Management</h3>
							</div>
							<div
								className={`flex items-center space-x-3 cursor-pointer rounded-lg px-3 py-2 ${
									route === PATHS.SELF_SERVICE_REIMBURSEMENT
										? "bg-primary-mango text-white hover:opacity-90"
										: "bg-none text-gray-500 hover:bg-slate-200"
								}`}
								onClick={() => navigate(PATHS.SELF_SERVICE_REIMBURSEMENT)}
							>
								<TbExchange className={`${"text-lg"}`} />
								<h3 className="text-sm">Reimbursement</h3>
							</div>
						</div>
					</div>
					<div className="flex flex-col space-y-4">
						<div
							className={`flex items-center space-x-3 cursor-pointer rounded-lg px-3 py-2 ${
								route === PATHS.SELF_SERVICE_SUPPORT
									? "bg-primary-mango text-white hover:opacity-90"
									: "bg-none text-gray-500 hover:bg-slate-200"
							}`}
							onClick={() => navigate(PATHS.SELF_SERVICE_SUPPORT)}
						>
							<HiOutlineSupport className={"text-lg"} />
							<h3 className="text-sm">Support</h3>
						</div>
					</div>
				</div>
			</div>
		</Box>
	);
};

export default MobileSidebar;
