import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { SkewLoader } from "react-spinners";
import { NotchOrderBy } from "../../../../../../models/notches.dto";
import { useGetNotch } from "../../../../../../services/notches.service";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import EditNotch from "./EditNotch";
import NewNotch from "./NewNotch";
import ModalComponent from "../../../../../../components/modals/ModalFormComponent";

type EmployeeBandNotchesProps = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EmployeeBandNotches = ({ open, setOpen }: EmployeeBandNotchesProps) => {
	const { formDataValues } = useEditFormStore();
	const [showNewNotchForm, setShowNewNotchForm] = useState(false);
	const [forceRefetch, setForceRefetch] = useState(Math.random());
	
	const { data: notches, isLoading, refetch } = useGetNotch({
		employeeBandId: formDataValues?.id,
		orderBy: NotchOrderBy.CREATED_AT_DESC,
		limit: 100,
    });

	useEffect(() => {refetch()}, [forceRefetch]);
	
	return (
		<ModalComponent
			width={730}
			open={open}
			title={formDataValues?.name + " Band Notches"}
			handleClose={() => setOpen(false)}
		>
			<div className="mb-4">
				<div className="border-b-[1.5px] pb-3 mb-4 flex w-full justify-between text-sm">
					<h2 className="font-semibold">Available Notches</h2>
					{!showNewNotchForm && (
						<button
							onClick={() => setShowNewNotchForm(true)}
							className="border-none flex items-center text-primary-blue"
						>
							<AiOutlinePlus className="mr-2 text-lg" />
							Add New Notch
						</button>
					)}
				</div>

				{showNewNotchForm && (
					<NewNotch 
						employeeBand={formDataValues}
						setShowNewNotchForm={setShowNewNotchForm} 
						setForceRefetch={setForceRefetch}
					/>
				)}

				{isLoading && (
					<div className="text-center mt-10 py-10">
						<SkewLoader color="#F58B00" />
					</div>
				)}
	
				{notches?.data?.map((notch) => (
					<EditNotch 
						key={notch.id}
						notch={notch} 
						employeeBand={formDataValues}
						setForceRefetch={setForceRefetch}
					/>
				))}

				{(!isLoading && notches?.data?.length === 0) && (
					<div>
						<h3 className="text-center text-sm font-semibold mt-10">
							Sorry no notches added yet. Click 'Add New Notch' to begin.
						</h3>
					</div>
				)}
			</div>
		</ModalComponent>
	);
};

export default EmployeeBandNotches;
