import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { EmployeeTerminationDto } from "../../../models/employee.dto";
import Label from "../../../components/form_fields/Label";
import OrangeButton from "../../../components/buttons/OrangeButton";
import ModalComponent from "../../../components/modals/ModalFormComponent";
import { useTerminateEmployee } from "../../../services/employee.service";
import useEditFormStore from "../../../state-management/useEditFormStore";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { validatePast } from "../../../helpers/appHelpers";

type TerminateEmployeeProps = {
	open: boolean;
	setOpen: (open: boolean) => void;
	tableAPIRef: React.MutableRefObject<GridApiCommunity>;
}

const TerminateEmployee = ({
	open,
	setOpen,
    tableAPIRef,
}: TerminateEmployeeProps) => {
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);
	const { mutate: terminateEmployee } = useTerminateEmployee();

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<EmployeeTerminationDto>();

	const onSubmit: SubmitHandler<EmployeeTerminationDto> = (data: any) => {
		setUploading(true);

		terminateEmployee(
			{ payload: data, id: formDataValues?.id },
			{
				onSuccess: (updatedData) => {
					tableAPIRef?.current.updateRows([updatedData.data]);
					setOpen(false);
					reset();
				},
				onSettled: () => setUploading(false),
			}
		);
	};

	const handleClose = () => {
		reset();
		setOpen(false);
	};

	return (
		<ModalComponent
			width={700}
			open={open}
			title={"Terminate " + formDataValues?.firstName + " " + formDataValues?.lastName}
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="col-span-2 w-full">
						<Label title="Termination Date" for="terminationDate" />
						<input
							placeholder={"mm/dd/yyyy"}
							type="date"
							{...register("terminationDate", {
								required: "Enter termination date here",
								validate: validatePast,
							})}
							id="terminationDate"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.terminationDate
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.terminationDate && (
							<p className="text-red-500 text-sm mt-1">
								{errors.terminationDate.message}
							</p>
						)}
					</div>
					<div className="col-span-2 w-full">
						<Label title="Reason" for="reason" optional />
						<input
							placeholder={"Enter reason for termination"}
							type="text"
							{...register("reason", {
								required: false,
							})}
							id="reason"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm border-none`}
						/>
					</div>
				</div>
				<div className="w-full flex justify-end mt-10">
					<OrangeButton
						title={`${
							uploading
								? "Uploading..."
								: "Terminate Employee"
						}`}
						className={`px-8`}
						type={"submit"}
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default TerminateEmployee;