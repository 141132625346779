import React, { useState } from "react";
import CustomTab from "../../../../components/custom_tab/CustomTab";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import { EditEmployeeTabColumns } from "../../../../components/custom_tab/tabColumns";
import PersonalDetails from "./forms/PersonalDetails";
import EmploymentDetails from "./forms/EmploymentDetails";
import PaymentInfo from "./forms/PaymentInfo";
import EmployeeRelatives from "./forms/relatives/index";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import EmployeeQualifications from "./forms/qualifications/index";
import UnionMembership from "./forms/union-membership";
import AssetAssignment from "./forms/asset-assignment";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import EmployeeDocuments from "./forms/documents";
import useUserStore from "../../../../state-management/useUserStore";
import { APP_CONFIG } from "../../../../helpers/appHelpers";
import { useGetPaymentInstitutionBranch } from "../../../../services/payment-institution-branch.service";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import { EmployeeOrderBy } from "../../../../models/employee.dto";
import { useGetEmployee } from "../../../../services/employee.service";
import RequestApprovers from "./forms/RequestApprovers";

interface IEditEmployee {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	tableAPIRef: React.MutableRefObject<GridApiCommunity>;
}

const EditEmployee = ({
	open,
	setOpen,
	tableAPIRef
}: IEditEmployee) => {
	const { currentCompany } = useCompanyStore();
	const { userType } = useUserStore();
	const { formDataValues } = useEditFormStore();
	const [view, setView] = useState("personalDetails");

	const getAccountInfo = (field: string) => {
		const accountInfo = formDataValues?.employeeAccountInfo?.find(
			accountInfo => accountInfo[field] === true
		);

		return accountInfo || undefined;
	};

	const [formData, setFormData] = useState<any>({
		...formDataValues,
		basicPay: formDataValues?.employeePayInfo?.basicPay,
		currencyId: formDataValues?.employeePayInfo?.currencyId,
		payPeriod: formDataValues?.employeePayInfo?.payPeriod,
		splitPayment: formDataValues?.employeePayInfo?.splitPayment ? "yes" : "no",
		primaryPayPercent: Number(formDataValues?.employeePayInfo?.primaryPayPercent) * 100,
		primaryPaymentChannel: getAccountInfo("primary")?.type,
		primaryInstitutionId: getAccountInfo("primary")?.institutionId,
		primaryInstitutionBranchId: getAccountInfo("primary")?.institutionBranchId,
		primaryAccountNumber: getAccountInfo("primary")?.accountNumber,
		secondaryPaymentChannel: getAccountInfo("secondary")?.type,
		secondaryInstitutionId: getAccountInfo("secondary")?.institutionId,
		secondaryInstitutionBranchId: getAccountInfo("secondary")?.institutionBranchId,
		secondaryAccountNumber: getAccountInfo("secondary")?.accountNumber,
	});

	const { data: primaryInstitutionBranches } = useGetPaymentInstitutionBranch({
		limit: APP_CONFIG.PAGE_LIMIT,
		active: true,
		orderBy: "name:asc" as any,
		paymentInstitutionId: formData.primaryInstitutionId || getAccountInfo("primary")?.institutionId,
	});
	const { data: secondaryInstitutionBranches } = useGetPaymentInstitutionBranch({
		limit: APP_CONFIG.PAGE_LIMIT,
		active: true,
		orderBy: "name:asc" as any,
		paymentInstitutionId: formData.secondaryInstitutionId || getAccountInfo("secondary")?.institutionId,
	});

	const { data: employees } = useGetEmployee({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		status: "ACTIVE",
		orderBy: EmployeeOrderBy.FIRST_NAME_ASC,
	});

	const handleClose = () => {
		setOpen(false);
		setView("personalDetails");
	};

	return (
		<ModalComponent
			open={open}
			title={`Edit ${formDataValues?.firstName + " " + formDataValues?.lastName}'s Details`}
			handleClose={handleClose}
		>
			<div className="overflow-x-auto">
				<CustomTab
					view={view}
					setView={setView}
					tabColumns={
						userType === "HR"
							? EditEmployeeTabColumns
							: EditEmployeeTabColumns.slice(0, EditEmployeeTabColumns.length - 2)
					}
					popup
				>
					<div className="mt-8">
						{view === "personalDetails" && (
							<PersonalDetails
								formData={formData}
								setFormData={setFormData}
								setView={setView}
							/>
						)}
						{view === "employmentDetails" && (
							<EmploymentDetails
								formData={formData}
								setFormData={setFormData}
								setView={setView}
							/>
						)}
						{view === "paymentInfo" && (
							<PaymentInfo
								formData={formData}
								setFormData={setFormData}
								setView={setView}
								closeForm={handleClose}
								tableAPIRef={tableAPIRef}
								defaultPrimaryInstitutionBranches={primaryInstitutionBranches?.data || []}
								defaultSecondaryInstitutionBranches={secondaryInstitutionBranches?.data || []}
							/>
						)}
						{view === "employeeRelatives" && <EmployeeRelatives />}
						{view === "qualifications" && <EmployeeQualifications />}
						{view === "unionMembership" && <UnionMembership />}
						{view === "assetAssignment" && <AssetAssignment />}
						{view === "requestApprovers" && <RequestApprovers employees={employees?.data || []} />}
						{view === "documents" && <EmployeeDocuments />}
					</div>
				</CustomTab>
			</div>
		</ModalComponent>
	);
};

export default EditEmployee;
