import { useContext, useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import Label from "../../../../components/form_fields/Label";
import { GoPencil } from "react-icons/go";
import { MdCancel } from "react-icons/md";
import { EmployeeDataContext, IEmployeeDataContext } from '..';
import OrangeButton from '../../../../components/buttons/OrangeButton';
import { useCreateEmployeeAccountInfo, useUpdateEmployeeAccountInfo } from '../../../../services/employee-account-info.service';
import { PaymentInstitutionBranchDto } from '../../../../models/payment-institution-branch.dto';
import { useAsyncEffect } from 'ahooks';
import { APP_CONFIG } from '../../../../helpers/appHelpers';
import API from '../../../../services/utils/api';
import { CreateEmployeeAccountInfoDto, EmployeeAccountInfo } from '../../../../models/employee-account-info.dto';
import { toast } from 'react-toastify';

interface IFormInputs {
	primaryPaymentChannel: string;
	primaryInstitutionId: number;
	primaryInstitutionBranchId?: number;
	primaryAccountNumber: string;
	secondaryPaymentChannel?: string;
	secondaryInstitutionId?: number;
	secondaryInstitutionBranchId?: number;
	secondaryAccountNumber?: string;
}

const PaymentInfoView = () => {
	const {
		employeeData,
		setEmployeeData, 
		paymentInstitutions,
	} = useContext(EmployeeDataContext) as IEmployeeDataContext;
	const [isEdit, setIsEdit] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [formData, setFormData] = useState<IFormInputs>({} as any);
	
	const [primaryAccountInfo, setPrimaryAccountInfo] =
		useState<EmployeeAccountInfo>();
	const [secondaryAccountInfo, setSecondaryAccountInfo] =
		useState<EmployeeAccountInfo>();
	const [primaryInstitutionBranches, setPrimaryBankBranches] = 
		useState<PaymentInstitutionBranchDto[]>([]);
	const [secondaryInstitutionBranches, setSecondaryBankBranches] = 
		useState<PaymentInstitutionBranchDto[]>([]);

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		clearErrors,
		formState: { errors },
	} = useForm<IFormInputs>();
	
	useEffect(() => {
		const { unsubscribe } = watch((values) => {
			setFormData({ ...formData, ...values });
		});
		return () => unsubscribe();
	}, [watch])

	useEffect(() => {
		const employeeAccountInfo = employeeData?.employeeAccountInfo;

		if (!employeeAccountInfo?.length || !paymentInstitutions?.length) return;

		const primaryInfo = employeeAccountInfo?.find(
			accountInfo => accountInfo.primary === true
		);
		const secondaryInfo = employeeAccountInfo?.find(
			accountInfo => accountInfo.secondary === true
		);

		setValue("primaryPaymentChannel", primaryInfo?.type as string);
		setValue("primaryInstitutionId", primaryInfo?.institutionId as number);
		setValue("primaryInstitutionBranchId", primaryInfo?.institutionBranchId || undefined);
		setValue("primaryAccountNumber", primaryInfo?.accountNumber as string);
		setValue("secondaryPaymentChannel", secondaryInfo?.type || undefined);
		setValue("secondaryInstitutionId", secondaryInfo?.institutionId || undefined);
		setValue("secondaryInstitutionBranchId", secondaryInfo?.institutionBranchId || undefined);
		setValue("secondaryAccountNumber", secondaryInfo?.accountNumber || undefined);

		setPrimaryAccountInfo(primaryInfo);
		setSecondaryAccountInfo(secondaryInfo);
	}, [employeeData, paymentInstitutions?.length])

	useEffect(() => {
		if (formData?.secondaryInstitutionId || formData?.secondaryAccountNumber) return;

		clearErrors("secondaryInstitutionId");
		clearErrors("secondaryAccountNumber");
	}, [formData?.secondaryInstitutionId, formData?.secondaryAccountNumber])

	const handlePaymentInstitutionChange = async (id: string | number, primary = true) => {
		if (!id) {
			if (primary) {
				setPrimaryBankBranches([]);
				setValue( "primaryPaymentChannel", "");
			} else  {
				setSecondaryBankBranches([]);
				setValue( "secondaryPaymentChannel", undefined);
			}

			return
		}

		const paymentInstitution = paymentInstitutions?.find(
			paymentInstitution => paymentInstitution.id === Number(id)
		);

		if (!paymentInstitution) return;
		if (paymentInstitution.paymentChannelType !== "BANK") {
			if (primary) {
				setValue(
					"primaryPaymentChannel", 
					paymentInstitution.paymentChannelType
				);
				setValue( "primaryInstitutionBranchId", undefined);
			} else  {
				setValue(
					"secondaryPaymentChannel", 
					paymentInstitution.paymentChannelType
				);
				setValue( "secondaryInstitutionBranchId", undefined);
			}
			
			return
		};

		try {
			const paymentInstitutionBranches = await API.BillingAndSubscriptionAPI
				.getPaymentInstitutionBranch({
					limit: APP_CONFIG.PAGE_LIMIT,
					active: true,
					orderBy: "name:asc" as any,
					paymentInstitutionId: paymentInstitution.id
				})

			if (primary) {
				setPrimaryBankBranches(paymentInstitutionBranches.data);
				setValue(
					"primaryPaymentChannel", 
					paymentInstitution.paymentChannelType
				);
			} else  {
				setSecondaryBankBranches(paymentInstitutionBranches.data);
				setValue(
					"secondaryPaymentChannel", 
					paymentInstitution.paymentChannelType
				);
			}
		} catch {}
	};

	// Setting the default values of institution branches
	useAsyncEffect(async () => {
		if (
			formData?.primaryInstitutionId && 
			formData?.primaryPaymentChannel === "BANK" && 
			!primaryInstitutionBranches.length
		) {
			await handlePaymentInstitutionChange(formData.primaryInstitutionId);
		}

		if (
			formData?.secondaryInstitutionId && 
			formData?.secondaryPaymentChannel === "BANK" && 
			!secondaryInstitutionBranches.length
		) {
			await handlePaymentInstitutionChange(formData.secondaryInstitutionId, false);
		}
	}, [formData?.primaryInstitutionId, formData?.secondaryInstitutionId])

	useEffect(() => {
		if (formData?.primaryInstitutionBranchId && primaryInstitutionBranches.length) {
			setValue("primaryInstitutionBranchId", formData.primaryInstitutionBranchId);
		}

		if (formData?.secondaryInstitutionBranchId && secondaryInstitutionBranches.length) {
			setValue("secondaryInstitutionBranchId", formData.secondaryInstitutionBranchId);
		}
	}, [primaryInstitutionBranches, secondaryInstitutionBranches])
	
	const { mutate: createEmployeeAccountInfo } = useCreateEmployeeAccountInfo();
	const { mutate: updateEmployeeAccountInfo } = useUpdateEmployeeAccountInfo();

	const onSubmit = (data: IFormInputs) => {
		if (!setEmployeeData) return;
		setUploading(true);

		const primaryInfo = {
			employeeId: employeeData?.id as number,
			type: data.primaryPaymentChannel,
			institutionId: data.primaryInstitutionId,
			institutionBranchId: data.primaryInstitutionBranchId || undefined,
			accountNumber: data.primaryAccountNumber,
		};
		const secondaryInfo = {
			employeeId: employeeData?.id as number,
			type: data.secondaryPaymentChannel,
			institutionId: data.secondaryInstitutionId,
			institutionBranchId: data.secondaryInstitutionBranchId || undefined,
			accountNumber: data.secondaryAccountNumber,
			primary: false,
			secondary: true,
		};

		updateEmployeeAccountInfo(
			{ payload: primaryInfo, id: primaryAccountInfo?.id as number },
			{
				onSuccess: ({ data: updatedData }) => {
					toast.success("Primary Payment Channel updated successfully");

					const updatedPrimaryAccountInfo = employeeData?.employeeAccountInfo?.map(
						(accountInfo) => accountInfo.primary ? updatedData :  accountInfo
					) || []
					
					if (secondaryAccountInfo) {
						updateEmployeeAccountInfo(
							{ payload: secondaryInfo, id: secondaryAccountInfo?.id as number },
							{
								onSuccess: ({ data: updatedData }) => {
									toast.success("Secondary Payment Channel updated successfully");

									const updatedAccountInfo = updatedPrimaryAccountInfo.map(
										(accountInfo) => accountInfo.secondary ? updatedData :  accountInfo
									) || []
				
									setEmployeeData({ 
										...(employeeData as any), 
										employeeAccountInfo: { data: updatedAccountInfo }
									})
								},
								onSettled: () => setUploading(false),
							}
						)
					} else {
						if (!data.secondaryPaymentChannel) return;

						createEmployeeAccountInfo(
							{ 
								payload: {
									...secondaryInfo,
									active: true,
									currencyId: employeeData?.employeePayInfo.currencyId as number,
								} as CreateEmployeeAccountInfoDto
							},
							{
								onSuccess: ({ data: newData }) => {
									toast.success("Primary Payment Channel created successfully");

									const updatedAccountInfo = [...updatedPrimaryAccountInfo, newData];
				
									setEmployeeData({ 
										...(employeeData as any), 
										employeeAccountInfo: { data: updatedAccountInfo }
									})
								},
								onSettled: () => setUploading(false),
							}
						)
					}
				},
				onError: () => setUploading(false),
			}
		)
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<section className="flex lg:justify-end max-lg:mb-6">
				{isEdit === false ? (
					<div
						onClick={() => setIsEdit((edit) => !edit)}
						className="bg-[#26A0F91A] text-primary-blue p-4 rounded-lg flex space-x-2 items-center cursor-pointer"
					>
						<GoPencil />
						<p className="text-2xs">Edit Details</p>
					</div>
				) : (
					<div
						onClick={() => {
							setIsEdit((edit) => !edit);
							clearErrors();
						}}
						className="bg-[#f926261a] text-red-500 p-4 rounded-lg flex space-x-2 items-center cursor-pointer"
					>
						<MdCancel />
						<p className="text-2xs">Cancel Edit</p>
					</div>
				)}
			</section>

			<section>
				<h3 className="font-bold">Primary Payment Channel</h3>
				<div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6 mt-4">
					<div className="w-full">
						<Label title="Bank" for="primaryInstitutionId" />
						<select
							id="primaryInstitutionId"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.primaryInstitutionId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("primaryInstitutionId", {
								required: "Select primary bank here",
								valueAsNumber: true,
							})}
							onInput={(e: any) => handlePaymentInstitutionChange(e.target.value)}
							disabled={!isEdit}
						>
							<option value="">Select Bank</option>
							{paymentInstitutions?.map((paymentInstitution) => (
								<option 
									key={paymentInstitution?.id} 
									value={paymentInstitution?.id}
								>
									{paymentInstitution?.name}
								</option>
							))}
						</select>
						{errors.primaryInstitutionId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.primaryInstitutionId.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Account Number" for="primaryAccountNumber" />
						<input
							placeholder="Enter number"
							type="number"
							{...register("primaryAccountNumber", {
								required: "Enter primary bank account number here",
							})}
							id="primaryAccountNumber"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.primaryAccountNumber
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							disabled={!isEdit}
						/>
						{errors.primaryAccountNumber && (
							<p className="text-red-500 text-sm mt-1">
								{errors.primaryAccountNumber.message}
							</p>
						)}
					</div>
					{formData?.primaryPaymentChannel === "BANK" && (
						<div className="w-full">
							<Label title="Bank Branch" for="primaryInstitutionBranchId" />
							<select
								id="primaryInstitutionBranchId"
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.primaryInstitutionBranchId
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("primaryInstitutionBranchId", {
									required: "Enter primary bank branch here",
									valueAsNumber: true,
								})}
								disabled={!isEdit}
							>
								<option value="">Select Branch</option>
								{primaryInstitutionBranches.map((paymentInstitutionBranch) => (
									<option 
										key={paymentInstitutionBranch?.id} 
										value={paymentInstitutionBranch?.id}
									>
										{paymentInstitutionBranch?.name}
									</option>
								))}
							</select>
							{errors.primaryInstitutionBranchId && (
								<p className="text-red-500 text-sm mt-1">
									{errors.primaryInstitutionBranchId.message}
								</p>
							)}
						</div>
					)}
				</div>
			</section>
			<section className="mt-8 lg:mt-14">
				<h3 className="font-bold">Secondary Payment Channel</h3>
				<div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6 mt-4">
					<div className="w-full">
						<Label title="Bank" for="secondaryInstitutionId" />
						<select
							id="secondaryInstitutionId"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.secondaryInstitutionId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("secondaryInstitutionId", {
								validate: (value, formData) => {
									if (!value && formData.secondaryAccountNumber) {
										return "Select bank or clear account number field"
									}

									return true
								},
								valueAsNumber: true,
							})}
							onInput={(e: any) => handlePaymentInstitutionChange(e.target.value, false)}
							disabled={!isEdit}
						>
							<option value="">Select Bank</option>
							{paymentInstitutions?.map((paymentInstitution) => (
								<option 
									key={paymentInstitution?.id} 
									value={paymentInstitution?.id}
								>
									{paymentInstitution?.name}
								</option>
							))}
						</select>
						{errors.secondaryInstitutionId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.secondaryInstitutionId.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Account Number" for="secondaryAccountNumber" />
						<input
							placeholder="Enter number"
							type="number"
							id="secondaryAccountNumber"
							{...register("secondaryAccountNumber", {
								validate: (value, formData) => {
									if (!value && formData.secondaryInstitutionId) {
										return "Enter account number or clear bank field"
									}

									return true
								}
							})}
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.secondaryAccountNumber
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							disabled={!isEdit}
						/>
						{errors.secondaryAccountNumber && (
							<p className="text-red-500 text-sm mt-1">
								{errors.secondaryAccountNumber.message}
							</p>
						)}
					</div>
					{formData?.secondaryPaymentChannel === "BANK" && (
						<div className="w-full">
							<Label title="Bank Branch" for="secondaryInstitutionBranchId" />
							<select
								id="secondaryInstitutionBranchId"
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.secondaryInstitutionBranchId
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("secondaryInstitutionBranchId", {
									required: "Enter secondary bank branch or clear bank field",
									valueAsNumber: true,
								})}
								disabled={!isEdit}
							>
								<option value="">Select Branch</option>
								{secondaryInstitutionBranches.map((paymentInstitutionBranch) => (
									<option 
										key={paymentInstitutionBranch?.id} 
										value={paymentInstitutionBranch?.id}
									>
										{paymentInstitutionBranch?.name}
									</option>
								))}
							</select>
							{errors.secondaryInstitutionBranchId && (
								<p className="text-red-500 text-sm mt-1">
									{errors.secondaryInstitutionBranchId.message}
								</p>
							)}
						</div>
					)}
				</div>
			</section>

			<div className="w-full flex justify-end mt-8 max-lg:mb-4">
				<OrangeButton
					title={uploading ? "Uploading..." : "Save Changes"}
					type="submit"
					className={`px-4`}
					disabled={!isEdit || uploading}
				/>
			</div>
		</form>
	);
};

export default PaymentInfoView