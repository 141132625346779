import { useState } from "react";
import PayrollCompanyLayout from "../../../components/layouts/PayrollCompanyLayout";
import CustomTab from "../../../components/custom_tab/CustomTab";
import { Helmet } from "react-helmet-async";
import LoansView from "./view/LoansView";
import { useSearchParams } from "react-router-dom";
import { DeductionsAndLoansTabColumns } from "../../../components/custom_tab/tabColumns";
import ExplicitDeductionsView from "./view/ExplicitDeductionsView";

const DeductionAndLoans = () => {
	const [searchParams] = useSearchParams();
	const [view, setView] = useState(
		searchParams.get("view") || "explicitDeductions"
	);
	
	return (
		<PayrollCompanyLayout>
			<Helmet>
				<title>Deductions and Loans | Company Flow</title>
			</Helmet>
			<h1 className="font-bold text-xl mb-8">Deductions and Loans</h1>
			<CustomTab
				view={view}
				setView={setView}
				tabColumns={DeductionsAndLoansTabColumns}
			>
				{view === "explicitDeductions" && <ExplicitDeductionsView />}
				{view === "loans" && <LoansView />}
			</CustomTab>
		</PayrollCompanyLayout>
	);
};

export default DeductionAndLoans;
