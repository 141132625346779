import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, formatDateAndTime } from "../../../../../helpers/appHelpers";

export const Columns: GridColDef[] = [
	{
		field: "name",
		headerName: "Currency",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "code",
		headerName: "Currency Code",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "symbol",
		headerName: "Symbol",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "active",
		headerName: "Active",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	{
		field: "isDefault",
		headerName: "Default",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	
	...createdAtmodifiedAtActions
];
