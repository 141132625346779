import { useState } from "react";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../components/form_fields/Label";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import { formatRate, IAddEditTableForm } from "../../../../helpers/appHelpers";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { APPLY_ON } from "../../../../models/explicit-deduction.dto";
import { 
	useCreateExplicitDeduction, 
	useUpdateExplicitDeduction 
} from "../../../../services/explicit-deduction.service";
import FormDescriptionInput from "../../../../components/form_fields/FormDescriptionInput";
import { PayPeriodDto } from "../../../../models/pay-period.dto";
import MoneyInput from "../../../../components/form_fields/MoneyInput";
import { EmployeeDto } from "../../../../models/employee.dto";
import { EmployeeBandDto } from "../../../../models/employee-band.dto";
import { useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import APISelectField from "../../../../components/form_fields/APISelectField";
import { PATHS } from "../../../../routes/routes.paths";
import FormCodeInput from "../../../../components/form_fields/FormCodeInput";
import MultiSelectField from "../../../../components/form_fields/MultiSelectField";
import RateComponent from "../../../../components/form_fields/RateComponent";

type IFormInputs = {
	name: string
	code: string
	description: string
	applyOn: APPLY_ON
	figure: number
	startPayPeriodId: number
	endPayPeriodId?: number
	employeeIds?: number[]
	employeeBandIds?: number[]
}

type ExplicitDeductionModalFormProps = IAddEditTableForm & {
	payPeriods: PayPeriodDto[];
	employees: EmployeeDto[];
	employeeBands: EmployeeBandDto[];
}

const ExplicitDeductionModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	payPeriods,
	employees,
	employeeBands,
}: ExplicitDeductionModalFormProps) => {
	const { currentCompany } = useCompanyStore();
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);
	
	const {
		register,
		watch,
		setValue,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			name: isEditForm ? formDataValues?.name : "",
			code: isEditForm ? formDataValues?.code : "",
			description: isEditForm ? formDataValues?.description : "",
			applyOn: isEditForm ? formDataValues?.applyOn : "",
			startPayPeriodId: isEditForm ? formDataValues?.startPayPeriodId : "",
			endPayPeriodId: isEditForm ? formDataValues?.endPayPeriodId : "",
		}
	});
	
	const applyOn = watch("applyOn");
	const [employeeIds, setEmployeeIds] = useState<number[]>([]);
	const [employeeBandIds, setEmployeeBandIds] = useState<number[]>([]);

	const { mutate: createExplicitDeduction } = useCreateExplicitDeduction();
	const { mutate: updateExplicitDeduction } = useUpdateExplicitDeduction();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);
		applyOn !== "FIXED" && formatRate(data, "figure");

		if (isEditForm) {
			updateExplicitDeduction(
				{ payload: data, id: formDataValues?.id }, 
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			data.employeeIds = employeeIds.length ? employeeIds as any : undefined;
			data.employeeBandIds = employeeBandIds.length ? employeeBandIds as any : undefined;

			createExplicitDeduction(
				{ payload: { ...data, companyId: currentCompany?.id as number } }, 
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		reset();
		setOpen(false);
	};

	return (
		<ModalComponent
			width={700}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Name" for="name" />
						<input
							defaultValue={isEditForm ? formDataValues?.name : ""}
							placeholder={"Enter name"}
							type="text"
							{...register("name", {
								required: "Enter name here",
							})}
							id="name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.name
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.name && (
							<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Unique Code" for="code" />
						<FormCodeInput
							register={register}
							attributes={{
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.code
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.code && (
							<p className="text-red-500 text-sm mt-1">
								{errors.code.message}
							</p>
						)}
					</div>
					<div className="w-full col-span-2">
						<Label title="Description" for="description" />
						<FormDescriptionInput 
							register={register}
							attributes={{
								defaultValue: isEditForm ? formDataValues?.description : "",
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.description
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`
							}}
						/>
						{errors.description && (
							<p className="text-red-500 text-sm mt-1">
								{errors.description.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Apply On" for="applyOn" />
						<select
							defaultValue={isEditForm ? formDataValues?.applyOn : ""}
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.applyOn
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("applyOn", {
								required: "Select apply on here",
							})}
						>
							<option value="">
								Select Value
							</option>
							<option value="BASIC">Basic</option>
							<option value="GROSS">Gross</option>
							<option value="FIXED">Fixed</option>
						</select>
						{errors.applyOn && (
							<p className="text-red-500 text-sm mt-1">
								{errors.applyOn.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Figure" for="figure" />
						{(applyOn === "FIXED") ? (
							<MoneyInput 
								attributes={{
									id: "figure",
									placeholder: "0"
								}}
								register={register}
								name="figure"
								setValue={setValue}
								defaultValue={
									isEditForm 
										? Number(formDataValues?.fixedComponent) > 0 
											? formDataValues?.fixedComponent 
											: undefined
										: undefined
								}
								required="Enter figure here"
								error={errors.figure}
							/>
						):(
							<RateComponent 
								attributes={{ 
									id: "figure",
									defaultValue: isEditForm 
										? Number(formDataValues?.rateComponent) > 0 
											? Number(formDataValues?.rateComponent) * 100
											: undefined
										: undefined
								}}
								register={register}
								name="figure"
								required="Enter figure here"
								error={errors.figure}
							/>
						)}
						{errors.figure && (
							<p className="text-red-500 text-sm mt-1">
								{errors.figure.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Start Pay Period" for="startPayPeriodId" />
						<APISelectField 
							key="startPayPeriodId"
							attributes={{
								id: "startPayPeriodId",
							}}
							placeholder="Select Pay Period"
							register={register}
							name="startPayPeriodId"
							options={payPeriods}
							mapText="code"
							pathname={PATHS.COMPANY_SETTINGS + "?view=payPeriods"}
							required="Select start pay period here"
							error={errors.startPayPeriodId}
							valueAsNumber
						/>
						{errors.startPayPeriodId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.startPayPeriodId.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="End Pay Period" for="endPayPeriodId" />
						<APISelectField 
							key="endPayPeriodId"
							attributes={{
								id: "endPayPeriodId",
							}}
							placeholder="Select Pay Period"
							register={register}
							name="endPayPeriodId"
							options={payPeriods}
							mapText="code"
							pathname={PATHS.COMPANY_SETTINGS + "?view=payPeriods"}
							required={false}
							error={errors.endPayPeriodId}
							valueAsNumber
						/>
						{errors.endPayPeriodId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.endPayPeriodId.message}
							</p>
						)}
					</div>
					{!isEditForm && (<>
						<div className="w-full">
							<Label title="Assign Employees" for="employeeIds" optional />
							<MultiSelectField
								key={"employeeIds"}
								options={employees}
								setResourceIds={setEmployeeIds}
								placeholder={"Select Employees"}
								pathname={PATHS.EMPLOYEES}
								required={false}
								mapTexts={["firstName", "lastName"]}
							/>
						</div>
						<div className="w-full">
							<Label title="Assign Employee Bands" for="employeeBandIds" optional />
							<MultiSelectField
								key={"employeeBandIds"}
								options={employeeBands}
								setResourceIds={setEmployeeBandIds}
								placeholder={"Select Bands"}
								pathname={PATHS.COMPANY_SETTINGS + "?view=bands"}
								required={false}
							/>
						</div>
					</>)}
				</div>

				<div className="w-full flex justify-end mt-10">
					<OrangeButton
						title={`${
							uploading 
							? "Uploading..." 
							: isEditForm 
								? "Update Explicit Deduction" 
								: "Add Explicit Deduction"
						}`}
						className={`px-8`}
						type="submit"
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default ExplicitDeductionModalForm;
