import { useEffect, useState } from "react";
import ModalComponent from "../../../../../components/modals/ModalFormComponent";
import Label from "../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import { useCreateOrganizationRole } from "../../../../../services/organization-role.service";
import { PermissionDto } from "../../../../../models/permission.dto";
import useUserStore from "../../../../../state-management/useUserStore";
import { SubmitHandler, useForm } from "react-hook-form";

type IFormInputs = {
	name: string;
	active: boolean;
}
type INewOrganizationRole = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    permissionsData: PermissionDto[];
	setNewData: React.Dispatch<React.SetStateAction<string>>;
}

const NewOrganizationRole = ({ 
	open, 
	setOpen, 
	permissionsData,
	setNewData, 
}: INewOrganizationRole) => {
	const userOrganization = useUserStore().currentUser.user.organization;
	const { mutate: createRole } = useCreateOrganizationRole();
	const [uploading, setUploading] = useState(false);
	const [permissions, setPermissions] = useState<string[]>([]);
	const [permissionsError, setPermissionsError] = useState(false);
	
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>();

	const updatePermissions = (newEntry: string) => {
        const optionAlreadyPresent = permissions.find((permission) => {
			return permission === newEntry
		});
		const filteredPermissions = permissions.filter((permission) => {
			return permission !== newEntry
		});

		if (optionAlreadyPresent) {
			setPermissions(filteredPermissions);
		} else {
			setPermissions(prev => [...prev, newEntry]);
		}
    };

	useEffect(() => {
		if (permissionsData.length < 1) return;

		permissionsData.forEach((permission) => {
			if (permission.isDefault) updatePermissions(permission.code);
		})
	}, [permissionsData])

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		if (!permissions.length) {
			setPermissionsError(true);
			return
		}
		setUploading(true);

		createRole(
			{
				payload: {
					organizationId: userOrganization?.id as string,
					permissions,
					...data,
				}
			}, 
			{
				onSuccess: () => {
					setNewData("true");
					setOpen(false);
				},
				onSettled: () => setUploading(false),
			}
		)
	};

	const handleClose = () => setOpen(false);

	return (
		<ModalComponent
			width={850}
			open={open}
			title={"Add New Role"}
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full col-span-2">
						<Label title="Name" for="name" />
						<input
							placeholder={"Enter name"}
							type="text"
							{...register("name", {
								required: "Enter name here",
							})}
							id="name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.name
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.name && (
							<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
						)}
					</div>
                    <div className="w-full col-span-2 flex space-x-3 items-center">
                        <input 
                            type="checkbox"
                            {...register("active", {
                                required: false,
                            })}
                            id="active"
                        />
                        <label htmlFor="active">Active</label>
                    </div>
				</div>
				<div>
					<p className="text-gray-500 text-sm mt-8">
						Select permissions for this role
					</p>
					<ul className="mt-4 space-y-6">
						{permissionsData.map((permission) => (
							<li key={permission.code} className="flex items-center space-x-3">
								<input 
									type="checkbox" 
									name={permission.code} 
									id={permission.code} 
									defaultChecked={permission.isDefault === true}
									onChange={() => updatePermissions(permission.code)}
								/>
								<label htmlFor={permission.code}>{permission.name}</label>
							</li>
						))}
					</ul>
					{permissionsError &&
						<p className="text-red-500 text-sm mt-4">Select at least one permission to continue</p>
					}
				</div>

				<div className="flex justify-end mt-3">
					<OrangeButton 
						title={uploading ? "Uploading..." : "Create Role"} 
						disabled={uploading}
						className={"h-12 px-24"} 
						type="submit"
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default NewOrganizationRole;
