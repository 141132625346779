import { useContext, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import Label from "../../../../components/form_fields/Label";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import {
	IAddEditTableForm,
	compareDateFields,
	validateFuture,
} from "../../../../helpers/appHelpers";
import {
	useCreateLeaveRequest,
	useUpdateLeaveRequest,
} from "../../../../services/leave-request.service";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import useUserStore from "../../../../state-management/useUserStore";
import { ApplicableLeaveTypeContext } from "..";
import { useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import { useViewPort } from "../../../../helpers/useViewPort";

interface IFormInputs {
	leaveTypeId: number;
	startDate: string;
	returnDate: string;
	comment: string;
}

const LeaveRequestModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
}: IAddEditTableForm) => {
	const viewPortWidth = useViewPort();
	const leaveTypes = useContext(ApplicableLeaveTypeContext);
	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);

	const {
		register,
		watch,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
        defaultValues: {
            leaveTypeId: isEditForm ? formDataValues?.leavePackage?.leaveType.id : "",
            startDate: isEditForm ? formDataValues?.startDate.slice(0, 10)  : "",
            returnDate: isEditForm ? formDataValues?.returnDate.slice(0, 10)  : "",
            comment: isEditForm ? formDataValues?.comment : "",
        }
    });

	const { mutate: createLeaveRequest } = useCreateLeaveRequest();
	const { mutate: updateLeaveRequest } = useUpdateLeaveRequest();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		if (isEditForm) {
			updateLeaveRequest(
				{ payload: data, id: formDataValues?.id },
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			createLeaveRequest(
				{ 
                    payload: {
                        ...data,
                        employeeId: companyProfile.employeeId as number,
                    }
                },
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		setOpen(false);
		reset();
	};

	return (
		<ModalComponent
			width={viewPortWidth < 1024 ? "90%" : 775}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
				<div className="space-y-5 lg:space-y-0 lg:grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full col-span-2">
						<Label title="Leave Type" for="leaveTypeId" />
						<select
							defaultValue={isEditForm ? formDataValues?.leavePackage?.leaveType.id : ""}
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								(!leaveTypes.length || errors.leaveTypeId)
									? "border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("leaveTypeId", {
								required: "Select leave type here",
								valueAsNumber: true,
							})}
						>
							<option value="">Select Leave Type</option>
							{leaveTypes.map((leaveType) => (
								<option key={leaveType.id} value={leaveType.id}>
									{leaveType.name}
								</option>
							))}
						</select>
						{errors.leaveTypeId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.leaveTypeId.message}
							</p>
						)}
						{!leaveTypes.length ? (
							<p className="text-red-500 text-sm mt-1">
								No applicable leave types found
							</p>
						): null}
					</div>
					<div className="w-full">
						<Label title="Start Date" for="startDate" />
						<input
							defaultValue={
								isEditForm ? formDataValues?.startDate.slice(0, 10) : ""
							}
							type="date"
							{...register("startDate", {
								required: "Enter start date here",
								validate: validateFuture,
							})}
							id="startDate"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.startDate
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.startDate && (
							<p className="text-red-500 text-sm mt-1">
								{errors.startDate.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Return Date" for="returnDate" />
						<input
							defaultValue={
								isEditForm ? formDataValues?.returnDate.slice(0, 10) : ""
							}
							type="date"
							{...register("returnDate", {
								required: "Enter return date here",
								validate: (value) => compareDateFields(
									watch("startDate"), 
									value,
									"Start date must be less than return date"
								),
							})}
							id="returnDate"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.returnDate
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.returnDate && (
							<p className="text-red-500 text-sm mt-1">
								{errors.returnDate.message}
							</p>
						)}
					</div>
					<div className="w-full col-span-2">
						<Label title="Comment" for="comment" />
						<input
							defaultValue={isEditForm ? formDataValues?.comment : ""}
							placeholder={"Enter comment"}
							type="text"
							{...register("comment", {
								required: "Enter comment here",
							})}
							id="comment"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.comment
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.comment && (
							<p className="text-red-500 text-sm mt-1">
								{errors.comment.message}
							</p>
						)}
					</div>
				</div>

				<div className="flex justify-end mt-8">
					<OrangeButton
						type="submit"
						title={
							uploading
								? "Uploading..."
								: isEditForm
									? "Edit Request"
									: "Request Leave"
						}
						className={"px-8"}
						disabled={Boolean(leaveTypes.length === 0) || uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default LeaveRequestModalForm;
