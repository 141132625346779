import { Disabilities } from "./disabilities.dto";
import {PaginatorInfo} from "./generic.dto";
import {HolidayDto} from "./holiday.dto";

export type JobTitle = {
    id: number
    organizationId: string
    companyId: number
    employeeBandId: number
    code: string
    description: string
    name: string
    companyLevelId: number
    minimumAge: number | null
    maximumAge: number | null
    minimumExperienceYears: number | null
    acceptDisability: boolean
    createdAt: string
    modifiedAt: string | null
}

export interface JobTitleDto extends JobTitle {
    jobTitleAcceptedDisabilities?: JobTitleAcceptedDisabilityDto[]
}

export interface JobTitleAcceptedDisabilityDto extends JobTitleAcceptedDisability {
    disability?: Disabilities
}

type JobTitleAcceptedDisability = {
    jobTitleId: number;
    disabilityId: number;
    createdAt: string;
}

export class CreateJobTitleDto {
    organizationId!: string
    companyId!: number
    code!: string
    name!: string
    employeeBandId!: number
    description!: string
    companyLevelId!: number
    maximumAge?: number
    minimumAge?: number
    minimumExperienceYears?: number
    acceptDisability!: boolean
    acceptedDisabilities?: number[]
}



export class JobTitleResponseDto {
    id!: number
    organizationId!: string
    companyId!: number
    code!: string
    name!: string
    employeeBandId!: number
    description!: string
    companyLevelId!: number
    maximumAge!: number | null
    minimumAge!: number | null
    minimumExperienceYears!: number | null
    acceptDisability!: boolean
    acceptedDisabilities?: number[]
    createdAt!: string
    modifiedAt!: string | null
}

export class UpdateJobTitleDto {
    organizationId?: string
    companyId?: number
    employeeBandId?: number
    code?: string
    name?: string
    description?: string
    companyLevelId?: number
    maximumAge?: number
    minimumAge?: number
    minimumExperienceYears?: number
    acceptDisability?: boolean
    remove?: number[]  //add joi validation here
    add?: number[]
}

export class QueryJobTitleDto {
    organizationId?: string;
    companyId?: number;
    employeeBandId?: number;
    companyLevelId?: number;
    code?: string;
    minimumAge?: number;
    'minimumAge.gte'?: number;
    'minimumAge.lte'?: number;
    maximumAge?: number;
    'maximumAge.gte'?: number;
    'maximumAge.lte'?: number;
    minimumExperienceYears?: number;
    'minimumExperienceYears.gte'?: number;
    'minimumExperienceYears.lte'?: number;
    acceptDisability?: boolean;
    page?: number = 1;
    limit?: number;
    orderBy?: JobTitleOrderBy = JobTitleOrderBy.CREATED_AT_ASC;
}

export class SearchJobTitleDto {
    q?: string;
    page?: number = 1;
    limit?: number;
    orderBy?: JobTitleOrderBy = JobTitleOrderBy.CREATED_AT_ASC;
}

export enum JobTitleOrderBy {
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
    MODIFIED_AT_ASC = 'modifiedAt:asc',
    MODIFIED_AT_DESC = 'modifiedAt:desc',
    CODE_ASC = 'code:asc',
    CODE_DESC = 'code:desc',
    NAME_ASC = 'name:asc',
    NAME_DESC = 'name:desc',
}

export interface JobTitlePaginator extends PaginatorInfo<JobTitleDto> {}
export type JobTitleSummary = Pick<JobTitle, 'id' | 'code' | 'name'>;