import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import FormDescriptionInput from "../../../../../../components/form_fields/FormDescriptionInput";
import Label from "../../../../../../components/form_fields/Label";
import ModalComponent from "../../../../../../components/modals/ModalFormComponent";
import { IAddEditTableForm } from "../../../../../../helpers/appHelpers";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import { useManageTableWithSearchParamsData } from "../../../../../../helpers/useManageTable";
import useCompanyStore from "../../../../../../state-management/useCompanyStore";
import FormCodeInput from "../../../../../../components/form_fields/FormCodeInput";
import { useCreateDepartment, useUpdateDepartment } from "../../../../../../services/department.service";

interface IFormInputs {
	name: string;
	code: string;
	description: string;
	active: boolean;
}

const DepartmentModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
}: IAddEditTableForm) => {
	const { currentCompany } = useCompanyStore();
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			name: isEditForm ? formDataValues?.name : undefined,
			code: isEditForm ? formDataValues?.code : undefined,
			description: isEditForm ? formDataValues?.description : undefined,
			active: isEditForm ? formDataValues?.active : undefined,
		}
	});

	const { mutate: createDepartment } = useCreateDepartment();
	const { mutate: updateDepartment } = useUpdateDepartment();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		if (isEditForm) {
			updateDepartment(
				{ 
					payload: data, 
					companyId: currentCompany?.id as number,
					id: formDataValues?.id,
				}, 
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {

			createDepartment(
				{ 
					payload: data, 
					companyId: currentCompany?.id as number,
				},
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		reset();
		setOpen(false);
	};

	return (
		<ModalComponent
			width={730}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit(onSubmit)} className="mt-4">
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Name" for="name" />
						<input
							placeholder={"Enter Name"}
							type="text"
							{...register(`name`, {
								required: "Enter name here",
							})}
							id="name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors?.name
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors?.name && (
							<p className="text-red-500 text-sm mt-1">
								{errors?.name?.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Unique Code" for="code" />
						<FormCodeInput
							register={register}
							attributes={{
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.code
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors?.code && (
							<p className="text-red-500 text-sm mt-1">
								{errors?.code?.message}
							</p>
						)}
					</div>
					<div className="col-span-2 w-full">
						<Label title="Description" for="description" />
						<FormDescriptionInput 
							attributes={{
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.description
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`
							}}
							register={register}
							required="Enter brief description here"
						/>
						{errors?.description && (
							<p className="text-red-500 text-sm mt-1">
								{errors?.description?.message}
							</p>
						)}
					</div>
					<div className="col-span-2 flex space-x-3 items-center">
						<input 
							type="checkbox"
							{...register("active", {
								required: false
							})}
							id="active"
						/>
						<label htmlFor="active">Active</label>
					</div>
				</div>
				
				<div className="w-full flex justify-end mt-10">
					<OrangeButton
						title={`${
							uploading
								? "Uploading..."
								: isEditForm
									? "Update Department"
									: "Create Department"
						}`}
						className={`px-8`}
						type={"submit"}
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default DepartmentModalForm;
