import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    BonusDto, BonusPaginator,
    CreateBonusDto,
    QueryBonusDto,
    SearchBonusDto,
    UpdateBonusDto
} from "../models/bonus.dto";
import ENDPOINTS from "./utils/endpoints";
import {QueryOptionsDto} from "../models/generic.dto";

export function useGetBonus(options?: QueryBonusDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.BONUS.GET;
    const request = (options: QueryOptionsDto) => API.ProcessingCompanyAPI.getBonus(options);

    const { data, isLoading, error, refetch } = useQuery<BonusPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch }
}

export function useGetBonusSearch() {

    return useMutation(
        (options?: SearchBonusDto) => API.ProcessingCompanyAPI.getBonusSearch(options),
        {
            onSuccess: () => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetBonusById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.BONUS.DETAIL;
    const request = API.ProcessingCompanyAPI.getBonusById(id);

    const {data, isLoading, error} = useQuery<{ data:BonusDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateBonus() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateBonusDto}) => API.ProcessingCompanyAPI.createBonus(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.BONUS.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateBonus() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateBonusDto, id: number}) => API.ProcessingCompanyAPI.updateBonus(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.BONUS.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useDeleteBonus() {

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteBonusById(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}