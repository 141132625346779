import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    CostCenterDto, CostCenterPaginator,
    CreateCostCenterDto,
    QueryCostCentreDto,
    SearchCostCentreDto,
    UpdateCostCenterDto
} from "../models/cost-center.dto";
import ENDPOINTS from "./utils/endpoints";
import {QueryOptionsDto} from "../models/generic.dto";

export function useGetCostCenter(options?: QueryCostCentreDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.COST_CENTER.GET;
    const request = (options: QueryCostCentreDto) => API.ProcessingCompanyAPI.getCostCenter(options);

	const { data, isLoading, error, refetch } = useQuery<CostCenterPaginator, Error>(
		[url, options],
		({ queryKey, pageParam }) =>
			request(Object.assign({}, queryKey[1], pageParam)),
		{...API.Handlers.pageParamOption}
	);

	return { data, isLoading, error, refetch };
}

export function useGetCostCenterSearch() {
    const queryClient = useQueryClient();

    return useMutation(
        (options?: SearchCostCentreDto) => API.ProcessingCompanyAPI.getCostCenterSearch(options),
        {
            onSuccess: (response,) => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.BENEFIT.GET);
            },
        }
    )
}

export function useGetCostCenterById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.COST_CENTER.DETAIL;
    const request = API.ProcessingCompanyAPI.getCostCenterById(id);

    const {data, isLoading, error} = useQuery<{ data: CostCenterDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateCostCenter() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateCostCenterDto}) => API.ProcessingCompanyAPI.createCostCenter(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.COST_CENTER.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateCostCenter() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateCostCenterDto, id: number}) => API.ProcessingCompanyAPI.updateCostCenter(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.COST_CENTER.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useDeleteCostCentre() {

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteCostCenterById(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}