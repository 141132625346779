import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    UnionDto, UnionPaginator,
    CreateUnionDto, QueryUnionDto,
    UpdateUnionDto
} from "../models/union.dto";
import ENDPOINTS from "./utils/endpoints";
import {QueryOptionsDto} from "../models/generic.dto";

export function useGetUnion(options?: QueryUnionDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.UNION.GET;
    const request = (options: QueryUnionDto) => API.ProcessingCompanyAPI.getUnion(options);

	const { data, isLoading, error, refetch } = useQuery<UnionPaginator, Error>(
		[url, options],
		({ queryKey, pageParam }) =>
			request(Object.assign({}, queryKey[1], pageParam)),
		{...API.Handlers.pageParamOption}
	);

	return { data, isLoading, error, refetch };
}

export function useGetUnionById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.UNION.DETAIL;
    const request = API.ProcessingCompanyAPI.getUnionById(id);

    const {data, isLoading, error} = useQuery<{ data:UnionDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateUnion() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateUnionDto}) => API.ProcessingCompanyAPI.createUnion(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.UNION.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateUnion() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateUnionDto, id: number}) => API.ProcessingCompanyAPI.updateUnion(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.UNION.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useDeleteUnion() {

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteUnion(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}