import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    DepartmentDto,
    CreateDepartmentDto,
    QueryDepartmentDto,
    DepartmentPaginator,
    SearchDepartmentDto,
    UpdateDepartmentDto
} from "../models/department.dto";
import ENDPOINTS from "./utils/endpoints";
import {QueryOptionsDto} from "../models/generic.dto";

export function useGetDepartments(companyId: number, options?: QueryDepartmentDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.DEPARTMENT.GET;
    const request = (options: QueryDepartmentDto) => API.ProcessingCompanyAPI.getDepartment(companyId, options);

    const { data, isLoading, error, refetch } = useQuery<DepartmentPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch }
}

export function useGetDepartmentSearch(companyId: number) {

    return useMutation(
        (options?: SearchDepartmentDto) => API.ProcessingCompanyAPI.getDepartmentSearch(companyId, options),
        {
            onSuccess: () => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetDepartmentById(companyId: number, id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.DEPARTMENT.DETAIL;
    const request = API.ProcessingCompanyAPI.getDepartmentById(companyId, id);

    const {data, isLoading, error} = useQuery<{ data:DepartmentDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateDepartment() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ companyId, payload }: { companyId: number, payload: CreateDepartmentDto}) => API.ProcessingCompanyAPI.createDepartment(companyId, payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.DEPARTMENT.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateDepartment() {
    const queryClient = useQueryClient();

    return useMutation(
        (
            {payload, companyId, id}: {payload: UpdateDepartmentDto, companyId: number, id: number}
        ) => API.ProcessingCompanyAPI.updateDepartment(companyId, id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.DEPARTMENT.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useDeleteDepartment() {

    return useMutation(
        ({ companyId, id }: { companyId: number, id: number }) => API.ProcessingCompanyAPI.deleteDepartmentById(companyId,id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}