import { useState } from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { SavingsColumns, SavingsColumnVisibilityModel } from "../data";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import { useDeleteSavingsScheme, useGetSavingsScheme, useGetSavingsSchemeSearch } from "../../../../services/savings-scheme.service";
import DeleteModal from "../../../../components/modals/DeleteModal";
import ActionPopover from "../../../../components/popovers/actionPopover";
import SavingsModalForm from "../modals/SavingsModalForm";
import { APPLY_SAVINGS_CONTRIBUTION_ON, SavingsSchemeDto } from "../../../../models/savings-scheme.dto";
import { AiOutlinePlus } from "react-icons/ai";
import TableFilter from "../../../../components/table/filter/TableFilter";
import { APP_CONFIG } from "../../../../helpers/appHelpers";
import { useManageTableWithSearchParams, useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import { useGetEmployeeBand } from "../../../../services/employee-band.service";
import { useGetEmployee } from "../../../../services/employee.service";
import { useGetPayPeriod } from "../../../../services/pay-period.service";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import APISearchInput from "../../../../components/search/APISearchInput";
import { EmployeeOrderBy } from "../../../../models/employee.dto";
import ManageTableColumns from "../../../../components/table/ManageTableColumns";
import { useColumnVisibilityModel } from "../../../../helpers/useColumnVisibilityModel";

const SavingsView = () => {
	const { currentCompany } = useCompanyStore();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);
	const {
        tableKey,
        columnVisibilityModel,
        setColumnVisibilityModel,
    } = useColumnVisibilityModel(SavingsColumnVisibilityModel);
	
	const { mutate: searchSavingsSchemes } = useGetSavingsSchemeSearch();
	const {
        tableAPIRef,
        fetchedTableData,
        tableData,
		isLoading,
		pageCount,
		setTableData,
	} = useManageTableWithSearchParams<SavingsSchemeDto>({
		fetchHook: useGetSavingsScheme,
		options: { companyId: currentCompany?.id }
	});
	
	// Fetched data for select fields requiring data from database
	const { data: employees } = useGetEmployee({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		status: "ACTIVE",
		orderBy: EmployeeOrderBy.FIRST_NAME_ASC,
	});
	const { data: payPeriods } = useGetPayPeriod({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
	});
	const { data: employeeBands } = useGetEmployeeBand({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setIsEditForm(false);
		setOpenModal(true);
	};

	const { formDataValues, setFormData } = useEditFormStore();
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteSavingsScheme } = useDeleteSavingsScheme();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteSavingsScheme(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadTableData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>,
				];
			},
		},
	];

	const columnsWithAdditions = SavingsColumns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<>
			<div className="flex justify-between items-center mt-4 mb-8">
				<div className="flex items-center gap-3">
					<APISearchInput 
						fallbackData={fetchedTableData}
						setTableData={setTableData}
						mutate={searchSavingsSchemes}
					/>
					<TableFilter
						fields={[
							{
								title: "",
								fieldName: "",
								fieldType: "SORT",
								options: [
									{
										title: "Name",
										fieldName: "name",
									},
									{
										title: "Code",
										fieldName: "code",
									},
									{
										title: "Date Created",
										fieldName: "createdAt",
									},
									{
										title: "Date Modified",
										fieldName: "modifiedAt",
									},
								]
							},
							{
								title: "Apply Employee Contribution On",
								fieldName: "applyEmployeeContributionOn",
								fieldType: "CHECKBOX_LIST",
								options: APPLY_SAVINGS_CONTRIBUTION_ON,
							},
							{
								title: "Apply Employer Contribution On",
								fieldName: "applyEmployerContributionOn",
								fieldType: "CHECKBOX_LIST",
								options: APPLY_SAVINGS_CONTRIBUTION_ON,
							},
							{
								title: "Prorate",
								fieldName: "prorate",
								fieldType: "YES_NO",
							},
							{
								title: "Statutory",
								fieldName: "statutory",
								fieldType: "YES_NO",
							},
						]}
					/>
					<ManageTableColumns 
						columns={SavingsColumns}
						defaultVisibilityModel={SavingsColumnVisibilityModel}
						visibilityModel={columnVisibilityModel}
						setVisibilityModel={setColumnVisibilityModel}
					/>
				</div>
				<div
					className="flex items-center text-primary-blue cursor-pointer"
					onClick={handleOpenModal}
				>
					<AiOutlinePlus className="mr-1 text-base" />
					<span className="text-sm">Add New Savings Scheme</span>
				</div>
			</div>
			<CustomStyledTable
				key={tableKey}
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				columnVisibilityModel={columnVisibilityModel}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
			<ActionPopover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				handleClose={handleClose}
				setOpenDeleteModal={setOpenDeleteModal}
				setOpenEdit={setIsEditForm}
				setOpenModal={setOpenModal}
			/>
			<DeleteModal
				openDeleteModal={openDeleteModal}
				spanContent={formDataValues?.name || ""}
				setOpenDeleteModal={setOpenDeleteModal}
				handleDeleteMethod={handleDeleteMethod}
				deleting={deleting}
			/>
			{openModal && (
				<SavingsModalForm
					open={openModal}
					setOpen={setOpenModal}
					title={
						isEditForm
							? "Update Savings Scheme"
							: "Add New Savings Scheme"
					}
					isEditForm={isEditForm}
					tableAPIRef={tableAPIRef}
					payPeriods={payPeriods?.data || []}
					employees={
						employees?.data?.sort((a, b) => {
							const fullNameA = a.firstName + ' ' + a.lastName;
							const fullNameB = b.firstName + ' ' + b.lastName;
							return fullNameA.localeCompare(fullNameB);
						}) || []
					}
					employeeBands={
						employeeBands?.data?.sort((a, b) => a.name.localeCompare(b.name)) || []
					}
				/>
			)}
		</>
	);
};

export default SavingsView;
