import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    OvertimePaymentTierDto, OvertimePaymentTierPaginator,
    CreateOvertimePaymentTierDto, QueryOvertimePaymentTierDto,
    UpdateOvertimePaymentTierDto
} from "../models/overtime-payment-tier.dto";
import ENDPOINTS from "./utils/endpoints";
import {QueryOptionsDto} from "../models/generic.dto";

export function useGetOvertimePaymentTier(options?: QueryOvertimePaymentTierDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.OVERTIME_PAYMENT_TIER.GET;
    const request = (options: QueryOvertimePaymentTierDto) => API.ProcessingCompanyAPI.getOvertimePaymentTier(options);

    const { data, isLoading, error, refetch } = useQuery<OvertimePaymentTierPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch }
}

export function useGetOvertimePaymentTierById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.OVERTIME_PAYMENT_TIER.DETAIL;
    const request = API.ProcessingCompanyAPI.getOvertimePaymentTierById(id);

    const {data, isLoading, error} = useQuery<{ data:OvertimePaymentTierDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateOvertimePaymentTier() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateOvertimePaymentTierDto}) => API.ProcessingCompanyAPI.createOvertimePaymentTier(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.OVERTIME_PAYMENT_TIER.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateOvertimePaymentTier() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateOvertimePaymentTierDto, id: number}) => API.ProcessingCompanyAPI.updateOvertimePaymentTier(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.OVERTIME_PAYMENT_TIER.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useDeleteOvertimePaymentTier() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteOvertimePaymentTier(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.OVERTIME_PAYMENT_TIER.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}