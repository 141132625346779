import { useEffect, useState } from "react";
import OrangeButton from "../../../components/buttons/OrangeButton";
import { Helmet } from "react-helmet-async";
import CompanyDetails from "./forms/CompanyDetails";
import CompanySetupLayout from "../../../components/layouts/CompanySetupLayout";
import { useNavigate } from "react-router-dom";
import useCompanyStore from "../../../state-management/useCompanyStore";
import useUserStore from "../../../state-management/useUserStore";
import { PATHS } from "../../../routes/routes.paths";
import { toast } from "react-toastify";
import UpdateAccountConfigModal from "../../../components/modals/UpdateAccountConfigModal";

const CompanyAccountSetup = () => {
	const navigate = useNavigate();
	const { currentUser } = useUserStore();
	const userOrganization = currentUser.user.organization;
	const { savedCompanies } = useCompanyStore();
	const [view, setView] = useState("company details");
	const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
	const [upgradeModalContent, setUpgradeModalContent] = useState("");
	const [onClickActionButton, setOnClickActionButton] = useState<(() => void) | null>(null);
	const highestSubscriptionTierId = sessionStorage.getItem("highestSubscriptionTierId");

	useEffect(() => {
		if (!userOrganization?.config) {
			toast.info("Organization configurations not found! Kindly sign out and sign back in to fix this.");
			return
		}
		
		const maxCompanies = userOrganization?.config.maxCompanies as number;

		if ((maxCompanies < 0) || maxCompanies > savedCompanies.length) return;

		if (userOrganization?.billingType.name.toLowerCase() === "contract") {
			setOpenUpgradeModal(true);
			setOnClickActionButton(() => navigate(-1));
			setUpgradeModalContent("Maximum company limit reached! Kindly contact the Akatua team to increase limit.");
			return
		}

		if (highestSubscriptionTierId === userOrganization?.subsciptionTier.id) {
			setOpenUpgradeModal(true);
			setOnClickActionButton(() => navigate(-1));
			setUpgradeModalContent(`Maximum company limit reached! Kindly contact the Akatua team to increase 
				limit as you are currently on the highest subscription package.`
			);
			return
		}

		setOpenUpgradeModal(true);
		setOnClickActionButton(() => navigate(PATHS.CREATE_PAYROLL_COMPANY + "?upgradeRequired=true"));
		setUpgradeModalContent("Maximum company limit reached! Upgrade to higher subscription package to continue.");
	}, [userOrganization])

	return (
		<CompanySetupLayout pathname={window.location.pathname}>
			<Helmet>
				<title>Company Account Setup | Akatua</title>
			</Helmet>
			{/* <Header /> */}
			{view !== "dashboard" && (
				<div className="mt-6 flex flex-col justify-center items-center text-sm w-full">
					<div className="flex flex-col max- w-[900px]">
						<h1 className="text-2xl font-medium mb-10">Begin by setting up your company</h1>
						{view === "company details" && (
							<CompanyDetails setView={setView} />
						)}
					</div>
				</div>
			)}
			{view === "dashboard" && (
				<div className="mt-28 flex flex-col justify-center items-center text-sm">
					<div className="text-primary-blue bg-green-50 rounded-full flex justify-center items-center h-20 w-20 text-4xl">
						🎉
					</div>
					<h1 className="text-xl font-bold mt-10">
						You’ve successfully set up
					</h1>
					<h1 className="text-xl font-bold mb-8">your company </h1>
					<p className="font-medium">
						Nice, you’re all set up now and ready to roll!
					</p>
					<div className="w-[15%] mt-8">
						<OrangeButton
							title={"Go to Dashboard"}
							className={"w-full"}
							onClick={() => navigate(PATHS.OVERVIEW)}
						/>
					</div>
				</div>
			)}
			<UpdateAccountConfigModal 
				open={openUpgradeModal}
				content={upgradeModalContent}
				handleClose={() => {}}
				onClickActionButton={onClickActionButton}
				doNotShowCloseButton
			/>
		</CompanySetupLayout>
	);
};

export default CompanyAccountSetup;
