import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, enumToStringConverter, formatDate, moneyFormat } from "../../../helpers/appHelpers";

export const OvertimeColumns: GridColDef[] = [
	{
		field: "name",
		headerName: "Name",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "code",
		headerName: "Code",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "description",
		headerName: "Description",
		width: 380,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "employeeBand",
		headerName: "Employee Band",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name,
	},
	{
		field: "minHoursRequired",
		headerName: "Min Hours Required",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "maxHoursPermitted",
		headerName: "Max Hours Permitted",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "active",
		headerName: "Active",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	{
		field: "taxable",
		headerName: "Taxable",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	
	...createdAtmodifiedAtActions
];

export const OvertimeColumnVisibilityModel: Record<string, boolean> = {
	name: true,
	code: true,
	description: true,
	employeeBand: true,
	minHoursRequired: true,
	maxHoursPermitted: true,
	active: true,
	taxable: true,
	createdAt: true,
	modifiedAt: true,
};

export const BonusesColumns: GridColDef[] = [
	{
		field: "name",
		headerName: "Name",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "code",
		headerName: "Code",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "description",
		headerName: "Description",
		width: 380,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "active",
		headerName: "Active",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	{
		field: "fixedComponent",
		headerName: "Fixed Component",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter(params) {
			const amount = params.value;
			const currency = params.row.companyCurrency?.currency;
			return params.value ? `${currency?.code || ""} ${moneyFormat(amount)}` : "--";
		},
	},
	{
		field: "rateComponent",
		headerName: "Rate Component",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ?  (params.value * 100 + "%") : "--",
	},
	{
		field: "minValue",
		headerName: "Min Value",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter(params) {
			const amount = params.row.minValue;
			const currency = params.row.companyCurrency?.currency;
			return `${currency?.code || ""} ${moneyFormat(amount)}`;
		},
	},
	{
		field: "maxValue",
		headerName: "Max Value",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter(params) {
			const amount = params.row.maxValue;
			const currency = params.row.companyCurrency?.currency;
			return `${currency?.code || ""} ${moneyFormat(amount)}`;
		},
	},
	{
		field: "periodStartDate",
		headerName: "Period Start Date",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params: GridValueGetterParams) => {
			const periodStartDate = formatDate(params);
			return periodStartDate;
		},
	},
	{
		field: "periodEndDate",
		headerName: "Period End Date",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params: GridValueGetterParams) => {
			const periodEndDate = formatDate(params);
			return periodEndDate;
		},
	},
	{
		field: "prorate",
		headerName: "Prorate",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	{
		field: "minEmploymentPeriod",
		headerName: "Min Employment Period",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value + ` ${enumToStringConverter(params.row.employmentPeriodUnit)}(s)`,
	},
	{
		field: "paymentFrequency",
		headerName: "Payment Frequency",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	
	...createdAtmodifiedAtActions
];

export const BonusesColumnVisibilityModel: Record<string, boolean> = {
	name: true,
	code: true,
	description: true,
	active: true,
	fixedComponent: false,
	rateComponent: false,
	minValue: false,
	maxValue: false,
	periodStartDate: true,
	periodEndDate: true,
	prorate: true,
	minEmploymentPeriod: true,
	paymentFrequency: true,
	createdAt: true,
	modifiedAt: true,
};
