import { GridColDef } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, moneyFormat } from "../../../../../helpers/appHelpers";

export const Columns: GridColDef[] = [
	{
		field: "currency",
		headerName: "Currency",
		width: 220,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name || "--",
	},
	{
		field: "buyRate",
		headerName: "Buy Rate",
		width: 200,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value),
	},
	{
		field: "sellRate",
		headerName: "Sell Rate",
		width: 200,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value),
	},
	
	...createdAtmodifiedAtActions
];
