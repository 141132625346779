import { GridColDef } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, enumToStringConverter } from "../../../../../helpers/appHelpers";

export const Columns: GridColDef[] = [
	{
		field: "name",
		headerName: "Name",
		width: 300,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value + " " + `(${params.row.shortName})`,
	},
	{
		field: "code",
		headerName: "Code",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "paymentChannelType",
		headerName: "Payment Channel Type",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "active",
		headerName: "Active",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	{
		field: "country",
		headerName: "Country",
		width: 200,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name || "--",
	},
	{
		field: "swiftCode",
		headerName: "Swift Code",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	
	...createdAtmodifiedAtActions
];
//  className="truncate"