import { GridColDef } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, enumToStringConverter, moneyFormat } from "../../../../../helpers/appHelpers";

export const Columns: GridColDef[] = [
	{
		field: "name",
		headerName: "Name",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "description",
		headerName: "Description",
		width: 350,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "priceAmount",
		headerName: "Price",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => {
			const amount = params.value;
			const currency = params.row.currency;
			return (currency?.code || "") + " " + moneyFormat(amount);
		},
	},
	{
		field: "netAmount",
		headerName: "Net Price",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter(params) {
			const amount = params.value;
			const currency = params.row.currency;
			return (currency?.code || "") + " " + moneyFormat(amount);
		},
	},
	{
		field: "maxUsers",
		headerName: "Max Users",
		width: 160,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "maxCompanies",
		headerName: "Max Companies",
		width: 180,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "maxEmployees",
		headerName: "Max Employees",
		width: 180,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "billingPeriod",
		headerName: "Billing Period",
		width: 200,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "plan",
		headerName: "Plan",
		width: 200,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "promotion",
		headerName: "Promotion",
		width: 220,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.title || "--",
	},
	{
		field: "country",
		headerName: "Country",
		width: 220,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name || "--",
	},
	
	...createdAtmodifiedAtActions
];

export const PromotionsColumnVisibilityModel: Record<string, boolean> = {
	name: true,
	description: true,
	priceAmount: true,
	netAmount: true,
	maxUsers: true,
	maxCompanies: true,
	maxEmployees: true,
	billingPeriod: true,
	plan: true,
	promotion: false,
	country: false,
	createdAt: true,
	modifiedAt: true,
};