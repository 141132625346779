import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import Label from "../../../../../components/form_fields/Label";
import useUserStore from "../../../../../state-management/useUserStore";
import { SetPasswordPolicyDto } from "../../../../../models/password-policy.dto";
import { Typography } from "@mui/material";
import { FaQuestionCircle } from "react-icons/fa";
import { CustomizedTooltip } from "../../../../../helpers/appHelpers";
import { 
	useGetPasswordPolicy, 
	useGetDefaultPasswordPolicy, 
	useSetPasswordPolicy, 
	useClearPasswordPolicy, 
} from "../../../../../services/password-policy.service";

const PasswordPolicy = () => {
	const { currentUser } = useUserStore();
	const [isEditable, setIsEditable] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [clearing, setClearing] = useState(false);
	const [infiniteValidity, setInfiniteValidity] = useState(false);

	const { data: passwordPolicy, isLoading: loadingPasswordPolicy } = useGetPasswordPolicy();
	const { data: defaultPasswordPolicy } = useGetDefaultPasswordPolicy();

	useEffect(() => {
		if (loadingPasswordPolicy) return;

		Object.entries(passwordPolicy?.data || {}).map(([key, value]) => {
			if (!excludedKeys.has(key)) {
				setValue(key as any, value);
				if (key === "rotationFrequencyDays" && value === -1) {
					setInfiniteValidity(true);
				}
			}
		});
	}, [loadingPasswordPolicy])

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<SetPasswordPolicyDto>({
		defaultValues: {
			minLength: undefined,
			maxLength: undefined,
			minUppercaseCount: undefined,
			minLowercaseCount: undefined,
			minDigitCount: undefined,
			minSymbolCount: undefined,
			rotationFrequencyDays: undefined,
			maxConsecutiveInvalidAttempts: undefined,
			lockoutDuration: undefined,
		}
	});

	const handleInfiniteValidity = () => {
		setInfiniteValidity(prev => {
			const isInfinite = !prev;
			setValue("rotationFrequencyDays", isInfinite ? -1 : undefined);
			return isInfinite;
		});
	};

	const { mutate: setPasswordPolicy } = useSetPasswordPolicy();
	const { mutate: clearPasswordPolicy } = useClearPasswordPolicy();

	const onSubmit: SubmitHandler<SetPasswordPolicyDto> = async (data) => {
		setUploading(true);

		setPasswordPolicy(
			{ 
				payload: {
					...data,
					organizationId: currentUser.user.organizationId,
				} 
			}, 
			{
				onSuccess: () => {
					setIsEditable(false);
				},
				onSettled: () => setUploading(false),
			}
		);
	};

	const restoreDefaultPolicy = async () => {
		setClearing(true);

		clearPasswordPolicy(
			{ }, 
			{
				onSuccess: () => {
					Object.entries(defaultPasswordPolicy?.data || {}).map(([key, value]) => {
						if (!excludedKeys.has(key)) {
							setValue(key as any, value);
							if (key === "rotationFrequencyDays" && value === -1) {
								setInfiniteValidity(true);
							}
						}
					});
					setIsEditable(false);
				},
				onSettled: () => setClearing(false),
			}
		);
	};

	return (
		<div className="mb-4">
			<div className="border-b-[1.5px] pb-3 flex w-full justify-between font-semibold text-sm">
				<h2 className="font-semibold text-sm">Password Policy</h2>
				<button
					className={`border-none text-primary-blue text-sm ${
						isEditable === true ? "hidden" : ""
					}`}
					onClick={() => setIsEditable(true)}
				>
					Edit
				</button>
			</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-8 mt-8">
					<div className="w-full">
						<Label title="Minimum Character Length" for="minLength" />
						<input
							placeholder="Enter value"
							type="number"
							{...register("minLength", {
								required: "Enter minimum character length",
							})}
							min={1}
							id="minLength"
							className={`w-full bg-secondary-gray rounded-lg h-12 px-5 mt-2 text-sm  ${
								errors.minLength
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							disabled={!isEditable}
						/>
						{errors.minLength && (
							<p className="text-red-500 text-sm mt-1">
								{errors.minLength.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Maximum Character Length" for="maxLength" optional />
						<input
							placeholder="Enter value"
							type="number"
							{...register("maxLength", {
								required: false,
							})}
							min={0}
							id="maxLength"
							className={`w-full bg-secondary-gray rounded-lg h-12 px-5 mt-2 text-sm  ${
								errors.maxLength
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							disabled={!isEditable}
						/>
						{errors.maxLength && (
							<p className="text-red-500 text-sm mt-1">
								{errors.maxLength.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Minimum Uppercase Letters" for="minUppercaseCount" optional />
						<input
							placeholder="Enter value"
							type="number"
							{...register("minUppercaseCount", {
								required: false,
							})}
							min={0}
							id="minUppercaseCount"
							className={`w-full bg-secondary-gray rounded-lg h-12 px-5 mt-2 text-sm  ${
								errors.minUppercaseCount
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							disabled={!isEditable}
						/>
						{errors.minUppercaseCount && (
							<p className="text-red-500 text-sm mt-1">
								{errors.minUppercaseCount.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Minimum Lowercase Letters" for="minLowercaseCount" optional />
						<input
							placeholder="Enter value"
							type="number"
							{...register("minLowercaseCount", {
								required: false,
							})}
							min={0}
							id="minLowercaseCount"
							className={`w-full bg-secondary-gray rounded-lg h-12 px-5 mt-2 text-sm  ${
								errors.minLowercaseCount
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							disabled={!isEditable}
						/>
						{errors.minLowercaseCount && (
							<p className="text-red-500 text-sm mt-1">
								{errors.minLowercaseCount.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Minimum Numbers (Digits)" for="minDigitCount" optional />
						<input
							placeholder="Enter value"
							type="number"
							{...register("minDigitCount", {
								required: false,
							})}
							min={0}
							id="minDigitCount"
							className={`w-full bg-secondary-gray rounded-lg h-12 px-5 mt-2 text-sm  ${
								errors.minDigitCount
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							disabled={!isEditable}
						/>
						{errors.minDigitCount && (
							<p className="text-red-500 text-sm mt-1">
								{errors.minDigitCount.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<div className="flex items-center gap-1.5">
							<Label title="Minimum Special Characters" for="minSymbolCount" optional />
							<CustomizedTooltip 
								title="i.e. !@#$%^&*()" 
								placement="top"
								arrow
							>
								<Typography> 
									<FaQuestionCircle className="text-black" />
								</Typography>
							</CustomizedTooltip>
						</div>
						<input
							placeholder="Enter value"
							type="number"
							{...register("minSymbolCount", {
								required: false,
							})}
							min={0}
							id="minSymbolCount"
							className={`w-full bg-secondary-gray rounded-lg h-12 px-5 mt-2 text-sm  ${
								errors.minSymbolCount
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							disabled={!isEditable}
						/>
						{errors.minSymbolCount && (
							<p className="text-red-500 text-sm mt-1">
								{errors.minSymbolCount.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<div className="flex items-center justify-between">
							<div className="flex items-center gap-1.5">
								<Label title="Validity Period" for="rotationFrequencyDays" optional />
								<CustomizedTooltip 
									title="The number of days required before requesting a password change from the user." 
									placement="top"
									arrow
								>
									<Typography> 
										<FaQuestionCircle className="text-black" />
									</Typography>
								</CustomizedTooltip>
							</div>
							<button
								type="button"
								onClick={() => handleInfiniteValidity()}
								className="px-2 rounded border border-black text-xs hover:scale-[1.05]"
								disabled={!isEditable}
							>
								{infiniteValidity ? "Cancel" : "Infinite"}
							</button>
						</div>
						<div className="relative mt-2">
							<div className={`flex items-center h-14 ${infiniteValidity ? "invisible" : ""}`}>
								<input
									placeholder={"Enter values"}
									type="number"
									{...register("rotationFrequencyDays", {
										required: "Enter validity period here",
										valueAsNumber: true,
									})}
									id="rotationFrequencyDays"
									className={`w-full bg-secondary-gray rounded-lg h-full px-5 text-sm 
										${errors.rotationFrequencyDays
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
										}
									`}
								/>
								<div className="h-full w-fit px-6 flex items-center justify-center text-sm rounded-r-lg text-white bg-black">
									Days
								</div>
							</div>
							{infiniteValidity && (
								<input
									type="text"
									value="Infinite"
									className="absolute inset-0 w-full bg-secondary-gray rounded-lg h-14 px-5 text-sm border-none"
									disabled
								/>
							)}
						</div>
						{errors.rotationFrequencyDays && (
							<p className="text-red-500 text-sm mt-1">{errors.rotationFrequencyDays.message}</p>
						)}
					</div>
					<div className="w-full">
						<div className="flex items-center gap-1.5">
							<Label title="Maximum Consecutive Invalid Attempts" for="maxConsecutiveInvalidAttempts" optional />
							<CustomizedTooltip 
								title="The maximum number of consecutive invalid attempts allowed before the user is locked out." 
								placement="top"
								arrow
							>
								<Typography> 
									<FaQuestionCircle className="text-black" />
								</Typography>
							</CustomizedTooltip>
						</div>
						<input
							placeholder="Enter value"
							type="number"
							{...register("maxConsecutiveInvalidAttempts", {
								required: false,
							})}
							min={0}
							id="maxConsecutiveInvalidAttempts"
							className={`w-full bg-secondary-gray rounded-lg h-12 px-5 mt-2 text-sm  ${
								errors.maxConsecutiveInvalidAttempts
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							disabled={!isEditable}
						/>
						{errors.maxConsecutiveInvalidAttempts && (
							<p className="text-red-500 text-sm mt-1">
								{errors.maxConsecutiveInvalidAttempts.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<div className="flex items-center gap-1.5">
							<Label title="Lockout Duration" for="lockoutDuration" optional />
							<CustomizedTooltip 
								title="The number of minutes to lock out the user after consecutive invalid attempts." 
								placement="top"
								arrow
							>
								<Typography> 
									<FaQuestionCircle className="text-black" />
								</Typography>
							</CustomizedTooltip>
						</div>
						<input
							placeholder="Enter value"
							type="number"
							{...register("lockoutDuration", {
								required: false,
							})}
							min={0}
							id="lockoutDuration"
							className={`w-full bg-secondary-gray rounded-lg h-12 px-5 mt-2 text-sm  ${
								errors.lockoutDuration
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							disabled={!isEditable}
						/>
						{errors.lockoutDuration && (
							<p className="text-red-500 text-sm mt-1">
								{errors.lockoutDuration.message}
							</p>
						)}
					</div>
				</div>
			
				<div className="w-full flex items-center justify-end gap-5 mt-10">
					<button
						type="button"
						onClick={restoreDefaultPolicy}
						className="px-5 py-4 border text-gray-400 hover:text-gray-500 border-gray-500 rounded-lg text-2xs"
						disabled={!isEditable || clearing || uploading}
					>
						{clearing ? "Restoring..." : "Restore Default Policy"}
					</button>
					<OrangeButton
						type="submit"
						title={uploading ? "Uploading..." : "Save"}
						className="px-12 text-sm"
						disabled={!isEditable || uploading || clearing}
					/>
				</div>
			</form>
		</div>
	);
};

export default PasswordPolicy;

const excludedKeys = new Set(["id", "createdAt", "modifiedAt"]);