import { GridColDef } from "@mui/x-data-grid";
import { moneyFormat } from "../../../helpers/appHelpers";

export const RecentPayColumns: GridColDef[] = [
	{
		field: "payPeriod",
		headerName: "Pay Period",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.code,
	},
	{
		field: "basicPayAmount",
		headerName: "Basic Pay",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "grossAmount",
		headerName: "Gross Pay",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "netAmount",
		headerName: "Net Pay",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "employeePensionAmount",
		headerName: "Employee Pension Contribution",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "employerPensionAmount",
		headerName: "Employer Pension Contribution",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "taxAmount",
		headerName: "Income Tax",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
];
