import AccountsManagement from "../pages/admin/accounts_management";
import SystemConfigurations from "../pages/admin/system_configurations";
import BenefitsAndAllowances from "../pages/payroll_company/benefits_and_allowances";
import CompanyAccountSetup from "../pages/payroll_company/company_account_setup";
import CompanySettings from "../pages/payroll_company/company_settings";
import CreatePassword from "../pages/authentication/create-password";
import CreatePayrollCompany from "../pages/payroll_company/create_payroll_company";
import Employees from "../pages/shared/employees";
import ForgotPassword from "../pages/authentication/forgotpassword";
import Overview from "../pages/payroll_company/overview";
import CreditNotes from "../pages/admin/billing";
import Payroll from "../pages/shared/payroll";
import SavingsAndBackpay from "../pages/payroll_company/savings_and_backpay";
import ResetPassword from "../pages/authentication/reset-password";
import SelfserviceDashboard from "../pages/self_service/dashboard";
import SelfservicePayslips from "../pages/self_service/payslips";
import SelfserviceProfileDetails from "../pages/self_service/profile_details";
import SelfserviceReimbursement from "../pages/self_service/reimbursement";
import SelfserviceLeaveMgmt from "../pages/self_service/leave_management";
import SetPassword from "../pages/authentication/set-password";
import Signin from "../pages/authentication/signin";
import Signup from "../pages/authentication/signup";
import SignupConfirmation from "../pages/authentication/signup-confirmation";
import SubscriptionPayment from "../pages/payroll_company/subscription_payment";
import Support from "../pages/shared/support";
import DeductionAndLoans from "../pages/payroll_company/deductions_and_loans";
import OvertimeAndBonuses from "../pages/payroll_company/overtime_and_bonuses";
import { PATHS } from "./routes.paths";
import DisciplinaryAndGrievance from "../pages/human_resource/disciplinary_and_grievance";
import LeaveManagement from "../pages/human_resource/leave_management";
import OrganisationalTree from "../pages/human_resource/organisation_tree";
import ReimbursementsHR from "../pages/human_resource/reimbursements";
import WorkTime from "../pages/shared/work_time";
import Announcements from "../pages/human_resource/announcements";
import PayAdvances from "../pages/shared/pay_advances";
import HRBonus from "../pages/human_resource/bonus_management";
import SelfserviceWortTimeAndOvertime from "../pages/self_service/work_time";
import CompanyDocumentTypes from "../pages/human_resource/company_document_types";
import Reports from "../pages/shared/reports";

export const authRoutes = [
	{ path: PATHS.SIGN_UP, element: Signup },
	{ path: PATHS.SIGN_IN, element: Signin },
	{ path: PATHS.FORGOT_PASSWORD, element: ForgotPassword },
	{ path: PATHS.RESET_PASSWORD, element: ResetPassword },
	{ path: PATHS.SIGN_UP_CONFIRMATION, element: SignupConfirmation },
	{ path: PATHS.CREATE_PASSWORD, element: CreatePassword },
	{ path: PATHS.SET_PASSWORD, element: SetPassword },
];

export const payrollCompanySetupRoutes = [
	{ path: PATHS.COMPANY_ACCOUNT_SETUP, element: CompanyAccountSetup },
	{ path: PATHS.CREATE_PAYROLL_COMPANY, element: CreatePayrollCompany },
	{ path: PATHS.SUBSCRIPTION_PAYMENT, element: SubscriptionPayment },
];

export const payrollCompanyRoutes = [
	{ path: PATHS.OVERVIEW, element: Overview },
	{ path: PATHS.EMPLOYEES, element: Employees },
	{ path: PATHS.DEDUCTIONS_AND_LOANS, element: DeductionAndLoans },
	{ path: PATHS.OVERTIME_AND_BONUSES, element: OvertimeAndBonuses },
	{ path: PATHS.SAVINGS_AND_BACKPAY, element: SavingsAndBackpay },
	{ path: PATHS.PAYROLL, element: Payroll },
	{ path: PATHS.PAY_ADVANCES, element: PayAdvances },
	{ path: PATHS.BENEFIT_AND_ALLOWANCE, element: BenefitsAndAllowances },
	{ path: PATHS.WORK_TIME, element: WorkTime },
	{ path: PATHS.REPORTS, element: Reports },
	{ path: PATHS.COMPANY_SETTINGS, element: CompanySettings },
	{ path: PATHS.SUPPORT, element: Support },
];

export const adminRoutes = [
	{ path: PATHS.ADMIN_ACCOUNTS_MANAGEMENT, element: AccountsManagement },
	{ path: PATHS.ADMIN_SYSTEM_CONFIGURATIONS, element: SystemConfigurations },
	{ path: PATHS.ADMIN_BILLING, element: CreditNotes },
	{ path: PATHS.ADMIN_SUPPORT, element: Support },
];

export const selfServiceRoutes = [
	{ path: PATHS.SELF_SERVICE_DASHBOARD, element: SelfserviceDashboard },
	{
		path: PATHS.SELF_SERVICE_PROFILE_DETAILS,
		element: SelfserviceProfileDetails,
	},
	{ path: PATHS.SELF_SERVICE_PAYSLIPS, element: SelfservicePayslips },
	{ path: PATHS.SELF_SERVICE_WORK_TIME, element: SelfserviceWortTimeAndOvertime },
	{ path: PATHS.SELF_SERVICE_LEAVE_MANAGEMENT, element: SelfserviceLeaveMgmt },
	{ path: PATHS.SELF_SERVICE_REIMBURSEMENT, element: SelfserviceReimbursement },
	{ path: PATHS.SELF_SERVICE_SUPPORT, element: Support },
];

export const hrRoutes = [
	{ path: PATHS.HR_EMPLOYEE_MANAGEMENT, element: Employees },
	{ path: PATHS.HR_DISCIPLINARY_GRIEVANCE, element: DisciplinaryAndGrievance },
	{ path: PATHS.HR_LEAVE_MANAGEMENT, element: LeaveManagement },
	{ path: PATHS.HR_REIMBURSEMENTS, element: ReimbursementsHR },
	{ path: PATHS.HR_PAYROLL_MANAGEMENT, element: Payroll },
	{ path: PATHS.HR_PAY_ADVANCES, element: PayAdvances },
	{ path: PATHS.HR_ORGANISATIONAL_TREE, element: OrganisationalTree },
	{ path: PATHS.HR_WORK_TIME, element: WorkTime },
	{ path: PATHS.HR_ANNOUNCEMENTS, element: Announcements },
	{ path: PATHS.HR_DOCUMENT_TYPES, element: CompanyDocumentTypes },
	{ path: PATHS.HR_BONUS_MANAGEMENT, element: HRBonus },
	{ path: PATHS.HR_REPORTS, element: Reports },
	{ path: PATHS.HR_SUPPORT, element: Support },
];