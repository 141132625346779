import React, { useRef, useState, useEffect, useMemo } from "react";
import { FiSearch } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import SearchItems from "./SearchItems";
import { Link } from 'react-router-dom';
import useUserStore from "../../../../state-management/useUserStore";
import { 
    AdminPanelSearchItems,
    HRSearchItems, 
    PayrollCompanySearchItems, 
    SelfServiceSearchItems 
} from "./data";
import useRecentSearchStore from "../../../../state-management/useRecentSearch";

interface GlobalSearchProps {
    setOpenedState: React.Dispatch<React.SetStateAction<boolean>>;
}

const GlobalSearch = ({ setOpenedState }: GlobalSearchProps) => {
    const { userType } = useUserStore();
    const { 
        recentSearch, 
        filterRecentSearch,
        deleteFromRecentSearch, 
    } = useRecentSearchStore();
	const [searchValue, setSearchValue] = useState("");
	const [emptySearchField, setEmptySearchField] = useState(true);
	const [searchItemsData, setSearchItemsData] = useState<any[]>([]);
    const globalSearchItems = useMemo(() => {
        switch(userType) {
            case "ADMIN":
                filterRecentSearch(AdminPanelSearchItems);
                return AdminPanelSearchItems;
            case "EMPLOYEE":
                filterRecentSearch(SelfServiceSearchItems);
                return SelfServiceSearchItems;
            case "HR":
                filterRecentSearch(HRSearchItems.concat(SelfServiceSearchItems));
                return HRSearchItems.concat(SelfServiceSearchItems);
            default:
                filterRecentSearch(PayrollCompanySearchItems);
                return PayrollCompanySearchItems;
        }
    }, [userType])

    // Clear input value
	const handleSearchClear = (e: { preventDefault: () => void }) => {
		e.preventDefault();
		setSearchValue("");

        setSearchItemsData([]);
        setEmptySearchField(true);
	};

    // Global search logic
    const search = () => {
		const lowercasedSearchValue = searchValue.trim().toLowerCase();

        lowercasedSearchValue ? setEmptySearchField(false) : setEmptySearchField(true);

        // Items with no sub pages
		const noSubPageItems = globalSearchItems.filter(
            (searchItem) => !searchItem.parentPageName
		);
		const filteredNoSubPageItems = noSubPageItems.filter((searchItem) =>
			searchItem.name?.toLowerCase().includes(lowercasedSearchValue)
		);

        // Items with sub pages
		const subPageItems = globalSearchItems.filter(
			(searchItem) =>  searchItem.parentPageName
		);
        const filteredSubPageItems = subPageItems.flatMap(subPageItem => {
            const filteredSubPages = subPageItem.subPages?.filter(
                (subPage) => subPage.name?.toLowerCase().includes(lowercasedSearchValue)
            );

            if (!filteredSubPages?.length && subPageItem.parentPageName?.toLowerCase().includes(lowercasedSearchValue)) {
                return subPageItem;
            }

            return { ...subPageItem, subPages: filteredSubPages };
        });

        setSearchItemsData([...filteredNoSubPageItems, ...filteredSubPageItems]);
	};

    // Close global search box on clicking outside it
    const searchBoxRef = useRef<HTMLDivElement>(null);
    const clickedOutsideSearchBox = (event: any) => {
        if (searchBoxRef.current && !searchBoxRef.current.contains(event.target as Node)) {
            handleSearchClose();
        }
    };

    useEffect(() => {
        const searchBoxBackground = document.querySelector<HTMLDivElement>(".search-box-background");
        searchBoxBackground?.addEventListener("click", clickedOutsideSearchBox)

        return () => {
            searchBoxBackground?.removeEventListener("click", clickedOutsideSearchBox)
        };
    }, []);

    // Close global search box
	const handleSearchClose = () => {
        setSearchValue("");
        setSearchItemsData([]);
		setOpenedState(false);
	};

    return (
        <div className="search-box-background h-[100svh] w-screen fixed inset-0 z-[9999999999] bg-black bg-opacity-[0.4] ">
            <div ref={searchBoxRef} className="lg:w-[600px] w-[90%] pt-6 pb-8 rounded-[8px] bg-white absolute top-10 left-1/2 -translate-x-1/2 shadow-2xl ">
                <div className="px-8 ">
                    <div className="flex justify-end items-center mb-4">
                        <IoMdClose
                            onClick={handleSearchClose}
                            className="text-xl hover:animate-pulse cursor-pointer"
                        />
                    </div>
                    <form className="relative">
                        <input
                            autoFocus
                            value={searchValue}
                            type="text"
                            placeholder="Search across the site"
                            className="w-full bg-secondary-gray focus:outline-none focus:ring-2 focus:ring-slate-200 focus:border-transparent border rounded-lg h-14 pl-14 pr-5 mt-2 text-sm"
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                                search();
                            }}
                            onKeyUp={search}
                        />
                        <FiSearch className="absolute top-[28px] left-5 text-lg text-gray-500" />
                        <button
                            onClick={(e) => handleSearchClear(e)}
                            className="absolute top-[18px] right-5 text-2xs text-gray-500 hover:bg-slate-300 bg-slate-200 py-2 px-3 rounded-lg"
                        >
                            Clear
                        </button>
                    </form>
                </div>
                <div className="flex flex-col border-t mt-8 pt-2 justify-center w-full text-gray-400 text-sm ">
                    {/* show this if there are no recent searches */}
                    {emptySearchField ? (
                        <>
                            {recentSearch.length ? (
                                <div className="px-8 pt-6">
                                    <p className="pb-2">Recent searches</p>
                                    {recentSearch.map((item, index) => (
                                        <div key={index} className="relative">
                                            <Link
                                                to={item.link ? item.link : ""}
                                                onClick={handleSearchClose}
                                                className="w-full py-2 pl-4 pr-8 bg-secondary-gray border-[0.5px] border-[#999c9c] rounded-lg mb-2
                                                    flex items-center justify-between hover:shadow-md transition-all duration-300 ease-in-out
                                                "
                                            >
                                                <div>
                                                    <div className="flex items-center gap-[6px] mb-[2px]">
                                                        <div className="text-sm text-[#585B5B] font-semibold">{item.name}</div>
                                                        {item.parentPage &&
                                                            <div className="text-[11px] leading-3 text-[#585B5B]">{`(${item.parentPage})`}</div>
                                                        }
                                                    </div>
                                                    <div className="text-xs text-[#707373] line-clamp-1">{item.description}</div>
                                                </div>

                                            </Link>
                                            <IoMdClose
                                                className="absolute right-4 top-1/2 -translate-y-1/2 text-xl hover:scale-[1.2] cursor-pointer"
                                                onClick={() => deleteFromRecentSearch(item.id)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ):(
                                <p className="pt-10 pb-4 text-center">No recent searches</p>
                            )}
                        </> 
                    ):(
                        <SearchItems
                            searchItemsArray={searchItemsData}
                            closeGlobalSearch={handleSearchClose}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default GlobalSearch;