import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    NationalityDto, NationalityPaginator,
    CreateNationalityDto, QueryNationalityDto,
    UpdateNationalityDto,
    SearchNationalityDto
} from "../models/nationality.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetNationality(options?: QueryNationalityDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.NATIONALITY.GET;
    const request = (options: QueryNationalityDto) => API.ProcessingCompanyAPI.getNationality(options);

    const { data, isLoading, error, refetch } = useQuery<NationalityPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch };
}

export function useGetNationalitySearch() {

    return useMutation(
        (options?: SearchNationalityDto) => API.ProcessingCompanyAPI.getNationalitySearch(options),
        {
            onSuccess: () => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetNationalityById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.NATIONALITY.DETAIL
    const request = API.ProcessingCompanyAPI.getNationalityById(id);

    const {data, isLoading, error} = useQuery<{ data:NationalityDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateNationality() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateNationalityDto}) => API.ProcessingCompanyAPI.createNationality(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.NATIONALITY.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateNationality() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateNationalityDto, id: number}) => API.ProcessingCompanyAPI.updateNationality(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.NATIONALITY.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useDeleteNationality() {

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteNationality(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}