import { GridColDef } from "@mui/x-data-grid";
import { formatDateAndTime, moneyFormat } from "../../../helpers/appHelpers";
import { Avatar } from "@mui/material";

export const PartialSalaryColumns: GridColDef[] = [
	{
		field: "employee",
		headerName: "Employee",
		headerClassName: "super-app-theme--header",
		width: 250,
		renderCell: (params: any) => {
			const employee = params.row.employee || undefined;
			return (
				<div className="flex items-center space-x-4">
					{employee && <>
						<Avatar
							src={employee.photoUrl || ""}
							alt="employee"
							className="w-10 h-10 rounded-full"
						/>
						<p className="text-sm truncate">
							{employee.firstName + " " + employee.lastName}
						</p>
					</>}
				</div>
			);
		},
	},
	{
		field: "startingPayPeriod",
		headerName: "Start Pay Period",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.code,
	},
	{
		field: "amount",
		headerName: "Amount",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value),
	},
	{
		field: "expired",
		headerName: "Expired",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	{
		field: "createdAt",
		headerName: "Date Created",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => {
			const createdOn = formatDateAndTime(params);
			return createdOn;
		},
	},
	{
		field: "modifiedAt",
		headerName: "Date Modified",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => {
			const modifiedOn = formatDateAndTime(params);
			return modifiedOn;
		},
	},
	{
		field: "actions",
		headerName: "Actions",
		cellClassName: "actions",
		headerClassName: "super-app-theme--header",
	}
];

export const SalaryAdvanceColumns: GridColDef[] = [
	{
		field: "employee",
		headerName: "Employee",
		headerClassName: "super-app-theme--header",
		width: 300,
		renderCell: (params: any) => {
			const employee = params.row.employee || undefined;
			return (
				<div className="flex items-center space-x-4">
					{employee && <>
						<Avatar
							src={employee.photoUrl || ""}
							alt="employee"
							className="w-10 h-10 rounded-full"
						/>
						<p className="text-sm truncate">
							{employee.firstName + " " + employee.lastName}
						</p>
					</>}
				</div>
			);
		},
	},
	{
		field: "startingPayPeriod",
		headerName: "Start Pay Period",
		width: 280,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.code,
	},
	{
		field: "amount",
		headerName: "Amount",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value),
	},
	{
		field: "createdAt",
		headerName: "Date Created",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => {
			const createdOn = formatDateAndTime(params);
			return createdOn;
		},
	},
	{
		field: "modifiedAt",
		headerName: "Date Modified",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => {
			const modifiedOn = formatDateAndTime(params);
			return modifiedOn;
		},
	},
	{
		field: "actions",
		headerName: "Actions",
		cellClassName: "actions",
		headerClassName: "super-app-theme--header",
	}
];
