import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { CreateEmployeeRelativeDto, RELATIONSHIP } from "../../../../../../models/employee-relative.dto";
import Label from "../../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useCreateEmployeeRelative } from "../../../../../../services/employee-relative.service";
import { enumToStringConverter } from "../../../../../../helpers/appHelpers";

interface IFormInputs extends CreateEmployeeRelativeDto {}

interface INewRelative {
	employeeId: number;
	companyId: number;
	setShowNewRelativeForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewRelative = ({ employeeId, companyId, setShowNewRelativeForm }: INewRelative) => {
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<IFormInputs>();

	const { mutate: createEmployeeRelative } = useCreateEmployeeRelative();
	const [uploading, setUploading] = React.useState(false);

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		createEmployeeRelative(
			{
				payload: {
					...data,
					employeeId,
					companyId,
				},
			},
			{
				onSuccess: () => setShowNewRelativeForm(false),
				onSettled: () => setUploading(false),
			}
		);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="lg:grid grid-cols-2 gap-x-4 gap-y-6 mt-6 space-y-4 lg:space-y-0">
				<div className="w-full">
					<Label title="First Name" for="firstName" />
					<input
						placeholder={"Enter first name"}
						type="text"
						{...register("firstName", {
							required: "Enter first name here",
						})}
						id="firstName"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.firstName
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.firstName && (
						<p className="text-red-500 text-sm mt-1">
							{errors.firstName.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Last Name" for="lastName" />
					<input
						placeholder={"Enter last name"}
						type="text"
						{...register("lastName", {
							required: "Enter last name here",
						})}
						id="lastName"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.lastName
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.lastName && (
						<p className="text-red-500 text-sm mt-1">
							{errors.lastName.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Other Names" for="otherNames" optional />
					<input
						placeholder={"Enter other names"}
						type="text"
						{...register("otherNames", {
							required: false,
						})}
						id="otherNames"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.otherNames
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
				</div>
				<div className="w-full">
					<Label title="Gender" for="gender" />
					<select
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.gender
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
						id="gender"
						{...register("gender", {
							required: "Select gender here",
						})}
					>
						<option value="">Select Gender</option>
						<option value="MALE">Male</option>
						<option value="FEMALE">Female</option>
					</select>
					{errors.gender && (
						<p className="text-red-500 text-sm mt-1">
							{errors.gender.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Telephone" for="msisdn" />
					<Controller
						name={"msisdn"}
						control={control}
						rules={{
							required: "Enter telephone number here",
							maxLength: {
								value: 12,
								message: "Enter a valid phone number",
							},
						}}
						render={({ field }) => (
							<div
								className={`w-full bg-secondary-gray rounded-lg mt-2 flex items-center ${
									errors.msisdn
										? "border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							>
								<PhoneInput
									{...field}
									country={"gh"}
									inputStyle={{
										height: "50px",
										border: "none",
										backgroundColor: "#F2F2F4",
										color: "black",
										boxShadow: "none",
										width: "95%",
										margin: "0px 0px 0px 15px",
										borderRadius: "10px",
									}}
									containerStyle={{
										// marginTop: "8px",
										height: "50px",
										border: "none",
										backgroundColor: "#F2F2F4",
										boxShadow: "none",
										borderRadius: "10px",
									}}
									buttonStyle={{
										border: "none",
										backgroundColor: "#F2F2F4",
										boxShadow: "none",
										padding: "0px 10px",
									}}
								/>
							</div>
						)}
					/>
					{errors.msisdn && (
						<p className="text-red-500 text-sm mt-1">
							{errors.msisdn.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Relationship" for="relationship" />
					<select
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.relationship
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
						id="relationship"
						{...register("relationship", {
							required: "Select relationship here",
						})}
					>
						<option value="">Select Relationship</option>
						{Object.entries(RELATIONSHIP).map(([key, value], index) => (
							<option 
								key={index} 
								value={key}
							>
								{enumToStringConverter(value)}
							</option>
						))}
					</select>
					{errors.relationship && (
						<p className="text-red-500 text-sm mt-1">
							{errors.relationship.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Identification Type" for="idType" />
					<select
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.idType
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
						id="idType"
						{...register("idType", {
							required: "Select Id type here",
						})}
					>
						<option value="">Select Type</option>
						<option value="PASSPORT">Passport</option>
						<option value="VOTERS">Voters ID Card</option>
						<option value="NATIONAL_ID">National ID Card</option>
						<option value="DRIVER_LICENCE">Driver's License</option>
						<option value="EMPLOYER_ID">Employer ID Card</option>
						<option value="SCHOOL_ID">School ID Card</option>
						<option value="SOCIAL_SECURITY">Social Security Card</option>
						<option value="TRAVEL_CERT">Travel Certificate</option>
						<option value="HEALTH_INSURANCE">Health Insurance Card</option>
					</select>
					{errors.idType && (
						<p className="text-red-500 text-sm mt-1">
							{errors.idType.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Identification Number" for="idNumber" />
					<input
						placeholder={"Enter number"}
						type="text"
						{...register("idNumber", {
							required: "Enter Id number here",
						})}
						id="idNumber"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.idNumber
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.idNumber && (
						<p className="text-red-500 text-sm mt-1">
							{errors.idNumber.message}
						</p>
					)}
				</div>
				<div className="col-span-2 flex space-x-3 items-center">
					<input 
						type="checkbox"
						{...register("nextOfKin", {
							required: false,
						})}
						id="nextOfKin"
					/>
					<label htmlFor="nextOfKin" className="max-lg:text-sm">Next Of Kin</label>
				</div>
				<div className="col-span-2 flex space-x-3 items-center">
					<input 
						type="checkbox"
						{...register("active", {
							required: false,
						})}
						id="active"
					/>
					<label htmlFor="active" className="max-lg:text-sm">Active</label>
				</div>
			</div>

			<div className="w-full flex items-center gap-4 lg:justify-between my-8">
				<button
					onClick={() => setShowNewRelativeForm(false)}
					className="px-10 py-4 max-lg:w-full border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
				>
					Close Form
				</button>
				<OrangeButton
					title={`${uploading ? "Saving..." : "Add Relative"}`}
					type="submit"
					className={"h-14 px-8 max-lg:w-full"}
					disabled={uploading}
				/>
			</div>
		</form>
	);
};

export default NewRelative;