import { useState } from "react";
import AdminLayout from "../../../components/layouts/AdminLayout";
import { PATHS } from "../../../routes/routes.paths";
import { Helmet } from "react-helmet-async";
import CustomTab from "../../../components/custom_tab/CustomTab";
import { useSearchParams } from "react-router-dom";
import TransactionsView from "./views/TransactionsView";
import InvoicesView from "./views/InvoicesView";

const Billing = () => {
	const [searchParams] = useSearchParams();
	const [view, setView] = useState(
		searchParams.get("view") || "invoices"
	);

	return (
		<AdminLayout>
			<Helmet>
				<title>Billing | Admin Flow</title>
			</Helmet>
			<h1 className="font-bold text-xl text-charcoal mb-8">Billing</h1>
			<CustomTab
				view={view}
				setView={setView}
				tabColumns={CreditNotesAndInvoicesTabColumns}
			>
				<div className="pt-6">
					{view === "invoices" && <InvoicesView />}
					{view === "transactions" && <TransactionsView />}
				</div>
			</CustomTab>
		</AdminLayout>
	);
};

export default Billing;

const CreditNotesAndInvoicesTabColumns = [
	{
		view: "invoices",
		name: "Invoices",
	},
	{
		view: "transactions",
		name: "Transactions",
	},
];
