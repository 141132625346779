import { useContext, useState } from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { columns, EmployeesColumnVisibilityModel } from "./data";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import DeleteModal from "../../../../components/modals/DeleteModal";
import ActionPopover from "../../../../components/popovers/actionPopover";
import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import TableFilter from "../../../../components/table/filter/TableFilter";
import { EmployeeDto, GENDER, STATUS } from "../../../../models/employee.dto";
import EmployeeFormContextWrapper, { EmployeeFormContext } from "../EmployeeFormContext";
import { 
	useDeleteEmployee, 
	useGetEmployee, 
	useGetEmployeeCount, 
	useGetEmployeeSearch, 
} from "../../../../services/employee.service";
import APISearchInput from "../../../../components/search/APISearchInput";
import { useManageTableWithSearchParams, useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import { AiOutlinePlus } from "react-icons/ai";
import { GoUpload } from "react-icons/go";
import AddNewEmployee from "../AddNewEmployee";
import EditEmployee from "../EditEmployee";
import UploadEmployees from "../UploadEmployees";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import useUserStore from "../../../../state-management/useUserStore";
import { toast } from "react-toastify";
import { PATHS } from "../../../../routes/routes.paths";
import { useNavigate } from "react-router-dom";
import UpdateAccountConfigModal from "../../../../components/modals/UpdateAccountConfigModal";
import ManageTableColumns from "../../../../components/table/ManageTableColumns";
import { useColumnVisibilityModel } from "../../../../helpers/useColumnVisibilityModel";
import { RiIndeterminateCircleLine } from "react-icons/ri";
import TerminateEmployee from "../TerminateEmployee";

const EmployeesMainContent = () => {
	const navigate = useNavigate();
	const { currentCompany } = useCompanyStore();
	const { currentUser, userType } = useUserStore();
	const userOrganization = currentUser.user.organization;
	const employeeFormContextData = useContext(EmployeeFormContext);
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openUploadEmployeesModal, setOpenUploadEmployeesModal] = useState(false);
	const [openAddEmployeeModal, setOpenAddEmployeeModal] = useState(false);
	const [openEditEmployeeModal, setOpenEditEmployeeModal] = useState(false);
	const [openTerminateEmployeeModal, setOpenTerminateEmployeeModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
	const [upgradeModalContent, setUpgradeModalContent] = useState("");
	const [onClickActionButton, setOnClickActionButton] = useState<(() => void) | null>(null);
	const highestSubscriptionTierId = sessionStorage.getItem("highestSubscriptionTierId");
	const {
        tableKey,
        columnVisibilityModel,
        setColumnVisibilityModel,
    } = useColumnVisibilityModel(EmployeesColumnVisibilityModel);

	const { data: totalOrganizationEmployees } = useGetEmployeeCount({
		organizationId: userOrganization?.id as string
	});
	
	const { mutate: searchEmployees } = useGetEmployeeSearch();
	const {
		tableAPIRef,
		fetchedTableData,
		tableData,
		isLoading,
		pageCount,
		setTableData,
	} = useManageTableWithSearchParams<EmployeeDto>({
		fetchHook: useGetEmployee,
		options: { companyId: currentCompany?.id as number }
	});

	const handleClose = () => setAnchorEl(null);
	const handleCreateEmployeeForm = (setCreateEmployeeForm: any) => {
		if (userType !== "ORGANIZATION") {
			setCreateEmployeeForm(true);
			return
		};

		if (!userOrganization?.config) {
			toast.info("Organization configurations not found! Kindly sign out and sign back in to fix this.");
			return
		}
		
		const maxEmployees = userOrganization?.config.maxEmployees as number;
		
		if ((maxEmployees < 0) || maxEmployees > (totalOrganizationEmployees?.data.count as number)) {
			setCreateEmployeeForm(true);
			return
		};

		if (userOrganization?.billingType.name.toLowerCase() === "contract") {
			setOpenUpgradeModal(true);
			// setOnClickActionButton(() => navigate(-1));
			setUpgradeModalContent("Maximum employee limit reached! Kindly contact the Akatua team to increase limit.");
			return
		}

		if (highestSubscriptionTierId === userOrganization?.subsciptionTier.id) {
			setOpenUpgradeModal(true);
			// setOnClickActionButton(() => navigate(-1));
			setUpgradeModalContent(`Maximum employee limit reached! Kindly contact the Akatua team to increase 
				limit as you are currently on the highest subscription package.`
			);
			return
		}

		setOpenUpgradeModal(true);
		setOnClickActionButton(() => navigate(PATHS.CREATE_PAYROLL_COMPANY + "?upgradeRequired=true"));
		setUpgradeModalContent("Maximum employee limit reached! Upgrade to higher subscription package to continue.");
	};

	const { formDataValues, setFormData } = useEditFormStore();
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteEmployee } = useDeleteEmployee();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteEmployee(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadTableData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 200,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>,
				];
			},
		},
	];

	const columnsWithAdditions = columns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<>
			<div className="flex justify-between items-center mb-8">
				<h1 className="font-bold text-xl">Employees</h1>
			</div>
			<EmployeeFormContextWrapper>
				<div className="mb-6 flex justify-between items-center">
					<div className="flex items-center gap-3">
						<APISearchInput 
							fallbackData={fetchedTableData}
							setTableData={setTableData}
							mutate={searchEmployees}
						/>
						<TableFilter
							fields={[
								{
									title: "",
									fieldName: "",
									fieldType: "SORT",
									options: [
										{
											title: "First Name",
											fieldName: "firstName",
										},
										{
											title: "Last Name",
											fieldName: "lastName",
										},
										{
											title: "Date Created",
											fieldName: "createdAt",
										},
										{
											title: "Date Modified",
											fieldName: "modifiedAt",
										},
									]
								},
								{
									title: "Gender",
									fieldName: "gender",
									fieldType: "CHECKBOX_LIST",
									options: GENDER,
								},
								{
									title: "HR",
									fieldName: "hr",
									fieldType: "YES_NO",
								},
								{
									title: "Job Title",
									fieldName: "jobTitleId",
									fieldType: "SELECT_FIELD",
									options: employeeFormContextData?.jobTitles || [],
								},
								{
									title: "Major Grade Level",
									fieldName: "majorGradeLevelId",
									fieldType: "SELECT_FIELD",
									options: employeeFormContextData?.MajorGradeLevelData || [],
								},
								{
									title: "Minor Grade Level",
									fieldName: "minorGradeLevelId",
									fieldType: "SELECT_FIELD",
									options: employeeFormContextData?.MinorGradeLevelData || [],
								},
								{
									title: "Status",
									fieldName: "status",
									fieldType: "CHECKBOX_LIST",
									options: STATUS,
								},
								{
									title: "Date Of Birth",
									fieldName: "dateOfBirth",
									fieldType: "DATE_RANGE",
								},
								{
									title: "Employment Date",
									fieldName: "employmentDate",
									fieldType: "DATE_RANGE",
								},
								{
									title: "Termination Date",
									fieldName: "terminationDate",
									fieldType: "DATE_RANGE",
								},
								{
									title: "Date Created",
									fieldName: "createdAt",
									fieldType: "DATE_RANGE",
								},
								{
									title: "Resident",
									fieldName: "resident",
									fieldType: "YES_NO",
								},
								{
									title: "Reemployed",
									fieldName: "reemployed",
									fieldType: "YES_NO",
								},
								{
									title: "Union Member",
									fieldName: "unionMember",
									fieldType: "YES_NO",
								},
							]}
						/>
						<ManageTableColumns 
							columns={columns}
							defaultVisibilityModel={EmployeesColumnVisibilityModel}
							visibilityModel={columnVisibilityModel}
							setVisibilityModel={setColumnVisibilityModel}
						/>
					</div>
					<div className="flex items-center space-x-4 font-medium">
						<button
							onClick={() => handleCreateEmployeeForm(setOpenUploadEmployeesModal)}
							className="border border-gray-300 rounded-lg text-[12px] text-gray-600 py-3 px-3 flex items-center"
						>
							<GoUpload className="mr-3 text-[17px] text-gray-500" />
							Upload Employee
						</button>
						<button
							onClick={() => handleCreateEmployeeForm(setOpenAddEmployeeModal)}
							className="border border-gray-300 rounded-lg text-[12px] py-3 px-3 flex items-center bg-primary-mango text-white"
						>
							<AiOutlinePlus className="mr-2 text-lg" />
							Add New Employee
						</button>
					</div>
				</div>
				<CustomStyledTable
					key={tableKey}
					rows={tableData}
					columns={columnsWithAdditions}
					apiRef={tableAPIRef}
					columnVisibilityModel={columnVisibilityModel}
					loading={isLoading}
					pageCount={pageCount}
					setAnchorEl={setAnchorEl}
				/>
				<ActionPopover
					id={anchorEl ? "simple-popover" : undefined}
					open={Boolean(anchorEl)}
					anchorEl={anchorEl}
					handleClose={handleClose}
					setOpenDeleteModal={setOpenDeleteModal}
					setOpenEdit={setOpenEditEmployeeModal}
					setOpenModal={setOpenEditEmployeeModal}
					additionalOptions={(
						<>
						{formDataValues?.status === "ACTIVE" && (
							<div
								onClick={() => {
									setOpenTerminateEmployeeModal(true);
									handleClose();
								}}
								className="table-action-popover"
							>
								<RiIndeterminateCircleLine />
								<p className="text-2sm">Terminate</p>
							</div>
						)}
						</>
					)}
				/>
				<DeleteModal
					openDeleteModal={openDeleteModal}
					setOpenDeleteModal={setOpenDeleteModal}
					handleDeleteMethod={handleDeleteMethod}
					spanContent={
						formDataValues?.firstName + " " + formDataValues?.lastName
					}
					deleting={deleting}
				/>

				{openAddEmployeeModal && (
					<AddNewEmployee
						open={openAddEmployeeModal}
						setOpen={setOpenAddEmployeeModal}
					/>
				)}

				{openEditEmployeeModal && (
					<EditEmployee
						open={openEditEmployeeModal}
						setOpen={setOpenEditEmployeeModal}
						tableAPIRef={tableAPIRef}
					/>
				)}
			</EmployeeFormContextWrapper>

			{openUploadEmployeesModal && (
				<UploadEmployees
					open={openUploadEmployeesModal}
					setOpen={setOpenUploadEmployeesModal}
				/>
			)}

			{openTerminateEmployeeModal && (
				<TerminateEmployee
					open={openTerminateEmployeeModal}
					setOpen={setOpenTerminateEmployeeModal}
					tableAPIRef={tableAPIRef}
				/>
			)}
			
			<UpdateAccountConfigModal 
				open={openUpgradeModal}
				content={upgradeModalContent}
				handleClose={() => setOpenUpgradeModal(false)}
				onClickActionButton={onClickActionButton}
			/>
		</>
	);
};

export default EmployeesMainContent;
