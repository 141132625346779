import { useState } from 'react'
import { PATHS } from "../../../routes/routes.paths";
import HumanResourceLayout from "../../../components/layouts/HumanResourceLayout";
import { Helmet } from "react-helmet-async";
import CustomTab from "../../../components/custom_tab/CustomTab";
import { useSearchParams } from 'react-router-dom';
import DisciplinaryActionsView from './views/DisciplinaryActionsView';
import GrievanceReportsView from './views/GrievanceReportsView';

const DisciplinaryAndGrievance = () => {
	const [searchParams] = useSearchParams();
	const [view, setView] = useState(
		searchParams.get("view") || "disciplinary"
	);

	return (
		<HumanResourceLayout>
			<Helmet>
				<title>Disciplinary & Grievance | HR Flow</title>
			</Helmet>
			<h1 className="font-bold text-xl mb-8">Disciplinary & Grievance</h1>
			<CustomTab
				view={view}
				setView={setView}
				tabColumns={DisciplinaryAndGrievanceTabColumns}
			>
				<div className="mt-6">
					{view === "disciplinary" && <DisciplinaryActionsView />}
					{view === "grievance" && <GrievanceReportsView />}
				</div>
			</CustomTab>
		</HumanResourceLayout>
	);
};

export default DisciplinaryAndGrievance;

const DisciplinaryAndGrievanceTabColumns = [
	{
		view: "disciplinary",
		name: "Disciplinary",
	},
	{
		view: "grievance",
		name: "Grievance",
	},
];