import {Allowance} from "./allowance.dto";
import { PayrollCompanyDto } from "./company-account.dto";
import { EmployeeDto } from "./employee.dto";
import {PaginatorInfo} from "./generic.dto";
import { PayPeriodDto } from "./pay-period.dto";

export const BACKPAY_CATEGORY = {
  BASIC: 'BASIC',
  GROSS: 'GROSS',
  ALLOWANCES: 'ALLOWANCES',
  FIXED: 'FIXED',
  BASIC_DIFFERENCE: 'BASIC_DIFFERENCE',
  GROSS_DIFFERENCE: 'GROSS_DIFFERENCE'
};

export type BACKPAY_CATEGORY = keyof typeof BACKPAY_CATEGORY;

export type BackPayAllowance = {
  id: number
  backPayId: number
  allowanceId: number
  createdAt: string
}

export type BackPay = {
  id: number
  companyId: number
  employeeId: number
  code: string
  name: string
  description: string
  category: BACKPAY_CATEGORY
  fixedComponent:number| null
  rateComponent: number | null
  startPayPeriodId: number
  endPayPeriodId: number
  applicablePayPeriodId: number
  createdAt: string
  modifiedAt: string | null
}

export class CreateBackPayDto {
  companyId!: number
  employeeId!: number
  code!: string
  name!: string
  description!: string
  category!: BACKPAY_CATEGORY
  fixedComponent?: number
  rateComponent?: number
  startPayPeriodId!: number
  endPayPeriodId!: number
  applicablePayPeriodId!: number
  allowances?: number[]
}

export class UpdateBackPayDto {
  code?: string
  name?: string
  description?: string
  category?: BACKPAY_CATEGORY
  fixedComponent?: number
  rateComponent?: number
  startPayPeriodId?: number
  endPayPeriodId?: number
  applicablePayPeriodId?: number
  add?: number[]
  remove?: number[]
}


export interface BackPayDto extends BackPay {
  backPayAllowances?: BackPayAllowanceDto[]
  employee?: EmployeeDto
  company?: PayrollCompanyDto
  startPayPeriod?: PayPeriodDto
  endPayPeriod?: PayPeriodDto
}

export interface BackPayPaginator extends PaginatorInfo<BackPayDto> {}

export interface BackPayAllowanceDto extends BackPayAllowance {
  allowance?: Allowance
}


export class QueryBackPayDto {
  companyId?: number;
  code?: string
  employeeId?: number
  category?: BACKPAY_CATEGORY
  page?: number = 1;
  limit?: number ;
  orderBy?: BackPayOrderBy = BackPayOrderBy.CREATED_AT_ASC;
}

export class SearchBackPayDto {
  q?: string;
  page?: number = 1;
  limit?: number;
  orderBy: BackPayOrderBy = BackPayOrderBy.CREATED_AT_ASC;
}

export enum BackPayOrderBy {
  CREATED_AT_ASC = 'createdAt:asc',
  CREATED_AT_DESC = 'createdAt:desc',
  MODIFIED_AT_ASC = 'modifiedAt:asc',
  MODIFIED_AT_DESC = 'modifiedAt:desc',
  CODE_ASC = 'code:asc',
  CODE_DESC = 'code:desc',
  NAME_ASC = 'name:asc',
  NAME_DESC = 'name:desc'
}