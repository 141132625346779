import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import CustomStyledTable from "../../../../../components/table/CustomStyledTable";
import { ExchangeRateColumns } from "./data";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import ExchangeRatesModalForm from "./modal/ExchangeRatesModalForm";
import useEditFormStore from "../../../../../state-management/useEditFormStore";
import { useManageTableWithSearchParams, useManageTableWithSearchParamsData } from "../../../../../helpers/useManageTable";
import { APP_CONFIG } from "../../../../../helpers/appHelpers";
import DeleteModal from "../../../../../components/modals/DeleteModal";
import TableFilter from "../../../../../components/table/filter/TableFilter";
import APISearchInput from "../../../../../components/search/APISearchInput";
import { CurrencyOrderBy } from "../../../../../models/currency.dto";
import { useGetCurrency } from "../../../../../services/currency.service";
import { useDeleteExchangeRate, useGetExchangeRate } from "../../../../../services/exchange-rate.service";
import { DetailedExchangeRateDto } from "../../../../../models/exchange-rate.dto";
import { Popover } from "@mui/material";
import { BsPencil, BsTrash } from "react-icons/bs";
import { LuEye } from "react-icons/lu";
import ExchangeRateHistoryModal from "./modal/ExchangeRateHistoryModal";
import { useQueryClient } from 'react-query';
import ENDPOINTS from "../../../../../services/utils/endpoints";

const ExchangeRates = () => {
    const queryClient = useQueryClient();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModalForm, setOpenModalForm] = useState(false);
	const [openHistoryModal, setOpenHistoryModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);
	
	// const { mutate: searchExchangeRates } = useGetExchangeRateSearch();
	const {
        tableAPIRef,
        fetchedTableData,
        tableData,
        isLoading,
        pageCount,
        setTableData,
    } = useManageTableWithSearchParams<DetailedExchangeRateDto>({ 
		fetchHook: useGetExchangeRate,
		options: {}
	});

	// Fetched data for select fields requiring data from 
	const { data: currencies } = useGetCurrency({
		active: true,
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		orderBy: CurrencyOrderBy.NAME_ASC,
	});
	
	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setIsEditForm(false);
		setOpenModalForm(true);
	};

	const { formDataValues, setFormData } = useEditFormStore();
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteExchangeRate } = useDeleteExchangeRate();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteExchangeRate(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadTableData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>
				];
			},
		},
	];
	const columnsWithAdditions = ExchangeRateColumns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<div>
			<h2 className="text-2sm font-semibold mb-3">Exchange Rates</h2>
			<hr />
			<div className="flex justify-between items-center mt-7 mb-8">
				<div className="flex items-center gap-3">
					{/* <APISearchInput 
						fallbackData={fetchedTableData}
						setTableData={setTableData}
						mutate={searchExchangeRates}
					/> */}
					<TableFilter
						fields={[
							{
								title: "",
								fieldName: "",
								fieldType: "SORT",
								options: [
									{
										title: "From Currency",
										fieldName: "fromCurrency.name",
									},
									{
										title: "To Currency",
										fieldName: "toCurrency.name",
									},
									{
										title: "Date Created",
										fieldName: "createdAt",
									},
								]
							},
							{
								title: "From Currency",
								fieldName: "fromCurrencyId",
								fieldType: "SELECT_FIELD",
								options: currencies?.data || [],
							},
							{
								title: "To Currency",
								fieldName: "toCurrencyId",
								fieldType: "SELECT_FIELD",
								options: currencies?.data || [],
							},
						]}
					/>
				</div>
				<div
					className="flex items-center text-primary-blue text-sm cursor-pointer"
					onClick={handleOpenModal}
				>
					<AiOutlinePlus className="mr-1 text-sm" />
					Add New Exchange Rate	
				</div>
			</div>
			<CustomStyledTable
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
			<Popover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				PaperProps={{
					style: {
						width: "auto",
						borderRadius: 8,
						display: "flex",
						justifyContent: "center",
					},
				}}
			>
				<div className="flex flex-col justify-center p-3 text-secondary-darkgray">
					<div
						onClick={() => {
							setOpenModalForm(true);
							setIsEditForm(true);
							handleClose();
						}}
						className="table-action-popover"
					>
						<BsPencil />
						<p className="text-2sm">Edit</p>
					</div>
					<div
						onClick={() => {
							queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.EXCHANGE_RATE_HISTORY.GET);
							setOpenHistoryModal(true);
							handleClose();
						}}
						className="table-action-popover"
					>
						<LuEye />
						<p className="text-2sm">View History</p>
					</div>
					<div
						onClick={() => {
							handleClose();
							setOpenDeleteModal(true);
						}}
						className="table-action-popover"
					>
						<BsTrash />
						<p className="text-2sm">Delete</p>
					</div>
				</div>
			</Popover>

			<DeleteModal
				openDeleteModal={openDeleteModal}
				spanContent={`${formDataValues?.fromCurrency?.name} - ${formDataValues?.toCurrency?.name}`}
				setOpenDeleteModal={setOpenDeleteModal}
				handleDeleteMethod={handleDeleteMethod}
				deleting={deleting}
			/>
			{openModalForm && (
				<ExchangeRatesModalForm
					open={openModalForm}
					setOpen={setOpenModalForm}
					title={
						isEditForm
							? "Update Exchange Rate"
							: "Add New Exchange Rate"
					}
					isEditForm={isEditForm}
					tableAPIRef={tableAPIRef}
					currencies={currencies?.data || []}
				/>
			)}
			{openHistoryModal && (
				<ExchangeRateHistoryModal
					openModal={openHistoryModal}
					setOpenModal={setOpenHistoryModal}
					fromCurrency={formDataValues?.fromCurrency}
					toCurrency={formDataValues?.toCurrency}
				/>
			)}
		</div>
	);
};

export default ExchangeRates;
