import { useSearchParams } from "react-router-dom";
import { PaginatorInfo } from "../models/generic.dto";
import { useEffect, useState } from "react";
import { useGridApiRef } from "@mui/x-data-grid";

type ReturnedObject = {
    data: PaginatorInfo<any>;
    isLoading: boolean;
    error: Error | null;
    refetch: () => Promise<any>;
}

type ManageAccordions = {
    /** Hook to get accordions data `ie useGetBenefit` */
    fetchHook: (params: any) => any;

    /** Params for accordions data hook */
    options?: any;
}

/** Custom hook to manage accordion-based pages with pagination */
export function useManageAccordions<TData>({ fetchHook, options }: ManageAccordions) {
	const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get("page");
    const newData = searchParams.get("newData");
    const reloadData = searchParams.get("reloadData");
    const searching = searchParams.get("searching");
	const [accordionsData, setAccordionsData] = useState<TData[]>([]);
	const [fetchedAccordionsData, setFetchedAccordionsData] = useState<TData[]>([]); // For search operations
	const [pageCount, setPageCount] = useState(1);

    const returnedObject = fetchHook({
        limit: 8,
        orderBy: "createdAt:desc",
        page: newData ? 1 : (Number(page) || 1),
        ...options,
    });
    
    const { data, isLoading, refetch } = returnedObject as ReturnedObject;

	useEffect(() => {
		if (!isLoading && data?.data) {
			setAccordionsData(data.data);
			setFetchedAccordionsData(data.data);
            setPageCount(data.pagination?.totalPages || 1);
		}
		// eslint-disable-next-line
	}, [isLoading]);
    
	useEffect(() => {
        if (searching) {
            setPageCount(1);
            return
        }
        
        refetch().then((response) => {
            if (response) {
                setAccordionsData(response.data?.data || []);
                setFetchedAccordionsData(response.data?.data || []);
                setPageCount(response.data?.pagination?.totalPages || 1);
            }
        });

        if (newData) {
            const currentParams = new URLSearchParams(window.location.search);
            currentParams.delete("newData");
            setSearchParams(currentParams);
        }
        if (reloadData) {
            const currentParams = new URLSearchParams(window.location.search);
            currentParams.delete("reloadData");
            setSearchParams(currentParams);
        }
	}, [searchParams])

    return {
        accordionsData,
        fetchedAccordionsData,
        isLoading,
        pageCount,
        setAccordionsData,
        setFetchedAccordionsData
    }
}

export function useManageAccordionsData() {
	const [searchParams, setSearchParams] = useSearchParams();
    const view = searchParams.get("view");

    const addNewAccordionsData = () => {
        if (view) {
            setSearchParams({ 
                view, 
                page: "1",
                newData: "true"
            });
        } else {
            setSearchParams({ page: "1", newData: "true" });
        }
    };

    const reloadAccordionsData = () => {
        const currentParams = new URLSearchParams(window.location.search);
        currentParams.set("reloadData", "true");
        setSearchParams(currentParams);
    };

    return { addNewAccordionsData, reloadAccordionsData }
}