import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    BenefitTaxDto, BenefitTaxPaginator,
    CreateBenefitTaxDto, QueryBenefitTaxDto,
    UpdateBenefitTaxDto
} from "../models/benefit-tax.dto";
import ENDPOINTS from "./utils/endpoints";
import {QueryOptionsDto} from "../models/generic.dto";

export function useGetBenefitTax(options?: QueryBenefitTaxDto, queryOptions?: any) {
    const url = ENDPOINTS.PROCESSING_COMPANY.BENEFIT_TAX.GET;
    const request = (options: QueryBenefitTaxDto) => API.ProcessingCompanyAPI.getBenefitTax(options);

    const { data, isLoading, error, refetch } = useQuery<BenefitTaxPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption, ...queryOptions}
    );

    return { data, isLoading, error, refetch };
}

export function useGetBenefitTaxById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.BENEFIT_TAX.DETAIL;
    const request = API.ProcessingCompanyAPI.getBenefitTaxById(id);

    const { data, isLoading, error, refetch } = useQuery<{ data:BenefitTaxDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return { data, isLoading, error, refetch };
}
export function useCreateBenefitTax() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateBenefitTaxDto}) => API.ProcessingCompanyAPI.createBenefitTax(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.BENEFIT_TAX.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateBenefitTax() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateBenefitTaxDto, id: number}) => API.ProcessingCompanyAPI.updateBenefitTax(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.BENEFIT_TAX.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useDeleteBenefitTax() {

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteBenefitTax(id),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}
