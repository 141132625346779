import { useState } from "react";
import Header from "../header";
import { HiOutlineSupport } from "react-icons/hi";
import { Avatar, IconButton, SwipeableDrawer } from "@mui/material";
import { PATHS } from "../../routes/routes.paths";
import { TbExchange } from "react-icons/tb";
import { BsCalendar4Week } from "react-icons/bs";
import { RxDashboard } from "react-icons/rx";
import { MdOutlineAccountBox } from "react-icons/md";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import useUserStore from "../../state-management/useUserStore";
import MobileSidebar from "../sidebars/MobileSidebar";
import { AiOutlineMenu } from "react-icons/ai";
import SidebarItem from "./resources/SidebarItem";
import { LuBookMarked } from "react-icons/lu";

type Anchor = "left";

interface ISelfserviceLayoutProps {
	children: React.ReactNode;
	pageHeader: string;
	pageHeaderNode?: React.ReactNode;
}

const bgColor = "linear-gradient(0deg, rgba(204,247,247,1) 0%, rgba(255,255,255,0.665703781512605) 70%)";

const SelfserviceLayout = ({ children, pageHeader, pageHeaderNode }: ISelfserviceLayoutProps) => {
	const pathname = window.location.pathname;
	const { currentUser } = useUserStore();
	const [showSidebar, setShowSidebar] = useState(
		sessionStorage.getItem("showSidebar") ? false : true
	);
	const [isSidebarOpen, setSidebarOpen] = useState({ left: false });
	
	const toggleSidebar = () => {
		setShowSidebar(prev => {
			if (prev) {
				sessionStorage.setItem("showSidebar", "false");
			} else {
				sessionStorage.removeItem("showSidebar");
			}
			return !prev
		})
	};

	const toggleDrawer =
		(anchor: Anchor, open: boolean) =>
		(event: React.KeyboardEvent | React.MouseEvent) => {
			if (
				event &&
				event.type === "keydown" &&
				((event as React.KeyboardEvent).key === "Tab" ||
					(event as React.KeyboardEvent).key === "Shift")
			) {
				return;
			}

			setSidebarOpen({ ...isSidebarOpen, [anchor]: open });
		};

	return (
		<div className={"flex h-[100svh] w-full"}>
			{/* sidebar */}
			<section className={`h-full ${showSidebar ? "w-[300px] px-6" : "w-[80px] pr-4 pl-5"} sticky top-0 border-r py-6 
				overflow-y-auto sidebar-two-scroll hidden lg:flex flex-col justify-between`
			}>
				<div>
					<div className={`flex items-center mb-10 ${!showSidebar && "justify-center"}`}>
						{showSidebar ? (
							<img
								src={"/assets/logos/akatua-logo.svg"}
								alt="Akatua Logo"
								width={150}
							/>
						) : (
							<img
								src={"/assets/icons/akatua-icon.png"}
								alt="Akatua"
								width={40}
							/>
						)}
					</div>
					<div
						className={`w-full rounded-lg flex py-2 items-center text-white ${
							showSidebar && "bg-primary-blue justify-start px-3 space-x-3"
						}`}
					>
						<Avatar
							alt={currentUser?.user?.firstName}
							src={currentUser?.user?.imageUrl}
							sx={{ width: 40, height: 40 }}
						/>
						{showSidebar && (
							<div className="min-w-0">
								<h3 className="text-[11px] lg:text-xs font-bold">
									{currentUser?.user?.firstName +
										" " +
										currentUser?.user?.lastName}
								</h3>
								<p className="text-[8px] lg:text-[10px] mt-[2px] overflow-hidden truncate font-normal">
									{currentUser?.user?.emailAddress}
								</p>
							</div>
						)}
					</div>
					<div className="flex flex-col space-y-4 border-b pb-5 mt-3">
						<SidebarItem 
							title="Dashboard"
							path={PATHS.SELF_SERVICE_DASHBOARD}
							Icon={RxDashboard}
							showSidebar={showSidebar}
						/>
						<SidebarItem 
							title="Profile Details"
							path={PATHS.SELF_SERVICE_PROFILE_DETAILS}
							Icon={MdOutlineAccountBox}
							showSidebar={showSidebar}
						/>
						<SidebarItem 
							title="Payslips"
							path={PATHS.SELF_SERVICE_PAYSLIPS}
							Icon={LiaFileInvoiceDollarSolid}
							showSidebar={showSidebar}
						/>
						<SidebarItem 
							title="Work Time"
							path={PATHS.SELF_SERVICE_WORK_TIME}
							Icon={LuBookMarked}
							showSidebar={showSidebar}
						/>
						<SidebarItem 
							title="Leave Management"
							path={PATHS.SELF_SERVICE_LEAVE_MANAGEMENT}
							Icon={BsCalendar4Week}
							showSidebar={showSidebar}
						/>
						<SidebarItem 
							title="Reimbursements"
							path={PATHS.SELF_SERVICE_REIMBURSEMENT}
							Icon={TbExchange}
							showSidebar={showSidebar}
						/>
					</div>
				</div>
				<div className="flex flex-col space-y-4 py-5">
					<SidebarItem 
						title="Support"
						path={PATHS.SELF_SERVICE_SUPPORT}
						Icon={HiOutlineSupport}
						showSidebar={showSidebar}
					/>
				</div>
			</section>
			{/* Header + Children */}
			<section 
				className="max-lg:hidden"
				style={{
					width: showSidebar ? "calc(100% - 300px)": "calc(100% - 80px)",
				}}
			>
				<div className={`sticky top-0 z-50 w-full`}>
					<Header onMenuClick={toggleSidebar} />
				</div>
				<div
					style={{ 
						background: (pathname === PATHS.SELF_SERVICE_DASHBOARD || 
							pathname === PATHS.SELF_SERVICE_LEAVE_MANAGEMENT) ? "white" : bgColor,
						height: "calc(100% - 70px)",
					}}
					className="px-10 py-8 overflow-y-auto w-full"
				>
					<div className="w-full h-full flex flex-col max-w-[2000px] mx-auto">
						<div className="mb-6">
							{pageHeader && (
								<h1 className="font-bold text-[23px] text-charcoal">
									{pageHeader}
								</h1>
							)}
							{pageHeaderNode && pageHeaderNode}
						</div>
						{children}
					</div>
				</div>
			</section>
			<section className="hidden w-full h-full max-lg:flex flex-col">
				<SwipeableDrawer
					anchor="left"
					open={isSidebarOpen.left}
					onClose={toggleDrawer("left", false)}
					onOpen={toggleDrawer("left", true)}
				>
					<MobileSidebar
						onClose={() => setSidebarOpen({ ...isSidebarOpen, left: false })}
						route={pathname}
					/>
				</SwipeableDrawer>
				<div className="sticky top-0 bg-white">
					<Header onMenuClick={toggleSidebar} />
					<div className="p-4 flex items-center justify-between">
						{pageHeader && (
							<h1 className="font-bold text-lg text-charcoal">{pageHeader}</h1>
						)}
						{pageHeaderNode && pageHeaderNode}
						<IconButton 
							sx={{ color: "black", padding: "0", margin: "0" }}
							onClick={toggleDrawer("left", true)}
						>
							<AiOutlineMenu
								className="text-2xl lg:hidden hover:cursor-pointer"
							/>
						</IconButton>
					</div>
				</div>
				<div className="grow flex flex-col px-4 pt-2 overflow-y-auto overflow-x-hidden">
					{children}
				</div>
			</section>
		</div>
	);
};

export default SelfserviceLayout;
