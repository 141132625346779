import { useMemo, useState } from "react";
import PayrollCompanyLayout from "../../components/layouts/PayrollCompanyLayout";
import { PATHS } from "../../routes/routes.paths";
import AdminLayout from "../../components/layouts/AdminLayout";
import { Helmet } from "react-helmet-async";
import OrangeButton from "../../components/buttons/OrangeButton";
import SelfserviceLayout from "../../components/layouts/SelfserviceLayout";
import HumanResourceLayout from "../../components/layouts/HumanResourceLayout";
import { IconButton, SwipeableDrawer } from "@mui/material";
import MobileSidebar from "../../components/sidebars/MobileSidebar";
import { AiOutlineMenu } from "react-icons/ai";
import APITestingComponent from "../../helpers/APITestingComponent";
import { useViewPort } from "../../helpers/useViewPort";

type Anchor = "left";

const Support = () => {
	const pathname = window.location.pathname;
	const viewPortWidth = useViewPort();
	const [isSidebarOpen, setSidebarOpen] = useState({ left: false });
	const LAYOUT = useMemo(() => {
		switch(pathname) {
			case PATHS.SUPPORT: 
				return PayrollCompanyLayout;
			case PATHS.ADMIN_SUPPORT: 
				return AdminLayout;
			case PATHS.HR_SUPPORT: 
				return HumanResourceLayout;
			default:
				return SelfserviceLayout;
		}
	}, [pathname])

	const toggleDrawer =
		(anchor: Anchor, open: boolean) =>
		(event: React.KeyboardEvent | React.MouseEvent) => {
			if (
				event &&
				event.type === "keydown" &&
				((event as React.KeyboardEvent).key === "Tab" ||
					(event as React.KeyboardEvent).key === "Shift")
			) {
				return;
			}

			setSidebarOpen({ ...isSidebarOpen, [anchor]: open });
		};

	return (
		<LAYOUT pageHeader="Support">
			<Helmet>
				<title>Support | Company Flow</title>
			</Helmet>
			{pathname !== PATHS.SELF_SERVICE_SUPPORT && (
			<>
				{viewPortWidth < 1024 && (
					<SwipeableDrawer
						anchor="left"
						open={isSidebarOpen.left}
						onClose={toggleDrawer("left", false)}
						onOpen={toggleDrawer("left", true)}
					>
						<MobileSidebar
							onClose={() => setSidebarOpen({ ...isSidebarOpen, left: false })}
							route={PATHS.SELF_SERVICE_SUPPORT}
						/>
					</SwipeableDrawer>
				)}
				<div className="flex justify-between items-center mt-6 mb-4 lg:mt-2">
					<h1 className="font-bold text-xl text-charcoal">Support</h1>
					<IconButton sx={{ color: "black" }}>
						<AiOutlineMenu
							onClick={toggleDrawer("left", true)}
							className="text-2xl lg:hidden hover:cursor-pointer"
						/>
					</IconButton>
				</div>
			</>
			)}
			<div className="flex items-center w-full">
				<div className="flex flex-col items-center border-y border-l rounded-l-lg w-full py-8">
					<h2 className="font-bold text-2sm mb-2">Send us an email</h2>
					<p className="text-2xs">
						Contact us via our email{" "}
						<a href="mailto:info@softtribe.com" className="text-primary-blue">
							info@softtribe.com
						</a>
					</p>
					<a
						href="mailto:info@softtribe.com"
						className={
							"bg-primary-mango py-4 rounded-lg text-white hover:opacity-95 capitalize px-16 text-2xs mt-5"
						}
					>
						Send email
					</a>
				</div>
				<div className="flex flex-col items-center border rounded-r-lg w-full py-8">
					<h2 className="font-bold text-2sm mb-2">Give us a call</h2>
					<p className="text-2xs">
						Call us on{" "}
						<a href="tel:0550206353" className="text-primary-blue">
							0550206353
						</a>
					</p>
					<a
						href="tel:0550206353"
						className={
							"bg-primary-mango py-4 rounded-lg text-white hover:opacity-95 capitalize px-16 text-2xs mt-5"
						}
					>
						Call us
					</a>
				</div>
			</div>

			{/* <APITestingComponent /> */}
		</LAYOUT>
	);
};

export default Support;
