import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import API from './utils/api';
import {
    GrievanceReportDto, GrievanceReportPaginator,
    CreateGrievanceReportDto, QueryGrievanceReportDto,
    UpdateGrievanceReportDto, SearchGrievanceReportDto, 
} from '../models/grievance-report.dto';
import ENDPOINTS from './utils/endpoints';

export function useGetGrievanceReport(options?: QueryGrievanceReportDto) {
    const url = ENDPOINTS.HR.GRIEVANCE_REPORT.GET;
    const request = (options: QueryGrievanceReportDto) => API.HrAPI.getGrievanceReport(options);

    const { data, isLoading, error, refetch } = useQuery<GrievanceReportPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption},
    );

    return { data, isLoading, error, refetch };
}

export function useGetGrievanceReportBySearch() {

    return useMutation(
        (options?: SearchGrievanceReportDto) => API.HrAPI.getGrievanceReportBySearch(options),
        {
            onSuccess: (response) => {
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetGrievanceReportById(id: number){
    const url = ENDPOINTS.HR.GRIEVANCE_REPORT.DETAIL;
    const request = API.HrAPI.getGrievanceReportById(id);

    const { data, isLoading, error } = useQuery<{ data: GrievanceReportDto }, Error>(
        [url, id],
        (() => request),
        { enabled: !!(id) },
    );
    
    return { data, isLoading, error };
}


export function useCreateGrievanceReport() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload }: { payload: CreateGrievanceReportDto }) => API.HrAPI.createGrievanceReport(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.HR.GRIEVANCE_REPORT.GET);
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useUpdateGrievanceReport() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id, payload }: { id: number, payload: UpdateGrievanceReportDto }) => API.HrAPI.updateGrievanceReport(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.HR.GRIEVANCE_REPORT.GET);
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useDeleteGrievanceReport() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id }: { id: number }) => API.HrAPI.deleteGrievanceReport(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
                queryClient.invalidateQueries(ENDPOINTS.HR.GRIEVANCE_REPORT.GET);
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}