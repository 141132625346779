import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    EmployeeUnionDto, EmployeeUnionPaginator,
    CreateEmployeeUnionDto, QueryEmployeeUnionDto,
    UpdateEmployeeUnionDto
} from "../models/employee-union.dto";
import ENDPOINTS from "./utils/endpoints";
import {QueryOptionsDto} from "../models/generic.dto";

export function useGetEmployeeUnion(options?: QueryEmployeeUnionDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.UNION.GET;
    const request = (options: QueryEmployeeUnionDto) => API.ProcessingCompanyAPI.getEmployeeUnion(options);

    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<EmployeeUnionPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return API.Handlers.paginationHandler(data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage)
}

export function useGetEmployeeUnionById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.UNION.DETAIL;
    const request = API.ProcessingCompanyAPI.getEmployeeUnionById(id);

    const {data, isLoading, error} = useQuery<{ data:EmployeeUnionDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateEmployeeUnion() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateEmployeeUnionDto}) => API.ProcessingCompanyAPI.createEmployeeUnion(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_UNION.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateEmployeeUnion() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateEmployeeUnionDto, id: number}) => API.ProcessingCompanyAPI.updateEmployeeUnion(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_UNION.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useDeleteEmployeeUnion() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteEmployeeUnion(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_UNION.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}