import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {LoginDto, RotatePasswordDto} from "../models/auth.dto";
import {
    ChangePasswordDto,
    InitiatePasswordResetDto, 
    ResendUserConfirmationDto, 
    SetPasswordDto
} from "../models/user.dto";
import useUserStore from "../state-management/useUserStore";
import { useNavigate } from "react-router-dom";
import { useOrganizationTreeStore } from "../state-management/useOrganizationTreeStore";
import { PATHS } from "../routes/routes.paths"
import useEditFormStore from "../state-management/useEditFormStore";
import useCompanyStore from "../state-management/useCompanyStore";
import { setAccessToken } from "./utils/app.data";
import ENDPOINTS from "./utils/endpoints";
import { PasswordPolicyPublicDto } from "../models/password-policy.dto";

export function useSignIn() {

    return useMutation(
        ({payload}: {payload: LoginDto}) => API.UserAPI.signIn(payload),
        {
            onSuccess: () => {
            },
            onError: (error: any) => {
                if (error?.response?.data?.error === "ORGANIZATION_NOT_ACTIVE") {
                    toast.error("Your account has been suspended! Contact the Akatua team for more details.");
                } else {
                    API.Handlers.onErrorHandler(error, null, null)
                }
            },
        }
    )
}

export function useRefreshToken() {
    const { updateUserAuthTokens } = useUserStore();

    return useMutation(
        ({token}: {token: string}) => API.UserAPI.refreshToken(token),
        {
            onSuccess: (data: any) => {
                if (data) {
                    updateUserAuthTokens(data?.data);
                }
            },
            // onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useConfirmTokenAndCreatePassword() {

    return useMutation(
        ({payload}: {payload: SetPasswordDto}) => API.UserAPI.confirmToken(payload),
        {
            onSuccess: () => {
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useForgotPassword() {

    return useMutation(
        ({payload}: {payload: InitiatePasswordResetDto}) => API.UserAPI.forgotPassword(payload),
        {
            onSuccess: () => {
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useResetPassword() {

    return useMutation(
        ({payload}: {payload: SetPasswordDto}) => API.UserAPI.resetPassword(payload),
        {
            onSuccess: () => {
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useResendConfirmationPassword() {

    return useMutation(
        ({payload}: {payload: ResendUserConfirmationDto}) => API.UserAPI.resendConfirmation(payload),
        {
            onSuccess: () => {
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useChangePassword() {

    return useMutation(
        ({payload}: {payload: ChangePasswordDto}) => API.UserAPI.changePassword(payload),
        {
            onSuccess: () => {
                toast.success('Password updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useSignOut() {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { userType, clearUser } = useUserStore();
    const { clearOrgTreeStore } = useOrganizationTreeStore();
    const { clearFormData } = useEditFormStore();
	const { currentCompany, clearCompanyStore } = useCompanyStore();

	return (location?: any) => {
		clearUser();
		clearOrgTreeStore();
		clearCompanyStore();
		clearFormData();
        setAccessToken("");
		queryClient.clear();
        sessionStorage.removeItem("showSidebar");

        if (userType === "ORGANIZATION") {
            localStorage.setItem(
                "recentCompanyId", 
                currentCompany?.id as any
            );
        }

        const path = location?.pathname + location?.search;

        if (path) {
            navigate(
                PATHS.SIGN_IN + 
                `?prev_page=${encodeURIComponent(path)}`
            );
        } else {
            navigate(PATHS.SIGN_IN);
        }
	}
}

export function useGetAuthPasswordPolicyById(id: string) {
    const url = ENDPOINTS.USER.AUTH.GET_PWD_POLICY
    const request = API.UserAPI.getAuthPasswordPolicyById(id);

    const {data, isLoading, error} = useQuery<{ data: PasswordPolicyPublicDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id)
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}

export function useGetAuthDefaultPasswordPolicy() {
    const url = ENDPOINTS.USER.AUTH.GET_DEFAULT_PWD_POLICY
    const request = API.UserAPI.getAuthDefaultPasswordPolicy();

    const {data, isLoading, error} = useQuery<{ data: PasswordPolicyPublicDto }, Error>(
        [url],
        (() => request),
    );

    return {
        data: data,
        isLoading,
        error,
    };
}

export function useRotatePassword() {

    return useMutation(
        ({payload}: {payload: RotatePasswordDto}) => API.UserAPI.rotatePassword(payload),
        {
            onSuccess: () => {
                toast.success('New password created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}