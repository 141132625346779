import { GridColDef } from "@mui/x-data-grid";
import { Avatar } from "@mui/material";
import { 
	createdAtmodifiedAtActions, 
	enumToStringConverter, 
	formatDate 
} from "../../../../../helpers/appHelpers";

export const Columns: GridColDef[] = [
	{
		field: "name",
		headerName: "Name",
		width: 250,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-4">
					<Avatar
						src={params.row?.imageUrl}
						alt="Dp"
						className="w-10 h-10 rounded-full"
					/>
					<p className="truncate">{(params.row?.firstName || "") + " " + (params.row?.lastName || "")}</p>
				</div>
			);
		},
	},
	{
		field: "emailAddress",
		headerName: "Email Address",
		width: 250,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "status",
		headerName: "Status",
		width: 180,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div 
					className="rounded-lg px-4 py-2 min-w-[80px] text-center relative font-semibold overflow-hidden"
					style={{ color: getUserStatusColor(params.value) }}
				>
					<div 
						className="absolute inset-0" 
						style={{ 
							background: getUserStatusColor(params.value), 
							opacity: "15%"
						}}
					/>
					<p>{enumToStringConverter(params.value)}</p>
				</div>
			);
		},
	},
	{
		field: "organizationRole",
		headerName: "Organization Role",
		width: 250,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value.name || "",
	},
	
	...createdAtmodifiedAtActions
];

const getUserStatusColor = (status: string) => {
	switch(status) {
		case "ACTIVE":
			return "#4CAF50"
		case "INACTIVE":
			return "#F44336"
		default: 
			return "#808080"
	}
};
