import { useState } from "react";
import {AiOutlineMenu, AiOutlineThunderbolt} from "react-icons/ai";
import {FiSearch} from "react-icons/fi";
import {HiBell} from "react-icons/hi";
import {BsCaretDownFill} from "react-icons/bs";
import {Avatar, Popover} from "@mui/material";
import {VscChevronRight} from "react-icons/vsc";
import {IoSettingsOutline} from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import {styled} from "@mui/material/styles";
import AccountSettingsModalForm from "../../pages/shared/account_settings";
import GlobalSearch from './resources/global_search/GlobalSearch';
import { PATHS } from "../../routes/routes.paths";
import { Link } from "react-router-dom";
import { PayrollCompanyDto } from "../../models/company-account.dto";
import ChangePasswordModal from "./resources/ChangePasswordModal";
import useUserStore from "../../state-management/useUserStore";
import { useSignOut } from "../../services/auth.service";
import { useQueryClient } from "react-query";
import useNotificationsStore from "../../state-management/useNotificationsStore";
import { RxUpdate } from "react-icons/rx";
import { MdOutlineHistory } from "react-icons/md";
import StatementsRequestView from "./resources/statement_requests/StatementsRequestView";
import { HiOutlineCreditCard } from "react-icons/hi";
import { RiLogoutCircleLine } from "react-icons/ri";
import { useViewPort } from "../../helpers/useViewPort";
import { useGetOrganizationAccount } from "../../services/organization-account.service";
import { OrganizationAccountOrderBy } from "../../models/organization-account.dto";
import { moneyFormat } from "../../helpers/appHelpers";

const StyledPopover = styled(Popover)`
	& .MuiPaper-root {
		border-radius: 16px;
		min-width: 274px;
	}
`;

interface IHeaderProps {
	onMenuClick: () => void;
	companies?: PayrollCompanyDto[];
}

const Header = ({ onMenuClick, companies }: IHeaderProps) => {
	const pathname = window.location.pathname;
	const signOutUser = useSignOut();
	const queryClient = useQueryClient();
	const viewPortWidth = useViewPort();
	const { currentUser, userType } = useUserStore();
	const [openStatementRequestModal, setOpenStatementRequestModal] = useState(false);
	const [openUpdatePassword, setOpenUpdatePassword] = useState(false);
	const [openAccountSettingsModalForm, setOpenAccountSettingsModalForm] = useState(false);
	const [openSearch, setOpenSearch] = useState(false);
	const [subscriptionTier] = useState(
		currentUser.user.organization?.subsciptionTier?.name
	);
	const [requireSubscription] = useState(
		currentUser.user.organization?.billingType?.requireSubscription
	);
	const [hrViewEl, setHrViewEl] = useState<any>(null);
	const [anchorElProfile, setAnchorElProfile] = useState<any>(null);
	const [anchorElNotifs, setAnchorElNotifs] = useState<any>(null);
	
	const { 
		notifications,
		unread: unreadNotifications,
		markAsRead,
		markAllAsRead,
	} = useNotificationsStore();

	// const organizationAccount = useGetOrganizationAccount({
	// 	organizationId: currentUser.user.organizationId,
	// 	orderBy: OrganizationAccountOrderBy.CREATED_AT_DESC,
	// });

	const handleOpenSettingsModal = () => {
		// If company is false, user is an Admin
		if (!companies) {
			setOpenAccountSettingsModalForm(true);
			setAnchorElProfile(null);
			return
		}

		// At this point, user is a team member or the owner of an Organization
		if (companies.length) {
			setOpenAccountSettingsModalForm(true);
			setAnchorElProfile(null);
		} else {
			setOpenAccountSettingsModalForm(false);
		}
	};

	return (
		<div className="h-[70px] w-full flex justify-between items-center bg-secondary-gray py-4 lg:px-10 px-4">
			<div className="h-full w-full max-w-[2000px] mx-auto flex items-center justify-between">
				{pathname === PATHS.CREATE_PAYROLL_COMPANY ||
				pathname === PATHS.SUBSCRIPTION_PAYMENT ? (
					<div></div>
				):(
					<div className="flex max-lg:hidden items-center space-x-6">
						<div
							className="bg-white rounded-full flex justify-center cursor-pointer items-center h-8 w-8 shadow text-sm"
							onClick={
								pathname !== PATHS.COMPANY_ACCOUNT_SETUP
									? onMenuClick
									: () => {}
							}
						>
							<AiOutlineMenu />
						</div>

						{(requireSubscription === true && userType === "ORGANIZATION") && (
							<div className="bg-[#6978F0] text-white text-[10px] px-2 py-1 rounded">
								<p>{subscriptionTier}</p>
							</div>
						)}

						{userType === "HR" && (
							<div className="text-white text-xs">
								<div 
									onClick={(e) => setHrViewEl(e.currentTarget)}
									className="bg-[#6978F0] px-2.5 py-2 rounded cursor-pointer flex items-center gap-1 hover:shadow-sm"
								>
									<p>{pathname.includes("hr") ? "Human Resource" : "Employee"}</p>
									<IoIosArrowDown className={`text-sm ${hrViewEl && "rotate-[180deg]"}`} />
								</div>
								<Popover
									open={Boolean(hrViewEl)}
									anchorEl={hrViewEl}
									onClose={() => setHrViewEl(null)}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "left",
									}}
									transformOrigin={{
										vertical: "top",
										horizontal: "left",
									}}
									PaperProps={{
										style: {
											width: "fit",
											borderRadius: 4,
											fontSize: "12px",
											background: "white",
											color: "black",
											marginTop: "8px",
											display: "flex",
											flexDirection: "column",
										},
									}}
								>
									<Link 
										to={PATHS.HR_EMPLOYEE_MANAGEMENT}
										className={`px-3 py-1.5 ${pathname.includes("hr") ? "bg-[#D8D8D8]" : "hover:bg-[#B8B8B8]"}`}
										onClick={() => queryClient.clear()}
									>
										Human Resource
									</Link>
									<Link 
										to={PATHS.SELF_SERVICE_DASHBOARD}
										className={`px-3 py-1.5 ${pathname.includes("selfservice") ? "bg-[#D8D8D8]" : "hover:bg-[#B8B8B8]"}`}
										onClick={() => queryClient.clear()}
									>
										Employee
									</Link>
								</Popover>
							</div>
						)}
					</div>
				)}
				<div className={`flex lg:space-x-10 space-x-5 items-center ${viewPortWidth < 1024 && "w-full"}`}>
					<div className={`flex justify-between lg:justify-normal ${viewPortWidth < 1024 && "w-full"} space-x-3`}>
						<img
							src={"/assets/logos/akatua-logo.svg"}
							alt="Akatua Logo"
							className="lg:hidden w-[110px]"
						/>
						<div
							className="max-lg:hidden bg-white cursor-pointer rounded-full flex justify-center items-center h-8 w-8 text-secondary-darkgray text-sm"
							onClick={() => setOpenSearch(true)}
						>
							<FiSearch />
						</div>
						<button
							onClick={(e) => setAnchorElNotifs(e.currentTarget)}
							className="cursor-pointer text-primary-blue bg-white rounded-full flex justify-center items-center h-8 w-8 relative text-base"
						>
							<HiBell />
							{unreadNotifications !== 0 && (
								<div
									className="px-1 py-[2px] h-fit bg-red-500 rounded-full absolute -top-1
									-right-1 text-[10px] leading-3 text-[#585B5B] font-semibold "
								>
									{unreadNotifications}
								</div>
							)}
						</button>
						<StyledPopover
							id={anchorElNotifs ? "simple-popover" : undefined}
							open={Boolean(anchorElNotifs)}
							anchorEl={anchorElNotifs}
							onClose={() => setAnchorElNotifs(null)}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
						>
							<div className="w-[342px] px-6 py-4">
								<div className="flex items-center justify-between border-b pb-3 border-gray-100">
									<h2 className="font-bold text-2xs">Notifications</h2>
									<p
										className="text-[9px] text-primary-blue font-bold cursor-pointer"
										onClick={() => markAllAsRead()}
									>
										Mark all as read
									</p>
								</div>

								{notifications.map(
									(notification) => (
										<div
											key={notification.id}
											className={`flex items-center mt-4 space-x-2 border-b pb-3 border-gray-100
											cursor-pointer ${notification.read && "opacity-50"}`}
											onClick={() => markAsRead(notification.id)}
										>
											<div
												className={`${
													notification.read ? "bg-gray-300" : "bg-red-500"
												} w-1 h-1 rounded-full`}
											></div>
											<div>
												<p className="text-2xs">{notification.message}</p>
												<p className="text-[10px] text-gray-300">
													{notification.date}
												</p>
											</div>
										</div>
									)
								)}
							</div>
						</StyledPopover>
					</div>
					<button
						onClick={(e) => setAnchorElProfile(e.currentTarget)}
						className="flex items-center bg-white rounded-3xl px-1 py-[1px] space-x-2"
					>
						<Avatar
							src={currentUser.user.imageUrl}
							alt="avatar"
							sx={{ width: 35, height: 35 }}
						/>
						<BsCaretDownFill className="text-[10px]" />
					</button>
					<StyledPopover
						id={anchorElProfile ? "simple-popover" : undefined}
						open={Boolean(anchorElProfile)}
						anchorEl={anchorElProfile}
						onClose={() => setAnchorElProfile(null)}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "left",
						}}
						sx={{ width: "275px" }}
					>
						<div className="bg-white rounded-lg flex px-4 py-5 items-center justify-start gap-3 border-b text-black mb-2">
							<Avatar
								alt={currentUser.user.firstName}
								src={currentUser.user.imageUrl}
								sx={{ width: 40, height: 40 }}
							/>
							<div className="text-charcoal">
								<h3 className="text-2sm font-bold">
									{" "}
									{currentUser.user.firstName + " " + currentUser.user.lastName}
								</h3>
								<p className="text-2xs mt-[2px] truncate w-[191px] font-normal">
									{currentUser.user.emailAddress}
								</p>
							</div>
						</div>
						{(userType === "ORGANIZATION" || userType === "ADMIN") && (
							<div 
								onClick={handleOpenSettingsModal}
								className="flex items-center justify-between text-charcoal px-4 py-3 hover:bg-charcoal hover:bg-opacity-10 cursor-pointer"
							>
								<div className="flex items-center space-x-5">
									<IoSettingsOutline className="text-2xl text-[#333333]" />
									<p className="text-2sm">Account Settings</p>
								</div>
								<VscChevronRight className="text-xl" />
							</div>
						)}
						{/* {(userType === "ORGANIZATION" && organizationAccount) && (<> */}
						{(userType === "ORGANIZATION") && (<>
							{/* <div className="flex items-center justify-between text-charcoal px-4 py-3 cursor-default">
								<div className="flex items-center space-x-5">
									<HiOutlineCreditCard className="text-2xl text-[#333333]" />
									<p className="text-2sm">Balance</p>
								</div>
								<div className="max-w-[120px] px-2 py-0.5 truncate rounded-[10px] bg-primary-blue bg-opacity-10 text-2xs text-primary-blue font-bold">
									{organizationAccount.data?.data[0] 
										? `${organizationAccount.data?.data[0].currency?.code} 
											${moneyFormat(organizationAccount.data?.data[0].balance)}`
										: "Loading..."
									}
								</div>
							</div> */}
							{currentUser.user.organization?.billingType?.name.toLowerCase() !== "contract" && (
								<div 
									onClick={() => {
										setOpenStatementRequestModal(true);
										setAnchorElProfile(null);
									}}
									className="flex items-center justify-between text-charcoal px-4 py-3 hover:bg-charcoal hover:bg-opacity-10 cursor-pointer"
								>
									<div className="flex items-center space-x-5">
										<MdOutlineHistory className="text-2xl text-[#333333]" />
										<p className="text-2sm">Account Statements</p>
									</div>
									<VscChevronRight className="text-xl" />
								</div>
							)}
						</>)}
						{(userType === "HR" || userType === "EMPLOYEE") && (
							<div 
								onClick={() => {
									setOpenUpdatePassword(true);
									setAnchorElProfile(null);
								}}
								className="flex items-center justify-between text-charcoal px-4 py-3 hover:bg-charcoal hover:bg-opacity-10 cursor-pointer"
							>
								<div className="flex items-center space-x-5">
									<RxUpdate className="text-2xl text-[#333333]" />
									<p className="text-2sm">Update Password</p>
								</div>
								<VscChevronRight className="text-xl" />
							</div>
						)}
						{/* {userType === "ORGANIZATION" && (
							<div 
								onClick={() => {}}
								className="flex items-center justify-between text-charcoal px-4 py-3 hover:bg-charcoal hover:bg-opacity-10 cursor-pointer"
							>
								<div className="flex items-center space-x-5 text-red-500">
									<AiOutlineThunderbolt className="text-2xl" />
									<p className="text-2sm">Upgrade Package</p>
								</div>
								<VscChevronRight className="text-xl" />
							</div>
						)} */}
						<div className="h-[1px] border-t my-2" />
						<div
							className="flex items-center space-x-5 text-charcoal mt-2 px-4 py-3 mb-2 hover:bg-charcoal hover:bg-opacity-10 cursor-pointer"
							onClick={signOutUser}
						>
							<RiLogoutCircleLine className="text-2xl" />
							<p className="text-2sm">Logout</p>
						</div>
					</StyledPopover>

					{openAccountSettingsModalForm && (
						<AccountSettingsModalForm
							open={openAccountSettingsModalForm}
							setOpen={setOpenAccountSettingsModalForm}
							title={"Account Settings"}
						/>
					)}
					{openStatementRequestModal && (
						<StatementsRequestView
							openModal={openStatementRequestModal}
							setOpenModal={setOpenStatementRequestModal}
						/>
					)}
					{openUpdatePassword && (
						<ChangePasswordModal
							open={openUpdatePassword}
							setOpen={setOpenUpdatePassword}
							title={"Change Password"}
						/>
					)}
				</div>
			</div>

			{openSearch && (
				<GlobalSearch setOpenedState={setOpenSearch} />
			)}
		</div>
	);
};

export default Header;

const defaultNotifications = [
    {
        id: 1,
        message: "This is an unread notification message",
        date: "Today",
        read: false
    },
    {
        id: 2,
        message: "This is an unread notification message",
        date: "Today",
        read: true
    },
    {
        id: 3,
        message: "This is an unread notification message",
        date: "Today",
        read: false
    },
    {
        id: 4,
        message: "This is an unread notification message",
        date: "Today",
        read: false
    },
];