import { useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import Label from "../../../components/form_fields/Label";
import { useForm, SubmitHandler } from "react-hook-form";
import OrangeButton from "../../../components/buttons/OrangeButton";
import ModalComponent from "../../../components/modals/ModalFormComponent";
import { useRotatePassword } from "../../../services/auth.service";

type RotatePasswordModalProps = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IFormInputs {
	username: string;
	currentPassword: string;
	newPassword: string;
	confirmPassword: string;
}

const RotatePasswordModal = ({
	open,
	setOpen,
}: RotatePasswordModalProps) => {
    const [isShown, setIsShown] = useState(false);
	const [uploading, setUploading] = useState(false);
	const { mutate: rotatePassword } = useRotatePassword();

	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm<IFormInputs>();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		rotatePassword(
			{
				payload: {
					username: data.username,
					password: data.currentPassword,
					newPassword: data.newPassword,
				}
			},
			{
				onSuccess: () => handleClose(),
				onSettled: () => setUploading(false),
			}
		)
	};

	const handleClose = () => setOpen(false);

	return (
		<ModalComponent
			width={window.innerWidth < 1024 ? "90%" : 600}
			open={open}
			title={"Create New Password"}
			handleClose={handleClose}
		>
			<div className="space-y-4 mt-4">
				<div className="w-full">
					<Label title="Username" for="username" />
					<input
						type="text"
						{...register("username", {
							required: "Enter your username",
						})}
						id="username"
						placeholder="Enter username"
						className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm ${
							errors.username
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.username && (
						<p className="text-red-500 text-sm mt-1">
							{errors.username.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Current Password" for="currentPassword" />
					<div className="relative">
						<input
							type={!isShown ? "password" : "text"}
							id={"currentPassword"}
							placeholder={"Enter current password"}
							className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm  ${
								errors.currentPassword
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("currentPassword", {
								required: "Enter your current password",
							})}
							autoComplete="new-password"
						/>
						<div
							className={`absolute top-[26px] right-6 block `}
							onClick={() => setIsShown(prev => !prev)}
						>
							{!isShown ? (
								<BsEyeSlash className="text-gray-400 text-lg"/>
							) : (
								<BsEye className="text-gray-400 text-lg"/>
							)}
						</div>
					</div>
					{errors.currentPassword && (
						<p className="text-red-500 text-sm mt-1">
							{errors.currentPassword.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="New Password" for="newPassword"/>
					<div className="relative">
						<input
							type={!isShown ? "password" : "text"}
							id={"newPassword"}
							placeholder={"Enter new password"}
							className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm  ${
								errors.newPassword
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("newPassword", {
								required: "Enter your new password",
								minLength: {
									value: 8,
									message: "Password should be at least 8 characters",
								},
							})}
							autoComplete="new-password"
						/>
						<div
							className={`absolute top-[26px] right-6 block `}
							onClick={() => setIsShown(prev => !prev)}
						>
							{!isShown ? (
								<BsEyeSlash className="text-gray-400 text-lg"/>
							) : (
								<BsEye className="text-gray-400 text-lg"/>
							)}
						</div>
					</div>
					{errors.newPassword && (
						<p className="text-red-500 text-sm mt-1">
							{errors.newPassword.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Confirm Password" for="confirmPassword" />
					<div className="relative">
						<input
							type={!isShown ? "password" : "text"}
							id={"confirmPassword"}
							placeholder={"Confirm new password"}
							className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm  ${
								errors.confirmPassword
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("confirmPassword", {
								required: "Confirm your new password",
								validate: {
									matchesPreviousPassword: (value) => {
										const { newPassword } = getValues();
										return newPassword === value || "Passwords should match!";
									},
								},
							})}
							autoComplete="new-password"
						/>
						<div
							className={`absolute top-[26px] right-6 block `}
							onClick={() => setIsShown(prev => !prev)}
						>
							{!isShown ? (
								<BsEyeSlash className="text-gray-400 text-lg"/>
							) : (
								<BsEye className="text-gray-400 text-lg"/>
							)}
						</div>
					</div>
					{errors.confirmPassword && (
						<p className="text-red-500 text-sm mt-1">
							{errors.confirmPassword.message}
						</p>
					)}
				</div>
			</div>
			<div className="flex justify-end pt-10">
				<OrangeButton
					title={uploading ? "Submitting..." : "Submit"}
					className={"px-12"}
					onClick={handleSubmit(onSubmit)}
					disabled={uploading}
				/>
			</div>
		</ModalComponent>
	);
};

export default RotatePasswordModal;
