import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from 'react-query';
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    LeaveTypeDto, LeaveTypePaginator,
    CreateLeaveTypeDto, QueryLeaveTypeDto,
    UpdateLeaveTypeDto, SearchLeaveTypeDto, QueryApplicableLeaveTypeDto
} from '../models/leave-type.dto';
import ENDPOINTS from './utils/endpoints';

export function useGetLeaveType(options?: QueryLeaveTypeDto) {
    const url = ENDPOINTS.HR.LEAVE_TYPE.GET;
    const request = (options: QueryLeaveTypeDto) => API.HrAPI.getLeaveType(options);

    const { data, isLoading, error, refetch } = useQuery<LeaveTypePaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption }
    );

    return { data, isLoading, error, refetch };
}

export function useGetLeaveTypeSearch() {

    return useMutation(
        (options?: SearchLeaveTypeDto) => API.HrAPI.getLeaveTypeSearch(options),
        {
            onSuccess: (response) => {
                
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetApplicableLeaveType(options?: QueryApplicableLeaveTypeDto) {
    const url = ENDPOINTS.HR.LEAVE_TYPE.GET_APPLICABLE;
    const request = (options: QueryApplicableLeaveTypeDto) => API.HrAPI.getApplicableLeaveType(options);

    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<LeaveTypePaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption }
    );

    return API.Handlers.paginationHandler(
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    );
}

export function useGetLeaveTypeById(id: number) {
    const url = ENDPOINTS.HR.LEAVE_TYPE.DETAIL;
    const request = API.HrAPI.getLeaveTypeById(id);

    const { data, isLoading, error } = useQuery<{ data: LeaveTypeDto }, Error>(
        [url, id],
        (() => request),
        { enabled: !!(id) },
    );

    return { data, isLoading, error };
}

export function useCreateLeaveType() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload }: { payload: CreateLeaveTypeDto }) => API.HrAPI.createLeaveType(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfullly');
                queryClient.invalidateQueries(ENDPOINTS.HR.LEAVE_TYPE.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    );
}

export function useUpdateLeaveType() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload, id }: { payload: UpdateLeaveTypeDto, id: number }) => API.HrAPI.updateLeaveType(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.HR.LEAVE_TYPE.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    );
}

export function useDeleteLeaveType() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id }: { id: number}) => API.HrAPI.deleteLeaveType(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
                queryClient.invalidateQueries(ENDPOINTS.HR.LEAVE_TYPE.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    );
}