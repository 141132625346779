import {APP_CONFIG} from "../helpers/appHelpers";
import {PaginatorInfo} from "./generic.dto";

type Currency = {
    id: number;
    code: string;
    name: string;
    symbol: string;
    active?: boolean;
    isDefault: boolean;
    createdAt: string;
    modifiedAt?: string;
}

export class CreateCurrencyDto {
    code!: string;
    name!: string;
    symbol!: string;
    active?: boolean;
    isDefault?: boolean;
}

export class UpdateCurrencyDto {
    code?: string;
    name?: string;
    symbol?: string;
    active?: boolean;
    isDefault?: boolean;
}

export interface CurrencyDto extends Currency { }

export enum CurrencyOrderBy {
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
    NAME_ASC = 'name:asc',
    NAME_DESC = 'name:desc',
}

export class QueryCurrencyDto {
    code?: string;
    active?: boolean;
    isDefault?: boolean;
    page?:number = 1;
    limit?: number = APP_CONFIG.PAGE_LIMIT;
    orderBy?: CurrencyOrderBy = CurrencyOrderBy.CREATED_AT_DESC;
}

export interface CurrencyPaginator extends PaginatorInfo<CurrencyDto> {}

export class SearchCurrencyDto {
    q?: string;
  
    page= 1;
    limit?: number;
    orderBy: CurrencyOrderBy = CurrencyOrderBy.CREATED_AT_DESC;
  }

  export type CurrencySummary = Pick<Currency, 'id' | 'code' | 'symbol' | 'name'>;