import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, enumToStringConverter, formatDateAndTime } from "../../../helpers/appHelpers";
import { STATUS } from "../../../models/organization.dto";

export const Columns: GridColDef[] = [
	{
		field: "name",
		headerName: "Name",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "status",
		headerName: "Status",
		width: 220,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div 
					className="rounded-lg px-4 py-2 min-w-[80px] text-center relative font-semibold overflow-hidden"
					style={{ color: getOrganizationStatusColor(params.value) }}
				>
					<div 
						className="absolute inset-0" 
						style={{ 
							background: getOrganizationStatusColor(params.value), 
							opacity: "15%"
						}}
					/>
					<p> 
						{params.value === "SUSPENDED_PENDING_PAYMENT" 
							? "Subscription Expired"
							: enumToStringConverter(params.value)
						}
					</p>
				</div>
			);
		},
	},
	{
		field: "contactEmail",
		headerName: "Contact Email",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "contactMsisdn",
		headerName: "Telephone",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "address",
		headerName: "Address",
		width: 400,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "billingType",
		headerName: "Billing Type",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name,
	},
	{
		field: "subsciptionTier",
		headerName: "Subsciption Tier",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name,
	},
	{
		field: "country",
		headerName: "Country",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name,
	},
	{
		field: "currency",
		headerName: "Currency",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name + `(${params.value?.code})`,
	},
	{
		field: "maxUsers",
		headerName: "Max Users",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.row.config?.maxUsers,
	},
	{
		field: "maxCompanies",
		headerName: "Max Companies",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.row.config?.maxCompanies,
	},
	{
		field: "maxEmployees",
		headerName: "Max Employees",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.row.config?.maxEmployees,
	},
	{
		field: "statusLastModifiedAt",
		headerName: "Status Date Modified",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => formatDateAndTime(params),
	},
	
	...createdAtmodifiedAtActions
];

export const getOrganizationStatusColor = (status: STATUS) => {
	switch(status) {
		case "ACTIVE":
			return "#4CAF50"
		case "SUSPENDED":
			return "#F44336"
		case "INACTIVE":
			return "#FFC107"
		case "SUSPENDED_PENDING_PAYMENT":
			return "#808080"
		default: 
			return "#2196F3"
	}
};

export const OrganizationsColumnVisibilityModel: Record<string, boolean> = {
	name: true,
	status: true,
	contactEmail: true,
	contactMsisdn: false,
	address: false,
	billingType: true,
	subsciptionTier: false,
	country: false,
	currency: false,
	maxUsers: false,
	maxCompanies: false,
	maxEmployees: false,
	statusLastModifiedAt: true,
	createdAt: true,
	modifiedAt: true,
};