import { useEffect, useState  } from "react";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { getLeaveRequestStatusColor } from "../data";
import { enumToStringConverter } from "../../../../helpers/appHelpers";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import { useSearchParams } from "react-router-dom";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { useGetLeaveRequestById } from "../../../../services/leave-request.service";
import LeaveRequestActionModal from "./LeaveRequestActionModal";
import useUserStore from "../../../../state-management/useUserStore";
import { PATHS } from "../../../../routes/routes.paths";

type LeaveRequestDetailsModalProps = {
	title: string;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	supervisor: boolean;
	tableAPIRef: React.MutableRefObject<GridApiCommunity>;
}

const LeaveRequestDetailsModal = ({ 
	title, 
	open, 
	setOpen, 
	supervisor,
	tableAPIRef,
}: LeaveRequestDetailsModalProps) => {
	const pathname = window.location.pathname;
	const { currentUser, userType } = useUserStore();
	const companyProfile = (currentUser.user.companyProfiles || [])[0];
	const [searchParams] = useSearchParams();
    const requestId = searchParams.get("id");
	const { formDataValues, setFormData } = useEditFormStore();
	const [openRequestActionModal, setOpenRequestActionModal] = useState(false);

	const { data: leaveRequestData, isLoading } = useGetLeaveRequestById(
		requestId ? Number(requestId) : formDataValues?.id
	);

	useEffect(() => {
		if (!isLoading && leaveRequestData) {
			setFormData(leaveRequestData.data);
		}
	}, [isLoading])

	const handleClose = () => setOpen(false);

	return (<>
		<ModalComponent
			open={open}
			title={title}
			width={660}
			handleClose={handleClose}
			additionalStyles={{ opacity: openRequestActionModal ? 0 : 100 }}
		>
			<div className="text-charcoal">
				<section className="flex items-start justify-between mt-3">
                    <div>
                        <h2 className="text-se text-gray-500 text-2xs">Status</h2>
                        <div
                            className="relative rounded-lg px-3 py-2 inline-flex overflow-hidden"
                            style={{ color: getLeaveRequestStatusColor(formDataValues?.status) }}
                        >
                            <div 
                                className="absolute inset-0" 
                                style={{ 
                                    background: getLeaveRequestStatusColor(formDataValues?.status), 
                                    opacity: "15%"
                                }}
                            />
                            <p className="text-xs">{enumToStringConverter(formDataValues?.status)}</p>
                        </div>
                    </div>
                    <div>
                        <h2 className="text-se text-gray-500 text-2xs">Leave Type</h2>
                        <div
                            className="relative rounded-lg px-3 py-2 inline-flex overflow-hidden"
                            style={{ color: formDataValues?.leavePackage?.leaveType?.colorCode }}
                        >
                            <div 
                                className="absolute inset-0" 
                                style={{ 
                                    background: formDataValues?.leavePackage?.leaveType?.colorCode, 
                                    opacity: "15%"
                                }}
                            />
                            <p className="text-xs">{formDataValues?.leavePackage?.leaveType?.name}</p>
                        </div>
                    </div>
				</section>
				<section className="mt-6">
					<h2 className="text-se text-gray-500 text-2xs">Comment</h2>
					<p className="text-2sm">
						{formDataValues?.comment || ""}
					</p>
				</section>
				{supervisor && (
					<div className="flex items-start justify-between mt-6">
						<div>
							<h2 className="text-se text-gray-500 text-2xs">Employee</h2>
							<p className="text-2sm">
								{(formDataValues?.employee?.firstName || "") + " "}
								{formDataValues?.employee?.lastName || ""}
							</p>
						</div>
					</div>
				)}
				{(formDataValues?.approver && !supervisor) && (
					<div className="flex items-start justify-between mt-6">
						<div>
							<h2 className="text-se text-gray-500 text-2xs">Approver</h2>
							<p className="text-2sm">
								{(formDataValues?.approver?.firstName || "") + " "}
								{formDataValues?.approver?.lastName || ""}
							</p>
						</div>
					</div>
				)}
				<section className="flex items-start justify-between mt-6">
					<div>
						<h2 className="text-se text-gray-500 text-2xs">Date Submitted</h2>
						<p className="text-2sm">
							{new Date(formDataValues?.createdAt).toLocaleDateString("en-US", { 
								month: "short", day: "numeric", year: "numeric" 
							})}
						</p>
					</div>
					<div className="flex flex-col items-end">
						<h2 className="text-se text-gray-500 text-2xs">No. of Days</h2>
						<p className="text-2sm">
							{getDaysBetweenDates(formDataValues?.startDate, formDataValues?.returnDate)}
						</p>
					</div>
				</section>
                <section className="flex items-start justify-between mt-6 pb-5">
                    <div>
                        <h2 className="text-se text-gray-500 text-2xs">Start Date</h2>
                        <p className="text-2sm">
                            {new Date(formDataValues?.startDate).toLocaleDateString("en-US", { 
                                month: "short", day: "numeric", year: "numeric" 
                            })}
                        </p>
                    </div>
                    <div>
                        <h2 className="text-se text-gray-500 text-2xs">Return Date</h2>
                        <p className="text-2sm">
                            {new Date(formDataValues?.returnDate).toLocaleDateString("en-US", { 
                                month: "short", day: "numeric", year: "numeric" 
                            })}
                        </p>
                    </div>
                </section>

				{(
					(userType === "HR" && formDataValues?.status === "APPROVED" && !window.location.pathname.includes("selfservice")) || 
					(supervisor && formDataValues?.status === "PENDING")
				) && (<>
					{pathname === PATHS.HR_LEAVE_MANAGEMENT ? (<>
						{formDataValues?.employee?.id !== companyProfile?.employeeId && (
							<div className="w-full flex justify-end mt-8">
								<OrangeButton 
									title={
										formDataValues?.status === "PENDING" 
										? "Respond To Request" 
										: "Adjust Request"
									}
									className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
									onClick={() => setOpenRequestActionModal(true)}
								/>
							</div>
						)}
					</>) : (<>
						<div className="w-full flex justify-end mt-8">
							<OrangeButton 
								title={
									formDataValues?.status === "PENDING" 
									? "Respond To Request" 
									: "Adjust Request"
								}
								className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
								onClick={() => setOpenRequestActionModal(true)}
							/>
						</div>
					</>)}
				</>)}
			</div>
		</ModalComponent>

		{openRequestActionModal && (
			<LeaveRequestActionModal 
				open={openRequestActionModal}
				setOpen={setOpenRequestActionModal}
				response={formDataValues?.status === "PENDING"}
				tableAPIRef={tableAPIRef}
			/>
		)}
	</>);
};

export default LeaveRequestDetailsModal;

function getDaysBetweenDates(startDate, endDate) {
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    
    const diffInMilliseconds = endDate.getTime() - startDate.getTime();

    const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);

    return diffInDays;
}