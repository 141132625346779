import { PayrollCompanyDto } from "./company-account.dto";
import { EmployeeBand } from "./employee-band.dto";
import { EmployeeDto } from "./employee.dto";
import {PaginatorInfo} from "./generic.dto";
import { PayPeriodDto } from "./pay-period.dto";

export const APPLY_ON = {
  BASIC: 'BASIC',
  GROSS: 'GROSS',
  FIXED: 'FIXED'
};

export type APPLY_ON = keyof typeof APPLY_ON

/**
 * Model ExplicitDeduction
 *
 */
export type ExplicitDeduction = {
  id: number
  companyId: number
  code: string
  name: string
  description: string
  applyOn: APPLY_ON
  fixedComponent: number
  rateComponent: number
  startPayPeriodId: number
  endPayPeriodId?: number
  createdAt: string
  modifiedAt: string | null
}

export class CreateExplicitDeductionDto {
  companyId!: number
  code!: string
  name!: string
  description!: string
  applyOn!: APPLY_ON
  figure!: number
  startPayPeriodId!: number
  endPayPeriodId?: number
  employeeIds?: number[]
  employeeBandIds?: number[]

}

export class UpdateExplicitDeductionDto {
  code?: string
  name?: string
  description?: string
  applyOn?: APPLY_ON
  figure?: number
  startPayPeriodId?: number
  endPayPeriodId?: number
}


export interface ExplicitDeductionDto extends ExplicitDeduction {
  employeeExplicitDeductions?: EmployeeExplicitDeductionDto[];
  employeeBandExplicitDeductions?: EmployeeBandExplicitDeductionDto[];
  company?: PayrollCompanyDto;
  startPayPeriod?: PayPeriodDto;
  endPayPeriod?: PayPeriodDto;
}

export interface EmployeeExplicitDeductionDto extends EmployeeExplicitDeduction {
  employee?: EmployeeDto;
}

export interface EmployeeBandExplicitDeductionDto extends EmployeeBandExplicitDeduction {
  employeeBand?: EmployeeBand;
}

type EmployeeExplicitDeduction = {
  id: number;
  employeeId: number;
  explicitDeductionId: number;
  active: boolean;
  createdAt: string;
  modifiedAt: string | null;
}

type EmployeeBandExplicitDeduction = {
  id: number;
  employeeBandId: number;
  explicitDeductionId: number;
  active: boolean;
  createdAt: string;
  modifiedAt: string | null;
}

export interface ExplicitDeductionPaginator extends PaginatorInfo<ExplicitDeductionDto> {}

export class QueryExplicitDeductionDto {
  companyId?: number;
  applyOn?: APPLY_ON;
  page?: number = 1;
  limit?: number;
  orderBy?: ExplicitDeductionOrderBy = ExplicitDeductionOrderBy.CREATED_AT_ASC;
}


export class SearchExplicitDeductionDto {
  q?: string
  page?: number = 1;
  limit?: number;
  orderBy?: ExplicitDeductionOrderBy = ExplicitDeductionOrderBy.CREATED_AT_ASC;
}

export enum ExplicitDeductionOrderBy {
  CREATED_AT_ASC = 'createdAt:asc',
  CREATED_AT_DESC = 'createdAt:desc',
  MODIFIED_AT_ASC = 'modifiedAt:asc',
  MODIFIED_AT_DESC = 'modifiedAt:desc',
  CODE_ASC = 'code:asc',
  CODE_DESC = 'code:desc',
  NAME_ASC = 'name:asc',
  NAME_DESC = 'name:desc'
}

