import { twMerge } from 'tailwind-merge';

interface OrangeButtonProps {
	title: string;
	className: string;
	disabled?: boolean;
	onClick?: () => void;
	type?: 'button'| 'submit'
}

const OrangeButton = ({ title, disabled, className, onClick, type = 'button' }: OrangeButtonProps) => {
	return (
		<button 
			onClick={onClick} 
			disabled={disabled} 
			type={type} 
			className={twMerge(`w-fit h-fit bg-primary-mango py-4 px-8 rounded-lg text-white text-2xs font-medium
			capitalize ${disabled ? "opacity-50" : "hover:shadow-sm hover:opacity-95"}`, `${className}`)}
		>
			{title}
		</button>
	);
};

export default OrangeButton;
