import {useQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    ContractTypeDto, ContractTypePaginator,
    CreateContractTypeDto, QueryContractTypeDto,
    UpdateContractTypeDto
} from "../models/contract-type.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetContractType(options?: QueryContractTypeDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.CONTRACT_TYPE.GET;
    const request = (options: QueryContractTypeDto) => API.ProcessingCompanyAPI.getContractType(options);

	const { data, isLoading, error, refetch } = useQuery<ContractTypePaginator, Error>(
		[url, options],
		({ queryKey, pageParam }) =>
			request(Object.assign({}, queryKey[1], pageParam)),
		{...API.Handlers.pageParamOption}
	);

	return { data, isLoading, error, refetch };
}

export function useGetContractTypeById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.CONTRACT_TYPE.DETAIL
    const request = API.ProcessingCompanyAPI.getContractTypeById(id);

    const {data, isLoading, error} = useQuery<{ data:ContractTypeDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateContractType() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateContractTypeDto}) => API.ProcessingCompanyAPI.createContractType(payload),
        {
            onSuccess: (response) => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.CONTRACT_TYPE.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateContractType() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateContractTypeDto, id: number}) => API.ProcessingCompanyAPI.updateContractType(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.CONTRACT_TYPE.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useDeleteContractType() {

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deletContractTypeById(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}