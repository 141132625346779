import { useSearchParams } from "react-router-dom";
import { PaginatorInfo } from "../models/generic.dto";
import { useEffect, useState } from "react";
import { useGridApiRef } from "@mui/x-data-grid";

type ReturnedObject = {
    data: PaginatorInfo<any>;
    isLoading: boolean;
    error: Error | null;
    refetch: () => Promise<any>;
}

type ManageTable = {
    /** Hook to get table data `ie useGetBenefit` */
    fetchHook: (params: any) => any;

    /** Params for table data hook */
    options?: any;

    /** Reload dependencies */
    reloadDeps?: any[];
}

/**
 * Custom hook to manage tables with pagination using search params.
 *
 * @template TData - The type of data in the table.
 * @param {ManageTable} options - The options for managing the table.
 * @param {function} options.fetchHook - The hook to fetch table data.
 * @param {object} [options.options] - The additional options for the table data hook.
 * @param {Array} [options.reloadDeps] - The dependencies to trigger a reload of the table data.
 * @return {object} - The managed table data.
 * @property {GridApiRef} tableAPIRef - The reference to the table API.
 * @property {TData[]} tableData - The current table data.
 * @property {TData[]} fetchedTableData - The fetched table data for search operations.
 * @property {boolean} isLoading - Indicates if the table data is currently being loaded.
 * @property {number} pageCount - The total number of pages in the table.
 * @property {function} setTableData - Sets the current table data.
 * @property {function} setFetchedTableData - Sets the fetched table data for search operations.
 */
export function useManageTableWithSearchParams<TData>({ fetchHook, options, reloadDeps }: ManageTable) {
	const tableAPIRef = useGridApiRef();
	const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get("page");
    const newData = searchParams.get("newData");
    const reloadData = searchParams.get("reloadData");
    const searching = searchParams.get("searching");
	const [tableData, setTableData] = useState<TData[]>([]);
	const [fetchedTableData, setFetchedTableData] = useState<TData[]>([]); // For search operations
	const [pageCount, setPageCount] = useState(1);

    const returnedObject = fetchHook({
        limit: 6,
        orderBy: "createdAt:desc",
        page: newData ? 1 : (Number(page) || 1),
        ...options,
        ...generateFilter(searchParams, "filter"),
    });
    
    const { data, isLoading, refetch } = returnedObject as ReturnedObject;

	useEffect(() => {
		if (!isLoading && data?.data) {
			setTableData(data.data);
			setFetchedTableData(data.data);
            setPageCount(data.pagination?.totalPages || 1);
		}
		// eslint-disable-next-line
	}, [isLoading]);
    
	useEffect(() => {
        if (searching) {
            setPageCount(1);
            return
        }
        
        refetch().then((response) => {
            if (response) {
                setTableData(response.data?.data || []);
                setFetchedTableData(response.data?.data || []);
                setPageCount(response.data?.pagination?.totalPages || 1);
            }
        });

        if (newData) {
            const currentParams = new URLSearchParams(window.location.search);
            currentParams.delete("newData");
            setSearchParams(currentParams);
        }
        if (reloadData) {
            const currentParams = new URLSearchParams(window.location.search);
            currentParams.delete("reloadData");
            setSearchParams(currentParams);
        }
	}, [searchParams, ...(reloadDeps || [])])

    return {
        tableAPIRef,
        tableData,
        fetchedTableData,
        isLoading,
        pageCount,
        setTableData,
        setFetchedTableData
    }
}

export function useManageTableWithStateVariables<TData>({ fetchHook, options, reloadDeps }: ManageTable) {
	const tableAPIRef = useGridApiRef();
	const [tableData, setTableData] = useState<TData[]>([]);
	const [tableFilter, setTableFilter] = useState<any>({});
	const [page, setPage] = useState(1);
	const [pageCount, setPageCount] = useState(1);
	const [newData, setNewData] = useState("");
	const [reloadData, setReloadData] = useState("");

    const returnedObject = fetchHook({
        limit: 6,
        orderBy: "createdAt:desc",
        page: newData ? 1 : page,
        ...options,
        ...tableFilter,
    });
    
    const { data, isLoading, refetch } = returnedObject as ReturnedObject;

	useEffect(() => {
		if (!isLoading && data?.data) {
			setTableData(data.data);
            setPageCount(data.pagination?.totalPages || 1);
		}
	}, [isLoading]);
    
	useEffect(() => {
		newData && setPage(1);
		newData && setTableFilter({});

        refetch().then((response) => {
            if (response) {
                setTableData(response.data?.data || []);
                setPageCount(response.data?.pagination?.totalPages || 1);
            }

			newData && setNewData("");
			reloadData && setReloadData("");
        });
	}, [page, newData, reloadData, tableFilter, ...(reloadDeps || [])])

	const handlePageChange = (_, value: number) => setPage(value);

    return {
        tableAPIRef,
        tableData,
        isLoading,
        page,
        pageCount,
        tableFilter,
        setTableData,
        setTableFilter,
        addNewData: () => setNewData("true"),
        reloadData: () => setReloadData("true"),
        handlePageChange,
    }
}

export function useManageTableWithSearchParamsData() {
	const [searchParams, setSearchParams] = useSearchParams();
    const view = searchParams.get("view");

    const addNewTableData = () => {
        if (view) {
            setSearchParams({ 
                view, 
                page: "1",
                newData: "true"
            });
        } else {
            setSearchParams({ page: "1", newData: "true" });
        }
    };

    const reloadTableData = () => {
        const currentParams = new URLSearchParams(window.location.search);
        currentParams.set("reloadData", "true");
        setSearchParams(currentParams);
    };

    return { addNewTableData, reloadTableData }
}

export function generateFilter(searchParams: URLSearchParams, cancel?: string) {
    if (cancel && !searchParams.get(cancel)) return {};
    
    let filter: any = {};

    searchParams.forEach((value, key) => {
        if (!excludedKeys.has(key)) {
            filter[key] = value;
        }
    });

    return filter;
}

const excludedKeys = new Set([
	"view", "filter", "page", "reloadData",
    "searching", "newData", "taxRule", "clearSearch"
]);