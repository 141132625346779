import { GridColDef } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, enumToStringConverter, moneyFormat } from "../../../helpers/appHelpers";
import { Avatar } from "@mui/material";

export const ExplicitDeductionsColumns: GridColDef[] = [
	{
		field: "name",
		headerName: "Name",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "code",
		headerName: "Code",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "description",
		headerName: "Description",
		width: 380,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "applyOn",
		headerName: "Apply On",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "fixedComponent",
		headerName: "Fixed Component",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value),
	},
	{
		field: "rateComponent",
		headerName: "Rate Component",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value + "%",
	},
	{
		field: "startPayPeriod",
		headerName: "Start Pay Period",
		width: 280,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.code,
	},
	{
		field: "endPayPeriod",
		headerName: "End Pay Period",
		width: 280,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.code,
	},

	...createdAtmodifiedAtActions
];

export const ExplicitDeductionsColumnVisibilityModel: Record<string, boolean> = {
	name: true,
	code: true,
	description: true,
	applyOn: true,
	fixedComponent: true,
	rateComponent: true,
	startPayPeriod: false,
	endPayPeriod: false,
	createdAt: true,
	modifiedAt: true,
};

export const LoansColumns: GridColDef[] = [
	{
		field: "employee",
		headerName: "Employee",
		headerClassName: "super-app-theme--header",
		width: 300,
		renderCell: (params: any) => {
			const employee = params.row.employee || undefined;
			return (
				<div className="flex items-center space-x-4">
					{employee && <>
						<Avatar
							src={employee.photoUrl || ""}
							alt="employee"
							className="w-10 h-10 rounded-full"
						/>
						<p className="text-sm truncate">
							{employee.firstName + " " + employee.lastName}
						</p>
					</>}
				</div>
			);
		},
	},
	{
		field: "amount",
		headerName: "Amount",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "description",
		headerName: "Description",
		width: 400,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "repaymentType",
		headerName: "Repayment Type",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "interestRate",
		headerName: "Interest Rate",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value + "%",
	},
	{
		field: "numberOfMonths",
		headerName: "Number Of Months",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "startPayPeriod",
		headerName: "Start Pay Period",
		width: 280,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.code,
	},
	{
		field: "repaymentAmount",
		headerName: "Repayment Amount",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "initialPayment",
		headerName: "Initial Payment",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "finalPayment",
		headerName: "Final Payment",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},

	...createdAtmodifiedAtActions
];

export const LoansColumnVisibilityModel: Record<string, boolean> = {
	employee: true,
	amount: true,
	description: true,
	repaymentType: true,
	interestRate: true,
	numberOfMonths: true,
	startPayPeriod: true,
	repaymentAmount: false,
	initialPayment: false,
	finalPayment: false,
	createdAt: true,
	modifiedAt: true,
};