import { useState } from "react";
import OnboardingLayout from "../../components/layouts/OnboardingLayout";
import OrangeButton from "../../components/buttons/OrangeButton";
import { Link, useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../components/form_fields/Label";
import { Helmet } from "react-helmet-async";
import { BiMailSend } from "react-icons/bi";
import { useForgotPassword } from "../../services/auth.service";
import { toast } from "react-toastify";
import { PATHS } from "../../routes/routes.paths";

interface IFormInputs {
	username: string;
}

const ForgotPassword = () => {
	const [view, setView] = useState("forgot password");

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>();

	const { mutate: sendResetEmail, isLoading } = useForgotPassword();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		sendResetEmail(
			{ payload: data },
			{
				onSuccess: () => setView("confirmation"),
				onError: (data) => {
					// toast.error(data)
				},
			}
		);
	};

	return (
		<OnboardingLayout>
			<Helmet>
				<title>Forgot Password | Akatua</title>
			</Helmet>
			{view === "forgot password" && (
				<div
					className={`flex flex-col lg:px-24 px-4 w-full justify-center my-16 lg:my-0`}
				>
					<img src="/assets/logos/akatua-logo.svg" alt="akatua" width={130} />

					<h3 className="mt-6 text-lg font-normal">
						Forgot your password? Don’t worry, you will receive a reset email
					</h3>

					<div className="mt-4">
						<form className="text-charcoal">
							<div className="mt-6 flex items-center w-full space-x-4">
								<div className="lg:w-1/2 w-full">
									<Label title="Username" for="username" />
									<input
										type="text"
										{...register("username", {
											required: "Enter your username",
										})}
										id="username"
										placeholder="Enter username"
										className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm ${
											errors.username
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`}
									/>
									{errors.username && (
										<p className="text-red-500 text-sm mt-1">
											{errors.username.message}
										</p>
									)}
								</div>
							</div>

							<div className="w-[50%]">
								<OrangeButton
									title={isLoading ? "Loading..." : "Continue"}
									className={`w-full mt-10 font-light ${
										isLoading && "bg-slate-400 cursor-not-allowed"
									}`}
									disabled={isLoading}
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</form>
					</div>
				</div>
			)}
			{view === "confirmation" && (<>
				<div
					className={`flex flex-col lg:px-24 px-4 w-full justify-center items-center my-16 lg:my-0`}
				>
					<div className="flex flex-col items-center">
						<div className="bg-orange-100 rounded-full flex justify-center items-center h-20 w-20 mb-6">
							<BiMailSend className="text-4xl text-primary-mango" />
						</div>
						<h2 className="text-lg font-semibold w-2/3 text-center">
							If you have an account with us, you'll receive an email shortly to
							reset your password
						</h2>
						<p className="text-gray-500 mt-4">Check your inbox to confirm</p>
						<p className="text-gray-500 mt-1.5">
							Go back to{" "}
							<span className="text-primary-blue">
								<Link to={PATHS.SIGN_IN}>Sign in</Link>
							</span>
							{" "}page
						</p>
					</div>
				</div>

			</>)}
		</OnboardingLayout>
	);
};

export default ForgotPassword;
