import React from "react";
import { Switch, SwitchProps, styled } from "@mui/material";
import { IoCheckmarkCircle } from "react-icons/io5";
import { moneyFormat, subscriptionCardColors } from "../../../../../helpers/appHelpers";
import { useGetSubscriptionTierPackages } from "../../../../../services/subscription-tier.service";
import { DetailedSubscriptionTierDto, SubscriptionTierOrderBy } from "../../../../../models/subscription-tier.dto";
import useUserStore from "../../../../../state-management/useUserStore";
import { useManageTableWithSearchParams } from "../../../../../helpers/useManageTable";
import { InvoiceDto } from "../../../../../models/invoice.dto";
import CustomStyledTable from "../../../../../components/table/CustomStyledTable";
import { TransactionHistoryColumns } from "./data";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../../../routes/routes.paths";
import { OrganizationDto } from "../../../../../models/organization.dto";
import { useGetOrgTransaction } from "../../../../../services/organization-transaction.service";

const Subscription = ({ closeModal }: { closeModal: () => void }) => {
	const navigate = useNavigate();
	const { currentUser } = useUserStore();
	const userOrganization = currentUser.user.organization as OrganizationDto;

	const { 
		data: subscriptionPackages, 
		isLoading: loadingSubscriptionPackages,
	} = useGetSubscriptionTierPackages({
		detailed: true,
		page: 1,
		limit: 20,
		orderBy: SubscriptionTierOrderBy.PRICE_AMOUNT_ASC,
	});
	const {
        tableData: organizationTransactions,
        isLoading: loadingOrganizationTransactions,
        pageCount,
	} = useManageTableWithSearchParams<InvoiceDto>({
		fetchHook: useGetOrgTransaction,
		options: { organizationId: userOrganization?.id  }
	});

	return (
		<div>
			<div className="border-b-[1.5px] pb-3 flex w-full justify-between font-semibold text-sm">
				<h2 className="font-semibold text-sm">Plans</h2>
			</div>
			<div className="text-2xs mt-4 font-inter">
				<h3 className="font-normal">
					You are currently subscribed to the{" "}
					<span className="font-bold">
						{userOrganization.subsciptionTier?.name}
					</span>
				</h3>
				{loadingSubscriptionPackages ? (
					<div className="grid grid-cols-3 gap-3 mt-4">
						{Array.from({ length:  3 }).map(() => (
							<SkeletonSubscriptionCard key={Math.random()} />
						))}
					</div>
				):(
					<div className="grid grid-cols-3 gap-3 mt-4">
						{subscriptionPackages?.data?.map((subscriptionTierPackage: DetailedSubscriptionTierDto, index) => (
							<>
								{subscriptionTierPackage.id !==
								userOrganization.subsciptionTier?.id ? (
									<div
										key={subscriptionTierPackage.id}
										style={{ 
											backgroundImage: `linear-gradient(to bottom right, 
												${subscriptionCardColors[index.toString()][0]} , 
												${subscriptionCardColors[index.toString()][0]} ,
												${subscriptionCardColors[index.toString()][1]})` 
										}}
										className={`flex flex-col space-y-2 justify-center text-white w-full h-[135px] px-5 rounded-[10px]`}
									>
										<div className="flex justify-between text-base font-bold">
											<h4 className="truncate max-w-[100px]">
												{subscriptionTierPackage.name}
											</h4>
											<p>
												{Number(subscriptionTierPackage.priceAmount) === 0
													? "Free"
													: getPackageBillingStats(subscriptionTierPackage)
												}
											</p>
										</div>
										<p className="w-[70%] text-[11px] line-clamp-2 ">
											{subscriptionTierPackage.description}
										</p>
										<div>
											<button
												className="bg-white text-secondary-darkgray px-2.5 py-2 rounded-md text-[11px] font-bold"
												onClick={() => {
													navigate(
														PATHS.SUBSCRIPTION_PAYMENT + 
														`?subscriptionTierId=${subscriptionTierPackage.id}`
													);
													closeModal();
												}}
											>
												{Number(subscriptionTierPackage.priceAmount) === 0
													? "Use Free"
													: "Upgrade"}
											</button>
										</div>
									</div>
								):(
									<div
										key={subscriptionTierPackage.id}
										className="flex flex-col space-y-2 justify-center text-[#4F4F4F] w-full h-[135px] px-5 border-2 border-[#26A0F9] rounded-[10px]"
									>
										<div className="flex items-center justify-between text-base text-[#292D30] font-bold">
											<h4 className="truncate max-w-[150px]">
												{subscriptionTierPackage.name}
											</h4>
											<IoCheckmarkCircle className="text-primary-blue text-lg" />
										</div>
										<p className="w-[70%] text-[11px] line-clamp-2 ">
											{subscriptionTierPackage.description}
										</p>
										<div>
											<button
												className={`border border-[#B4B7BD] px-2.5 py-2 rounded-md text-[11px] font-bold
												${Number(subscriptionTierPackage.priceAmount) === 0 && "cursor-default"}`}
											>
												{Number(subscriptionTierPackage.priceAmount) === 0
													? "Free Version"
													: "Cancel Subscription"}
											</button>
										</div>
									</div>
								)}
							</>
						))}
					</div>
				)}
				{Number(userOrganization.subsciptionTier?.priceAmount) !== 0 && (
					<div className="mt-6">
						<div className="border-b-[1.5px] pb-3 flex w-full justify-between">
							<h2 className="font-semibold text-sm">Transaction History</h2>
						</div>
						<div className="mt-6">
							<CustomStyledTable
								rows={organizationTransactions}
								columns={TransactionHistoryColumns}
								apiRef={undefined}
								loading={loadingOrganizationTransactions}
								pageCount={pageCount}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Subscription;

const SkeletonSubscriptionCard = () => (
	<div className="animate-pulse flex flex-col space-y-2 justify-center text-white w-full h-[135px] px-5 rounded-[10px] bg-gray-200">
		<div className="flex justify-between text-base font-bold mb-2">
			<div className="h-5 bg-gray-300 rounded w-[60%]"></div>
			<div className="h-5 bg-gray-300 rounded w-[30%]"></div>
		</div>
		<div className="h-4 bg-gray-300 rounded w-full"></div>
		<div className="h-7 bg-gray-300 rounded w-[100px] mt-1"></div>
	</div>
);

const getPackageBillingStats = (subscriptionTierPackage: DetailedSubscriptionTierDto) => {
	const currencyCode = subscriptionTierPackage.currency?.code || "$";
	const priceAmount = moneyFormat(subscriptionTierPackage.priceAmount || "");

	switch(subscriptionTierPackage.billingPeriod) {
		case "MONTHLY":
			return currencyCode + " " + priceAmount + "/mo"
		case "ANNUALLY":
			return currencyCode + " " + priceAmount + "/yr"
		default:
			return currencyCode + " " + priceAmount
	}
};

const IOSSwitch = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 44,
	height: 22,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 2,
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(21px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#27AE60",
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color:
				theme.palette.mode === "light"
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 18,
		height: 18,
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));