import {PaginatorInfo} from "./generic.dto";
import {EmployeeQualificationDto} from "./employee-qualification.dto";

export const GENDER = {
    MALE: 'MALE',
    FEMALE: 'FEMALE'
};

export type GENDER = (typeof GENDER)[keyof typeof GENDER];

export enum IdType {
    VOTERS = "VOTERS",
    PASSPORT = "PASSPORT",
    NATIONAL_ID = "NATIONAL_ID",
    DRIVER_LICENCE = "DRIVER_LICENCE",
    EMPLOYER_ID = "EMPLOYER_ID",
    SCHOOL_ID = "SCHOOL_ID",
    SOCIAL_SECURITY = "SOCIAL_SECURITY",
    TRAVEL_CERT = "TRAVEL_CERT",
    HEALTH_INSURANCE = "HEALTH_INSURANCE"
}

export enum RELATIONSHIP {
    SPOUSE = "SPOUSE",
    CHILD = "CHILD",
    PARENT = "PARENT",
    GUARDIAN = "GUARDIAN",
    DEPENDENT = "DEPENDENT"
};

type EmployeeRelative = {
    id: number
    employeeId: number
    companyId: number
    firstName: string
    lastName: string
    otherNames: string
    gender: GENDER
    msisdn: string
    idType: IdType
    idNumber: string
    relationship: RELATIONSHIP
    nextOfKin: boolean
    active: boolean
    createdAt: string
    modifiedAt: string | null
}
export class CreateEmployeeRelativeDto {
    employeeId!: number
    companyId!: number
    firstName!: string
    lastName!: string
    otherNames?: string
    gender!: GENDER
    msisdn!: string
    idType!: IdType
    idNumber!: string
    relationship!: RELATIONSHIP
    nextOfKin!: boolean
    active!: boolean
}


export class UpdateEmployeeRelativeDto {
    employeeId?: number
    companyId?: number
    firstName?: string
    lastName?: string
    otherNames?: string
    gender?: GENDER
    msisdn?: string
    idType?: IdType
    idNumber?: string
    relationship?: RELATIONSHIP
    nextOfKin?: boolean
    active?: boolean
}

export interface EmployeeRelativeDto extends EmployeeRelative { }
export interface EmployeeRelativePaginator extends PaginatorInfo<EmployeeRelativeDto> {}

export class QueryEmployeeRelativeDto {
    employeeId?: number
    companyId?: number
    gender?: GENDER
    msisdn?: string
    idType?: IdType
    idNumber?: string
    relationship?: RELATIONSHIP
    nextOfKin?: boolean
    active?: boolean
    page?: number = 1;
    limit?: number;
    orderBy?: EmployeeRelativeOrderBy = EmployeeRelativeOrderBy.CREATED_AT_ASC;
}

export enum EmployeeRelativeOrderBy {
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
    MODIFIED_AT_ASC = 'modifiedAt:asc',
    MODIFIED_AT_DESC = 'modifiedAt:desc',
    FIRST_NAME_ASC = 'firstName:asc',
    FIRST_NAME_DESC = 'firstName:desc',
    LAST_NAME_ASC = 'lastName:asc',
    LAST_NAME_DESC = 'lastName:desc'
}
