import { Box } from "@mui/material";
import { StyledDataGrid } from "../../../../helpers/appHelpers";
import { RecentPayColumns } from "../data";
import { useGetEmployeePaysips } from "../../../../services/payrol.service";
import useUserStore from "../../../../state-management/useUserStore";

const RecentPayTable = () => {
	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];

	const { data, isLoading } = useGetEmployeePaysips(
		companyProfile?.employeeId as number, 
		{ page: 1, limit: 8 } as any
	);

	return (
		<Box
			sx={{
				height: "450px",
				backgroundColor: "background.paper",
				borderRadius: 4,
				width: "100%",
				"& .actions": {
					color: "text.secondary",
				},
				"& .textPrimary": {
					color: "text.primary",
				},
				"& .super-app-theme--header": {
					backgroundColor: "#F2F2F4",
					fontWeight: "bold",
				},
				"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
					width: "11px",
				},
				"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
					background: "#f7fafc",
				},
				"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
					backgroundColor: "#e2e8f0",
					borderRadius: "6px",
					border: "3px solid #f7fafc",
				},
				"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
					backgroundColor: "#e2e8f0",
					borderRadius: "7px",
					border: "3px solid #f7fafc",
				},
			}}
		>
			<StyledDataGrid
				hideFooterPagination
				hideFooter
				rows={data?.data || []}
				loading={isLoading}
				rowHeight={65}
				columnHeaderHeight={70}
				columns={RecentPayColumns}
				initialState={{
					columns: {
						columnVisibilityModel: {
							id: false,
						},
					},
				}}
			/>
		</Box>
	);
};

export default RecentPayTable;
