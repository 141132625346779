import { Popover } from "@mui/material";
import { useState } from "react";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import { YourClaimsTableColumns } from "../data";
import ReimbursementRequestModal from "../modals/ReimbursementRequestModal";
import RequestDetailsModal from "../modals/RequestDetailsModal";
import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import { useManageTableWithSearchParams, useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import { REIMBURESEMENT_REQUEST_STATUS, ReimbursementRequestDto } from "../../../../models/reimbursement-request.dto";
import { 
	useDeleteReimbursementRequest, 
	useGetReimbursementRequest, 
	useGetReimbursementRequestSearch 
} from "../../../../services/reimbursement-request.service";
import useUserStore from "../../../../state-management/useUserStore";
import { APP_CONFIG } from "../../../../helpers/appHelpers";
import { useGetCompanyCurrencyConfig } from "../../../../services/company-currency.service";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { BsPencil, BsTrash } from "react-icons/bs";
import { LuEye } from "react-icons/lu";
import TableFilter from "../../../../components/table/filter/TableFilter";
import APISearchInput from "../../../../components/search/APISearchInput";
import DeleteModal from "../../../../components/modals/DeleteModal";

const YourClaimsView = () => {
	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openReimbursementModalForm, setOpenReimbursementModalForm] = useState(false);
	const [openRequestDetailsModal, setOpenRequestDetailsModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	const { mutate: searchReimbursements } = useGetReimbursementRequestSearch();
	const {
        tableAPIRef,
		fetchedTableData,
        tableData,
        isLoading,
        pageCount,
        setTableData,
    } = useManageTableWithSearchParams<ReimbursementRequestDto>({ 
		fetchHook: useGetReimbursementRequest,
		options: { queryMode: "SELF" }
	});

	const { data: companyCurrencies } = useGetCompanyCurrencyConfig({
		companyId: companyProfile.companyId,
		limit: APP_CONFIG.PAGE_LIMIT,
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setIsEditForm(false);
		setOpenReimbursementModalForm(true);
	};

	const { formDataValues, setFormData } = useEditFormStore();
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteReimbursementRequest } = useDeleteReimbursementRequest();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteReimbursementRequest(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadTableData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>
				];
			},
		},
	];

	const columnsWithAdditions = YourClaimsTableColumns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);
	
	return (
		<>
			<div className="flex lg:flex-row flex-col lg:items-center lg:justify-between lg:mb-6 mb-4">
				<div className="flex items-center max-lg:justify-between gap-3">
					<div className="max-lg:hidden">
						<APISearchInput 
							fallbackData={fetchedTableData}
							setTableData={setTableData}
							mutate={searchReimbursements}
							mutateOptions={{ queryMode: "SELF" }}
						/>
					</div>
					<TableFilter
						fields={[
							{
								title: "",
								fieldName: "",
								fieldType: "SORT",
								options: [
									{
										title: "Title",
										fieldName: "title",
									},
									{
										title: "Expenditure Date",
										fieldName: "expenditureDate",
									},
									{
										title: "Date Approved",
										fieldName: "approvedAt",
									},
									{
										title: "Date Completed",
										fieldName: "completedAt",
									},
									{
										title: "Date Created",
										fieldName: "createdAt",
									},
									{
										title: "Date Modified",
										fieldName: "modifiedAt",
									},
								]
							},
							{
								title: "Status",
								fieldName: "status",
								fieldType: "CHECKBOX_LIST",
								options: REIMBURESEMENT_REQUEST_STATUS,
							},
							{
								title: "Expenditure Date",
								fieldName: "expenditureDate",
								fieldType: "DATE_RANGE",
							},
							{
								title: "Date Created",
								fieldName: "createdAt",
								fieldType: "DATE_RANGE",
							},
							{
								title: "Approved On",
								fieldName: "approvedAt",
								fieldType: "DATE_RANGE",
							},
							{
								title: "Completed On",
								fieldName: "completedAt",
								fieldType: "DATE_RANGE",
							},
						]}
					/>
					<div className="lg:hidden">
						<OrangeButton
							title={"+ Submit Claim"}
							className={"px-4 h-12"}
							onClick={handleOpenModal}
						/>
					</div>
				</div>

				<div className="max-lg:hidden">
					<OrangeButton
						title={"+ Submit Claim"}
						className={"px-4 h-12"}
						onClick={handleOpenModal}
					/>
				</div>
			</div>
			<div className="lg:hidden w-full mb-6">
				<APISearchInput 
					fallbackData={fetchedTableData}
					setTableData={setTableData}
					mutate={searchReimbursements}
					mutateOptions={{ queryMode: "SELF" }}
				/>
			</div>
			<CustomStyledTable
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
			<Popover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				PaperProps={{
					style: {
						width: "auto",
						borderRadius: 8,
						display: "flex",
						justifyContent: "center",
					},
				}}
			>
				<div className="flex flex-col justify-center p-3 text-secondary-darkgray">
					<div
						onClick={() => {
							setOpenRequestDetailsModal(true);
							handleClose();
						}}
						className="table-action-popover"
					>
						<LuEye />
						<p className="text-2sm">View Details</p>
					</div>
					{(formDataValues?.status === "SUBMITTED" || formDataValues?.status === "QUERIED") && (
						<div
							onClick={() => {
								setOpenReimbursementModalForm(true);
								setIsEditForm(true);
								handleClose();
							}}
							className="table-action-popover"
						>
							<BsPencil />
							<p className="text-2sm">Edit</p>
						</div>
					)}
					{formDataValues?.status === "SUBMITTED" && (
						<div
							onClick={() => {
								handleClose();
								setOpenDeleteModal(true);
							}}
							className="table-action-popover"
						>
							<BsTrash />
							<p className="text-2sm">Delete</p>
						</div>
					)}
				</div>
			</Popover>
			<DeleteModal
				openDeleteModal={openDeleteModal}
				spanContent={formDataValues?.title || ""}
				setOpenDeleteModal={setOpenDeleteModal}
				handleDeleteMethod={handleDeleteMethod}
				deleting={deleting}
			/>

			{openReimbursementModalForm && (
				<ReimbursementRequestModal
					open={openReimbursementModalForm}
					title={"Submit Claim"}
					setOpen={setOpenReimbursementModalForm}
					isEditForm={isEditForm}
					tableAPIRef={tableAPIRef}
					companyCurrencies={companyCurrencies?.data || []}
				/>
			)}
			{openRequestDetailsModal && (
				<RequestDetailsModal
					open={openRequestDetailsModal}
					title={"Claim Details"}
					setOpen={setOpenRequestDetailsModal}
					supervisor={false}
					tableAPIRef={tableAPIRef}
				/>
			)}
		</>
	);
};

export default YourClaimsView;
