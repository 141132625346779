import { useEffect, useState } from "react";
import HumanResourceLayout from "../../../components/layouts/HumanResourceLayout";
import { Helmet } from "react-helmet-async";
import { Popover } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { LuEye } from "react-icons/lu";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { useSearchParams } from "react-router-dom";
import CustomStyledTable from "../../../components/table/CustomStyledTable";
import { useManageTableWithSearchParams, useManageTableWithSearchParamsData } from "../../../helpers/useManageTable";
import { REIMBURESEMENT_REQUEST_STATUS, ReimbursementRequestDto } from "../../../models/reimbursement-request.dto";
import { 
	useDeleteReimbursementRequest, 
	useGetReimbursementRequest, 
	useGetReimbursementRequestSearch 
} from "../../../services/reimbursement-request.service";
import useEditFormStore from "../../../state-management/useEditFormStore";
import CompleteRequestModal from "../../self_service/reimbursement/modals/CompleteRequestModal";
import RequestDetailsModal from "../../self_service/reimbursement/modals/RequestDetailsModal";
import { ReimbursementColumnsHR } from "./data";
import useUserStore from "../../../state-management/useUserStore";
import TableFilter from "../../../components/table/filter/TableFilter";
import { APP_CONFIG } from "../../../helpers/appHelpers";
import { useGetEmployee } from "../../../services/employee.service";
import { EmployeeOrderBy } from "../../../models/employee.dto";
import APISearchInput from "../../../components/search/APISearchInput";
import { BsTrash } from "react-icons/bs";
import DeleteModal from "../../../components/modals/DeleteModal";

const Reimbursements = () => {
	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];
	const [searchParams] = useSearchParams();
    const requestId = searchParams.get("requestId");
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openRequestDetailsModal, setOpenRequestDetailsModal] = useState(false);
	const [openCompleteRequestModal, setOpenCompleteRequestModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	useEffect(() => {
		if (requestId) {
			setOpenRequestDetailsModal(true);
		}
	}, [requestId])

	const { mutate: searchReimbursements } = useGetReimbursementRequestSearch();
	const {
        tableAPIRef,
		fetchedTableData,
        tableData,
        isLoading,
        pageCount,
        setTableData,
    } = useManageTableWithSearchParams<ReimbursementRequestDto>({ 
		fetchHook: useGetReimbursementRequest,
		options: { queryMode: "ALL" }
	});
	
	// Fetched data for select fields requiring data from database
	const { data: employees } = useGetEmployee({
		companyId: companyProfile.companyId,
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		status: "ACTIVE",
		orderBy: EmployeeOrderBy.FIRST_NAME_ASC,
	});

	const handleClose = () => setAnchorEl(null);

	const { formDataValues, setFormData } = useEditFormStore();
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteReimbursementRequest } = useDeleteReimbursementRequest();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteReimbursementRequest(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadTableData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>
				];
			},
		},
	];

	const columnsWithAdditions = ReimbursementColumnsHR.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<HumanResourceLayout>
			<Helmet>
				<title>Reimbursements | HR Flow </title>
			</Helmet>
			<h1 className="font-bold text-xl text-charcoal">Reimbursements</h1>
			<>
				<div className="flex items-center gap-3 my-6">
					<APISearchInput 
						fallbackData={fetchedTableData}
						setTableData={setTableData}
						mutate={searchReimbursements}
						mutateOptions={{ queryMode: "ALL" }}
					/>
					<TableFilter
						fields={[
							{
								title: "",
								fieldName: "",
								fieldType: "SORT",
								options: [
									{
										title: "Title",
										fieldName: "title",
									},
									{
										title: "Expenditure Date",
										fieldName: "expenditureDate",
									},
									{
										title: "Date Approved",
										fieldName: "approvedAt",
									},
									{
										title: "Date Completed",
										fieldName: "completedAt",
									},
									{
										title: "Date Created",
										fieldName: "createdAt",
									},
									{
										title: "Date Modified",
										fieldName: "modifiedAt",
									},
								]
							},
							{
								title: "Employee",
								fieldName: "employeeId",
								fieldType: "SELECT_FIELD",
								options: employees?.data || [],
								mapTexts: ["firstName", "lastName"],
							},
							{
								title: "Status",
								fieldName: "status",
								fieldType: "CHECKBOX_LIST",
								options: REIMBURESEMENT_REQUEST_STATUS,
							},
							{
								title: "Expenditure Date",
								fieldName: "expenditureDate",
								fieldType: "DATE_RANGE",
							},
							{
								title: "Date Created",
								fieldName: "createdAt",
								fieldType: "DATE_RANGE",
							},
							{
								title: "Approved On",
								fieldName: "approvedAt",
								fieldType: "DATE_RANGE",
							},
							{
								title: "Completed On",
								fieldName: "completedAt",
								fieldType: "DATE_RANGE",
							},
						]}
					/>
				</div>
				<CustomStyledTable
					rows={tableData}
					columns={columnsWithAdditions}
					apiRef={tableAPIRef}
					loading={isLoading}
					pageCount={pageCount}
					setAnchorEl={setAnchorEl}
				/>
				<Popover
					id={anchorEl ? "simple-popover" : undefined}
					open={Boolean(anchorEl)}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					PaperProps={{
						style: {
							width: "auto",
							borderRadius: 8,
							display: "flex",
							justifyContent: "center",
						},
					}}
				>
					<div className="flex flex-col justify-center p-3 text-secondary-darkgray">
						<div
							onClick={() => {
								setOpenRequestDetailsModal(true);
								handleClose();
							}}
							className="table-action-popover"
						>
							<LuEye />
							<p className="text-2sm">View Details</p>
						</div>
						{(
							formDataValues?.status === "APPROVED" &&
							formDataValues?.employee?.id !== companyProfile?.employeeId
						) && (
							<div
								onClick={() => {
									setOpenCompleteRequestModal(true);
									handleClose();
								}}
								className="table-action-popover"
							>
								<IoMdCheckmarkCircleOutline />
								<p className="text-2sm">Complete</p>
							</div>
						)}
						{formDataValues?.status === "SUBMITTED" && (
							<div
								onClick={() => {
									handleClose();
									setOpenDeleteModal(true);
								}}
								className="table-action-popover"
							>
								<BsTrash />
								<p className="text-2sm">Delete</p>
							</div>
						)}
					</div>
				</Popover>
				<DeleteModal
					openDeleteModal={openDeleteModal}
					spanContent={formDataValues?.title || ""}
					setOpenDeleteModal={setOpenDeleteModal}
					handleDeleteMethod={handleDeleteMethod}
					deleting={deleting}
				/>

				{openRequestDetailsModal && (
					<RequestDetailsModal
						open={openRequestDetailsModal}
						title={"Claim Details"}
						setOpen={setOpenRequestDetailsModal}
						tableAPIRef={tableAPIRef}
						supervisor
					/>
				)}
				{openCompleteRequestModal && (
					<CompleteRequestModal
						open={openCompleteRequestModal}
						setOpen={setOpenCompleteRequestModal}
						tableAPIRef={tableAPIRef}
					/>
				)}
			</>
		</HumanResourceLayout>
	);
};

export default Reimbursements;