import { Typography, Popover } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import { BsColumnsGap } from "react-icons/bs";
import { CustomizedTooltip } from "../../helpers/appHelpers";

type ManageTableColumnsProps = {
    columns: GridColDef[];
    defaultVisibilityModel: Record<string, boolean>;
    visibilityModel: Record<string, boolean>;
    setVisibilityModel: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
    minVisibleColumns?: number;
    filterBoxWidth?: string;
    filterSectionMaxHeight?: string;
}

const ManageTableColumns = ({ 
    columns,
    defaultVisibilityModel,
    visibilityModel,
    setVisibilityModel,
    minVisibleColumns,
    filterBoxWidth,
    filterSectionMaxHeight,
}: ManageTableColumnsProps) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [visibilityState, setVisibilityState] = useState(visibilityModel);
	const visibleColumns = useMemo(() => {
        let count = 0;

        Object.entries(visibilityState).map(([_, value]) => {
            if (value) count++;
        });

        const limit = minVisibleColumns || 8;

        return (count > limit) ? count : limit;
    }, [visibilityState]);

    const toggleColumnVisibility = (key: string) => {
        setVisibilityState(previousValue => {
            return { ...previousValue, [key]: !previousValue[key] }
        });
    };

    const updateVisibilityModel = () => {
        setVisibilityModel(visibilityState);
        setAnchorEl(null);
    };

    const resetVisibilityModel = () => {
        setVisibilityModel(defaultVisibilityModel);
        setVisibilityState(defaultVisibilityModel);
        setAnchorEl(null);
    };

    return (<>
        <CustomizedTooltip title="Manage table columns" placement="right" arrow>
            <Typography> 
                <button 
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    className="w-fit h-[44px] px-3 rounded-lg border border-black text-black flex items-center text-xl"
                >
                    <BsColumnsGap />
                </button>
            </Typography>
        </CustomizedTooltip>
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            PaperProps={{
                className: "",
                style: {
                    width: filterBoxWidth || "330px",
                    borderRadius: 8,
                    padding: "16px 0",
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "15px",
                    boxShadow: "-10px -5px 30px 0px rgba(0, 0, 0, 0.15)",
                },
            }}
        >
            <h4 className="font-bold px-6">Manage Columns</h4>
            <section 
                className="w-full flex flex-col gap-4 mb-4 mt-2 px-6 overflow-y-auto"
                style={{ maxHeight: filterSectionMaxHeight || "450px" }}
            >
                {Object.entries(visibilityState).map(([key, value]) => (
                    <div key={key} className="flex items-center">
                        <input
                            checked={value === true}
                            type="checkbox"
                            value={key}
                            id={key.toLowerCase()}
                            onChange={(e) => toggleColumnVisibility(key)}
                            disabled={value === true && (minVisibleColumns || 8) === visibleColumns}
                        />
                        <label 
                            htmlFor={key.toLowerCase()} 
                            className="pl-3 capitalize"
                        >
                            {columns.find((column) => column.field === key)?.headerName}
                        </label>
                    </div>
                ))}
            </section>
            <div className="w-full flex items-center gap-4 px-6 font-medium">
                <button 
                    onClick={updateVisibilityModel}
                    className="w-full py-3 px-4 bg-primary-mango rounded-[10px] text-white hover:shadow-sm"
                >
                    Save
                </button>
                <button
                    onClick={resetVisibilityModel}
                    className="w-full py-3 px-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-[10px]"
                >
                    Reset
                </button>
            </div>
        </Popover>
    </>);
}
 
export default ManageTableColumns;