import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    BenefitTypeDto, BenefitTypePaginator,
    CreateBenefitTypeDto, QueryBenefitTypeDto,
    UpdateBenefitTypeDto, SearchBenefitTypeDto
} from "../models/benefit-type.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetBenefitType(options?: QueryBenefitTypeDto, queryOptions?: any) {
    const url = ENDPOINTS.PROCESSING_COMPANY.BENEFIT_TYPE.GET;
    const request = (options: QueryBenefitTypeDto) => API.ProcessingCompanyAPI.getBenefitType(options);

    const { data, isLoading, error, refetch } = useQuery<BenefitTypePaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch }
}

export function useGetBenefitTypeSearch() {

    return useMutation(
        (options?: SearchBenefitTypeDto) => API.ProcessingCompanyAPI.getBenefitTypeSearch(options),
        {
            onSuccess: (response,) => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}
export function useGetBenefitTypeById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.BENEFIT_TYPE.DETAIL;
    const request = API.ProcessingCompanyAPI.getBenefitTypeById(id);

    const {data, isLoading, error} = useQuery<{ data: BenefitTypeDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateBenefitType() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateBenefitTypeDto}) => API.ProcessingCompanyAPI.createBenefitType(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.BENEFIT_TYPE.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateBenefitType() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateBenefitTypeDto, id: number}) => API.ProcessingCompanyAPI.updateBenefitType(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.BENEFIT_TYPE.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useDeleteBenefitType() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteBenefitType(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.BENEFIT_TYPE.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}
