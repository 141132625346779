import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    OrganizationRoleDto, OrganizationRolePaginator,
    CreateOrganizationRoleDto, QueryOrganizationRoleDto,
    UpdateOrganizationRoleDto
} from "../models/organization-role.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetOrganizationRole(options?: QueryOrganizationRoleDto) {
    const url = ENDPOINTS.USER.ORGANIZATION_ROLE.GET;
    const request = (options: QueryOrganizationRoleDto) => API.UserAPI.getOrganizationRole(options);

	const { data, isLoading, error, refetch } = useQuery<OrganizationRolePaginator, Error>(
		[url, options],
		({ queryKey, pageParam }) =>
			request(Object.assign({}, queryKey[1], pageParam)),
		{...API.Handlers.pageParamOption}
	);

	return { data, isLoading, error, refetch };
}

export function useGetOrganizationRoleById(id: number) {
    const url = ENDPOINTS.USER.ORGANIZATION_ROLE.DETAIL
    const request = API.UserAPI.getOrganizationRoleById(id);

    const {data, isLoading, error} = useQuery<{ data:OrganizationRoleDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateOrganizationRole() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateOrganizationRoleDto}) => API.UserAPI.createOrganizationRole(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.USER.ORGANIZATION_ROLE.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateOrganizationRole() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateOrganizationRoleDto, id: string}) => API.UserAPI.updateOrganizationRole(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.USER.ORGANIZATION_ROLE.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

