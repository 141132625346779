import {INCOME_PERIOD, TaxRateDto} from "./tax-rate.dto";
import {TaxReliefType} from "./tax-relief-type.dto";
import {PaginatorInfo} from "./generic.dto";
import { Country } from "./country.dto";

export type TaxRelief = {
  id: number
  countryId: number
  rateComponent: number
  fixedComponent: number | null
  taxReliefTypeId: number
  incomePeriod: INCOME_PERIOD
  amountDerivedFrom: PayrollComponent
  createdAt: string
  modifiedAt: string | null
}

export enum PayrollComponent {
  BASIC = "BASIC",
  GROSS = "GROSS",
  TOTAL_CASH_EMOLUMENT = "TOTAL_CASH_EMOLUMENT",
  ASSESSABLE_INCOME = "ASSESSABLE_INCOME",
}

export class CreateTaxReliefDto {
  countryId!: number
  rateComponent!: number
  fixedComponent?: number
  taxReliefTypeId!: number
  incomePeriod!: INCOME_PERIOD
  amountDerivedFrom?: PayrollComponent
}

export interface TaxReliefDto extends TaxRelief {
  taxReliefType?: TaxReliefType;
  country?: Country
}

export interface TaxReliefPaginator extends PaginatorInfo<TaxReliefDto> {}

export class UpdateTaxReliefDto {
  rateComponent?: number
  fixedComponent?: number
  taxReliefTypeId?: number
  incomePeriod?: INCOME_PERIOD
  amountDerivedFrom?: PayrollComponent
}

export class QueryTaxReliefDto {
  countryId?: number;
  taxReliefTypeId?: number
  incomePeriod?: INCOME_PERIOD;
  amountDerivedFrom?: PayrollComponent
  page?: number = 1;
  limit?: number;
  orderBy?: TaxReliefOrderBy = TaxReliefOrderBy.CREATED_AT_ASC;
}

export enum TaxReliefOrderBy {
  CREATED_AT_ASC = 'createdAt:asc',
  CREATED_AT_DESC = 'createdAt:desc',
  MODIFIED_AT_ASC = 'modifiedAt:asc',
  MODIFIED_AT_DESC = 'modifiedAt:desc'
}