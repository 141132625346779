import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import API from './utils/api';
import {
    LeavePackageDto, LeavePackagePaginator,
    CreateLeavePackageDto, QueryLeavePackageDto,
    UpdateLeavePackageDto, SearchLeavePackageDto
} from '../models/leave-package.dto';
import ENDPOINTS from './utils/endpoints';

export function useGetLeavePackage(options?: QueryLeavePackageDto) {
    const url = ENDPOINTS.HR.LEAVE_PACKAGE.GET;
    const request = (options: QueryLeavePackageDto) => API.HrAPI.getLeavePackage(options);

    const { data, isLoading, error, refetch } = useQuery<LeavePackagePaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption},
    );

    return { data, isLoading, error, refetch };
}

export function useGetLeavePackageSearch() {

    return useMutation(
        (options?: SearchLeavePackageDto) => API.HrAPI.getLeavePackageSearch(options),
        {
            onSuccess: (response) => {
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetLeavePackageById(id: number){
    const url = ENDPOINTS.HR.LEAVE_PACKAGE.DETAIL;
    const request = API.HrAPI.getLeavePackageById(id);

    const { data, isLoading, error } = useQuery<{ data: LeavePackageDto }, Error>(
        [url, id],
        (() => request),
        { enabled: !!(id) },
    );
    
    return { data, isLoading, error };
}


export function useCreateLeavePackage() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload }: { payload: CreateLeavePackageDto }) => API.HrAPI.createLeavePackage(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.HR.LEAVE_PACKAGE.GET);
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useUpdateLeavePackage() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id, payload }: { id: number, payload: UpdateLeavePackageDto }) => API.HrAPI.updateLeavePackage(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.HR.LEAVE_PACKAGE.GET);
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useDeleteLeavePackage() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id }: { id: number }) => API.HrAPI.deleteLeavePackage(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
                queryClient.invalidateQueries(ENDPOINTS.HR.LEAVE_PACKAGE.GET);
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}