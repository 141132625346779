import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";
import CustomTab from "../../../components/custom_tab/CustomTab";
import PayrollCompanyLayout from "../../../components/layouts/PayrollCompanyLayout";
import EmployeesHistory from "./views/EmployeesHistory";
import useUserStore from "../../../state-management/useUserStore";
import HumanResourceLayout from "../../../components/layouts/HumanResourceLayout";

const Reports = () => {
	const { userType } = useUserStore();
	const [searchParams] = useSearchParams();
	const [view, setView] = useState(
		searchParams.get("view") || "employeesHistory"
	);
	
	return (<>
		{userType === "ORGANIZATION" ? (
			<PayrollCompanyLayout>
				<Helmet>
					<title>Reports | Company Flow</title>
				</Helmet>
				<h1 className="font-bold text-xl mb-8">Reports</h1>
				<CustomTab
					view={view}
					setView={setView}
					tabColumns={tabColumns}
				>
					{view === "employeesHistory" && <EmployeesHistory />}
				</CustomTab>
			</PayrollCompanyLayout>
		):(
			<HumanResourceLayout>
				<Helmet>
					<title>Reports | HR Flow</title>
				</Helmet>
				<h1 className="font-bold text-xl mb-8">Reports</h1>
				<CustomTab
					view={view}
					setView={setView}
					tabColumns={tabColumns}
				>
					{view === "employeesHistory" && <EmployeesHistory />}
				</CustomTab>
			</HumanResourceLayout>
		)}
	</>);
};

export default Reports;

const tabColumns = [
	{
		id: 1,
		view: "employeesHistory",
		name: "Employees History",
	},
];