import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, formatDateAndTime } from "../../../../../helpers/appHelpers";

export const Columns: GridColDef[] = [
	{
		field: "name",
		headerName: "Country",
		width: 240,
		headerClassName: "super-app-theme--header",
		renderCell: (params) => {
			return (
				<div className="w-full flex items-center gap-3">
					<img
						src={params.row.flagUrl}
						alt="flag"
						className="w-8 h-8 rounded-lg object-cover"
					/>
					<p className="text-sm line-clamp-1">{params.row.name}</p>
				</div>
			);
		},
	},
	{
		field: "code",
		headerName: "Country Code",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "currency",
		headerName: "Currency",
		width: 220,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.row.currency ? (params.row.currency?.name + " " + `(${params.row.currency.symbol})`) : "--",
	},
	{
		field: "active",
		headerName: "Active",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	{
		field: "applyLoanDeductionOnBasic",
		headerName: "Apply Loan Deduction On Basic",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	{
		field: "maxLoanDeductionPercentage",
		headerName: "Max Loan Deduction Percentage",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? params.value * 100 + "%" : "--",
	},
	
	...createdAtmodifiedAtActions
];
