import { useContext, useState, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";
import { PAY_PERIOD, STATUS } from "../../../../../models/employee.dto";
import { APP_CONFIG, compareDateFields, enumToStringConverter, validatePast } from "../../../../../helpers/appHelpers";
import APISelectField from "../../../../../components/form_fields/APISelectField";
import MoneyInput from "../../../../../components/form_fields/MoneyInput";
import { EmployeeFormContext } from "../../EmployeeFormContext";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import API from '../../../../../services/utils/api';
import { Notch } from "../../../../../models/notches.dto";
import { useAsyncEffect, useLockFn } from "ahooks";
import useUserStore from "../../../../../state-management/useUserStore";

interface IFormInputs {
	employeeNumber: string;
	startDate: string;
	jobTitleId: number;
	departmentId: number;
	stationId: number;
	divisionId: number;
	costAreaId?: number;
	status: STATUS;
	employmentDate: string;
	terminationDate: string;
	notchId?: number;
	payPeriod: PAY_PERIOD;
	basicPay: number;
	currencyId: number;
	hr?: string;
}

interface IEmploymentDetailsProps {
	formData: any;
	setFormData: React.Dispatch<React.SetStateAction<any>>;
	setView: (view: string) => void;
}

const EmploymentDetails = ({
	formData,
	setView,
	setFormData,
}: IEmploymentDetailsProps) => {
	const { userType } = useUserStore();
	const { currentCompany } = useCompanyStore();
	const {
		companyCurrencies,
		jobTitles,
		CostCenterData,
		DepartmentData,
		DivisionData,
		StationData,
	} = useContext(EmployeeFormContext);

	const {
		register,
		watch,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: formData
	});
	
	useEffect(() => {
		const { unsubscribe } = watch((values) => {
			setFormData({ ...formData, ...values });
		});
		return () => unsubscribe();
	}, [watch])

	const [jobTitleNotches, setJobTitleNotches] = useState<Notch[]>([]);
	const [jobTitleSelected, setJobTitleSelected] = useState(false);
	
	const handleJobTitleChange = async (id: string) => {
		if (!id) {
			setJobTitleNotches([]);
			setJobTitleSelected(false);
			return
		}
		
		const jobTitle = jobTitles.find(jobTitle => jobTitle.id === Number(id));

		try {
			const notches = await API.ProcessingCompanyAPI.getNotch({
				companyId: currentCompany?.id,
				limit: APP_CONFIG.PAGE_LIMIT,
				employeeBandId: jobTitle.employeeBandId, 
			})

			setJobTitleNotches(notches.data);
			setJobTitleSelected(true);
		} catch {}
	};

	useAsyncEffect(useLockFn(async () => {
		if (formData?.jobTitleId && !jobTitleNotches.length) {
			await handleJobTitleChange(formData.jobTitleId);
		}
	}), [])

	useEffect(() => {
		if (formData?.notchId && jobTitleNotches.length) {
			setValue("notchId", formData.notchId);
		}
	}, [jobTitleNotches])

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setFormData((prev: any) => ({ ...prev, data }));
		setView("paymentInfo");
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="flex space-x-4 mt-6">
				<div className="w-full">
					<Label title="Staff ID" for="employeeNumber" />
					<input
						placeholder={"Enter staff ID"}
						type="text"
						{...register("employeeNumber", {
							required: "Enter staff ID here",
						})}
						id="employeeNumber"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.employeeNumber
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.employeeNumber && (
						<p className="text-red-500 text-sm mt-1">
							{errors.employeeNumber.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Department" for="departmentId" optional />
					<APISelectField 
						key="departmentId"
						attributes={{
							id: "departmentId",
						}}
						placeholder="Select Department"
						register={register}
						name="departmentId"
						options={DepartmentData}
						pathname="/dashboard/company-settings?view=departments"
						required={false}
						error={errors.departmentId}
						valueAsNumber
						doNotLinkToResource={userType === "HR"}
					/>
				</div>
			</div>
			<div className="flex space-x-4 mt-6">
				<div className="w-full">
					<Label title="Station" for="stationId" optional />
					<APISelectField 
						key="stationId"
						attributes={{
							id: "stationId",
						}}
						placeholder="Select Station"
						register={register}
						name="stationId"
						options={StationData}
						pathname="/dashboard/company-settings?view=stations"
						required={false}
						error={errors.stationId}
						valueAsNumber
						doNotLinkToResource={userType === "HR"}
					/>
				</div>
				<div className="w-full">
					<Label title="Division" for="division" optional />
					<APISelectField 
						key="divisionId"
						attributes={{
							id: "divisionId",
						}}
						placeholder="Select Division"
						register={register}
						name="divisionId"
						options={DivisionData}
						pathname="/dashboard/company-settings?view=divisions"
						required={false}
						error={errors.divisionId}
						valueAsNumber
						doNotLinkToResource={userType === "HR"}
					/>
				</div>
			</div>
			<div className="flex space-x-4 mt-6">
				<div className="w-[50%]">
					<Label title="Cost Centre" for="costCentre" optional />
					<APISelectField 
						key="costAreaId"
						attributes={{
							id: "costAreaId",
						}}
						placeholder="Select Cost Centre"
						register={register}
						name="costAreaId"
						options={CostCenterData}
						pathname="/dashboard/company-settings?view=costCentres"
						required={false}
						error={errors.costAreaId}
						valueAsNumber
						doNotLinkToResource={userType === "HR"}
					/>
				</div>
				<div className="w-[50%]">
					<Label title="Employment Status" for="status" />
					<select
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.status
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
						{...register("status", {
							required: "Select contract status here",
						})}
					>
						<option value="">Select Option</option>
						{Object.entries(STATUS).map(([key, value]) => (
							<option 
								key={key} 
								value={value}
							>
								{enumToStringConverter(value)}
							</option>
						))}
					</select>
					{errors.status && (
						<p className="text-red-500 text-sm mt-1">{errors.status.message}</p>
					)}
				</div>
			</div>
			<div className="flex space-x-4 mt-6">
				<div className="w-full">
					<Label title="Employment Date" for="employmentDate" />
					<input
						placeholder={"mm/dd/yyyy"}
						type="date"
						{...register("employmentDate", {
							required: "Enter contract start date here",
							validate: validatePast,
						})}
						id="employmentDate"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.employmentDate
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.employmentDate && (
						<p className="text-red-500 text-sm mt-1">
							{errors.employmentDate.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Termination Date" for="terminationDate" optional />
					<input
						placeholder={"mm/dd/yyyy"}
						type="date"
						{...register("terminationDate", {
							required: formData?.status === STATUS.TERMINATED ? "Enter termination date here" : false,
							validate: (value) => {
								const employmentDate = compareDateFields(
									watch("employmentDate"), 
									value,
									"Termination date must be greater than employment date"
								);

								if (employmentDate === true) {
									return validatePast(value);
								}

								return employmentDate;
							},
						})}
						id="terminationDate"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
							errors.terminationDate
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.terminationDate && (
						<p className="text-red-500 text-sm mt-1">
							{errors.terminationDate.message}
						</p>
					)}
				</div>
			</div>
			<div className="flex mt-6 gap-4">
				<div className="w-full">
					<Label title="Job Title" for="jobTitleId" optional />
					<APISelectField 
						key="jobTitleId"
						attributes={{
							id: "jobTitleId",
							onInput: (e: any) => handleJobTitleChange(e.target.value)
						}}
						placeholder="Select Job Title"
						register={register}
						name="jobTitleId"
						options={jobTitles}
						pathname="/dashboard/company-settings?view=jobTitle"
						required={false}
						error={errors.jobTitleId}
						valueAsNumber
						doNotLinkToResource={userType === "HR"}
					/>
				</div>
				<div className="w-full">
					<Label title="Notch" for="notchId" optional />
					<select
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm border-none`}
						{...register("notchId", {
							required: false,
							valueAsNumber: true,
						})}
						disabled={!jobTitleSelected}
					>
						<option value="">Select Notch</option>
						{jobTitleNotches.map((item) => (
							<option key={item?.id} value={item?.id}>
								{item?.code}
							</option>
						))}
					</select>
				</div>
			</div>
			<div className="grid grid-cols-2 gap-x-4 mt-6">
				<div className="w-full">
					<Label title="Basic Monthly Rate" for="basicPay" />
					<div className="w-full flex items-center">
						<MoneyInput 
							attributes={{
								id: "basicPay",
								placeholder: "0"
							}}
							register={register}
							name="basicPay"
							setValue={setValue}
							defaultValue={formData?.basicPay}
							required="Enter basic pay here"
							error={errors.basicPay}
						/>
						<select
							{...register("currencyId", {
								required: "Select currency",
								valueAsNumber: true,
							})}
							className="w-[120px] dark"
						>
							<option value="">Select</option>
							{companyCurrencies?.map((item) => (
								<option key={item?.id} value={item?.id}>
									{item?.currency?.name} ({item?.currency?.code})
								</option>
							))}
						</select>
					</div>
					{(errors.basicPay || errors.currencyId) && (
						<p className="text-red-500 text-sm mt-1">
							{errors.basicPay?.message || errors.currencyId?.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Payment Period" for="payPeriod" />
					<select
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.payPeriod
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
						{...register("payPeriod", {
							required: "Please select an option here",
						})}
					>
						<option value="">Select Payment Period</option>
						<option value="HOUR">Hour</option>
						<option value="DAY">Day</option>
						<option value="WEEK">Week</option>
						<option value="MONTH">Month</option>
						<option value="QUARTER">Quarter</option>
						<option value="YEAR">Year</option>
					</select>
					{errors.payPeriod && (
						<p className="text-red-500 text-sm mt-1">
							{errors.payPeriod.message}
						</p>
					)}
				</div>
			</div>
			<div className="flex space-x-4 text-sm mt-6">
				<h3>Human Resource Personnel?</h3>
				<div className="flex space-x-3 items-center">
					<input
						{...register("hr", {
							required: false,
						})}
						type="radio"
						value="yes"
					/>
					<Label for={"yes"} title={"Yes"} />
				</div>
				<div className="flex space-x-3 items-center">
					<input
						{...register("hr", {
							required: false,
						})}
						type="radio"
						value="no"
					/>
					<Label for={"no"} title={"No"} />
				</div>
			</div>

			<div className="w-full flex items-center justify-end gap-4 mt-10">
				<button
					type="button"
					onClick={() => setView("personalDetails")}
					className="h-14 px-8 border border-gray-300 rounded-lg text-sm text-gray-600"
				>
					Go Back
				</button>
				<button type="submit" className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm">
					Continue
				</button>
			</div>
		</form>
	);
};

export default EmploymentDetails;
