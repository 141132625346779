import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import { 
    CreateLoanDto,
    LoanDto,
    LoanPaginator,
    QueryLoanDto,
    UpdateLoanDto
} from "../models/loan.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetLoan(options?: QueryLoanDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.LOAN.GET;
    const request = (options: QueryLoanDto) => API.ProcessingCompanyAPI.getLoan(options);

	const { data, isLoading, error, refetch } = useQuery<LoanPaginator, Error>(
		[url, options],
		({ queryKey, pageParam }) =>
			request(Object.assign({}, queryKey[1], pageParam)),
		{...API.Handlers.pageParamOption}
	);

	return { data, isLoading, error, refetch };
}


export function useGetLoanById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.LOAN.DETAIL;
    const request = API.ProcessingCompanyAPI.getLoanById(id);

    const {data, isLoading, error} = useQuery<{ data: LoanDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateLoan() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateLoanDto}) => API.ProcessingCompanyAPI.createLoan(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.LOAN.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateLoan() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateLoanDto, id: number}) => API.ProcessingCompanyAPI.updateLoan(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.LOAN.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useDeleteLoan() {

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteLoan(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}