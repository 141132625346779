import {PaginatorInfo} from "./generic.dto";
import {CostCenterDto} from "./cost-center.dto";
import { EmployeeBand } from "./employee-band.dto";
import { CompanyCurrencyConfigDto } from "./company-currency.dto";
import { PayPeriodDto } from "./pay-period.dto";

export const ALLOWANCE_FORMULA_TYPE = {
    FLAT: 'FLAT',
    PERCENT: 'PERCENT',
    HYBRID: 'HYBRID'
};

export type ALLOWANCE_FORMULA_TYPE = (typeof ALLOWANCE_FORMULA_TYPE)[keyof typeof ALLOWANCE_FORMULA_TYPE]

export const EMPLOYMENT_PERIOD_UNIT = {
    DAY: 'DAY',
    MONTH: 'MONTH',
    YEAR: 'YEAR'
};

export type EMPLOYMENT_PERIOD_UNIT = (typeof EMPLOYMENT_PERIOD_UNIT)[keyof typeof EMPLOYMENT_PERIOD_UNIT]

export const PAYMENT_FREQUENCY = {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    FORTNIGHTLY: 'FORTNIGHTLY',
    MONTHLY: 'MONTHLY',
    QUARTERLY: 'QUARTERLY',
    BIANNUALLY: 'BIANNUALLY',
    ANNUALLY: 'ANNUALLY',
    PRESET: 'PRESET'
};

enum AllowanceApplicableValuePeriod {
    MONTH,
    YEAR,
    QUARTER
}

export type PAYMENT_FREQUENCY = (typeof PAYMENT_FREQUENCY)[keyof typeof PAYMENT_FREQUENCY]


export type Allowance = {
    id: number
    companyId: number
    code: string
    name: string
    description: string
    formulaType: ALLOWANCE_FORMULA_TYPE
    fixedComponent: number
    rateComponent: number
    prorate: boolean
    minEmploymentPeriod: number
    employmentPeriodUnit: EMPLOYMENT_PERIOD_UNIT
    paymentFrequency: PAYMENT_FREQUENCY
    paymentInstance: number
    minValue: number
    maxValue: number
    active: boolean
    applicableValuePeriod: AllowanceApplicableValuePeriod
    createdAt: string
    modifiedAt: string | null
}
export class CreateAllowanceDto {
    companyId!: number;
    code!: string;
    name!: string;
    description!: string;
    formulaType!: ALLOWANCE_FORMULA_TYPE;
    fixedComponent!: number;
    rateComponent!: number;
    prorate!: boolean;
    minEmploymentPeriod?: number;
    employmentPeriodUnit?: EMPLOYMENT_PERIOD_UNIT;
    paymentFrequency!: PAYMENT_FREQUENCY;
    paymentInstance!: number;
    minValue!: number;
    maxValue!: number;
    active!: boolean  |  undefined;
    currencyId?: number;
    employeeBandIds?: number[]
    applicableValuePeriod!: AllowanceApplicableValuePeriod;
    payPeriodIds?: number[];
}


export class UpdateAllowanceDto {
    companyId?: number;
    code?: string;
    name?: string;
    description?: string;
    formulaType?: ALLOWANCE_FORMULA_TYPE;
    fixedComponent?: number;
    rateComponent?: number;
    prorate?: boolean;
    minEmploymentPeriod?: number;
    employmentPeriodUnit?: EMPLOYMENT_PERIOD_UNIT;
    paymentFrequency?: PAYMENT_FREQUENCY;
    paymentInstance?: number;
    minValue?: number;
    maxValue?: number;
    active?: boolean;
    currencyId?: number;
    applicableValuePeriod?: AllowanceApplicableValuePeriod;
    addPayPeriodIds?: number[];
    removePayPeriodIds?: number[];
}

export interface AllowanceDto extends Allowance {
    employeeBandAllowances?: EmployeeBandAllowanceDto[];
    companyCurrency?: CompanyCurrencyConfigDto,
    payPeriods?: PayPeriodDto[];
}

export interface EmployeeBandAllowanceDto extends EmployeeBandAllowance {
    employeeBand?: EmployeeBand;
}


type EmployeeBandAllowance = {
    id: number;
    employeeBandId: number;
    allowanceId: number;
    active: boolean;
    createdAt: string;
    modifiedAt: string | null;
}

export class QueryAllowanceDto {
    companyId?: number;
    formulaType?: ALLOWANCE_FORMULA_TYPE;
    prorate?: boolean;
    paymentFrequency?: PAYMENT_FREQUENCY;
    active?: boolean;
    page?: number = 1;
    limit?: number;
    orderBy?: string
}

export interface AllowancePaginator extends PaginatorInfo<AllowanceDto> {}

export enum AllowanceOrderBy {
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
    MODIFIED_AT_ASC = 'modifiedAt:asc',
    MODIFIED_AT_DESC = 'modifiedAt:desc',
    CODE_ASC = 'code:asc',
    CODE_DESC = 'code:desc',
    NAME_ASC = 'name:asc',
    NAME_DESC = 'name:desc',
    MIN_VALUE_ASC = 'minValue:asc',
    MIN_VALUE_DESC = 'minValue:desc',
    MAX_VALUE_ASC = 'maxValue:asc',
    MAX_VALUE_DESC = 'maxValue:desc',
  }

export class SearchAllownaceDto {
    q?: string
    page: number = 1;
    limit?: number;
    orderBy: AllowanceOrderBy = AllowanceOrderBy.CREATED_AT_ASC;
  }

  export type AllowanceSummary = Pick<Allowance, 'id' | 'code' | 'name'>;